import React, {useState} from 'react'
import ProjectCreateFlow from '../../../flows/ProjectCreateFlow'
import {localise} from '../../../../services/LocalizationServices'
import Buttons from '../common/Buttons'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectId} from '../../../../store/selectors/projectSelectors'
import {setProject} from '../../../../store/slices/projectSlice'
import {ADDRESS_URL, NEW_URL, PROJECTS_URL} from '../../../../constants/urls'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {updateProject} from '../../../../api/project/project'
import FundingForm from '../../form/FundingForm'
import ProjectCreateSuccessModal from '../../../../components/modals/ProjectCreateSuccessModal'
import {setCurrentUser} from '../../../../store/slices/currentUserSlice'
import {getCurrentUserPaymentsOverview} from '../../../../store/selectors/currentUserSelectors'
import {fetchUser} from '../../../../api/user/user'

const Funding = () => {
  const {push} = useHistory()
  const dispatch = useDispatch()

  const [show, setShow] = useState(false)

  const projectId = useSelector(getProjectId)
  const userPaymentsOverview = useSelector(getCurrentUserPaymentsOverview)

  const handleSubmit = values => {
    toggleLoading()

    if (userPaymentsOverview?.subscription === null && userPaymentsOverview?.unused_purchases?.length !== 0) {
      const unusedPurchase = userPaymentsOverview.unused_purchases?.at(0)
      values['purchase_id'] = unusedPurchase.id
    }

    updateProject(projectId, values)
      .then(project => {
        dispatch(setProject(project))
        dispatch(setCurrentUser({current_draft_project_id: null}))
        setShow(true)
      }).then(fetchUser)
      .then(user => dispatch(setCurrentUser(user)))
      .finally(toggleLoading)
  }

  const toProjects = () => push(PROJECTS_URL)
  const toProject = () => push(`${PROJECTS_URL}/${projectId}`)
  const toAddress = () => push(`${PROJECTS_URL}${NEW_URL}${ADDRESS_URL}`)

  return (
    <>
      <ProjectCreateFlow activeStep={2} title={localise('headings.projectFunded')}>
        <FundingForm
          onSubmit={handleSubmit}
          buttons={(
            <Buttons
              onCancelClicked={toProjects}
              onPreviousClicked={toAddress}
            />
          )}
        />
      </ProjectCreateFlow>
      <ProjectCreateSuccessModal
        show={show}
        toggle={toProject}
        onConfirm={toProject}
      />
    </>
  )
}
export default Funding
import {apiClient, GRANT_TYPE_CLIENT_CREDENTIALS, oauthClient} from '../client'
import {clearAuth, getToken, setAuth} from '../../utilities/auth-helpers/authHelpers'

export const fetchConfiguration = async () => {
  const bearerToken = getToken()

  if (bearerToken) {
    const {configuration} = await apiClient.get('configurations/generic')
    return configuration
  } else {
    const {token} = await oauthClient.post('token', {grant_type : GRANT_TYPE_CLIENT_CREDENTIALS})
    setAuth(token, true)
    const {configuration} = await apiClient.get('configurations/generic')
    clearAuth()
    return configuration
  }
}

import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import {RESET_PASSWORD_CONFIRMATION_URL, RESET_PASSWORD_URL, SIGN_IN_URL, SIGN_UP_URL} from '../constants/urls'
import Login from '../views/auth/Login'
import AuthLayout from './layouts/AuthLayout'
import Register from '../views/auth/Register'
import Reset from '../views/auth/Reset'
import ResetConfirmation from '../views/auth/ResetConfirmation'
import PageNotFound from '../views/error/PageNotFound'

const AuthRoutes = ({path}) => {
  const commonProps = {
    layout: AuthLayout,
    requiresAuth: false,
  }

  return (
    <Switch>
      <Route exact path={path}><Redirect to={`${path}${SIGN_IN_URL}`} /></Route>
      <RouteWithLayout exact path={`${path}${SIGN_IN_URL}`} component={Login} {...commonProps} />
      <RouteWithLayout exact path={`${path}${SIGN_UP_URL}`} component={Register} {...commonProps} />
      <RouteWithLayout exact path={`${path}${RESET_PASSWORD_URL}`} component={Reset} {...commonProps} />
      <RouteWithLayout
        exact
        path={`${path}${RESET_PASSWORD_CONFIRMATION_URL}`}
        component={ResetConfirmation}
        {...commonProps}
      />
      <Route path='*'><PageNotFound /></Route>
    </Switch>
  )
}

export default AuthRoutes
import React, {useState} from 'react'
import GoogleMapReact from 'google-map-react'
import {arrayOf, func, node, number, shape, string} from 'prop-types'
import MapMarker from './MapMarker'
import {v4} from 'uuid'
import {REACT_APP_GOOGLE_API_KEY} from '../../constants/env'

const propTypes = {
  className: string,
  center: shape({
    lat: number,
    lng: number,
  }),
  defaultCenter: shape({
    lat: number,
    lng: number,
  }),
  defaultZoom: number,
  height: string,
  markers: arrayOf(shape({
    className: string,
    icon: string,
    lat: number,
    lng: number,
    onClick: func,
    overlay: node,
  })),
  width: string,
}

const defaultProps = {
  className: '',
  center: {
    lat: 52.79492245641029,
    lng: -6.082953681808775,
  },
  defaultCenter: {
    lat: null,
    lng: null,
  },
  defaultZoom: 8,
  height: '86vh',
  markers: null,
  width: '100vw',
}

const Map = ({className, height, markers, width, ...rest}) => {
  const [selectedCoordinates, setSelectedCoordinates] = useState({lat: null, lng: null})

  const isSelectedCoordinates = (lat, lng) => selectedCoordinates?.lat === lat && selectedCoordinates?.lng === lng

  return (
    <div className={`${className} map-container`} style={{height, width}}>
      <div className='overlay w-100 ms-3'>
        {markers?.filter(({lat, lng}) => isSelectedCoordinates(lat, lng))[0]?.overlay}
      </div>
      <GoogleMapReact
        bootstrapURLKeys={{key: REACT_APP_GOOGLE_API_KEY}}
        options={{
          styles: [{
            featureType: 'all',
            elementType: 'all',
            stylers: [
              {saturation: -100},
            ],
          }],
        }}
        {...rest}>
        {
          markers?.map(({onClick, lat, lng, ...rest}) =>
            <MapMarker
              key={v4()}
              lat={lat}
              lng={lng}
              onClick={({lat, lng}) => {
                if (onClick) onClick({lat, lng})
                setSelectedCoordinates({lat, lng})
              }}
              selected={isSelectedCoordinates(lat, lng)}
              {...rest}
            />
          )
        }
      </GoogleMapReact>
    </div>
  )
}

Map.propTypes = propTypes
Map.defaultProps = defaultProps

export default Map

import React, {useEffect, useState} from 'react'
import {localise} from '../../services/LocalizationServices'
import {Button} from 'react-bootstrap'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import Form from '../../components/forms/Form'
import {fetchUnitsOfMeasurement} from '../../api/units-of-measurement/unitsOfMeasurement'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import useNav from '../../hooks/useNav'
import SideNavView from '../common/SideNavView'
import {fetchUserPreference, updateUserPreference} from '../../api/user-preference/userPreference'
import {toast} from 'react-hot-toast'
const DROPDOWN_ID_SELECT = 'dropdown-id-select'

const Preferences = () => {
  const {SIDE_NAV_ACCOUNT} = useNav()
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState({})

  useEffect(() => {
    toggleLoading()
    fetchUnitsOfMeasurement()
      .then(({units_of_measurement}) =>
        setOptions(units_of_measurement.map(({id: value, name: label}) => ({label, value}))))
      .finally(toggleLoading)
  }, [])

  useEffect(() => {
    toggleLoading()
    fetchUserPreference().then()
      .then(({unit_of_measurement}) =>
        setSelectedOption(
          unit_of_measurement
            ? {value: unit_of_measurement?.id, label: unit_of_measurement?.name}
            : null
        ))
      .finally(toggleLoading)
  }, [])

  const FORM_DATA = [
    {
      type: 'dropdown',
      classNamePrefix: DROPDOWN_ID_SELECT,
      id: DROPDOWN_ID_SELECT,
      handler: ({label, value}) => setSelectedOption({label, value}),
      label: localise('form.label.selectUnitOfMeasure'),
      menuPortalTarget: document.body,
      name: 'dropdown-unit-measure',
      options,
      placeholder: localise('form.label.selectUnitOfMeasure'),
      value: selectedOption,
    },
  ]

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      toggleLoading()
      updateUserPreference({unit_of_measurement_id: selectedOption.value})
        .then(({unit_of_measurement}) => {
          toast.success(localise('toast.successfullyUpdatedPreferences'))
          setSelectedOption({label: unit_of_measurement?.name, value: unit_of_measurement?.id})
        })
        .finally(toggleLoading)
    }
  }

  return (
    <SideNavView navList={SIDE_NAV_ACCOUNT}>
      <div className='font-bold font-20 mb-2'>{localise('nav.myPreferences')}</div>
      <Form noValidate onSubmit={handleSubmit}>
        {buildForm(FORM_DATA)}
        <Button
          variant='primary'
          className='w-100 mt-2 font-15'
          disabled={selectedOption?.value === null || selectedOption?.value === undefined}
          type='submit'>
          {localise('buttonText.update')}
        </Button>
      </Form>
    </SideNavView>
  )
}

export default Preferences

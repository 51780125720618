import React from 'react'
import {bool, number, object, shape, string} from 'prop-types'
import LogEntryCard from './LogEntryCard'
import {Col} from 'react-bootstrap'
import {localise} from '../../../services/LocalizationServices'

const propTypes = {
  log: shape({
    active: bool,
    created_at: number,
    id: string,
    man_hours: number,
    productivity_score: number,
    value_of_works_cents: object,
  }),
}

const defaultProps = {
  log: {},
}

const ProductivityLog = ({log, ...rest}) => {
  const section = ({body, bodyClasses, title}) =>
    <>
      <div className='font-10'>{title}</div>
      <div className={`font-17 ${bodyClasses}`}>
        {body}
      </div>
    </>

  return (
    <LogEntryCard {...rest}>
      {
        <>
          <Col>
            {
              section({
                title: localise('headings.valueOfWorksToDate'),
                body: log?.value_of_works_cents?.display,
              })
            }
          </Col>
          <Col>
            {
              section({
                title: localise('headings.manHoursToDate'),
                body: log?.man_hours,
              })
            }
          </Col>
          <Col>
            {
              section({
                title: localise('headings.productivity'),
                body: log?.productivity_score,
                bodyClasses: 'font-bold',
              })
            }
          </Col>
        </>
      }
    </LogEntryCard>
  )
}

ProductivityLog.propTypes = propTypes
ProductivityLog.defaultProps = defaultProps

export default ProductivityLog

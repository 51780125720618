import React, {useState} from 'react'
import BuildingFlow from '../../flows/BuildingFlow'
import {BUILDINGS_URL, COMPONENTS_URL, EDIT_URL, PROJECTS_URL} from '../../../constants/urls'
import {localise} from '../../../services/LocalizationServices'
import {useHistory, useParams} from 'react-router-dom'
import {toggleLoading} from '../../../utilities/LoadingIndicatorUtil'
import {useSelector} from 'react-redux'
import {getProjectTitle} from '../../../store/selectors/projectSelectors'
import Accordion from '../../../components/accordion/Accordion'
import {BUILDING_ICON} from '../../../constants/svgs'
import DetailsForm from './form/DetailsForm'
import {
  createBuilding,
  fetchBuilding,
  updateBuilding,
  updateBuildingUnitBreakdown,
} from '../../../api/building/building'
import {getBuildingId} from '../../../store/selectors/buildingSelectors'
import UnitsBedrooms from './form/UnitsBedrooms'
import AreaForm from './form/AreaForm'
import useBuilding from '../../../hooks/useBuilding'
import useValidations from '../../../hooks/useValidations'
import WarningModal from '../../../components/modals/WarningModal'

const SECTION_ONE = 'details'
const SECTION_TWO = 'units'
const SECTION_THREE = 'area'

const Setup = () => {
  const {projectSlug} = useParams()
  const {
    showValidationErrors,
    validateBuildingInternalArea,
    validateHouseInternalArea,
    validateUnitCount,
  } = useValidations()

  const {isHouse, setBuildingRedux} = useBuilding({
    onBuildingChanged: building => {
      setBuildingName(building?.name)
      setSectionOneComplete(building?.setup?.sectionOneComplete)
      setSectionTwoComplete(building?.setup?.sectionTwoComplete)
      setSectionThreeComplete(building?.setup?.sectionThreeComplete)
      setActiveKey(
        building?.setup?.sectionOneComplete
          ? building?.setup?.sectionTwoComplete
            ? building?.setup?.sectionThreeComplete
              ? SECTION_ONE
              : SECTION_THREE
            : SECTION_TWO
          : SECTION_ONE
      )
    },
    suppressCalculatorFetches: true,
  })
  const {push} = useHistory()

  const [activeKey, setActiveKey] = useState(null)
  const [sectionOneComplete, setSectionOneComplete] = useState(false)
  const [sectionTwoComplete, setSectionTwoComplete] = useState(false)
  const [sectionThreeComplete, setSectionThreeComplete] = useState(false)
  const [onConfirm, setOnConfirm] = useState(null)
  const [show, setShow] = useState(false)
  const [warnings, setWarnings] = useState([])
  const [buildingName, setBuildingName] = useState('')

  const buildingId = useSelector(getBuildingId)
  const title = useSelector(getProjectTitle)

  const handleDetailsForm = payload => {
    const success = building => {
      setBuildingRedux(building)
      setSectionOneComplete(true)
      setActiveKey(SECTION_TWO)
    }

    toggleLoading()
    buildingId
      ? updateBuilding(projectSlug, buildingId, payload).then(success).finally(toggleLoading)
      : createBuilding(projectSlug, payload).then(success).finally(toggleLoading)
  }

  const handleUnitsBedrooms = payload => {
    toggleLoading()
    updateBuildingUnitBreakdown(projectSlug, buildingId, payload)
      .then(() => {
        fetchBuilding(projectSlug, buildingId).then(setBuildingRedux).then(() => {
          setSectionTwoComplete(true)
          setActiveKey(SECTION_THREE)
        })
      })
      .finally(toggleLoading)
  }

  const handleHouseSubmit = payload => {
    toggleLoading()
    updateBuilding(projectSlug, buildingId, payload)
      .then(building => {
        setBuildingRedux(building)
        setSectionTwoComplete(true)
        setActiveKey(SECTION_THREE)
      })
      .finally(toggleLoading)
  }

  const handleAreaForm = payload => {
    toggleLoading()
    updateBuilding(projectSlug, buildingId, payload)
      .then(_ => {
        setSectionThreeComplete(true)
        push(`${PROJECTS_URL}/${projectSlug}${BUILDINGS_URL}/${buildingId}${EDIT_URL}${COMPONENTS_URL}`)
      })
      .finally(toggleLoading)
  }

  const handleValidation = (validation, payload, confirmCallback) => {
    const {warnings, errors} = validation

    setOnConfirm(null)
    if (errors?.length > 0) {
      showValidationErrors(errors)
    } else if (warnings?.length > 0) {
      setWarnings(warnings)
      setShow(true)
      setOnConfirm(() => () => {
        setShow(false)
        confirmCallback(payload)
      })
    } else {
      confirmCallback(payload)
    }
  }

  const doUnitsBedroomsValidations = payload => {
    toggleLoading()
    validateUnitCount(payload?.building_unit_breakdown?.unit_count)
      .then(validation => handleValidation(validation, payload, handleUnitsBedrooms))
      .finally(toggleLoading)
  }

  const doAreaFormValidation = payload => {
    if (isHouse()) {
      toggleLoading()
      validateHouseInternalArea(payload?.house_gia_nia)
        .then(validation => handleValidation(validation, payload, handleAreaForm))
        .finally(toggleLoading)
    } else {
      toggleLoading()
      validateBuildingInternalArea(payload?.gross_internal_area, payload?.net_internal_area)
        .then(validation => handleValidation(validation, payload, handleAreaForm))
        .finally(toggleLoading)
    }
  }

  const sections = [
    {
      eventKey: SECTION_ONE,
      body: <DetailsForm onSubmit={handleDetailsForm} />,
      complete: sectionOneComplete,
      onClick: () => setActiveKey(SECTION_ONE),
      title: localise('accordion.details'),
    },
    {
      eventKey: SECTION_TWO,
      body: <UnitsBedrooms onSubmit={doUnitsBedroomsValidations} onHouseSubmit={handleHouseSubmit} />,
      complete: sectionTwoComplete,
      disabled: !sectionOneComplete,
      onClick: () => setActiveKey(SECTION_TWO),
      title: localise('accordion.unitsBedrooms'),
    },
    {
      eventKey: SECTION_THREE,
      body: <AreaForm onSubmit={doAreaFormValidation} />,
      complete: sectionThreeComplete,
      disabled: !sectionTwoComplete,
      onClick: () => setActiveKey(SECTION_THREE),
      title: localise('accordion.area'),
    },
  ]

  return (
    <>
      <BuildingFlow
        breadcrumbs={[
          {
            active: false,
            href: `${PROJECTS_URL}/${projectSlug}`,
            text: title,
          },
          ...(
            buildingName
              ? [{
                active: false,
                href: `${PROJECTS_URL}/${projectSlug}${BUILDINGS_URL}/${buildingId}`,
                text: buildingName,
              }]
              : []
          ),
          {
            active: true,
            text: localise('nav.buildingSetup'),
          },
        ]}>
        <Accordion
          active
          activeKey={activeKey || SECTION_ONE}
          icon={BUILDING_ICON}
          sections={sections}
          title={localise('nav.buildingSetup')}
        />
      </BuildingFlow>
      <WarningModal
        show={show}
        onCancel={() => setShow(false)}
        onConfirm={onConfirm}
        warnings={warnings}
      />
    </>
  )
}

export default Setup

import {CURRENCY_SYMBOLS} from '../../../constants/currency'

const TOOLTIP_CONTAINER_ID = 'tooltip-container'

const getOrCreateTooltip = chart => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')

    tooltipEl.style.background = 'rgba(0, 0, 0, 0.5)'
    tooltipEl.style.borderRadius = '3px'
    tooltipEl.style.color = 'white'
    tooltipEl.style.opacity = 1
    tooltipEl.style.zIndex = 100
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.transform = 'translate(-50%, 0)'
    tooltipEl.style.transition = 'all .1s ease'

    const toolTipContainer = document.createElement('div')
    toolTipContainer.id = TOOLTIP_CONTAINER_ID
    toolTipContainer.style.margin = '0px'
    toolTipContainer.style.lineHeight = '20px'

    tooltipEl.appendChild(toolTipContainer)
    chart.canvas.parentNode.appendChild(tooltipEl)
  }

  return tooltipEl
}

export const externalTooltipHandler = (context, opts) => {
  let unitSymbol = opts?.unitSymbol
  if (!unitSymbol) unitSymbol = '%'

  // Tooltip Element
  const {chart, tooltip} = context
  const tooltipEl = getOrCreateTooltip(chart)

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0
    return
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map(b => b.lines)

    const container = document.createElement('div')
    bodyLines.forEach((body, i) => {
      const colors = tooltip.labelColors[i]

      const span = document.createElement('span')
      span.style.background = colors.backgroundColor
      span.style.borderColor = colors.borderColor
      span.style.borderWidth = '2px'
      span.style.marginRight = '10px'
      span.style.height = '10px'
      span.style.width = '10px'
      span.style.display = 'inline-block'

      const textContainer = document.createElement('div')
      let textNode = `${body}${unitSymbol}`
      if (CURRENCY_SYMBOLS.includes(unitSymbol)) {
        const amountCents = context?.tooltip?.dataPoints[i]?.raw
        textNode = `${unitSymbol}${Number(amountCents / 100)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
      }

      const text = document.createTextNode(textNode)

      textContainer.className = 'd-flex align-items-center'
      textContainer.appendChild(span)
      textContainer.appendChild(text)
      container.appendChild(textContainer)
    })

    const tooltipContainerRoot = tooltipEl.querySelector(`#${TOOLTIP_CONTAINER_ID}`)

    // Remove old children
    while (tooltipContainerRoot.firstChild) {
      tooltipContainerRoot.firstChild.remove()
    }

    // Add new children
    // tooltipContainerRoot.appendChild(tableHead)
    tooltipContainerRoot.appendChild(container)
  }

  const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`
  tooltipEl.style.top = `${positionY + tooltip.caretY}px`
  tooltipEl.style.font = tooltip.options.bodyFont.string
  tooltipEl.style.padding = `${tooltip.options.padding}px ${tooltip.options.padding}px`
}

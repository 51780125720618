import React from 'react'
import {string} from 'prop-types'
import {PROJECT_SETUP_PLACEHOLDER} from '../../constants/svgs'
import {localise} from '../../services/LocalizationServices'
import Placeholder from './Placeholder'

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

const ProjectPlaceholder = ({className}) =>
  <Placeholder
    className={className}
    description={localise('placeholder.noProjectsCreatedDesc')}
    src={PROJECT_SETUP_PLACEHOLDER}
    title={localise('placeholder.noProjectsCreated')}
  />

ProjectPlaceholder.propTypes = propTypes
ProjectPlaceholder.defaultProps = defaultProps

export default ProjectPlaceholder

import React, {useEffect, useState} from 'react'
import {func} from 'prop-types'
import {localise} from '../../../../services/LocalizationServices'
import {useDispatch, useSelector} from 'react-redux'
import {
  getBuildingName,
  getBuildingSectorId,
  getBuildingSectorName,
  getBuildingSectorSlug,
  getBuildingTypeId,
  getBuildingTypeName,
} from '../../../../store/selectors/buildingSelectors'
import {setBuilding} from '../../../../store/slices/buildingSlice'
import {fetchBuildingSectors} from '../../../../api/building-sectors/buildingSectors'
import {fetchBuildingTypes} from '../../../../api/building-types/buildingTypes'
import ConfirmForm from '../../../../components/forms/ConfirmForm'

const propTypes = {
  onSubmit: func,
}

const defaultProps = {
  onSubmit: () => {},
}

const DetailsForm = ({onSubmit}) => {
  const dispatch = useDispatch()

  const [sectorLoading, setSectorLoading] = useState(false)
  const [sectors, setSectors] = useState([])
  const [typeLoading, setTypeLoading] = useState(false)
  const [types, setTypes] = useState([])

  const name = useSelector(getBuildingName) || ''
  const sectorId = useSelector(getBuildingSectorId)
  const sectorName = useSelector(getBuildingSectorName)
  const sectorSlug = useSelector(getBuildingSectorSlug)
  const typeId = useSelector(getBuildingTypeId)
  const typeName = useSelector(getBuildingTypeName)

  useEffect(() => {
    setSectorLoading(true)

    fetchBuildingSectors()
      .then(({building_sectors}) =>
        setSectors(building_sectors.map(({id: value, name: label, slug}) => ({value, label, slug}))))
      .finally(() => setSectorLoading(false))
    // eslint-disable-next-line
  },[])

  useEffect(() => {
    setTypeLoading(true)

    fetchBuildingTypes(sectorSlug)
      .then(({building_types}) => setTypes(building_types.map(({id: value, name: label}) => ({value, label}))))
      .finally(() => setTypeLoading(false))
  }, [dispatch, sectorSlug])

  const fields = [
    {
      type: 'text',
      name: 'line1',
      handler: name => dispatch(setBuilding({name})),
      label: localise('form.label.buildingTitle'),
      required: true,
      validationMessage: localise('form.validation.buildingTitle'),
      value: name,
    },
    {
      type: 'dropdown',
      name: 'sector',
      handler: ({value: id, label: name, slug}) => {
        dispatch(setBuilding(
          {
            building_sector: {id, name, slug},
            building_type: {},
          }
        ))
      },
      label: localise('form.label.sector'),
      loading: sectorLoading,
      menuPortalTarget: document.body,
      options: sectors,
      placeholder: localise('form.placeholder.sector'),
      required: true,
      validationMessage: localise('form.validation.sector'),
      value: sectorId && sectorName ? {value: sectorId, label: sectorName} : null,
    },
    {
      type: 'dropdown',
      name: 'type',
      disabled: !sectorName && !sectorId,
      handler: ({value: id, label: name}) => dispatch(setBuilding({building_type: {id, name}})),
      label: localise('form.label.type'),
      loading: typeLoading,
      menuPortalTarget: document.body,
      options: types,
      placeholder: localise('form.placeholder.selectOption'),
      required: true,
      validationMessage: localise('form.validation.sector'),
      value: typeId && typeName ? {value: typeId, label: typeName} : null,
    },
  ]

  const submitFormData = () => onSubmit({name, building_sector: sectorId, building_type: typeId})

  const submittable = !!name && !!sectorId && !!typeId

  return <ConfirmForm fields={fields} onSubmit={submitFormData} submittable={submittable} />
}

DetailsForm.propTypes = propTypes
DetailsForm.defaultProps = defaultProps

export default DetailsForm

import React from 'react'
import {array, string} from 'prop-types'
import {v4} from 'uuid'
import {localise} from '../../services/LocalizationServices'
import NumberBadge from '../badge/NumberBadge'

const propTypes = {
  badgeCompletedColor: string,
  categoryItems: array,
  className: string,
}

const defaultProps = {
  badgeCompletedColor: '',
  categoryItems: [],
  className: '',
}

const MMCCategories = ({badgeCompletedColor, categoryItems, className}) => {
  const categories = categoryItems?.map(({active, completed, name}, index) =>
    <li key={v4()} className='list-inline-item'>
      <NumberBadge
        completed={completed || active}
        completedColor={badgeCompletedColor}
        size={24}>
        {name || index + 1}
      </NumberBadge>
    </li>
  )

  return (
    <div className={className}>
      <div className='font-regular font-10'>{localise('supplierCard.mmcCategories')}</div>
      <ul className='list-inline mt-1 mb-2'>
        {categories}
      </ul>
    </div>
  )
}

MMCCategories.propTypes = propTypes
MMCCategories.defaultProps = defaultProps

export default MMCCategories

import React from 'react'
import {Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import {CALCULATOR_URL, COMPONENTS_URL, EDIT_URL, NEW_URL} from '../constants/urls'
import Setup from '../views/building/setup/Setup'
import FlowLayout from './layouts/FlowLayout'
import Building from '../views/building/view/Building'
import Components from '../views/building/components/Components'
import Calculator from '../views/building/calculator/Calculator'

const BuildingRoutes = ({path}) => {
  return (
    <Switch>
      <RouteWithLayout exact path={`${path}${NEW_URL}`} component={Setup} layout={FlowLayout} />
      <RouteWithLayout exact path={`${path}/:slug`} component={Building} />
      <RouteWithLayout exact path={`${path}/:slug${EDIT_URL}`} component={Setup} layout={FlowLayout} />
      <RouteWithLayout
        exact
        path={`${path}/:slug${EDIT_URL}${COMPONENTS_URL}`}
        component={Components}
        layout={FlowLayout}
      />
      <RouteWithLayout
        exact
        path={`${path}/:slug${EDIT_URL}${CALCULATOR_URL}`}
        component={Calculator}
        layout={FlowLayout}
      />
    </Switch>
  )
}

export default BuildingRoutes

import React from 'react'
import {bool, func, string} from 'prop-types'
import EntityCreateSuccessModal from './EntityCreateSuccessModal'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'
import {PROJECT_SETUP_ICON} from '../../constants/svgs'

const propTypes = {
  className: string,
  isSubscription: bool,
  onConfirm: func,
  show: bool,
  toggle: func,
}

const defaultProps = {
  className: '',
  isSubscription: false,
  onConfirm: () => Console.dev('click onConfirm prop'),
  show: false,
  toggle: () => Console.dev('click toggle prop'),
}

const PurchaseSuccessModal = ({className, isSubscription, onConfirm, purchaseDesc, show, toggle}) =>
  <EntityCreateSuccessModal
    badgeIconSrc={PROJECT_SETUP_ICON}
    className={className}
    descriptionLine1={localise('modalText.youWillReceiveEmailConfirming')}
    descriptionLine2={localise(isSubscription ? 'modalText.yourSubscriptionDetails' : 'modalText.yourPurchaseDetails')}
    onConfirm={onConfirm}
    show={show}
    titleLine1={localise('modalText.success')}
    titleLine2={isSubscription ?
      localise('modalText.yourMyPMVSubIsLive', {subscription: purchaseDesc}) :
      localise('modalText.youPurchasedAProject')}
    toggle={toggle}
  />

PurchaseSuccessModal.propTypes = propTypes
PurchaseSuccessModal.defaultProps = defaultProps

export default PurchaseSuccessModal

import React, {useEffect, useState} from 'react'
import {localise} from '../../../services/LocalizationServices'
import Form from '../../../components/forms/Form'
import buildForm from '../../../utilities/form-helpers/FormBuilder'
import {func, node} from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectTitle} from '../../../store/selectors/projectSelectors'
import {setProject} from '../../../store/slices/projectSlice'
import {Button} from 'react-bootstrap'

const propTypes = {
  buttons: node,
  isSubmittable: func,
  onSubmit: func,
}

const defaultProps = {
  buttons: null,
  isSubmittable: () => {},
  onSubmit: () => 'on submit',
}

const DetailsForm = ({buttons, isSubmittable, onSubmit}) => {
  const dispatch = useDispatch()

  const [validated, setValidated] = useState(false)

  const projectTitle = useSelector(getProjectTitle)

  useEffect(() => isSubmittable(!!projectTitle), [isSubmittable, projectTitle])

  const fields = [
    {
      type: 'text',
      label: localise('form.label.projectTitle'),
      validationMessage: localise('form.validation.projectTitle'),
      handler: name => dispatch(setProject({name})),
      value: projectTitle,
      required: true,
    },
  ]

  const submitFormData = () => onSubmit({projectTitle})

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      submitFormData()
    }
    setValidated(true)
  }

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      {buildForm(fields)}
      {
        buttons || (
          <Button
            disabled={!projectTitle}
            type='submit'
            variant='primary'
            className='font-15'
            size='lg'>
            {localise('buttonText.update')}
          </Button>
        )
      }
    </Form>
  )
}

DetailsForm.propTypes = propTypes
DetailsForm.defaultProps = defaultProps

export default DetailsForm
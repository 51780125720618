import React from 'react'
import {object, string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import Placeholder from './Placeholder'

const propTypes = {
  className: string,
  style: object,
}

const defaultProps = {
  className: '',
  style: {},
}

const ChartPlaceholder = ({className, style}) =>
  <Placeholder
    alt={localise('alt.noData')}
    className={`chart-placeholder ${className}`}
    description={localise('placeholder.noChartDataToDisplay')}
    style={style}
  />

ChartPlaceholder.propTypes = propTypes
ChartPlaceholder.defaultProps = defaultProps

export default ChartPlaceholder

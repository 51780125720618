import React, {useEffect, useState} from 'react'
import {bool, func, string} from 'prop-types'
import {useSelector} from 'react-redux'
import {getBuildingSurveyId, getBuildingSurveyResponseId} from '../../../store/selectors/buildingSelectors'
import {fetchSurveyGroups} from '../../../api/surveys/surveys'
import Skeleton from 'react-loading-skeleton'
import Accordion from '../../../components/accordion/Accordion'
import Survey from './Survey'

const propTypes = {
  active: bool,
  disabled: bool,
  element: string,
  icon: string,
  name: string,
  onAnswerUpdated: func,
  onComplete: func,
}

const defaultProps = {
  active: false,
  disabled: false,
  element: '',
  icon: '',
  name: '',
  onConfirm: null,
  onAnswerUpdated: null,
  onComplete: null,
}

const Element = ({active, disabled, element, icon, name, onAnswerUpdated, onComplete}) => {
  const [activeKey, setActiveKey] = useState(null)
  const [loading, setLoading] = useState(false)
  const [sections, setSections] = useState([])

  const surveyId = useSelector(getBuildingSurveyId)
  const surveyResponseId = useSelector(getBuildingSurveyResponseId)

  const handleGroups = groups => {
    let activeKey
    setSections(groups.map(group => {
      if (!activeKey && group?.progress !== 1) activeKey = group?.number

      return {
        eventKey: group?.number,
        body: (
          <Survey
            element={element}
            groupId={group?.id}
            onAnswered={updated => {
              if (updated && onAnswerUpdated) {
                onAnswerUpdated()
              } else {
                loadGroups(true)
              }
            }}
            questions={group?.questions}
          />
        ),
        complete: group?.progress === 1,
        onClick: () => setActiveKey(group?.number),
        title: group?.name,
        disabled: group?.questions?.length < 1,
      }
    }))
    setActiveKey(activeKey)
  }

  const loadGroups = answered => {
    setLoading(true)
    fetchSurveyGroups(surveyId, element, surveyResponseId)
      .then(({groups}) => {
        if (answered) {
          if (groups.every(group => group.progress === 1)) if (onComplete) return onComplete(element)
        }
        handleGroups(groups)
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (surveyId && surveyResponseId && element && !disabled) loadGroups()
    // eslint-disable-next-line
  }, [surveyId, surveyResponseId])

  return loading
    ? <Skeleton height={80} />
    : (
      <Accordion
        active={active}
        activeKey={activeKey}
        disabled={disabled}
        icon={icon}
        title={name}
        sections={sections}
      />
    )
}

Element.propTypes = propTypes
Element.defaultProps = defaultProps

export default Element

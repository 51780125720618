import React, {useEffect, useState} from 'react'
import {localise} from '../../../../services/LocalizationServices'
import Buttons from '../common/Buttons'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import MMCSupplierCreateFlow from '../../../flows/MMCSupplierCreateFlow'
import {PROJECTS_URL} from '../../../../constants/urls'
import SupplierForm from '../form/SupplierForm'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {useParams} from 'react-router'
import {getSelectedSuppliers} from '../../../../store/selectors/selectedSuppliersSelectors'
import {createProjectStagedSupplier, createProjectSuppliers} from '../../../../api/project/project'
import {toast} from 'react-hot-toast'
import useSupplierForm from '../../../../hooks/useSupplierForm'
import {clearSelectedSuppliers, setSelectedSuppliers} from '../../../../store/slices/selectedSuppliersSlice'
import StagedSupplierCard from '../../../../components/cards/StagedSupplierCard'
import {PRELIMINARIES} from '../../../../constants/suppliers'

const Preliminaries = () => {
  const dispatch = useDispatch()
  const {push} = useHistory()
  const {slug} = useParams()
  const selectedSuppliers = useSelector(getSelectedSuppliers)
  const {MECHANICAL_ELECTRICAL_PLUMBING_STEP_URL, setSupplierFormDirty} = useSupplierForm()

  const [name, setName] = useState('')
  const [submittable, setSubmittable] = useState(false)

  useEffect(() => {
    const suppliersExist =
      selectedSuppliers?.filter(({element: selectedElem}) => selectedElem === PRELIMINARIES)?.length > 0
    setSubmittable(suppliersExist || !!name)
  }, [selectedSuppliers, name])

  const handleSubmit = async () => {
    toggleLoading()
    try {
      await setSupplierFormDirty(false)
      const supplier_ids = selectedSuppliers.map(({id}) => id)
      if (name) await createProjectStagedSupplier(slug, name)
      await createProjectSuppliers(slug, supplier_ids)
      dispatch(clearSelectedSuppliers())
      dispatch(setSelectedSuppliers({dirty: false}))
      push(`${PROJECTS_URL}/${slug}`)
      toast.success(localise('toast.suppliersSuccessfullyAdded'))
    }
    finally { toggleLoading() }
  }

  return (
    <MMCSupplierCreateFlow activeStep={5} title={localise('headings.preliminaries')}>
      <SupplierForm isSubmittable={setSubmittable} element={PRELIMINARIES} />
      <StagedSupplierCard element={PRELIMINARIES} onChange={setName} />
      <Buttons
        onCancelClicked={() => push(`${PROJECTS_URL}/${slug}`)}
        continueButtonDisabled={!submittable}
        onPreviousClicked={() => push(MECHANICAL_ELECTRICAL_PLUMBING_STEP_URL)}
        onSubmit={handleSubmit}
        submitText={localise('buttonText.confirm')}
      />
    </MMCSupplierCreateFlow>
  )
}

export default Preliminaries

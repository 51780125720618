import {CHARS_AND_NUMBERS} from '../constants/regex'

const useAuth = () => {
  const MIN_PASSWORD_LENGTH = 8

  const validatePassword = password => {
    const regexValidations = [CHARS_AND_NUMBERS]
    const testResults = []

    regexValidations.forEach(validation => testResults.push(validation.test(password)))
    return !testResults.includes(false) && password.length >= MIN_PASSWORD_LENGTH
  }


  return {
    MIN_PASSWORD_LENGTH,
    validatePassword,
  }
}

export default useAuth
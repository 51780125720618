import React from 'react'
import useNav from '../../hooks/useNav'
import {localise} from '../../services/LocalizationServices'
import {useHistory} from 'react-router'
import TabMenu from './TabMenu'
import {Button} from 'react-bootstrap'
import SupportButton from '../buttons/SupportButton'

const MainMenu = () => {
  const {breakpoint, isActiveLink, links} = useNav()
  const {push} = useHistory()

  return (
    <div className='d-flex flex-row'>
      <TabMenu
        className={`d-none d-${breakpoint}-flex`}
        data={[
          {
            eventKey: links.projects.to,
            title: localise(links.projects.translationKey),
            tabClassName: isActiveLink(links.projects.activeViews) ? 'active' : '',
          },
          //TODO: UNCOMMENT WHEN MMC SUPPLIERS ARE INTRODUCED
          // {
          //   eventKey: links.mmcSuppliers.to,
          //   title: localise(links.mmcSuppliers.translationKey),
          //   tabClassName: isActiveLink(links.mmcSuppliers.activeViews) ? 'active' : '',
          // },
          {
            eventKey: links.helpfulLinks.to,
            title: localise(links.helpfulLinks.translationKey),
            tabClassName: isActiveLink(links.helpfulLinks.activeViews) ? 'active' : '',
          },
          {
            eventKey: links.mapView.to,
            title: localise(links.mapView.translationKey),
            tabClassName: isActiveLink(links.mapView.activeViews) ? 'active' : '',
          },
          {
            eventKey: links.myAccount.to,
            title: localise(links.myAccount.translationKey),
            tabClassName: isActiveLink(links.myAccount.activeViews) ? 'active' : '',
          },
        ]}
        onSelect={to => push(to)}
      />
      <SupportButton className={`d-none d-${breakpoint}-block font-15 ms-auto mb-2`} />
    </div>
  )
}

export default MainMenu

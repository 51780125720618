import React from 'react'
import {array, arrayOf, func, string} from 'prop-types'
import 'chartkick/chart.js'
import {PieChart as ChartkickPieChart} from 'react-chartkick'
import {LIGHT_GREEN} from '../../constants/colours'
import {externalTooltipHandler} from './chart-js/tooltip'

const propTypes = {
  className: string,
  onLegendClick: func,
  colors: arrayOf(string),
  data: array,
}

const defaultProps = {
  className: '',
  onLegendClick: undefined,
  colors: [LIGHT_GREEN],
  data: [],
}

const HalfPieChart = ({className, onLegendClick, colors, data, libraryProps}) => {
  return (
    <div className={className}>
      <ChartkickPieChart
        donut
        colors={colors}
        data={data}
        library={{
          rotation: 270,
          circumference: 180,
          layout: {
            padding: 20,
          },
          animation: {
            duration: 500,
          },
          plugins: {
            legend: {
              position: 'bottom',
              labels: {
                usePointStyle: true,
                padding: 20,
                font: {
                  size: 12,
                  weight: 'bold',
                },
              },
              onClick: onLegendClick,
            },
            tooltip: {
              enabled: false,
              external: externalTooltipHandler,
              position: 'nearest',
            },
          },
          ...libraryProps,
        }}
      />
    </div>
  )

}

HalfPieChart.propTypes = propTypes
HalfPieChart.defaultProps = defaultProps

export default HalfPieChart

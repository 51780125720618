import React from 'react'
import {func} from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {generateNetCostBreakdown} from '../../../../api/generate-breakdowns/generateBreakdowns'
import {
  getBuildingNetCostAreas,
  getBuildingNetCostTotal,
  getBuildingNetCostTotalDisplay,
  getInitialBuildingNetCostAreas,
  getInitialBuildingNetCostTotal,
} from '../../../../store/selectors/buildingNetCostSelectors'
import {setBuildingNetCost} from '../../../../store/slices/buildingNetCostSlice'
import ConfirmForm from '../../../../components/forms/ConfirmForm'
import {localise} from '../../../../services/LocalizationServices'
import GenerateBreakdownButton from '../../../../components/buttons/GenerateBreakdownButton'
import SliderDistributionTable from '../../../../components/tables/SliderDistributionTable'
import Separator from '../../../../components/separator/Separator'
import {getCurrentUserCurrencySymbol} from '../../../../store/selectors/currentUserSelectors'
import {getBuildingId} from '../../../../store/selectors/buildingSelectors'
import {
  getEnablingWorksThreshold,
  getFitOutThreshold,
  getMepThreshold,
  getSubstructureThreshold,
  getSuperstructureThreshold,
} from '../../../../store/selectors/thresholdsSelectors'

const propTypes = {
  onSubmit: func,
}

const defaultProps = {
  onSubmit: () => {},
}

const ENABLING_WORKS_SLUG = 'enabling_works'
const FIT_OUT_SLUG = 'fit_out'
const MEP_SLUG = 'mechanical_electrical_plumbing'
const OTHER_WORKS_SLUG = 'other_works'
const SUBSTRUCTURE_SLUG = 'substructure'
const SUPERSTRUCTURE_SLUG = 'superstructure'

const NetCostForm = ({onSubmit}) => {
  const dispatch = useDispatch()

  const areas = useSelector(getBuildingNetCostAreas) || []
  const buildingId = useSelector(getBuildingId)
  const netCostTotal = useSelector(getBuildingNetCostTotal)
  const netCostTotalDisplay = useSelector(getBuildingNetCostTotalDisplay)
  const enablingWorksThreshold = useSelector(getEnablingWorksThreshold)
  const fitOutThreshold = useSelector(getFitOutThreshold)
  const mepThreshold = useSelector(getMepThreshold)
  const substructureThreshold = useSelector(getSubstructureThreshold)
  const superstructureThreshold = useSelector(getSuperstructureThreshold)
  const initialNetCostTotal = useSelector(getInitialBuildingNetCostTotal)
  const initialAreas = useSelector(getInitialBuildingNetCostAreas)

  const setNetCostBreakdownRedux = payload => dispatch(setBuildingNetCost(payload))

  const generateBreakdown = () => {
    toggleLoading()
    generateNetCostBreakdown(netCostTotal * 100, buildingId)
      .then(setNetCostBreakdownRedux)
      .finally(toggleLoading)
  }

  const handleChange = values => {
    setNetCostBreakdownRedux({
      building_net_cost_breakdown_areas: areas.map(area => ({
        ...area,
        percentage: values[area?.building_net_cost_breakdown_area_type?.slug]?.value,
      })),
    })
  }

  const renderAreas = () => areas.map(area => (
    {
      label: area?.building_net_cost_breakdown_area_type?.name,
      name: area?.building_net_cost_breakdown_area_type?.slug,
      auto: area?.building_net_cost_breakdown_area_type?.slug === OTHER_WORKS_SLUG,
      value: area?.percentage,
    }
  ))

  const fields = [
    {
      type: 'custom',
      render: (
        <div>
          <div className='font-13 font-bold'>{localise('headings.netConstructionCost')}</div>
          <div className='font-13 font-grey'>{localise('headings.calculatedFromBuildingGrossCost')}</div>
          <div className='font-17 font-bold'>{netCostTotalDisplay}</div>
          <Separator className='mt-2' />
        </div>
      ),
    },
    {
      type: 'custom',
      className: 'my-2',
      render: (
        <GenerateBreakdownButton
          message={localise('helpText.generateNetConstructionCostBreakdownMessage')}
          onClick={generateBreakdown}
        />
      ),
    },
    {
      type: 'custom',
      render: (
        <SliderDistributionTable
          currentValPrefix={useSelector(getCurrentUserCurrencySymbol)}
          maxTotal={parseFloat(netCostTotal).toFixed(2)}
          onChange={handleChange}
          data={renderAreas()}
          validations={{
            [ENABLING_WORKS_SLUG]: {
              threshold: enablingWorksThreshold,
              errMsg: localise('modalText.enablingWorksError', {percent: enablingWorksThreshold}),
            },
            [SUBSTRUCTURE_SLUG]: {
              threshold: substructureThreshold,
              errMsg: localise('modalText.substructureError', {percent: substructureThreshold}),
            },
            [SUPERSTRUCTURE_SLUG]: {
              threshold: superstructureThreshold,
              errMsg: localise('modalText.superstructureError', {percent: superstructureThreshold}),
            },
            [FIT_OUT_SLUG]: {
              threshold: fitOutThreshold,
              errMsg: localise('modalText.fitOutError', {percent: fitOutThreshold}),
            },
            [MEP_SLUG]: {
              threshold: mepThreshold,
              errMsg: localise('modalText.mepError', {percent: mepThreshold}),
            },
          }}
        />
      ),
    },
  ]

  const submitFormData = () => {
    let dirty = false

    if (netCostTotal !== initialNetCostTotal) dirty = true

    areas.forEach(area => {
      const newPercentage = area?.percentage
      const initPercentage = initialAreas.filter(initArea => initArea?.name === area?.name)[0]?.percentage

      if (newPercentage !== initPercentage) dirty = true
    })

    onSubmit(
      dirty
        ? {
          building_net_cost_breakdown: {
            building_net_cost: {
              total_cents: Math.round(netCostTotal * 100),
            },
            building_net_cost_breakdown_areas: areas.map(area => ({
              amount_cents: Math.round((netCostTotal * (area?.percentage / 100)) * 100),
              percentage: area?.percentage,
              building_net_cost_breakdown_area_type: area?.building_net_cost_breakdown_area_type?.slug,
            })),
          },
        }
        : null
    )
  }

  const submittable = !!netCostTotal && areas.reduce((previous, current) => previous + current?.percentage, 0) > 0

  return <ConfirmForm fields={fields} onSubmit={submitFormData} submittable={submittable} />
}

NetCostForm.propTypes = propTypes
NetCostForm.defaultProps = defaultProps

export default NetCostForm

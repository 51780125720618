import React from 'react'
import {bool, func, node, string} from 'prop-types'

const propTypes = {
  className: string,
  clickable: bool,
  children: node,
  hideBoxShadow: bool,
  onClick: func,
}

const defaultProps = {
  className: '',
  clickable: false,
  children: null,
  hideBoxShadow: false,
  onClick: null,
}

const Card = ({className, clickable, children, hideBoxShadow, onClick}) =>
  <div
    className={`card-container bg-white border-light-grey
     ${
      hideBoxShadow
        ? 'shadow-none'
        : clickable ? 'box-shadow box-shadow-hover cursor-pointer' : 'box-shadow'
    } 
     ${className}`}
    onClick={e => clickable && onClick ? onClick(e) : null}>
    {children}
  </div>

Card.propTypes = propTypes
Card.defaultProps = defaultProps

export default Card

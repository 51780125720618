import React from 'react'
import clsx from 'clsx'
import {bool, func, number, string} from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import {BRAND_PRIMARY} from '../../constants/colours'

const propTypes = {
  active: bool,
  backgroundColor: string,
  clickable: bool,
  completed: bool,
  onClick: func,
  opacity: number,
  src: string,
}

const defaultProps = {
  active: false,
  backgroundColor: null,
  clickable: true,
  completed: false,
  onClick: null,
  opacity: 0.4,
  src: '',
}

const useColorlibStepIconStyles = makeStyles({
  root: {
    alignItems: 'center',
    backgroundColor: ({backgroundColor}) => backgroundColor || BRAND_PRIMARY,
    borderRadius: '50%',
    color: 'white',
    cursor: ({clickable, onClick}) => (clickable && onClick) ? 'pointer' : 'default',
    display: 'flex',
    height: 36,
    justifyContent: 'center',
    opacity: ({opacity}) => opacity || 0.4,
    width: 36,
    zIndex: 1,
  },
  active: {
    backgroundColor: ({backgroundColor}) => backgroundColor || BRAND_PRIMARY,
    opacity: '1!important',
  },
  completed: {
    backgroundColor: ({backgroundColor}) => backgroundColor || BRAND_PRIMARY,
    opacity: '1!important',
  },
})

const StepIcon = ({active, backgroundColor, completed, clickable, onClick, opacity, src}) => {
  const classes = useColorlibStepIconStyles({active, backgroundColor, completed, clickable, onClick, opacity})

  return (
    <div
      className={clsx(classes.root, {[classes.active]: active, [classes.completed]: completed})}
      onClick={clickable ? onClick : null}>
      <img width={24} height={24} src={src} alt={`step-icon-${src}`}/>
    </div>
  )
}

StepIcon.propTypes = propTypes
StepIcon.defaultProps = defaultProps

export default StepIcon

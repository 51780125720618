import {createSlice} from '@reduxjs/toolkit'

const projectSlice = createSlice({
  name: 'project',
  initialState: {},
  reducers: {
    setProject: (state, action) => {
      if (action?.payload?.address?.country?.name && action?.payload?.address?.country?.alpha2) {
        action.payload.address.country_code = action.payload.address.country.alpha2
        action.payload.address.country = action.payload.address.country.name
      }

      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearProject: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const projectReducer = projectSlice.reducer
export const {clearProject, setProject} = projectSlice.actions

import React from 'react'
import {bool, number, shape, string} from 'prop-types'
import LogEntryCard from './LogEntryCard'
import {Col} from 'react-bootstrap'
import {localise} from '../../../services/LocalizationServices'

const propTypes = {
  log: shape({
    active: bool,
    created_at: number,
    id: string,
    gross_internal_area: number,
    gross_internal_area_unit_of_measurement: string,
    speed_score: number,
    projected_days_count: number,
  }),
}

const defaultProps = {
  log: {},
}

const SpeedLog = ({log, ...rest}) => {
  const section = ({body, bodyClasses, title}) =>
    <>
      <div className='font-10'>{title}</div>
      <div className={`font-17 ${bodyClasses}`}>
        {body}
      </div>
    </>

  return (
    <LogEntryCard {...rest}>
      {
        <>
          <Col>
            {
              section({
                title: localise('headings.gia', {units: log?.gross_internal_area_unit_of_measurement?.name}),
                body: log?.gross_internal_area,
              })
            }
          </Col>
          <Col>
            {
              section({
                title: localise('headings.projectedNumberOfDays'),
                body: log?.projected_days_count,
              })
            }
          </Col>
          <Col>
            {
              section({
                title: localise('headings.speed'),
                body: log?.speed_score,
                bodyClasses: 'font-bold',
              })
            }
          </Col>
        </>
      }
    </LogEntryCard>
  )
}

SpeedLog.propTypes = propTypes
SpeedLog.defaultProps = defaultProps

export default SpeedLog

import React, {useState} from 'react'
import useBuilding from '../../../hooks/useBuilding'
import {useSelector} from 'react-redux'
import {
  getBuildingGrossInternalArea,
  getBuildingId,
  getBuildingName,
  getBuildingNetInternalArea,
  getBuildingPhaseCountDisplay,
  getBuildingPhaseDisplay,
  getBuildingSectorName,
  getBuildingStatusColorRGBA,
  getBuildingStatusDisplay,
  getBuildingStepCountDisplay,
  getBuildingTypeName,
  getBuildingUnitCount,
  getHouseGrossNetInternalArea,
  getHouseTotalGrossInternalArea,
} from '../../../store/selectors/buildingSelectors'
import BreadCrumbsView from '../../common/BreadcrumbsView'
import {getProjectId, getProjectTitle} from '../../../store/selectors/projectSelectors'
import {Button, Col, Row} from 'react-bootstrap'
import {localise} from '../../../services/LocalizationServices'
import {EDIT_URL, PROJECTS_URL} from '../../../constants/urls'
import BuildingCard from '../../../components/cards/BuildingCard'
import BuildingDetails from './details/BuildingDetails'
import DeleteModal from '../../../components/modals/DeleteModal'
import {toggleLoading} from '../../../utilities/LoadingIndicatorUtil'
import {deleteBuilding, duplicateBuilding} from '../../../api/building/building'
import {toast} from 'react-hot-toast'
import {useHistory, useLocation} from 'react-router-dom'

const Building = () => {
  useBuilding()
  const {push} = useHistory()
  const location = useLocation()

  const [show, setShow] = useState(false)

  const buildingId = useSelector(getBuildingId)
  const grossInternalArea = useSelector(getBuildingGrossInternalArea)
  const houseGiaNia = useSelector(getHouseGrossNetInternalArea)
  const name = useSelector(getBuildingName)
  const netInternalArea = useSelector(getBuildingNetInternalArea)
  const phaseCountDisplay = useSelector(getBuildingPhaseCountDisplay)
  const phaseDisplay = useSelector(getBuildingPhaseDisplay)
  const projectId = useSelector(getProjectId)
  const projectTitle = useSelector(getProjectTitle)
  const sector = useSelector(getBuildingSectorName)
  const statusColor = useSelector(getBuildingStatusColorRGBA)
  const statusDisplay = useSelector(getBuildingStatusDisplay)
  const stepCountDisplay = useSelector(getBuildingStepCountDisplay)
  const totalGrossInternalArea = useSelector(getHouseTotalGrossInternalArea)
  const type = useSelector(getBuildingTypeName)
  const units = useSelector(getBuildingUnitCount)

  const handleDuplicate = () => {
    toggleLoading()
    duplicateBuilding(projectId, buildingId)
      .then(({id}) => push(`/projects/${projectId}/buildings/${id}${EDIT_URL}`))
      .finally(toggleLoading)
  }
  const handleEdit = () => push(`${location?.pathname}${EDIT_URL}`)
  const handleDelete = () => {
    toggleLoading()
    deleteBuilding(projectId, buildingId)
      .then(destroyed => {
        if (destroyed) {
          toggleModal()
          toast.success(localise('toast.buildingDeleteSuccess'))
          push(`${PROJECTS_URL}/${projectId}`)
        }
      })
      .finally(toggleLoading)
  }

  const toggleModal = () => setShow(!show)

  return (
    <>
      <BreadCrumbsView
        breadcrumbItems={[
          {
            active: false,
            href: PROJECTS_URL,
            text: localise('nav.projects'),
          },
          {
            active: false,
            href: `${PROJECTS_URL}/${projectId}`,
            text: projectTitle,
          },
          {
            active: true,
            text: name,
          },
        ]}
        buttons={(
          <>
            <Button
              variant='outline'
              className='font-12 py-1 me-2'
              onClick={handleDuplicate}>
              {localise('buttonText.duplicate')}
            </Button>
            <Button
              variant='outline'
              className='font-12 py-1 me-2'
              onClick={handleEdit}>
              {localise('buttonText.edit')}
            </Button>
            <Button
              variant='outline'
              className='font-12 py-1'
              onClick={toggleModal}>
              {localise('buttonText.delete')}
            </Button>
          </>
        )}>
        <Row>
          <Col xs='12'>
            <BuildingCard
              bannerColor={statusColor}
              buildingType={type}
              grossInternalArea={grossInternalArea}
              houseGiaNia={houseGiaNia}
              netInternalArea={netInternalArea}
              phaseCountDisplay={phaseCountDisplay}
              phaseDisplay={phaseDisplay}
              sector={sector}
              statusDisplay={statusDisplay}
              stepCount={stepCountDisplay}
              title={name}
              totalGrossInternalArea={totalGrossInternalArea}
              units={units}
            />
          </Col>
        </Row>
        <BuildingDetails />
      </BreadCrumbsView>
      <DeleteModal
        bodyText={localise('modalText.actionCannotBeUndone')}
        headerText={localise('modalText.areYouSureYouWantToDeleteBuilding')}
        onCancel={toggleModal}
        onDelete={handleDelete}
        show={show}
      />
    </>
  )
}

export default Building

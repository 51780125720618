import React, {useEffect, useState} from 'react'
import {string} from 'prop-types'
import {fetchProductivityLogs} from '../../../api/logs/logs'
import {toggleLoading} from '../../../utilities/LoadingIndicatorUtil'
import {useParams} from 'react-router'
import {DateTime} from 'luxon'
import {EUROPEAN_DATE_FORMAT} from '../../../constants/dates'
import {PROJECTS_URL} from '../../../constants/urls'
import {localise} from '../../../services/LocalizationServices'
import BreadCrumbsView from '../../common/BreadcrumbsView'
import {useSelector} from 'react-redux'
import {getProjectTitle} from '../../../store/selectors/projectSelectors'
import ProductivityLog from '../../../components/cards/logs/ProductivityLog'

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

const ProductivityLogs = ({className}) => {
  const {slug} = useParams()
  const title = useSelector(getProjectTitle)

  const [productivityLogs, setProductivityLogs] = useState([])

  useEffect(() => {
    toggleLoading()
    fetchProductivityLogs(slug)
      .then(({productivity_logs}) => setProductivityLogs(productivity_logs))
      .finally(toggleLoading)
  }, [slug])

  return (
    <BreadCrumbsView
      breadcrumbItems={[
        {
          active: false,
          href: PROJECTS_URL,
          text: localise('nav.projects'),
        },
        {
          active: false,
          href: `${PROJECTS_URL}/${slug}`,
          text: title,
        },
        {
          active: true,
          text: localise('headings.productivity'),
        },
      ]}
      className={`mt-2 ${className}`}>
      {
        productivityLogs.map(log =>
          <ProductivityLog
            className='m-0'
            date={DateTime.fromSeconds(log?.created_at || 0).toFormat(EUROPEAN_DATE_FORMAT)}
            log={log}
          />
        )
      }
    </BreadCrumbsView>
  )
}

ProductivityLogs.propTypes = propTypes
ProductivityLogs.defaultProps = defaultProps

export default ProductivityLogs

import React from 'react'
import {array, func, object, oneOfType, string} from 'prop-types'
import ProgrammeMetricsTable from '../../../../components/tables/ProgrammeMetricsTable'
import CommercialMetricsTable from '../../../../components/tables/CommercialMetricsTable'
import {Button} from 'react-bootstrap'
import {localise} from '../../../../services/LocalizationServices'

const propTypes = {
  className: string,
  grossCommercialMetrics: oneOfType([array, object]),
  netCommercialMetrics: oneOfType([array, object]),
  onConfirm: func,
  programmeMetrics: oneOfType([array, object]),
}

const defaultProps = {
  className: '',
  grossCommercialMetrics: null,
  netCommercialMetrics: null,
  programmeMetrics: null,
  onConfirm: null,
}

const BuildingAnalysisSummaryTable = ({
  className,
  grossCommercialMetrics,
  netCommercialMetrics,
  onConfirm,
  programmeMetrics,
}) => {
  return (
    <div className={`building-analysis-summary-table ${className}`}>
      <div className='mb-2'>
        <ProgrammeMetricsTable programmeMetrics={programmeMetrics} />
      </div>
      <div className='mb-2'>
        <CommercialMetricsTable
          grossCommercialMetrics={grossCommercialMetrics}
          netCommercialMetrics={netCommercialMetrics}
        />
      </div>
      {
        !!onConfirm &&
        <div className='d-grid d-flex justify-content-end mt-3'>
          <Button
            type='button'
            variant='primary'
            className='font-15'
            onClick={onConfirm}>
            {localise('buttonText.confirm')}
          </Button>
        </div>
      }
    </div>
  )
}

BuildingAnalysisSummaryTable.propTypes = propTypes
BuildingAnalysisSummaryTable.defaultProps = defaultProps

export default BuildingAnalysisSummaryTable

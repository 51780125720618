import {createSlice} from '@reduxjs/toolkit'

const buildingProgrammeSlice = createSlice({
  name: 'buildingProgramme',
  initialState: {},
  reducers: {
    setBuildingProgramme: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearBuildingProgramme: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const buildingProgrammeReducer = buildingProgrammeSlice.reducer
export const {clearBuildingProgramme, setBuildingProgramme} = buildingProgrammeSlice.actions

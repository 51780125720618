import React from 'react'
import {bool, func, string} from 'prop-types'
import {Image} from 'react-bootstrap'
import {STAR_EMPTY, STAR_FILLED} from '../../constants/svgs'
import styled from 'styled-components'

const propTypes = {
  active: bool,
  activeIcon: string,
  className: string,
  disabled: bool,
  inactiveIcon: string,
  onClick: func,
}

const defaultProps = {
  active: false,
  activeIcon: '',
  className: '',
  disabled: false,
  inactiveIcon: '',
  onClick: () => {},
}

const StyledImage = styled(Image)`
  border-radius: 50%;
  transition: background-color 0.3s;
  &:hover {background-color: #f2f2f2}
`

const IconButton = ({active, activeIcon, className, disabled, inactiveIcon, onClick, ...rest}) =>
  <StyledImage className={`align-top ${className} ${!disabled && 'cursor-pointer'}`}
    onClick={event => {
      if (disabled) return null
      else {
        event.stopPropagation()
        onClick(!active)
      }
    }}
    src={active ? activeIcon || STAR_FILLED : inactiveIcon || STAR_EMPTY}
    width={24}
    {...rest}
  />

IconButton.propTypes = propTypes
IconButton.defaultProps = defaultProps

export default IconButton

import React, {useState} from 'react'
import {localise} from '../../../services/LocalizationServices'
import Form from '../../../components/forms/Form'
import buildForm from '../../../utilities/form-helpers/FormBuilder'
import {func, node} from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {
  getProjectGlaFunding,
  getProjectHomesEnglandFunding,
  getProjectOtherPublicFunding,
} from '../../../store/selectors/projectSelectors'
import {setProject} from '../../../store/slices/projectSlice'
import {Button} from 'react-bootstrap'

const propTypes = {
  buttons: node,
  onSubmit: func,
}

const defaultProps = {
  buttons: null,
  onSubmit: () => 'on submit',
}

const FundingForm = ({buttons, onSubmit}) => {
  const dispatch = useDispatch()

  const [validated, setValidated] = useState(false)

  const homesEnglandFunding = useSelector(getProjectHomesEnglandFunding)
  const glaFunding = useSelector(getProjectGlaFunding)
  const otherFunding = useSelector(getProjectOtherPublicFunding)

  const fields = [
    {
      type: 'radio-group',
      hideValidUI: true,
      name: 'homes-england-funded',
      label: localise('form.label.homesEnglandFundedApplication'),
      radios: [
        {
          label: localise('form.label.yes'),
          checked: homesEnglandFunding,
          onChange: () => dispatch(setProject({homes_england_funding: true})),
        },
        {
          label: localise('form.label.no'),
          checked: !homesEnglandFunding,
          onChange: () => dispatch(setProject({homes_england_funding: false})),
        },
      ],
    },
    {
      type: 'radio-group',
      hideValidUI: true,
      name: 'gla-funded',
      label: localise('form.label.glaFundingApplication'),
      radios: [
        {
          label: localise('form.label.yes'),
          checked: glaFunding,
          onChange: () => dispatch(setProject({gla_funding: true})),
        },
        {
          label: localise('form.label.no'),
          checked: !glaFunding,
          onChange: () => dispatch(setProject({gla_funding: false})),
        },
      ],
    },
    {
      type: 'radio-group',
      hideValidUI: true,
      name: 'other-public-funded',
      label: localise('form.label.otherPublicFunded'),
      radios: [
        {
          label: localise('form.label.yes'),
          checked: otherFunding,
          onChange: () => dispatch(setProject({other_public_funding: true})),
        },
        {
          label: localise('form.label.no'),
          checked: !otherFunding,
          onChange: () => dispatch(setProject({other_public_funding: false})),
        },
      ],
    },
  ]

  const submitFormData = () => onSubmit(
    {
      homes_england_funding: !!homesEnglandFunding,
      gla_funding: !!glaFunding,
      other_public_funding: !!otherFunding,
    }
  )

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      submitFormData()
    }
    setValidated(true)
  }

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      {buildForm(fields)}
      {
        buttons || (
          <Button
            type='submit'
            variant='primary'
            className='font-15'
            size='lg'>
            {localise('buttonText.update')}
          </Button>
        )
      }
    </Form>
  )
}

FundingForm.propTypes = propTypes
FundingForm.defaultProps = defaultProps

export default FundingForm

export const getBuildingGrossCostTotal = state => (
  state?.buildingGrossCost?.building_gross_cost?.total?.amount
    ? state?.buildingGrossCost?.building_gross_cost?.total?.amount/100
    : null
)
export const getBuildingGrossCostAreas = state => {
  if (state?.buildingGrossCost?.building_gross_cost_breakdown_areas?.length > 0) {
    const sortable = [...state?.buildingGrossCost?.building_gross_cost_breakdown_areas]
    return sortable.sort((a, b) => {
      return a?.building_gross_cost_breakdown_area_type?.sort_order >
      b?.building_gross_cost_breakdown_area_type?.sort_order
        ? 1
        : -1
    })
  }
  return []
}

export const getInitialBuildingGrossCostTotal = state => (
  state?.buildingGrossCost?.initial?.building_gross_cost?.total?.amount
    ? state?.buildingGrossCost?.initial?.building_gross_cost?.total?.amount/100
    : null
)

export const getInitialBuildingGrossCostAreas = state => (
  state?.buildingGrossCost?.initial?.building_gross_cost_breakdown_areas || []
)

import {
  fetchGrossCostBreakdownValidations, fetchNetCostBreakdownValidations, fetchPreliminariesCostBreakdownValidations,
  validateGrossCostBreakdown as apiValidateGrossCostBreakdown,
  validateInternalArea,
  validateNetCostBreakdown as apiValidateNetCostBreakdown,
  validatePreliminariesCostBreakdown as apiValidatePreliminariesCostBreakdown,
  validateUnitCount as apiValidateUnitCount,
} from '../api/validations/validations'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectId} from '../store/selectors/projectSelectors'
import {getBuildingId} from '../store/selectors/buildingSelectors'
import {toast} from 'react-hot-toast'
import {useEffect} from 'react'
import {setThresholds} from '../store/slices/thresholdsSlice'

const useValidations = ({loadThresholds} = {}) => {
  const dispatch = useDispatch()

  const projectId = useSelector(getProjectId)
  const buildingId = useSelector(getBuildingId)

  useEffect(() => {
    if (loadThresholds) {
      Promise
        .all([
          fetchGrossCostBreakdownValidations(projectId, buildingId),
          fetchNetCostBreakdownValidations(projectId, buildingId),
          fetchPreliminariesCostBreakdownValidations(projectId, buildingId),
        ])
        .then(results => results.map(res =>
          dispatch(setThresholds(res))))
    }
  }, [buildingId, dispatch, loadThresholds, projectId])

  const showValidationErrors = errors => errors?.forEach(toast.error)
  const validateBuildingInternalArea = (gia, nia) =>
    validateInternalArea(
      projectId,
      buildingId,
      {
        gross_internal_area: parseInt(gia, 10),
        net_internal_area: parseInt(nia, 10),
      }
    )
  const validateHouseInternalArea = house_gia_nia =>
    validateInternalArea(
      projectId,
      buildingId,
      {
        house_gia_nia: parseInt(house_gia_nia, 10),
      }
    )
  const validateUnitCount = unitCount => apiValidateUnitCount(projectId, buildingId, unitCount)
  const validateGrossCostBreakdown = payload => {
    const grossCost = payload?.building_gross_cost_breakdown?.building_gross_cost?.total_cents
    const netCost = payload?.building_gross_cost_breakdown?.building_gross_cost_breakdown_areas?.filter(
      ({building_gross_cost_breakdown_area_type: type}) => type === 'net_construction_cost'
    )[0]?.amount_cents

    return apiValidateGrossCostBreakdown(projectId, buildingId, grossCost, netCost)
  }
  const validateNetCostBreakdown = payload => apiValidateNetCostBreakdown(projectId, buildingId, payload)
  const validatePreliminariesCostBreakdown = payload =>
    apiValidatePreliminariesCostBreakdown(projectId, buildingId, payload)

  return {
    showValidationErrors,
    validateBuildingInternalArea,
    validateGrossCostBreakdown,
    validateHouseInternalArea,
    validateNetCostBreakdown,
    validatePreliminariesCostBreakdown,
    validateUnitCount,
  }
}

export default useValidations
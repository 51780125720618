import React, {useEffect, useState} from 'react'
import HeaderAccordion from '../../../../components/accordion/HeaderAccordion'
import {Image} from 'react-bootstrap'
import {v4} from 'uuid'
import {useSelector} from 'react-redux'
import {getSurveyElements} from '../../../../store/selectors/surveySelectors'
import {getBuildingSurveyId, getBuildingSurveyResponseId} from '../../../../store/selectors/buildingSelectors'
import {fetchSurveyGroups} from '../../../../api/surveys/surveys'
import {BULLET_POINT_ICON} from '../../../../constants/svgs'
import Separator from '../../../../components/separator/Separator'
import Skeleton from 'react-loading-skeleton'

const Overview = () => {
  const elements = useSelector(getSurveyElements)

  const Header = ({icon, title}) => (
    <div className='d-flex align-items-center'>
      <Image src={icon} height={24} />
      <div className='heading font-bold font-15 ms-2'>{title}</div>
    </div>
  )

  const Groups = ({element}) => {
    const [groups, setGroups] = useState([])
    const [loading, setLoading] = useState(false)

    const surveyId = useSelector(getBuildingSurveyId)
    const surveyResponseId = useSelector(getBuildingSurveyResponseId)

    useEffect(() => {
      if (surveyId) {
        setLoading(true)
        fetchSurveyGroups(surveyId, element, surveyResponseId)
          .then(({groups}) => setGroups(groups))
          .finally(() => setLoading(false))
      }
    }, [element, surveyId, surveyResponseId])

    return loading
      ? <Skeleton height={30} className='my-2' />
      : groups.map((group, i) => {
        const name = group?.name
        const questions = group?.questions

        return (
          <div>
            <div className='font-10 ms-1'>{name}</div>
            <div>
              {
                questions
                  .sort((first, second) => (first?.sort_order < second?.sort_order ? -1 : 1))
                  .map(question => {
                    const text = question?.text
                    const type = question?.survey_question_type?.slug
                    const answer = question?.survey_answer?.value

                    if ((type === 'boolean' && answer === 'true') || type === 'value') {
                      return (
                        <div className='d-flex'>
                          <Image src={BULLET_POINT_ICON} />
                          <div className='font-13 flex-grow-1'>
                            {
                              type === 'value'
                                ? `${text} (${answer}%)`
                                : text
                            }
                          </div>
                        </div>
                      )
                    }
                    return null
                  })
              }
              {i < groups?.length - 1 && <Separator />}
            </div>
          </div>
        )
      })
  }

  const ACCORDIONS = elements?.map(({element}) => ({
    title: element?.name,
    icon: element?.images?.small_url,
    content: <Groups element={element?.slug} />,
  }))

  return (
    <div className='my-3'>
      {
        ACCORDIONS?.map(({content, icon, title}) => (
          <div key={v4()} className='my-3'>
            <HeaderAccordion
              active
              header={<Header icon={icon} title={title} />}
              hideProgress>
              {
                content && (
                  <div className='p-1'>
                    {content}
                  </div>
                )
              }
            </HeaderAccordion>
          </div>
        ))
      }
    </div>
  )
}

export default Overview

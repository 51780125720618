import React from 'react'
import {array, exact, node, string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import {v4} from 'uuid'
import {Table as BSTable} from 'react-bootstrap'
import styled from 'styled-components'
import {LIGHT_GREY} from '../../constants/colours'

const propTypes = {
  className: string,
  customNoData: node,
  data: exact({
    tableHeadings: array.isRequired,
    tableBody: array.isRequired,
  }).isRequired,
}

const defaultProps = {
  className: '',
  customNoData: null,
  data: {
    tableHeadings: [],
    tableBody: [],
  },
}

const Tr = styled.tr`
  transition: background-color .2s;
  &.clickable:hover {
    cursor: pointer;
    background-color: ${LIGHT_GREY};
  }
`

const Table = ({className, customNoData, data}) => {
  const noData = (
    <div className='p-3 border-bottom'>
      {
        customNoData || <div className='font-light-grey'>{localise('tables.noDataAvailable')}</div>
      }
    </div>
  )

  const renderHeading = (heading, i) => {
    const text = typeof heading === 'string' ? heading : heading?.text
    const attributes = typeof heading === 'string' ? {} : heading?.attributes
    const isObject = typeof text === 'object'
    const htmlCell = !isObject && {dangerouslySetInnerHTML: {__html: text}}
    return (
      <th
        className={`
        border-bottom
        font-13 
        font-bold 
        ps-2
        py-2
        text-capitalize 
        text-nowrap 
        ${attributes?.className}
        ${i < data?.tableHeadings?.length - 1 ? 'border-end' : ''}`}
        key={v4()}
        {...htmlCell}>
        {isObject ? text : null}
      </th>
    )
  }

  const renderRow = row => row?.map(({attributes, cell}, i) => {
    const isObject = typeof cell === 'object'
    const htmlCell = !isObject && {dangerouslySetInnerHTML: {__html: cell}}

    return (
      <td
        {...attributes}
        className={`
      font-13 
      border-bottom 
      py-2 
      ps-2
      ${i < data?.tableHeadings?.length - 1 ? 'border-end' : ''}
      ${attributes?.className}`}
        {...htmlCell}
        key={v4()}>
        {isObject ? cell : null}
      </td>
    )
  })

  return (
    <div className={`table-container border border-bottom-0 ${className}`}>
      {
        data?.tableBody?.length > 0 ?
          <>
            <BSTable size='sm' responsive borderless className='mb-0'>
              <thead>
                <tr>
                  {
                    data?.tableHeadings?.map(renderHeading)
                  }
                </tr>
              </thead>
              <tbody>
                {
                  data?.tableBody?.map(({row, rowAttributes}) => (
                    <Tr {...rowAttributes} key={v4()}>
                      {renderRow(row)}</Tr>)
                  )
                }
              </tbody>
            </BSTable>
          </>
          : noData
      }
    </div>
  )
}

Table.propTypes = propTypes
Table.defaultProps = defaultProps

export default Table

import {PROJECTS_URL} from '../constants/urls'
import {localise} from '../services/LocalizationServices'
import {useSelector} from 'react-redux'
import {getProjectId, getProjectTitle} from '../store/selectors/projectSelectors'

const useProject = () => {
  const projectId = useSelector(getProjectId)
  const title = useSelector(getProjectTitle)

  const editProjectBreadcrumbs = [
    {
      active: false,
      href: PROJECTS_URL,
      text: localise('nav.projects'),
    },
    {
      active: false,
      href: `${PROJECTS_URL}/${projectId}`,
      text: title,
    },
    {
      active: true,
      text: localise('buttonText.edit'),
    },
  ]

  return {editProjectBreadcrumbs}
}

export default useProject

import {Button} from 'react-bootstrap'
import {localise} from '../../../../services/LocalizationServices'
import React from 'react'
import {bool, func, string} from 'prop-types'
import Console from '../../../../utilities/ConsoleUtil'
import useSupplierForm from '../../../../hooks/useSupplierForm'

const propTypes = {
  cancelButtonDisabled: bool,
  continueButtonDisabled: bool,
  onCancelClicked: func,
  onPreviousClicked: func,
  previousButtonDisabled: bool,
  previousButtonHidden: bool,
  onSubmit: func,
  submitText: string,
}

const defaultProps = {
  cancelButtonDisabled: false,
  continueButtonDisabled: false,
  onCancelClicked: () => Console.dev('cancel clicked'),
  onPreviousClicked: () => Console.dev('previous clicked'),
  previousButtonDisabled: false,
  previousButtonHidden: false,
  onSubmit: () => Console.dev('onSubmit clicked'),
  submitText: '',
}

const Buttons = props => {
  const {setSupplierFormDirty} = useSupplierForm()

  const scrollTop = () => window.scrollTo(0, 0)

  const {
    cancelButtonDisabled,
    continueButtonDisabled,
    onCancelClicked,
    onPreviousClicked,
    onSubmit,
    previousButtonDisabled,
    previousButtonHidden,
    submitText,
  } = props

  return (
    <div className='d-flex mt-2'>
      <Button
        className='font-15 me-auto'
        disabled={cancelButtonDisabled}
        onClick={onCancelClicked}
        variant='outline'>
        {localise('buttonText.cancel')}
      </Button>
      <div className='d-flex'>
        <Button
          hidden={previousButtonHidden}
          variant='outline-primary'
          className='font-15 me-2'
          disabled={previousButtonDisabled}
          onClick={() => {
            scrollTop()
            setSupplierFormDirty(false).then(onPreviousClicked)
          }}>
          {localise('buttonText.previous')}
        </Button>
        <Button
          type='button'
          variant='primary'
          className='font-15'
          disabled={continueButtonDisabled}
          onClick={() => {
            scrollTop()
            setSupplierFormDirty(false).then(onSubmit)
          }}>
          {submitText || localise('buttonText.continue')}
        </Button>
      </div>
    </div>
  )
}

Buttons.propTypes = propTypes
Buttons.defaultProps = defaultProps

export default Buttons

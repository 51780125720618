import React from 'react'
import {string} from 'prop-types'
import styled from 'styled-components'
import buildForm from '../../utilities/form-helpers/FormBuilder'

const propTypes = {
  id: string,
}

const defaultProps = {
  id: '',
}

const Dropdown = styled.div` 
  #${({id}) => id} { 
    .${({id}) => id}__control {
      height: 24px !important;
      min-height: 24px !important;
      min-width: 100px;
      max-width: 150px;
      
      .${({id}) => id}__input-container {
        height: 15px;
        .${({id}) => id}__input {
          position: relative;
          bottom: 5px;
        }
      }
      .${({id}) => id}__value-container { height: 24px }
      .${({id}) => id}__indicators {
        height: 24px;
        width: 25px;
        &:after {
          border-left: 0;
          right: 10px;
        }
        .${({id}) => id}__indicator { display: none }
      }
    } 
  }
`

const SmallDropdown = ({id, ...rest}) =>
  <Dropdown id={id}>
    {
      buildForm([{
        menuPortalTarget: document.body,
        ...rest,
        type: 'dropdown',
        id,
        classNamePrefix: id,
      }])
    }
  </Dropdown>

SmallDropdown.propTypes = propTypes
SmallDropdown.defaultProps = defaultProps

export default SmallDropdown


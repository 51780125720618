import React from 'react'
import {array, arrayOf, bool, object, string} from 'prop-types'
import 'chartkick/chart.js'
import {PieChart as ChartkickPieChart} from 'react-chartkick'
import {LIGHT_GREEN} from '../../constants/colours'
import {externalTooltipHandler} from './chart-js/tooltip'

const propTypes = {
  className: string,
  colors: arrayOf(string),
  data: array,
  hideLegend: bool,
  legendPosition: string,
  libraryProps: object,
}

const defaultProps = {
  className: '',
  colors: [LIGHT_GREEN],
  data: [],
  hideLegend: false,
  legendPosition: 'bottom',
  libraryProps: {},
}

const PieChart = ({className, colors, data, hideLegend, legendPosition, libraryProps, unitSymbol, ...rest}) =>
  <div className={className}>
    <ChartkickPieChart
      colors={colors}
      data={data}
      legend={!hideLegend}
      round={2}
      library={{
        animation: {
          duration: 500,
        },
        layout: {
          padding: 20,
        },
        ...libraryProps,
        plugins: {
          legend: {
            position: legendPosition || 'bottom',
            labels: {
              usePointStyle: true,
              padding: 16,
              font: {
                size: 12,
                weight: 'bold',
              },
            },
          },
          tooltip: {
            enabled: false,
            external: externalTooltipHandler,
            position: 'nearest',
          },
          ...libraryProps.plugins,
        },
      }}
      {...rest}
    />
  </div>


PieChart.propTypes = propTypes
PieChart.defaultProps = defaultProps

export default PieChart

import {combineReducers} from 'redux'
import {CLEAR_STATE} from '../../constants/reduxTypes'
import {buildingGrossCostReducer} from './buildingGrossCostSlice'
import {buildingNetCostReducer} from './buildingNetCostSlice'
import {buildingOutputReducer} from './buildingOutputSlice'
import {buildingPreliminariesCostReducer} from './buildingPreliminariesCostSlice'
import {buildingProgrammeReducer} from './buildingProgrammeSlice'
import {buildingReducer} from './buildingSlice'
import {configurationReducer} from './configurationSlice'
import {currentUserReducer} from './currentUserSlice'
import {loadingIndicatorReducer} from './loadingIndicatorSlice'
import {projectReducer} from './projectSlice'
import {selectedSuppliersReducer} from './selectedSuppliersSlice'
import {userPreferenceReducer} from './userPreferenceSlice'
import {surveyReducer} from './surveySlice'
import {thresholdsReducer} from './thresholdsSlice'
import {surveyAnswersReducer} from './surveyAnswersSlice'

const appReducer = combineReducers({
  building: buildingReducer,
  buildingGrossCost: buildingGrossCostReducer,
  buildingNetCost: buildingNetCostReducer,
  buildingOutput: buildingOutputReducer,
  buildingPreliminariesCost: buildingPreliminariesCostReducer,
  buildingProgramme: buildingProgrammeReducer,
  configuration: configurationReducer,
  currentUser: currentUserReducer,
  loading: loadingIndicatorReducer,
  project: projectReducer,
  selectedSuppliers: selectedSuppliersReducer,
  surveyAnswers: surveyAnswersReducer,
  survey: surveyReducer,
  thresholds: thresholdsReducer,
  userPreference: userPreferenceReducer,
})

export default (state, action) => {
  if (action.type === CLEAR_STATE) {
    state = undefined
  }

  return appReducer(state, action)
}

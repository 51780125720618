import React, {useState} from 'react'
import ProjectCreateFlow from '../../../flows/ProjectCreateFlow'
import {localise} from '../../../../services/LocalizationServices'
import Buttons from '../common/Buttons'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectId} from '../../../../store/selectors/projectSelectors'
import {setProject} from '../../../../store/slices/projectSlice'
import {FUNDING_URL, NEW_URL, PROJECTS_URL} from '../../../../constants/urls'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {updateProject} from '../../../../api/project/project'
import AddressForm from '../../form/AddressForm'

const Address = () => {
  const {push} = useHistory()
  const dispatch = useDispatch()

  const [submittable, setSubmittable] = useState(false)

  const projectId = useSelector(getProjectId)

  const handleSubmit = address => {
    toggleLoading()
    updateProject(projectId, {address})
      .then(project => {
        dispatch(setProject(project))
        push(`${PROJECTS_URL}${NEW_URL}${FUNDING_URL}`)
      })
      .finally(toggleLoading)
  }

  const toProjects = () => push(PROJECTS_URL)
  const toDetails = () => push(`${PROJECTS_URL}${NEW_URL}`)

  return (
    <ProjectCreateFlow activeStep={1} title={localise('headings.projectAddress')}>
      <AddressForm
        onSubmit={handleSubmit}
        isSubmittable={setSubmittable}
        buttons={(
          <Buttons
            continueButtonDisabled={!submittable}
            onCancelClicked={toProjects}
            onPreviousClicked={toDetails}
          />
        )}
      />
    </ProjectCreateFlow>
  )
}
export default Address
export const getCurrentUserCurrency = state => state?.currentUser?.currency
export const getCurrentUserCurrencySymbol = state => state?.currentUser?.user_preference?.currency?.symbol
export const getCurrentUserCurrentRole = state => state?.currentUser?.current_role
export const getCurrentUserDraftProjectId = state => state?.currentUser?.current_draft_project_id
export const getCurrentUserEmail = state => state?.currentUser?.email
export const getCurrentUserFirstName = state => state?.currentUser?.first_name
export const getCurrentUserId = state => state?.currentUser?.id
export const getCurrentUserLastName = state => state?.currentUser?.last_name
export const getCurrentUserOrganisation = state => state?.currentUser?.organisation
export const getCurrentUserPaymentsOverview= state => state?.currentUser?.payments_overview
export const getCurrentUserPaymentsOverviewAllowanceFreemium= state =>
  state?.currentUser?.payments_overview?.allowances.freemium
export const getCurrentUserPaymentsOverviewAllowancePurchased = state =>
  state?.currentUser?.payments_overview?.allowances.purchased
export const getCurrentUserPaymentsOverviewCanCreateProject = state =>
  state?.currentUser?.payments_overview?.can_create_project
export const getCurrentUserPaymentsOverviewUnusedPurchase = state =>
  state?.currentUser?.payments_overview?.unused_purchases
export const getCurrentUserSubscription = state => state?.currentUser?.subscription
export const isCurrentUserActive = state => state?.currentUser?.active

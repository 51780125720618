import {createSlice} from '@reduxjs/toolkit'

const buildingGrossCostSlice = createSlice({
  name: 'buildingGrossCost',
  initialState: {},
  reducers: {
    setBuildingGrossCost: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearBuildingGrossCost: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const buildingGrossCostReducer = buildingGrossCostSlice.reducer
export const {clearBuildingGrossCost, setBuildingGrossCost} = buildingGrossCostSlice.actions

import React from 'react'
import {node, number, string} from 'prop-types'
import FlowView from '../common/FlowView'
import {BANK_BUILDING, MAP_MARKER, PROJECT_SETUP_ICON} from '../../constants/svgs'
import {PROJECTS_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'

const propTypes = {
  activeStep: number,
  children: node,
  className: string,
  title: string,
}

const defaultProps = {
  activeStep: 0,
  children: null,
  className: '',
  title: '',
}

const ProjectCreateFlow = ({activeStep, children, className, title}) => {
  const icons = [
    PROJECT_SETUP_ICON,
    MAP_MARKER,
    BANK_BUILDING,
  ]

  return (
    <FlowView
      activeStep={activeStep}
      breadcrumbs={[
        {
          active: false,
          href: PROJECTS_URL,
          text: localise('nav.projects'),
        }, {
          active: true,
          text: localise('nav.createProject'),
        },
      ]}
      className={className}
      icons={icons}>
      <div className='font-20 font-bold my-2'>{title}</div>
      {children}
    </FlowView>
  )
}

ProjectCreateFlow.propTypes = propTypes
ProjectCreateFlow.defaultProps = defaultProps

export default ProjectCreateFlow

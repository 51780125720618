import React from 'react'
import {bool, number, shape, string} from 'prop-types'
import LogEntryCard from './LogEntryCard'
import {Col} from 'react-bootstrap'
import {localise} from '../../../services/LocalizationServices'

const propTypes = {
  log: shape({
    id: string,
    base_gross_cost_cents: {
      amount: number,
      symbol: string,
      display: string,
    },
    projected_gross_cost_cents: {
      amount: string,
      symbol: string,
      display: string,
    },
    cost_certainty_percentage: number,
    active: bool,
    created_at: number,
  }),
}

const defaultProps = {
  log: {},
}

const CostCertaintyLog = ({log, ...rest}) => {
  const section = ({body, bodyClasses, title}) =>
    <>
      <div className='font-10'>{title}</div>
      <div className={`font-17 ${bodyClasses}`}>
        {body}
      </div>
    </>

  return (
    <LogEntryCard {...rest}>
      {
        <>
          <Col>
            {
              section({
                title: localise('headings.baseGrossConstructionCost'),
                body: log?.base_gross_cost_cents?.display,
              })
            }
          </Col>
          <Col>
            {
              section({
                title: localise('headings.projectedGrossConstructionCost'),
                body: log?.projected_gross_cost_cents?.display,
              })
            }
          </Col>
          <Col>
            {
              section({
                title: localise('headings.costCertainty'),
                body: `${log?.cost_certainty_percentage}%`,
                bodyClasses: 'font-bold',
              })
            }
          </Col>
        </>
      }
    </LogEntryCard>
  )
}
CostCertaintyLog.propTypes = propTypes
CostCertaintyLog.defaultProps = defaultProps

export default CostCertaintyLog

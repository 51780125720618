import {createSlice} from '@reduxjs/toolkit'

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState: {},
  reducers: {
    setCurrentUser: (state, action) => {
      if (action?.payload?.payments_overview?.subscription) {
        action.payload.subscription = action?.payload?.payments_overview?.subscription
      }

      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearCurrentUserSubscription: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const currentUserReducer = currentUserSlice.reducer
export const {clearCurrentUserSubscription, setCurrentUser} = currentUserSlice.actions
import React, {useEffect, useState} from 'react'
import {bool, func, string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {Button} from 'react-bootstrap'
import Modal from './Modal'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {createLogs, fetchLogs} from '../../api/logs/logs'
import {useParams} from 'react-router'
import {useSelector} from 'react-redux'
import {toast} from 'react-hot-toast'
import {
  getUserPreferenceCurrencySymbol,
  getUserPreferenceUnitOfMeasurementDisplay,
} from '../../store/selectors/userPreferenceSelectors'

const propTypes = {
  className: string,
  onCancel: func,
  show: bool,
  toggle: func,
}

const defaultProps = {
  className: '',
  onCancel: () => Console.dev('on cancel'),
  show: false,
  toggle: () => Console.dev('click toggle prop'),
}

const UpdateLogModal = ({className, onCancel, show, toggle}) => {
  const {slug} = useParams()
  const currencySymbol = useSelector(getUserPreferenceCurrencySymbol)
  const unitOfMeasurement = `(${useSelector(getUserPreferenceUnitOfMeasurementDisplay)})`

  const [gia, setGia] = useState(null)
  const [injuries, setInjuries] = useState(null)
  const [manHours, setManHours] = useState(null)
  const [projectedGrossConstructionCost, setProjectedGrossConstructionCost] = useState(null)
  const [projectedProgrammeDuration, setProjectedProgrammeDuration] = useState(null)
  const [totalWaste, setTotalWaste] = useState(null)
  const [validated, setValidated] = useState(false)
  const [valueOfWorksToDate, setValueOfWorksToDate] = useState(null)

  const fields = [
    {
      handler: setProjectedGrossConstructionCost,
      label: localise('logs.projectedGrossConstructionCost'),
      name: 'projectedGrossConstructionCost',
      placeholder: ' ',
      prepend: currencySymbol,
      type: 'number',
      value: projectedGrossConstructionCost,
    },
    {
      additionalLabelClasses: 'mt-2',
      handler: setProjectedProgrammeDuration,
      label: localise('logs.projectedProgrammeDurationWeeks'),
      name: 'projectedProgrammeDurationWeeks',
      placeholder: ' ',
      type: 'number',
      value: projectedProgrammeDuration,
    },
    {
      additionalLabelClasses: 'mt-2',
      handler: setValueOfWorksToDate,
      label: localise('logs.valueOfWorksToDate'),
      name: 'valueOfWorksToDate',
      placeholder: ' ',
      prepend: currencySymbol,
      type: 'number',
      value: valueOfWorksToDate,
    },
    {
      additionalLabelClasses: 'mt-2',
      handler: setManHours,
      label: localise('logs.manHoursToDate'),
      name: 'manHoursToDate',
      placeholder: ' ',
      type: 'number',
      value: manHours,
    },
    {
      additionalLabelClasses: 'mt-2',
      handler: setTotalWaste,
      label: localise('logs.totalWasteToDate', {unitOfMeasurement}),
      name: 'totalWasteToDate',
      placeholder: ' ',
      type: 'number',
      value: totalWaste,
    },
    {
      additionalLabelClasses: 'mt-2',
      handler: setInjuries,
      label: localise('logs.injuriesToDate'),
      name: 'injuriesToDate',
      placeholder: ' ',
      type: 'number',
      value: injuries,
    },
    {
      additionalLabelClasses: 'mt-2',
      handler: setGia,
      label: localise('logs.gtaToDate', {unitOfMeasurement}),
      name: 'gtaToDate',
      placeholder: ' ',
      type: 'number',
      value: gia,
    },
  ]

  useEffect(() => {
    toggleLoading()
    fetchLogs(slug).then(({
      current_cost_certainty_log,
      current_programme_certainty_log,
      current_productivity_log,
      current_waste_log,
      current_speed_log,
      current_safety_log,
    }) => {
      setProjectedGrossConstructionCost((current_cost_certainty_log?.projected_gross_cost_cents?.amount/100).toFixed(2))
      setProjectedProgrammeDuration(current_programme_certainty_log?.base_programme_duration_weeks)
      setValueOfWorksToDate((current_productivity_log?.value_of_works_cents?.amount/100).toFixed(2))
      setManHours(current_safety_log?.man_hours)
      setTotalWaste(current_waste_log?.total_waste)
      setInjuries(current_safety_log?.injuries_count)
      setGia(current_speed_log?.gross_internal_area)
    })
      .finally(toggleLoading)
  }, [slug])

  useEffect(() => {
    setValidated(
      Number(gia) > -1 &&
      Number(injuries) > -1 &&
      Number(manHours) > -1 &&
      Number(projectedGrossConstructionCost) > -1  &&
      Number(projectedProgrammeDuration) > -1 &&
      Number(totalWaste) > -1 &&
      Number(valueOfWorksToDate) > -1
    )
    // eslint-disable-next-line
  }, [
    gia,
    injuries,
    manHours,
    projectedGrossConstructionCost,
    projectedProgrammeDuration,
    totalWaste,
    valueOfWorksToDate,
  ])

  const updateLog = () => {
    toggleLoading()
    const payload = {
      cost_certainty_log: {
        projected_gross_cost_cents: projectedGrossConstructionCost * 100,
      },
      programme_certainty_log: {projected_programme_duration_weeks: projectedProgrammeDuration},
      productivity_log: {
        value_of_works_cents: valueOfWorksToDate * 100,
        man_hours: manHours,
      },
      safety_log: {injuries_count: injuries},
      speed_log: {gross_internal_area: gia},
      waste_log: {total_waste: totalWaste},
    }
    createLogs(slug, payload)
      .then(() => {
        toast.success(localise('toast.logsSuccessfullyUpdated'))
        toggle()
      })
      .finally(toggleLoading)
  }

  return (
    <Modal
      bodyElement={buildForm(fields)}
      className={className}
      headerText={localise('headings.updateLog')}
      show={show}
      toggle={onCancel}
      footerElement={<>
        <Button variant='outline' className='font-15 me-3' onClick={onCancel}>
          {localise('buttonText.cancel')}
        </Button>
        <Button variant='primary' className='font-15' disabled={!validated} onClick={updateLog}>
          {localise('buttonText.confirm')}
        </Button>
      </>}
    />
  )
}

UpdateLogModal.propTypes = propTypes
UpdateLogModal.defaultProps = defaultProps

export default UpdateLogModal

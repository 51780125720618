import React from 'react'
import {bool, number, shape, string} from 'prop-types'
import LogEntryCard from './LogEntryCard'
import {Col} from 'react-bootstrap'
import {localise} from '../../../services/LocalizationServices'

const propTypes = {
  log: shape({
    active: bool,
    created_at: number,
    id: string,
    injuries_count: number,
    man_hours: number,
    safety_score: number,
  }),
}

const defaultProps = {
  log: {},
}

const SafetyLog = ({log, ...rest}) => {
  const section = ({body, bodyClasses, title}) =>
    <>
      <div className='font-10'>{title}</div>
      <div className={`font-17 ${bodyClasses}`}>
        {body}
      </div>
    </>

  return (
    <LogEntryCard {...rest}>
      {
        <>
          <Col>
            {
              section({
                title: localise('headings.manHoursToDate'),
                body: log?.man_hours,
              })
            }
          </Col>
          <Col>
            {
              section({
                title: localise('headings.injuriesToDate'),
                body: log?.injuries_count,
              })
            }
          </Col>
          <Col>
            {
              section({
                title: localise('headings.healthAndSafety'),
                body: log?.safety_score,
                bodyClasses: 'font-bold',
              })
            }
          </Col>
        </>
      }
    </LogEntryCard>
  )
}

SafetyLog.propTypes = propTypes
SafetyLog.defaultProps = defaultProps

export default SafetyLog

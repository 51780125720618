import React, {useState} from 'react'
import {bool, func, node, string} from 'prop-types'
import {FormCheck} from 'react-bootstrap'
import Console from '../../utilities/ConsoleUtil'

const propTypes = {
  checked: bool,
  className: string,
  hideValidUI: bool,
  label: node,
  id: string,
  onChange: func,
}

const defaultProps = {
  checked: false,
  className: '',
  hideValidUI: false,
  label: '',
  id: null,
  onChange: ({target: {id}}) => Console.dev({id}),
}

const Radio = ({className, hideValidUI, label, onChange, ...rest}) => {
  const [mouseEntered, setMouseEntered] = useState(false)

  return (
    <FormCheck custom className={`${className} ${hideValidUI && 'prevent-valid-validation-ui'}`}>
      <FormCheck.Input
        className='cursor-pointer'
        onMouseEnter={() => setMouseEntered(true)}
        onMouseLeave={() => setMouseEntered(false)}
        type='radio'
        onChange={({target: {id}}) => mouseEntered ? onChange(id) : null}
        {...rest}
      />
      <FormCheck.Label>
        <div className='font-13 ms-2'>{label}</div>
      </FormCheck.Label>
    </FormCheck>
  )
}


Radio.propTypes = propTypes
Radio.defaultProps = defaultProps

export default Radio

import React from 'react'
import {bool, func, number, string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'
import {SUCCESS_PROJECT_ICON} from '../../constants/svgs'
import IconBadge from '../badge/IconBadge'
import {Button} from 'react-bootstrap'
import Modal from './Modal'
import {LIGHT_PINK} from '../../constants/colours'

const propTypes = {
  animation: bool,
  className: string,
  amount: number,
  onConfirm: func,
  show: bool,
  toggle: func,
}

const defaultProps = {
  animation: true,
  className: '',
  amount: null,
  onConfirm: () => Console.dev('click onConfirm prop'),
  show: false,
  toggle: () => Console.dev('click toggle prop'),
}

const FreeProjectsLeftModal = ({animation, className, amount, onConfirm, show, toggle}) =>
  <Modal
    animation={animation}
    className={`entity-modal-container ${className}`}
    toggle={toggle}
    show={show}
    bodyElement={<>
      <div className='border-0 mx-auto'>
        <div className='mt-3 d-flex justify-content-center'>
          <IconBadge size={50} src={SUCCESS_PROJECT_ICON} colour={LIGHT_PINK}/>
        </div>
        <div className='text-center mt-3'>
          <div className='font-bold font-20 pb-4'>
            {localise('modalText.freeProjectsLeftAvailable', {amount})}
          </div>
          <Button
            className='px-4 mt-3 font-15'
            variant='primary'
            onClick={() => {
              toggle()
              onConfirm()
            }}>
            {localise('buttonText.continue')}
          </Button>
        </div>
      </div>
    </>}
  />

FreeProjectsLeftModal.propTypes = propTypes
FreeProjectsLeftModal.defaultProps = defaultProps

export default FreeProjectsLeftModal

import React from 'react'
import {string} from 'prop-types'
import {Image} from 'react-bootstrap'
import {INFO_ICON} from '../../constants/svgs'
import Tooltip from './Tooltip'
import styled from 'styled-components'

const propTypes = {
  className: string,
  text: string,
}

const defaultProps = {
  className: '',
  text: '',
}

const StyledImage = styled(Image)`
  transition: opacity .3s;
  &:hover {
    cursor: pointer;
    opacity: .3;
  }
`

const InfoTooltip = ({className, text}) => {
  return (
    <Tooltip placement='auto-end' text={text} trigger={['hover', 'focus', 'click']}>
      <StyledImage
        className={className}
        src={INFO_ICON}
        width={16}
      />
    </Tooltip>
  )
}

InfoTooltip.propTypes = propTypes
InfoTooltip.defaultProps = defaultProps

export default InfoTooltip
import React, {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {toggleLoading} from '../../../utilities/LoadingIndicatorUtil'
import {fetchBuildings} from '../../../api/building/building'
import {Button, Col, Row} from 'react-bootstrap'
import {localise} from '../../../services/LocalizationServices'
import TabMenu from '../../../components/navs/TabMenu'
import Buildings from './Buildings'
import ProjectReport from './ProjectReport'
import ProjectLogs from '../logs/ProjectLogs'
import {BUILDINGS_URL, NEW_URL, PROJECTS_URL} from '../../../constants/urls'
import {useDispatch, useSelector} from 'react-redux'
import {clearBuilding, setBuilding} from '../../../store/slices/buildingSlice'
import UpdateLogModal from '../../../components/modals/UpdateLogModal'
import BuildingPlaceholder from '../../../components/placeholders/BuildingPlaceholder'
import {fetchReports} from '../../../api/report/report'
import {getBuildingId, getBuildingName} from '../../../store/selectors/buildingSelectors'
import {getProjectId} from '../../../store/selectors/projectSelectors'
import SmallDropdown from '../../../components/inputs/SmallDropdown'
import useSupportEmail from '../../../hooks/useSupportEmail'

const BUILDINGS = 'buildings'
const LOG = 'log'
const REPORT = 'report'

const BUILDING_ID_SELECT = 'building-id-select'

const BuildingsSection = () => {
  const dispatch = useDispatch()
  const {push} = useHistory()
  const {slug} = useParams()

  const buildingId = useSelector(getBuildingId)
  const buildingName = useSelector(getBuildingName)
  const projectId = useSelector(getProjectId)

  const [buildings, setBuildings] = useState([])
  const [currentEventKey, setCurrentEventKey] = useState(BUILDINGS)
  const [options, setOptions] = useState([])
  const [report, setReport] = useState({})
  const [showUpdateLog, setShowUpdateLog] = useState(false)
  const [total, setTotal] = useState([])

  const toggleUpdateLogModal = () => setShowUpdateLog(!showUpdateLog)

  const createReportRequestEmail = useSupportEmail(
    localise('support.reportRequest'),
    [
      {
        name: localise('support.buildingId'),
        value: buildingId,
      },
      {
        name: localise('support.buildingName'),
        value: buildingName,
      },
      {
        name: localise('support.projectId'),
        value: projectId,
      },
    ],
    localise('support.reportRequestMsg')
  )

  const loadReport = () => {
    if (currentEventKey === REPORT) {
      toggleLoading()
      if (projectId) {
        fetchReports(projectId, buildingId)
          .then(({report}) => setReport(report))
          .finally(toggleLoading)
      }
    }
  }

  useEffect(() => {
    toggleLoading()
    dispatch(clearBuilding())
    fetchBuildings(slug)
      .then(({buildings, total}) => {
        setBuildings(buildings)
        setTotal(total)
        dispatch(setBuilding(buildings[0]))
      })
      .finally(toggleLoading)
  }, [dispatch, slug])

  useEffect(() => {
    loadReport()
    // eslint-disable-next-line
  }, [buildingId])

  useEffect(() => {
    loadReport()
    // eslint-disable-next-line
  }, [currentEventKey])

  useEffect(() => {
    setOptions(buildings?.map(({id: value, name: label}) => ({value, label})))
  }, [buildings])

  const toBuildingCreate = () => {
    dispatch(clearBuilding())
    push(`${PROJECTS_URL}/${slug}${BUILDINGS_URL}${NEW_URL}`)
  }

  const toBuildingEdit = id => {
    dispatch(clearBuilding())
    push(`${PROJECTS_URL}/${slug}${BUILDINGS_URL}/${id}`)
  }

  const views = {
    [BUILDINGS]: <Buildings list={buildings} onClick={toBuildingEdit} />,
    [REPORT]: <ProjectReport report={report}/>,
    [LOG]: <ProjectLogs show={showUpdateLog}/>,
  }

  return (
    <>
      <Row className='mb-5'>
        <Col xs='12'>
          <div className='d-flex flex-column flex-lg-row align-items-lg-center'>
            <div>
              {
                total < 1
                  ?  <div className='font-15'>{localise('headings.buildingsTitle')}</div>
                  : (
                    <TabMenu
                      data={[
                        {
                          eventKey: BUILDINGS,
                          title: localise('nav.buildings'),
                          tabClassName: `font-15 ${currentEventKey === BUILDINGS && 'active'}`,
                        },
                        {
                          eventKey: REPORT,
                          title: localise('nav.report'),
                          tabClassName: `font-15 ${currentEventKey === REPORT && 'active'}`,
                        },
                        {
                          eventKey: LOG,
                          title: localise('nav.log'),
                          tabClassName: `font-15 ${currentEventKey === LOG && 'active'}`,
                        },
                      ]}
                      onSelect={setCurrentEventKey}
                    />
                  )
              }
            </div>
            <div className='ms-lg-auto mt-lg-0 mt-3'>
              {
                currentEventKey === BUILDINGS &&
                <Button
                  variant='primary'
                  className='font-12 py-1'
                  onClick={toBuildingCreate}>
                  {localise('buttonText.createBuilding')}
                </Button>
              }
              {
                total > 0 && currentEventKey === LOG &&
                <Button
                  variant='primary'
                  className='font-12 py-1'
                  onClick={() => setShowUpdateLog(true)}>
                  {localise('buttonText.updateLog')}
                </Button>
              }
              {
                currentEventKey === REPORT &&
                <div className='d-flex flex-row'>
                  {
                    report &&
                    <Button
                      className='font-12 text-nowrap me-2 py-0'
                      onClick={createReportRequestEmail}>
                      {localise('buttonText.reportRequest')}
                    </Button>
                  }
                  <SmallDropdown
                    id={BUILDING_ID_SELECT}
                    value={buildingId && buildingName ? {value: buildingId, label: buildingName} : null}
                    handler={e => {
                      const id = e?.value
                      const name = e?.label
                      if (id && name) dispatch(setBuilding({id, name}))
                    }}
                    name='building-ids'
                    options={options}
                  />
                </div>
              }
            </div>
          </div>
        </Col>
        <Col xs='12' className='my-3'>
          {
            total < 1 ? <BuildingPlaceholder /> : views[currentEventKey]
          }
        </Col>
      </Row>
      <UpdateLogModal
        className='top-0'
        onCancel={toggleUpdateLogModal}
        show={showUpdateLog}
        toggle={() => setShowUpdateLog(false)}
      />
    </>
  )
}

export default BuildingsSection

import React from 'react'
import {array, element, number, oneOf, oneOfType, string} from 'prop-types'
import {OverlayTrigger, Button, Tooltip as BSToolTip} from 'react-bootstrap'
import styled from 'styled-components'

const propTypes = {
  className: string,
  children: element,
  delay: number,
  placement: oneOf([
    'auto-start',
    'auto',
    'auto-end',
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-end',
    'bottom',
    'bottom-start',
    'left-end',
    'left',
    'left-start',
  ]),
  text: string,
  trigger: oneOfType([array, string]),
}

const defaultProps = {
  className: '',
  children: <Button>defaultButton</Button>,
  delay: 200,
  placement: 'top',
  text: 'tooltipText',
  trigger: ['hover', 'focus'],
}

const StyledToolTip = styled(BSToolTip)`
  &.show {opacity: 1;}
  .tooltip-arrow {display: none}
  .tooltip-inner {
    padding: 15px 16px 13px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.17);
    background-color: white;
    color: black;
    text-align: left;
    font-size: 13px;
  }
`

const Tooltip = ({className, children, delay, placement, text, trigger}) => {
  const renderTooltip = props => (
    <StyledToolTip className={`tooltip ${className}`} {...props}>{text}</StyledToolTip>
  )

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      delay={delay}
      overlay={renderTooltip}>
      {children}
    </OverlayTrigger>
  )
}

Tooltip.propTypes = propTypes
Tooltip.defaultProps = defaultProps

export default Tooltip
import {
  FIT_OUT_URL,
  MECHANICAL_ELECTRICAL_PLUMBING_URL,
  NEW_URL,
  PRELIMINARIES_URL,
  PROJECTS_URL,
  SUBSTRUCTURE_URL,
  SUPERSTRUCTURE_URL,
  SUPPLIERS_URL,
} from '../constants/urls'
import {useParams} from 'react-router'
import {setSelectedSuppliers} from '../store/slices/selectedSuppliersSlice'
import {useDispatch} from 'react-redux'

const useSupplierForm = () => {
  const dispatch = useDispatch()
  const {slug} = useParams()
  const projectSupplierFormUrl = `${PROJECTS_URL}/${slug}${SUPPLIERS_URL}${NEW_URL}`

  const SUBSTRUCTURE_STEP_URL = `${projectSupplierFormUrl}${SUBSTRUCTURE_URL}`
  const SUPERSTRUCTURE_STEP_URL = `${projectSupplierFormUrl}${SUPERSTRUCTURE_URL}`
  const FIT_OUT_STEP_URL = `${projectSupplierFormUrl}${FIT_OUT_URL}`
  const MECHANICAL_ELECTRICAL_PLUMBING_STEP_URL = `${projectSupplierFormUrl}${MECHANICAL_ELECTRICAL_PLUMBING_URL}`
  const PRELIMINARIES_STEP_URL = `${projectSupplierFormUrl}${PRELIMINARIES_URL}`

  const setSupplierFormDirty = (isDirty = true) => {
    return new Promise(resolve => {
      dispatch(setSelectedSuppliers({dirty: isDirty}))
      resolve()
    })
  }

  return {
    SUBSTRUCTURE_STEP_URL,
    SUPERSTRUCTURE_STEP_URL,
    FIT_OUT_STEP_URL,
    MECHANICAL_ELECTRICAL_PLUMBING_STEP_URL,
    PRELIMINARIES_STEP_URL,
    projectSupplierFormUrl,
    setSupplierFormDirty,
  }
}

export default useSupplierForm

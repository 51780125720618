import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import Details from '../views/account/Details'
import {
  DETAILS_URL,
  PASSWORD_URL,
  PAYMENT_HISTORY_URL,
  PAYMENT_URL,
  PREFERENCES_URL,
  SUBSCRIPTIONS_URL,
} from '../constants/urls'
import Password from '../views/account/Password'
import Preferences from '../views/account/Preferences'
import RouteWithLayout from './RouteWithLayout'
import Subscription from '../views/account/Subscription'
import PaymentHistory from '../views/subscriptions/PaymentHistory'
import SubscriptionPayment from '../views/project/purchase/SubscriptionPayment'
import PageNotFound from '../views/error/PageNotFound'
import useNav from '../hooks/useNav'

const AccountRoutes = ({path}) => {
  const {isFreeTier} = useNav()

  return (
    <Switch>
      <RouteWithLayout exact path={path}><Redirect to={`${path}${DETAILS_URL}`} /></RouteWithLayout>
      <RouteWithLayout exact path={`${path}${DETAILS_URL}`} component={Details} />
      <RouteWithLayout exact path={`${path}${PASSWORD_URL}`} component={Password}/>
      <RouteWithLayout exact path={`${path}${PREFERENCES_URL}`} component={Preferences}/>
      {
        !isFreeTier &&
          <>
            <RouteWithLayout exact path={`${path}${SUBSCRIPTIONS_URL}`} component={Subscription}/>
            <RouteWithLayout
              exact
              path={`${path}${SUBSCRIPTIONS_URL}${PAYMENT_HISTORY_URL}`}
              component={PaymentHistory}
            />
            <RouteWithLayout exact path={`${path}${SUBSCRIPTIONS_URL}${PAYMENT_URL}`} component={SubscriptionPayment}/>
          </>
      }
      <Route path='*'><PageNotFound /></Route>
    </Switch>
  )
}

export default AccountRoutes

import React from 'react'
import {arrayOf, exact, number, string} from 'prop-types'
import 'chartkick/chart.js'
import {BarChart as ChartkickBarChart} from 'react-chartkick'
import {GREY, LIGHT_GREEN} from '../../constants/colours'
import {useSelector} from 'react-redux'
import {getCurrentUserCurrencySymbol} from '../../store/selectors/currentUserSelectors'
import {getUserPreferenceCurrencySymbol} from '../../store/selectors/userPreferenceSelectors'

const propTypes = {
  className: string,
  colors: arrayOf(string),
  data: arrayOf(
    exact({
      title: string,
      value: number,
    })
  ),
}

const defaultProps = {
  className: '',
  colors: [LIGHT_GREEN],
  data: [],
}

const BarChart = ({className, colors, data, libraryProps, ...rest}) => {
  const userCurrencySymbol = useSelector(getCurrentUserCurrencySymbol || getUserPreferenceCurrencySymbol)

  return (
    <div className={className}>
      <ChartkickBarChart
        colors={colors}
        data={data}
        library={{
          indexAxis: 'y',
          scales: {
            x: {
              grid: {
                drawBorder: false,
                drawTicks: false,
              },
              ticks: {
                callback (value, index, ticks) {
                  if (index === 0 || index === ticks?.length - 1) {
                    // eslint-disable-next-line
                    return `${userCurrencySymbol}${Number(parseFloat(value)/100)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
                  }
                  return ''
                },
                color: GREY,
              },
            },
            y: {grid: {drawTicks: false}},
          },
          ...libraryProps,
        }}
        {...rest}
      />
    </div>
  )
}

BarChart.propTypes = propTypes
BarChart.defaultProps = defaultProps

export default BarChart

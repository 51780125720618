import React from 'react'
import {bool, func, string} from 'prop-types'
import EntityCreateSuccessModal from './EntityCreateSuccessModal'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'

const propTypes = {
  className: string,
  onConfirm: func,
  show: bool,
  toggle: func,
}

const defaultProps = {
  className: '',
  onConfirm: () => Console.dev('click onConfirm prop'),
  show: false,
  toggle: () => Console.dev('click toggle prop'),
}

const BuildingCreateSuccessModal = ({className, onConfirm, show, toggle}) =>
  <EntityCreateSuccessModal
    className={className}
    descriptionLine1={localise('modalText.youCanViewBuilding')}
    descriptionLine2={localise('modalText.projectDashboard')}
    onConfirm={onConfirm}
    show={show}
    titleLine1={localise('modalText.success')}
    titleLine2={localise('modalText.buildingWasCreated')}
    toggle={toggle}
  />

BuildingCreateSuccessModal.propTypes = propTypes
BuildingCreateSuccessModal.defaultProps = defaultProps

export default BuildingCreateSuccessModal

import {createSlice} from '@reduxjs/toolkit'

const buildingOutputSlice = createSlice({
  name: 'buildingOutput',
  initialState: {},
  reducers: {
    setBuildingOutput: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearBuildingOutput: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const buildingOutputReducer = buildingOutputSlice.reducer
export const {clearBuildingOutput, setBuildingOutput} = buildingOutputSlice.actions

import React from 'react'
import {Col} from 'react-bootstrap'
import SideNav from '../../components/navs/SideNav'
import useNav from '../../hooks/useNav'
import {array, node} from 'prop-types'
import BreadCrumbsView from './BreadcrumbsView'

const propTypes = {
  breadcrumbs: array,
  children: node,
  navList: array,
}

const defaultProps = {
  breadcrumbs: null,
  children: null,
  navList: [],
}

const SideNavView = ({breadcrumbs, children, navList}) => {
  const {breakpoint} = useNav()

  const sideNavSection = (
    <Col xs='12' lg={{span: 10, offset: 1}}>
      <div className='d-flex flex-wrap'>
        <SideNav navList={navList} className={`d-none d-${breakpoint}-block`} />
        <div className={`ms-sm-0 ms-${breakpoint}-4 w-50 w-${breakpoint}-100`}>
          {children}
        </div>
      </div>
    </Col>
  )


  return breadcrumbs
    ? (
      <BreadCrumbsView breadcrumbItems={breadcrumbs}>
        {sideNavSection}
      </BreadCrumbsView>
    )
    : (
      <div className='mt-4'>{sideNavSection}</div>
    )
}

SideNavView.propTypes = propTypes
SideNavView.defaultProps = defaultProps

export default SideNavView

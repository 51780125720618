import intl from 'react-intl-universal'
export const localise = (key, params) => {
  if (!key) {return ''}
  return intl.get(key, params)
}

export const localiseWithHTML = (key, params) => {
  if (!key) {return ''}
  return intl.getHTML(key, params)
}

export default {localise, localiseWithHTML}
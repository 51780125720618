import React, {useEffect, useState} from 'react'
import {string} from 'prop-types'
import {fetchCostCertaintyLogs} from '../../../api/logs/logs'
import {toggleLoading} from '../../../utilities/LoadingIndicatorUtil'
import {useParams} from 'react-router'
import {DateTime} from 'luxon'
import {EUROPEAN_DATE_FORMAT} from '../../../constants/dates'
import {PROJECTS_URL} from '../../../constants/urls'
import {localise} from '../../../services/LocalizationServices'
import BreadCrumbsView from '../../common/BreadcrumbsView'
import {useSelector} from 'react-redux'
import {getProjectTitle} from '../../../store/selectors/projectSelectors'
import CostCertaintyLog from '../../../components/cards/logs/CostCertaintyLog'

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

const CostCertaintyLogs = ({className}) => {
  const {slug} = useParams()
  const title = useSelector(getProjectTitle)

  const [costCertaintyLogs, setCostCertaintyLogs] = useState([])

  useEffect(() => {
    toggleLoading()
    fetchCostCertaintyLogs(slug)
      .then(({cost_certainty_logs}) => setCostCertaintyLogs(cost_certainty_logs))
      .finally(toggleLoading)
  }, [slug])

  return (
    <BreadCrumbsView
      breadcrumbItems={[
        {
          active: false,
          href: PROJECTS_URL,
          text: localise('nav.projects'),
        },
        {
          active: false,
          href: `${PROJECTS_URL}/${slug}`,
          text: title,
        },
        {
          active: true,
          text: localise('headings.costCertainty'),
        },
      ]}
      className={`mt-2 ${className}`}>
      {
        costCertaintyLogs.map(log =>
          <CostCertaintyLog
            className='m-0'
            date={DateTime.fromSeconds(log?.created_at || 0).toFormat(EUROPEAN_DATE_FORMAT)}
            log={log}
          />
        )
      }
    </BreadCrumbsView>
  )
}

CostCertaintyLogs.propTypes = propTypes
CostCertaintyLogs.defaultProps = defaultProps

export default CostCertaintyLogs

import React, {useEffect, useState} from 'react'
import {bool, func, node, number, oneOfType, string} from 'prop-types'
import {Form} from 'react-bootstrap'
import Console from '../../utilities/ConsoleUtil'
import is from 'is_js'
import styled from 'styled-components'
import {BRAND_PRIMARY, LIGHT_GREY} from '../../constants/colours'
import alpha from 'color-alpha'
import Pipe from '../pipe/Pipe'

const propTypes = {
  className: string,
  currentValLabelClasses: string,
  currentValPrefix: string,
  hideValueLabel: bool,
  max: oneOfType([number, string]),
  min: oneOfType([number, string]),
  onChange: func,
  sliderLabel: node,
  sliderLabelClasses: string,
  value: oneOfType([number, string]),
  valueLabel: oneOfType([number, string]),
  valueSuffix: string,
}

const defaultProps = {
  className: '',
  currentValLabelClasses: '',
  currentValPrefix: '£',
  hideValueLabel: false,
  max: 100,
  min: 0,
  onChange: currentVal => Console.dev(`Current Val is ${currentVal}`),
  sliderLabel: '',
  sliderLabelClasses: '',
  value: 0,
  valueLabel: null,
  valueSuffix: '%',
}

const sliderTrackStyles = ({max, value}) => {
  const percentage = (value / max) * 100
  return (
    `linear-gradient(
      to right, 
      ${alpha(BRAND_PRIMARY, .5)}, 0%, 
      ${alpha(BRAND_PRIMARY, .5)} ${percentage}%, 
      ${alpha(LIGHT_GREY, .5)} ${percentage}%, 
      ${alpha(LIGHT_GREY, .5)} 100%)
     `
  )
}

const ChromeSliderContainer = styled(Form.Range)`
  &::-webkit-slider-runnable-track {
    background: ${sliderTrackStyles};
  }
`

const Slider = props => {
  const {
    className,
    currentValLabelClasses,
    currentValPrefix,
    hideValueLabel,
    max,
    onChange,
    sliderLabel,
    sliderLabelClasses,
    value,
    valueLabel,
    valueSuffix,
    ...rest
  } = props

  const [currentVal, setCurrentVal] = useState(value)
  const [SliderComp, setSliderComp] = useState(null)

  useEffect(() => setCurrentVal(value), [value])

  useEffect(() => {
    if (is.chrome() || is.safari()) setSliderComp(ChromeSliderContainer)
    else setSliderComp(Form.Range)
  }, [])

  return (
    <div className={`slider-container ${className}`}>
      <div className='d-flex align-items-end font-13'>
        <div className={`${sliderLabelClasses} flex-grow-1`}>{sliderLabel}</div>
        <div className='d-flex'>
          {
            !hideValueLabel && (
              <>
                <div>{currentValPrefix} {valueLabel || currentVal}</div>
                <Pipe />
              </>
            )
          }
          <div>
            {currentVal}{valueSuffix}
          </div>
        </div>
      </div>
      {
        SliderComp && (
          <SliderComp
            className='slider'
            max={max}
            onChange={({target: {value}}) => setCurrentVal(Number(value))}
            onPointerUp={({target: {value}}) => onChange(Number(value))}
            onTouchEnd={({target: {value}}) => onChange(Number(value))}
            value={currentVal}
            {...rest}
          />
        )
      }
    </div>
  )
}

Slider.propTypes = propTypes
Slider.defaultProps = defaultProps

export default Slider

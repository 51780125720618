import React, {useEffect} from 'react'
import {node, number, string} from 'prop-types'
import FlowView from '../common/FlowView'
import {
  CONSTRUCTION_WORKER_ICON_WHITE,
  DOORWAY_ICON_WHITE,
  LIGHTNING_COG_ICON_WHITE,
  SHOVEL_ICON_WHITE,
  STRIPED_BUILDING_ICON_WHITE,
} from '../../constants/svgs'
import {PROJECTS_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectId, getProjectTitle} from '../../store/selectors/projectSelectors'
import {BRAND_PRIMARY, BROWN, NAVY, PALE_GREEN, PALE_GREY, SKY_BLUE} from '../../constants/colours'
import {fetchProject} from '../../api/project/project'
import {useParams} from 'react-router'
import {setProject} from '../../store/slices/projectSlice'
import useSupplierForm from '../../hooks/useSupplierForm'
import {clearSelectedSuppliers} from '../../store/slices/selectedSuppliersSlice'

const propTypes = {
  activeStep: number,
  children: node,
  className: string,
  title: string,
}

const defaultProps = {
  activeStep: null,
  children: null,
  className: '',
  title: '',
}

const MMCSupplierCreateFlow = ({activeStep, children, className, title}) => {
  const {projectSupplierFormUrl} = useSupplierForm()
  const dispatch = useDispatch()
  const {slug} = useParams()

  const projectId = useSelector(getProjectId)
  const projectTitle = useSelector(getProjectTitle)

  const icons = [
    {
      src: SHOVEL_ICON_WHITE,
      backgroundColor: BRAND_PRIMARY,
      active: activeStep === 1,
      completed: activeStep > 1,
    },
    {
      src: STRIPED_BUILDING_ICON_WHITE,
      backgroundColor: PALE_GREEN,
      active: activeStep === 2,
      completed: activeStep > 2,
    },
    {
      src: DOORWAY_ICON_WHITE,
      backgroundColor: BROWN,
      active: activeStep === 3,
      completed: activeStep > 3,
    },
    {
      src: LIGHTNING_COG_ICON_WHITE,
      backgroundColor: SKY_BLUE,
      active: activeStep === 4,
      completed: activeStep > 4,
    },
    {
      src: CONSTRUCTION_WORKER_ICON_WHITE,
      backgroundColor: NAVY,
      active: activeStep === 5,
      completed: activeStep > 5,
    },
  ]

  useEffect(() => {
    fetchProject(slug).then(project => dispatch(setProject(project)))
    return () => {
      if (!window.location.pathname.includes(projectSupplierFormUrl)) {
        dispatch(clearSelectedSuppliers())
      }
    }
    // eslint-disable-next-line
  }, [slug, dispatch])

  return (
    <FlowView
      activeStep={activeStep}
      breadcrumbs={[
        {
          active: false,
          href: `${PROJECTS_URL}/${projectId}`,
          text: projectTitle,
        },
        {
          active: true,
          text: localise('nav.addSuppliers'),
        },
      ]}
      className={className}
      connectorColor={PALE_GREY}
      icons={icons}
      stepperContainerProps={{md: 10, className: 'mx-auto'}}>
      <div className='font-20 font-bold my-2'>{title}</div>

      {children}
    </FlowView>
  )
}

MMCSupplierCreateFlow.propTypes = propTypes
MMCSupplierCreateFlow.defaultProps = defaultProps

export default MMCSupplierCreateFlow

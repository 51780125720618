import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import MainLayout from './layouts/MainLayout'
import {AUTH_URL, PROJECTS_URL} from '../constants/urls'

const RouteWithLayout = ({component: Component, layout: Layout = MainLayout, requiresAuth = true, ...rest}) => {
  const authenticated = localStorage.getItem('bearerToken')
  const layout = props => <Layout {...props}><Component {...props} /></Layout>
  const auth = props => authenticated ? layout(props) : <Redirect to={AUTH_URL} />
  const unauth = props => !authenticated ? layout(props) : <Redirect to={PROJECTS_URL} />

  return (
    <Route
      {...rest}
      render={props => requiresAuth ? auth(props) : unauth(props)}
    />
  )
}

export default RouteWithLayout

import React from 'react'
import styled from 'styled-components'
import {Col, Container, Row} from 'react-bootstrap'
import TopNav from '../../components/navs/TopNav'
import useNav from '../../hooks/useNav'
import MainMenu from '../../components/navs/MainMenu'

const FlowLayoutContainer = styled.div`overflow-x: hidden;`
const TopNavContainer = styled(Col)`max-width: 100vw;`
const ChildrenContainer = styled(Col)`max-width: 100vw;`

const MainMenuFlowLayout = ({children}) => {
  const {breakpoint} = useNav()

  return (
    <FlowLayoutContainer className='d-flex'>
      <div className='flex-grow-1'>
        <Container fluid className={`ms-${breakpoint}-1 ms-0`}>
          <Row className='py-0'>
            <TopNavContainer>
              <TopNav />
            </TopNavContainer>
            <Col xs={12} className='p-0 mx-3'>
              <MainMenu />
            </Col>
          </Row>
          <Row>
            <ChildrenContainer>
              {children}
            </ChildrenContainer>
          </Row>
        </Container>
      </div>
    </FlowLayoutContainer>
  )
}

export default MainMenuFlowLayout

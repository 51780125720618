export const getBuildingPreliminariesCostTotal = state => (
  state?.buildingPreliminariesCost?.building_preliminaries_cost?.total?.amount
    ? state?.buildingPreliminariesCost?.building_preliminaries_cost?.total?.amount / 100
    : null
)
export const getBuildingPreliminariesCostTotalDisplay = state => (
  state?.buildingPreliminariesCost?.building_preliminaries_cost?.total?.display
    ? state?.buildingPreliminariesCost?.building_preliminaries_cost?.total?.display
    : null
)

export const getBuildingPreliminariesCostAreas = state => {
  if (state?.buildingPreliminariesCost?.building_preliminaries_cost_breakdown_areas?.length > 0) {
    const sortable = [...state?.buildingPreliminariesCost?.building_preliminaries_cost_breakdown_areas]
    return sortable.sort((a, b) => {
      return a?.building_preliminaries_cost_breakdown_area_type?.sort_order >
      b?.building_preliminaries_cost_breakdown_area_type?.sort_order
        ? 1
        : -1
    })
  }
  return []
}

export const getInitialBuildingPreliminariesCostTotal = state => (
  state?.buildingPreliminariesCost?.initial?.building_preliminaries_cost?.total?.amount
    ? state?.buildingPreliminariesCost?.initial?.building_preliminaries_cost?.total?.amount / 100
    : null
)

export const getInitialBuildingPreliminariesCostAreas = state => (
  state?.buildingPreliminariesCost?.initial?.building_preliminaries_cost_breakdown_areas || []
)

import React, {useState} from 'react'
import {func, string, bool} from 'prop-types'
import {Button} from 'react-bootstrap'
import {toast} from 'react-hot-toast'
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import {BLACK} from '../../constants/colours'
import {STRIPE} from '../../constants/svgs'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import Form from '../forms/Form'
import {createPaymentMethod, setupPaymentMethod} from '../../api/payment-method/PaymentMethod'

const propTypes = {
  className: string,
  confirmButtonText: string,
  isSubscription: bool,
  onSuccess: func,
}

const defaultProps = {
  className: '',
  confirmButtonText: '',
  isSubscription: false,
  onSuccess: () => Console.dev('onSuccess here'),
}

const PaymentMethodEdit = ({className, confirmButtonText, isSubscription, onSuccess}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [validated, setValidated] = useState(false)
  const [validCardNumber, setValidCardNumber] = useState(false)
  const [validExpiry, setValidExpiry] = useState(false)
  const [validCvc, setValidCvc] = useState(false)

  const handleFormFields = () => {
    const elementConfig = {
      className: 'card-input-element',
      options: {
        style: {
          base: {
            fontSize: '13px',
            fontFamily: 'Roboto-bold, sans-serif',
            color: BLACK,
          },
        },
      },
    }

    return ([
      {
        type: 'custom',
        label: localise('payment.cardNumber'),
        render: <CardNumberElement
          onChange={({error, complete}) => setValidCardNumber(complete && !error)} {...elementConfig}
        />,
      }, {
        type: 'custom',
        label: localise('payment.expiryDate'),
        className: 'd-inline-block w-50 mt-3 pe-2',
        render: <CardExpiryElement
          onChange={({error, complete}) => setValidExpiry(complete && !error)} {...elementConfig}
        />,
      }, {
        type: 'custom',
        label: localise('payment.securityNumber'),
        className: 'd-inline-block w-50 mt-3 ps-2',
        render: <CardCvcElement
          onChange={({error, complete}) => setValidCvc(complete && !error)} {...elementConfig}
          options={{...elementConfig.options, placeholder: localise('payment.cvv')}}
        />,
      }])
  }

  const handleSubmit = async e => {
    try {
      toggleLoading()
      e.preventDefault()

      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      if (!stripe || !elements) return

      const form = e.currentTarget

      if (!form.checkValidity()) {
        e.stopPropagation()
      } else {
        const cardNumberElement = elements.getElement(CardNumberElement)
        const {setup_request: {client_secret}} = await setupPaymentMethod()
        const {
          setupIntent,
          error,
        } = await stripe.confirmCardSetup(client_secret, {payment_method: {card: cardNumberElement}})
        const paymentMethod = setupIntent?.payment_method

        return paymentMethod ?
          await createPaymentMethod(paymentMethod).then(onSuccess) :
          toast.error(error?.message || localise('toast.cardError'))
      }
      setValidated(true)
    }
    catch (e) {
      Console.dev(e)
      toast.error(localise('toast.cardError'))
    }
    finally {toggleLoading()}
  }

  const submittable = validCardNumber && validExpiry && validCvc

  return (
    <div>
      <div className='font-15 font-grey pb-1'>{localise('payment.paymentMethod')}</div>
      <Form className={`payment-method-edit border-light-grey border-1 ${className}`}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}>
        <div className='py-2 px-3'>
          {buildForm(handleFormFields())}
          <div className='d-flex align-items-end flex-column mt-3'>
            <img src={STRIPE} alt='powered-by-stripe'/>
          </div>
        </div>
      </Form>
      <div className='d-flex align-items-end flex-column mt-3'>
        <Button
          type='primary'
          className='font-15'
          disabled={!submittable}
          onClick={handleSubmit}>
          {confirmButtonText ||
          (isSubscription ? localise('payment.confirmSubscription') : localise('payment.confirmPurchase'))}
        </Button>
      </div>
    </div>
  )
}

PaymentMethodEdit.propTypes = propTypes
PaymentMethodEdit.defaultProps = defaultProps

export default PaymentMethodEdit

import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {
  ACCOUNT_URL,
  AUTH_URL,
  HELPFUL_LINKS_URL,
  MAP_VIEW_URL,
  MMC_SUPPLIERS_URL,
  PROJECTS_URL,
  ROOT_URL,
} from '../constants/urls'
import AccountRoutes from './AccountRoutes'
import ProjectRoutes from './ProjectRoutes'
import AuthRoutes from './AuthRoutes'
import MMCSupplierRoutes from './MMCSupplierRoutes'
import HelpfulLinksRoutes from './HelpfulLinksRoutes'
import MapRoutes from './MapRoutes'
import PageNotFound from '../views/error/PageNotFound'

const AppRoute = () =>
  <Switch>
    <Route exact path={ROOT_URL}><Redirect to={PROJECTS_URL}/></Route>
    <AccountRoutes path={ACCOUNT_URL}/>
    <AuthRoutes path={AUTH_URL} />
    <ProjectRoutes path={PROJECTS_URL} />
    <MMCSupplierRoutes path={MMC_SUPPLIERS_URL}/>
    <HelpfulLinksRoutes path={HELPFUL_LINKS_URL}/>
    <MapRoutes path={MAP_VIEW_URL}/>
    <Route path='*'><PageNotFound /></Route>
  </Switch>

export default AppRoute

import React, {useEffect, useState} from 'react'
import {localise} from '../../../../services/LocalizationServices'
import Buttons from '../common/Buttons'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import MMCSupplierCreateFlow from '../../../flows/MMCSupplierCreateFlow'
import {PROJECTS_URL} from '../../../../constants/urls'
import SupplierForm from '../form/SupplierForm'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {useParams} from 'react-router'
import {createProjectStagedSupplier} from '../../../../api/project/project'
import useSupplierForm from '../../../../hooks/useSupplierForm'
import {getSelectedSuppliers} from '../../../../store/selectors/selectedSuppliersSelectors'
import StagedSupplierCard from '../../../../components/cards/StagedSupplierCard'
import {FIT_OUT} from '../../../../constants/suppliers'

const FitOut = () => {
  const {push} = useHistory()
  const {slug} = useParams()

  const {SUPERSTRUCTURE_STEP_URL, MECHANICAL_ELECTRICAL_PLUMBING_STEP_URL} = useSupplierForm()
  const selectedSuppliers = useSelector(getSelectedSuppliers)

  const [name, setName] = useState('')
  const [submittable, setSubmittable] = useState(false)

  useEffect(() => {
    const suppliersExist = selectedSuppliers?.filter(({element: selectedElem}) => selectedElem === FIT_OUT)?.length > 0
    setSubmittable(suppliersExist || !!name)
  }, [selectedSuppliers, name])

  const toNextStep = () => push(MECHANICAL_ELECTRICAL_PLUMBING_STEP_URL)

  const handleSubmit = () => {
    if (name) {
      toggleLoading()
      createProjectStagedSupplier(slug, name)
        .then(toNextStep)
        .finally(toggleLoading)
    } else {toNextStep()}
  }

  return (
    <MMCSupplierCreateFlow activeStep={3} title={localise('headings.fitOut')}>
      <SupplierForm isSubmittable={setSubmittable} element={FIT_OUT} />
      <StagedSupplierCard element={FIT_OUT} onChange={setName} />
      <Buttons
        continueButtonDisabled={!submittable}
        onCancelClicked={() => push(`${PROJECTS_URL}/${slug}`)}
        onPreviousClicked={() => push(SUPERSTRUCTURE_STEP_URL)}
        onSubmit={handleSubmit}
        submitText={localise('buttonText.confirm')}
      />
    </MMCSupplierCreateFlow>
  )
}

export default FitOut

import React from 'react'
import {Route, Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import MapView from '../views/map/MapView'
import PageNotFound from '../views/error/PageNotFound'

const MapRoutes = ({path}) => {
  return (
    <Switch>
      <RouteWithLayout exact path={path} component={MapView} />
      <Route path='*'><PageNotFound /></Route>
    </Switch>
  )
}

export default MapRoutes

import React, {useState} from 'react'
import ProjectCreateFlow from '../../../flows/ProjectCreateFlow'
import {localise} from '../../../../services/LocalizationServices'
import DetailsForm from '../../form/DetailsForm'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {createProject, updateProject} from '../../../../api/project/project'
import {useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {setProject} from '../../../../store/slices/projectSlice'
import {ADDRESS_URL, NEW_URL, PROJECTS_URL} from '../../../../constants/urls'
import {getProjectId} from '../../../../store/selectors/projectSelectors'
import Buttons from '../common/Buttons'
import {setCurrentUser} from '../../../../store/slices/currentUserSlice'

const Details = () => {
  const {push} = useHistory()
  const dispatch = useDispatch()

  const [submittable, setSubmittable] = useState(false)

  const projectId = useSelector(getProjectId)

  const handleSubmit = ({projectTitle: name}) => {
    const onSuccess = project => {
      dispatch(setProject(project))
      dispatch(setCurrentUser({current_draft_project_id: project?.id}))
      push(`${PROJECTS_URL}${NEW_URL}${ADDRESS_URL}`)
    }

    toggleLoading()
    if (projectId) updateProject(projectId, {name}).then(onSuccess).finally(toggleLoading)
    else createProject({name}).then(onSuccess).finally(toggleLoading)
  }

  const toProjects = () => push(PROJECTS_URL)

  return (
    <ProjectCreateFlow title={localise('headings.projectDetails')}>
      <DetailsForm
        onSubmit={handleSubmit}
        isSubmittable={setSubmittable}
        buttons={(
          <Buttons
            continueButtonDisabled={!submittable}
            onCancelClicked={toProjects}
            previousButtonHidden
          />
        )}
      />
    </ProjectCreateFlow>
  )
}

export default Details

import React from 'react'
import {number, string} from 'prop-types'
import styled from 'styled-components'
import {PINK} from '../../constants/colours'

const propTypes = {
  className: string,
  colour: string,
  size: number,
  src: string,
}

const defaultProps = {
  className: '',
  colour: PINK,
  size: 50,
  src: '',
}

const IconContainer = styled.div`
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  background-color: ${({colour}) => colour};
`

const ImageContainer = styled.img`
  width: 60%;
  height: 60%;
`

const IconBadge = ({className, colour, size, src}) => {
  return (
    <div className={className}>
      <IconContainer
        className={'rounded-circle d-flex align-items-center justify-content-center'}
        colour={colour}
        size={size}>
        <ImageContainer src={src}/>
      </IconContainer>
    </div>
  )
}

IconBadge.propTypes = propTypes
IconBadge.defaultProps = defaultProps

export default IconBadge

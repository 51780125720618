import React from 'react'
import {arrayOf, bool, func, node, object, shape, string} from 'prop-types'
import {Button, Col, Row} from 'react-bootstrap'
import SearchInput from '../../components/inputs/SearchInput'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import styled from 'styled-components'
import Separator from '../../components/separator/Separator'

const propTypes = {
  buttonProps: object,
  children: node,
  childrenClasses: string,
  className: string,
  dropdownOptions: shape({
    label: string,
    isInvalid: bool,
    onChange: func,
    options: arrayOf(shape({value: string, label: string})),
    placeholder: string,
    validationMessage: string,
    value: string,
  }),
  onSearch: func,
}

const defaultProps = {
  buttonProps: null,
  children: null,
  childrenClasses: null,
  className: '',
  dropdownOptions: null,
  onSearch: () => {},
}

const FILTERS_BAR_DROPDOWN = 'filters-bar-dropdown'

const DropdownDiv = styled.div`
  max-width: 200px;
  min-width: 180px;
`

const FiltersBarViewContainer = styled(Row)`
  #filters-bar-dropdown {
    .filters-bar-dropdown__control {
      border: 0;
      &:active { border: 0 }
    }

    .filters-bar-dropdown__control--is-focused,
    .filters-bar-dropdown__control--menu-is-open { border: none }

    .filters-bar-dropdown__indicators {
      .filters-bar-dropdown__indicator-separator { display: none }
      &:after { border: none }
      svg { display: none }
    }
  }
`

const FiltersBarView = props => {
  const {
    buttonProps,
    children,
    childrenClasses,
    className,
    dropdownOptions,
    onSearch,
  } = props

  return (
    <FiltersBarViewContainer className={`filters-bar-view-container ${className}`}>
      <div className='d-sm-flex flex-wrap justify-content-between align-items-center'>
        <DropdownDiv>
          {
            buildForm([

              {
                type: 'dropdown',
                id: FILTERS_BAR_DROPDOWN,
                classNamePrefix: FILTERS_BAR_DROPDOWN,
                menuPortalTarget: document.body,
                ...dropdownOptions,
              },
            ])
          }
        </DropdownDiv>
        <div className='my-sm-0 my-3'><SearchInput handleSearch={onSearch} /></div>
        <div className={buttonProps ? 'col-auto d-lg-block d-grid' : 'invisible'}>
          <Button
            className='px-2 py-1 font-12 float-lg-end mb-lg-0'
            {...buttonProps}
          />
        </div>
      </div>
      <Separator/>
      <Col xs='12' className={childrenClasses}>
        {children}
      </Col>
    </FiltersBarViewContainer>
  )
}

FiltersBarView.propTypes = propTypes
FiltersBarView.defaultProps = defaultProps

export default FiltersBarView

import React from 'react'
import {array, func} from 'prop-types'
import BuildingCard from '../../../components/cards/BuildingCard'
import {Col, Row} from 'react-bootstrap'
import {v4} from 'uuid'
import {useSelector} from 'react-redux'
import {getBuildingStatusColorRGBA} from '../../../store/selectors/buildingSelectors'

const propTypes = {
  list: array,
  onClick: func,
}

const defaultProps = {
  list: [],
  onClick: null,
}

const Buildings = ({list, onClick}) => {
  const statusColor = useSelector(getBuildingStatusColorRGBA)

  return (
    <Row>
      {
        list?.map(building => {
          const {
            building_sector: {name: sector},
            building_type: {name: buildingType},
            gross_internal_area: grossInternalArea,
            house_gia_nia: houseGiaNia,
            id,
            name: title,
            net_internal_area: netInternalArea,
            phase_display,
            phase_count_display,
            status_display,
            step_count_display,
            total_gross_internal_area: totalGrossInternalArea,
            unit_count: units,
          } = building

          return (
            <Col key={v4()} sm='6' md='4' lg='3' className='mb-3'>
              <BuildingCard
                bannerColor={statusColor}
                buildingType={buildingType}
                clickable
                grossInternalArea={grossInternalArea}
                netInternalArea={netInternalArea}
                houseGiaNia={houseGiaNia}
                onClick={() => onClick(id)}
                phaseCountDisplay={phase_count_display}
                phaseDisplay={phase_display}
                sector={sector}
                statusDisplay={status_display}
                stepCount={step_count_display}
                title={title}
                totalGrossInternalArea={totalGrossInternalArea}
                units={units}
              />
            </Col>
          )
        })
      }
    </Row>
  )
}

Buildings.propTypes = propTypes
Buildings.defaultProps = defaultProps

export default Buildings

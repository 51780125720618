import {configureStore} from '@reduxjs/toolkit'
import reducers from './slices'
import {CLEAR_STATE} from '../constants/reduxTypes'
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {clearProject} from './slices/projectSlice'
import {clearBuilding} from './slices/buildingSlice'
import {clearUserPreference} from './slices/userPreferenceSlice'

const persistConfig = {
  storage,
  key: 'root',
  whitelist: ['building', 'project', 'userPreference'],
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    })
  },
})

export default store
export const persistor = persistStore(store)
export const clearStore = () => store.dispatch({type: CLEAR_STATE})
export const clearPersisted = () => {
  store.dispatch(clearBuilding())
  store.dispatch(clearProject())
  store.dispatch(clearUserPreference())
}

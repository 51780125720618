import React, {useEffect, useState} from 'react'
import {Col, Image, Row} from 'react-bootstrap'
import Card from '../../../components/cards/Card'
import {localise} from '../../../services/LocalizationServices'
import PieChart from '../../../components/charts/PieChart'
import {
  BROWN,
  LIGHT_PINK,
  PALE_GREEN,
  PALE_GREY,
  SKY_BLUE,
  OFF_WHITE,
  BRAND_PRIMARY,
  PALEST_GREY,
} from '../../../constants/colours'
import HalfPieChart from '../../../components/charts/HalfPieChart'
import OverallCostsWrapper, {CONTENT} from '../../../components/headings/OverallCostsWrapper'
import {object, string} from 'prop-types'
import ReportPlaceholder from '../../../components/placeholders/ReportPlaceholder'
import ChartPlaceholder from '../../../components/placeholders/ChartPlaceholder'
import useChart from '../../../hooks/useChart'
import {COMPANY_LOGO} from '../../../constants/svgs'
import styled from 'styled-components'

const ELEMENT_CHART_COLORS = [LIGHT_PINK, PALE_GREEN, BROWN, SKY_BLUE, OFF_WHITE, PALE_GREY]
const MATERIAL_ONSITE_PRELIM_CHART_COLORS = [BRAND_PRIMARY, PALE_GREY, PALEST_GREY]

const FIT_OUT = 'fit_out'
const GROSS_CONSTRUCTION_COST = 'gross_construction_cost'
const MEP = 'mechanical_electrical_plumbing'
const SUBSTRUCTURE = 'substructure'
const SUPERSTRUCTURE = 'superstructure'

const propTypes = {
  className: string,
  report: object,
}

const defaultProps = {
  className: '',
  report: {},
}

const ChartCardContainerCard = styled(Card)`height: 348px`

const ProjectReport = ({className, report}) => {
  const [fitOutData, setFitOutData] = useState({})
  const [grossCostData, setGrossCostData] = useState({})
  const [mepData, setMepData] = useState({})
  const [substructureData, setSubstructureData] = useState({})
  const [superstructureData, setSuperstructureData] = useState({})

  const {isChartDataArrayEmpty} = useChart()

  useEffect(() => {
    setFitOutData(pluckReportElement(FIT_OUT)?.data)
    setGrossCostData(pluckReportElement(GROSS_CONSTRUCTION_COST)?.data)
    setMepData(pluckReportElement(MEP)?.data)
    setSubstructureData(pluckReportElement(SUBSTRUCTURE)?.data)
    setSuperstructureData(pluckReportElement(SUPERSTRUCTURE)?.data)
    // eslint-disable-next-line
  }, [report])

  const pluckReportElement = elementName => report?.elements?.find(element => elementName === element?.label)

  const ChartCardContainer = ({className, children, title}) =>
    <div className={className}>
      <div className='font-15 font-grey pb-2'>{title}</div>
      <ChartCardContainerCard hideBoxShadow>
        {children}
      </ChartCardContainerCard>
    </div>

  return (
    !report || typeof report !== 'object' || Object.keys(report)?.length === 0 ? <ReportPlaceholder /> :
      <Row className={className}>
        <Col xl={4} className='lone-pie-chart-container'>
          <ChartCardContainer title={localise('headings.myPMVScore')} className='h-100'>
            {
              isChartDataArrayEmpty(report?.my_pmv_score_chart_data)
                ? <ChartPlaceholder className='border-0'/>
                : (
                  <PieChart
                    donut
                    colors={MATERIAL_ONSITE_PRELIM_CHART_COLORS}
                    data={report?.my_pmv_score_chart_data}
                  />
                )
            }
          </ChartCardContainer>
        </Col>
        <Col xl={4} className='my-3 my-lg-0'>
          <ChartCardContainer title={localise('headings.myPMVScore')} className='h-100'>
            <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
              <div className='font-80 font-primary'>
                {
                  `${report?.my_pmv_percentages?.gross_pmv}%`
                }
              </div>
              <Image src={COMPANY_LOGO} height={77} alt={localise('alt.companyLogo')} />
            </div>
          </ChartCardContainer>
        </Col>
        <Col xl={4} className='lone-pie-chart-container'>
          {
            <ChartCardContainer title={localise('headings.myPMVElements')} className='h-100'>
              {
                isChartDataArrayEmpty
                (report?.my_pmv_elements_chart_data)
                  ? <ChartPlaceholder className='border-0'/>
                  : (
                    <HalfPieChart
                      colors={ELEMENT_CHART_COLORS}
                      data={report?.my_pmv_elements_chart_data}
                      onLegendClick={null}
                      libraryProps={{
                        legend: {
                          floating: true,
                          y: -100,
                        },
                        chart: {
                          height: 400,
                        },
                        plotOptions: {
                          pie: {
                            allowPointSelect: false,
                            center: ['50%', '60%'],
                            dataLabels: {
                              enabled: false,
                            },
                            endAngle: 90,
                            innerSize: '50%',
                            showInLegend: true,
                            startAngle: -90,
                          },
                        },
                      }}
                    />
                  )
              }
            </ChartCardContainer>
          }
        </Col>
        <Col xs={12} className='my-3'>
          <OverallCostsWrapper
            active={CONTENT.TABLE}
            categoryItems={grossCostData?.mmc_categories}
            className='mt-1'
            colors={MATERIAL_ONSITE_PRELIM_CHART_COLORS}
            data={grossCostData?.pricing_area_chart_data}
            firstCellWidth='85%'
            material={grossCostData?.material_cost?.total_cents?.display}
            materialPercent={grossCostData?.material_cost?.percentage}
            max={grossCostData?.total_cents?.amount}
            onSiteLabour={grossCostData?.on_site_labour_cost?.total_cents?.display}
            onSiteLabourPercent={grossCostData?.on_site_labour_cost?.percentage}
            overallValue={grossCostData?.total_cents?.display}
            preliminaries={grossCostData?.preliminaries_cost?.total_cents?.display}
            preliminariesPercent={grossCostData?.preliminaries_cost?.percentage}
            title={localise('headings.myPMVGrossConstructionCost')}
          />
        </Col>
        {
          [
            {
              data: substructureData,
              title: localise('headings.substructureCosts'),
              colors: [LIGHT_PINK, PALE_GREY, PALEST_GREY],
            },
            {
              data: superstructureData,
              title: localise('headings.superstructureCosts'),
              colors: [PALE_GREEN, PALE_GREY, PALEST_GREY],
            },
            {
              data: fitOutData,
              title: localise('headings.fitOutCosts'),
              colors: [BROWN, PALE_GREY, PALEST_GREY],
            },
            {
              data: mepData,
              title: localise('headings.mepCosts'),
              colors: [SKY_BLUE, PALE_GREY, PALEST_GREY],
            },
          ].map(({data, title, colors}) => (
            <Col md={6}>
              <OverallCostsWrapper
                categoryItems={data?.mmc_categories}
                className='mt-1'
                colors={colors || MATERIAL_ONSITE_PRELIM_CHART_COLORS}
                data={data?.pricing_area_chart_data}
                material={data?.material_cost?.total_cents?.display}
                materialPercent={data?.material_cost?.percentage}
                max={data?.total_cents?.amount}
                onSiteLabour={data?.on_site_labour_cost?.total_cents?.display}
                onSiteLabourPercent={data?.on_site_labour_cost?.percentage}
                overallValue={data?.total_cents?.display}
                preliminaries={data?.preliminaries_cost?.total_cents?.display}
                preliminariesPercent={data?.preliminaries_cost?.percentage}
                title={title}
              />
            </Col>
          ))
        }
      </Row>
  )
}

ProjectReport.propTypes = propTypes
ProjectReport.defaultProps = defaultProps

export default ProjectReport


import React from 'react'
import {string} from 'prop-types'
import {REPORTS_PLACEHOLDER} from '../../constants/svgs'
import {localise} from '../../services/LocalizationServices'
import Placeholder from './Placeholder'

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

const ReportPlaceholder = ({className}) =>
  <Placeholder
    alt={localise('alt.reports')}
    className={className}
    description={localise('placeholder.noReportsCreatedDesc')}
    src={REPORTS_PLACEHOLDER}
    title={localise('placeholder.noReportsCreated')}
  />

ReportPlaceholder.propTypes = propTypes
ReportPlaceholder.defaultProps = defaultProps

export default ReportPlaceholder

import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {v4} from 'uuid'
import {localise} from '../../services/LocalizationServices'
import FiltersBarView from '../common/FiltersBarView'
import LinkCard from '../../components/cards/LinkCard'
import {fetchElements} from '../../api/elements/elements'
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'
import {fetchHelpfulLinks} from '../../api/helpful-links/helpfulLinks'
import NoSearchResultsPlaceholder from '../../components/placeholders/NoSearchResultsPlaceholder'

const HelpfulLinks = () => {
  const filterAll = {value: '', label: localise('filter.all')}

  const [dropdownOptions, setDropdownOptions] = useState([])
  const [filter, setFilter] = useState(filterAll)
  const [helpfulLinks, setHelpfulLinks] = useState([])
  const [loading, setLoading] = useState(true)
  const [loadingLength, setLoadingLength] = useState(12)
  const [query, setQuery] = useState('')

  const loadHelpfulLinks = () => fetchHelpfulLinks({element: filter?.value, query}).then(setHelpfulLinks)

  useEffect(() => {
    setLoadingLength(helpfulLinks?.length <= 12 && helpfulLinks?.length > 0 ? helpfulLinks?.length : 12)
    setLoading(true)
    loadHelpfulLinks().finally(() => setTimeout(() => setLoading(false), 500))
    // eslint-disable-next-line
  }, [filter, query])

  useEffect(() => {
    setLoading(true)
    Promise.all([
      loadElements(),
      loadHelpfulLinks(),
    ]).finally(() => setLoading(false))
    // eslint-disable-next-line
  }, [])

  const loadElements = () =>
    fetchElements()
      .then(elements => {
        setDropdownOptions([filterAll, ...elements.map(({slug: value, name: label}) => ({value, label}))])
      })

  const Loading = () => (
    _.times(loadingLength, () => (
      <Col key={v4()} md={4} lg={3}>
        <Skeleton className='my-2' height={220} />
      </Col>
    ))
  )

  return (
    <FiltersBarView
      childrenClasses='mt-3'
      dropdownOptions={{onChange: setFilter, options: dropdownOptions}}
      onSearch={setQuery}>
      {
        <Row>
          {
            loading ?
              <Loading/> :
              helpfulLinks.map(({description, element, images, name, url}) =>
                <Col key={v4()} md={4} lg={3}>
                  <LinkCard
                    className='my-2'
                    category={element}
                    description={description}
                    src={images?.small_url}
                    title={name}
                    url={url}
                  />
                </Col>
              )
          }
          {
            !loading && helpfulLinks?.length === 0 && query?.length > 0 &&
            <Col xs={12}><NoSearchResultsPlaceholder name={localise('headings.links')} /></Col>
          }
        </Row>
      }
    </FiltersBarView>
  )
}

export default HelpfulLinks

import runtimeEnv from '@mars/heroku-js-runtime-env'

const {
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_STRIPE_API_KEY,
  REACT_APP_USE_REFRESH_TOKEN,
  REACT_APP_GOOGLE_API_KEY,
} = runtimeEnv()

export {
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
  REACT_APP_STRIPE_API_KEY,
  REACT_APP_USE_REFRESH_TOKEN,
  REACT_APP_GOOGLE_API_KEY,
}

import React from 'react'
import TopNav from '../../components/navs/TopNav'
import {Col, Container, Row} from 'react-bootstrap'
import styled from 'styled-components'
import Separator from '../../components/separator/Separator'
import MainMenu from '../../components/navs/MainMenu'

const MainLayoutContainer = styled.div`overflow-x: hidden;`
const TopNavContainer = styled(Col)`max-width: 100vw;`
const ChildrenContainer = styled(Col)`max-width: 100vw;`

const MainLayout = ({children}) => {
  return (
    <MainLayoutContainer className='d-flex'>
      <Container fluid className='ms-0'>
        <Row className='py-0'>
          <TopNavContainer className='p-0 mx-3'>
            <TopNav />
          </TopNavContainer>
          <Col xs='12'>
            <MainMenu />
          </Col>
          <Separator />
        </Row>
        <Row>
          <ChildrenContainer>
            {children}
          </ChildrenContainer>
        </Row>
      </Container>
    </MainLayoutContainer>
  )
}

export default MainLayout

import React from 'react'
import {bool, number, object, shape, string} from 'prop-types'
import LogEntryCard from './LogEntryCard'
import {Col} from 'react-bootstrap'
import {localise} from '../../../services/LocalizationServices'

const propTypes = {
  log: shape({
    active: bool,
    created_at: number,
    id: string,
    total_waste: number,
    total_waste_unit_of_measurement: string,
    waste_score: number,
    value_of_works_cents: object,
  }),
}

const defaultProps = {
  log: {},
}

const WasteLog = ({log, ...rest}) => {
  const section = ({body, bodyClasses, title}) =>
    <>
      <div className='font-10'>{title}</div>
      <div className={`font-17 ${bodyClasses}`}>
        {body}
      </div>
    </>

  return (
    <LogEntryCard {...rest}>
      {
        <>
          <Col>
            {
              section({
                title: localise('headings.totalWasteToDate',
                  {units: log?.total_waste_unit_of_measurement?.name}),
                body: log?.total_waste,
              })
            }
          </Col>
          <Col>
            {
              section({
                title: localise('headings.valueOfWorksToDate'),
                body: log?.value_of_works_cents?.display,
              })
            }
          </Col>
          <Col>
            {
              section({
                title: localise('headings.waste'),
                body: log?.total_waste,
                bodyClasses: 'font-bold',
              })
            }
          </Col>
        </>
      }
    </LogEntryCard>
  )
}

WasteLog.propTypes = propTypes
WasteLog.defaultProps = defaultProps

export default WasteLog

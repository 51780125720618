import React from 'react'
import {bool, node, string} from 'prop-types'
import styled from 'styled-components'
import {Image} from 'react-bootstrap'
import {CHECK} from '../../constants/svgs'

const propTypes = {
  className: string,
  complete: bool,
  completedIconClasses: string,
  heading: string,
  headingClasses: string,
  icon: string,
  value: node,
}

const defaultProps = {
  className: '',
  complete: false,
  completedIconClasses: '',
  heading: '',
  headingClasses: '',
  icon: null,
  value: null,
}

const Circle = styled.div`
  border: 1px solid black;
  border-radius: 50%;
  width: 28px;
  height: 28px;
`

const IconHeader = ({className, complete, completedIconClasses, heading, headingClasses, icon, value}) =>
  <div className={`${className} d-flex text-center`}>
    {
      complete ?
        <Circle className={`icon-circle font-white align-items-center ${completedIconClasses}`}>
          <Image src={icon || CHECK} className='align-top'/>
        </Circle>
        :
        <Circle className='icon-circle font-15 d-flex justify-content-center align-items-center'>
          {value}
        </Circle>
    }
    <div className={`heading font-17 fw-bold text-capitalize ms-3 my-auto ${headingClasses}`}>{heading}</div>
  </div>

IconHeader.propTypes = propTypes
IconHeader.defaultProps = defaultProps

export default IconHeader

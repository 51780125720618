import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const createPaymentMethod = async token => {
  const {payment_method} = await apiClient.post('/payment_methods', {payment_method: {token}}).catch(handleError)
  return payment_method
}

export const setupPaymentMethod = async () => {
  const response = await apiClient.post(
    '/payment_methods/setup',
    {payment_method : {usage: 'off_session'}}
  ).catch(handleError)
  return response
}
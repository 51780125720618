import React, {useState} from 'react'
import Form from '../../components/forms/Form'
import {localise} from '../../services/LocalizationServices'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {Button} from 'react-bootstrap'
import {AUTH_URL, PROJECTS_URL, RESET_PASSWORD_URL, SIGN_UP_URL} from '../../constants/urls'
import {Link, useHistory} from 'react-router-dom'
import Separator from '../../components/separator/Separator'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {login} from '../../api/authentication/auth'
import {fetchUser} from '../../api/user/user'
import {setCurrentUser} from '../../store/slices/currentUserSlice'
import {useDispatch} from 'react-redux'
import {fetchUserPreference} from '../../api/user-preference/userPreference'
import {setUserPreference} from '../../store/slices/userPreferenceSlice'

const Login = () => {
  const dispatch = useDispatch()
  const {push} = useHistory()

  const [validated, setValidated] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const fields = [
    {
      type: 'email',
      required: true,
      label: localise('form.label.email'),
      placeholder: localise('form.placeholder.email'),
      validationMessage: localise('form.validation.email'),
      handler: setEmail,
    },
    {
      type: 'password',
      required: true,
      label: localise('form.label.password'),
      placeholder: localise('form.placeholder.password'),
      validationMessage: localise('form.validation.password'),
      handler: setPassword,
    },
  ]

  const submitFormData = () => {
    toggleLoading()
    login(email, password)
      .then(async () => {
        const user = await fetchUser()
        if (user) dispatch(setCurrentUser(user))
        const user_preference = await fetchUserPreference()
        if (user_preference) dispatch(setUserPreference(user_preference))
        push(PROJECTS_URL)
      })
      .finally(toggleLoading)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      submitFormData()
    }
    setValidated(true)
  }

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      <div className='font-22 font-bold mb-3'>{localise('headings.login')}</div>
      {buildForm(fields)}
      <Button type='submit' variant='primary' className='font-15 mt-3' size='lg'>
        {localise('buttonText.login')}
      </Button>
      <div className='text-center font-15 font-bold mt-3'>
        <Link to={`${AUTH_URL}${RESET_PASSWORD_URL}`}>{localise('auth.forgotPassword')}</Link>
      </div>
      <Separator className='my-2' />
      <div className='font-15 font-bold text-center'>
        {localise('auth.dontHaveAccount')}
        <Link to={`${AUTH_URL}${SIGN_UP_URL}`} className='ms-1'>
          {localise('buttonText.signUp')}
        </Link>
      </div>
    </Form>
  )
}

export default Login

import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {v4} from 'uuid'
import {localise} from '../../services/LocalizationServices'
import FiltersBarView from '../common/FiltersBarView'
import SupplierCard from '../../components/cards/SupplierCard'
import {MMC_SUPPLIERS_URL} from '../../constants/urls'
import {useHistory} from 'react-router-dom'
import {createSupplierFavourites, deleteSupplierFavourites, fetchSuppliers} from '../../api/supplier/supplier'
import {fetchFavouritedSuppliers} from '../../api/favourited-suppliers/favouritedSuppliers'
import Separator from '../../components/separator/Separator'
import {fetchElements} from '../../api/elements/elements'
import {PLACEHOLDER_LANDSCAPE} from '../../constants/svgs'
import Skeleton from 'react-loading-skeleton'
import _ from 'lodash'
import NoSearchResultsPlaceholder from '../../components/placeholders/NoSearchResultsPlaceholder'

const MMCSuppliers = () => {
  const {push} = useHistory()

  const [element, setElement] = useState(null)
  const [favouritedSuppliers, setFavouritedSuppliers] = useState([])
  const [options, setOptions] = useState([])
  const [query, setQuery] = useState('')
  const [suppliers, setSuppliers] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingLength, setLoadingLength] = useState(12)

  const toSupplier = id => push(`${MMC_SUPPLIERS_URL}/${id}`)
  const allFilter = {value: '', label: localise('filter.all')}

  const calcLoadLength = () =>
    favouritedSuppliers?.length + suppliers?.length <= 12
    && favouritedSuppliers?.length + suppliers?.length > 0
      ? favouritedSuppliers?.length + suppliers?.length
      : 12

  useEffect(() => {
    setLoadingLength(calcLoadLength())
    setLoading(true)
    fetchFavouritedSuppliers()
      .then(({favourited_suppliers}) => setFavouritedSuppliers(favourited_suppliers))
      .finally(() => setTimeout(() => setLoading(false), 500))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setLoadingLength(calcLoadLength())
    setLoading(true)
    fetchSuppliers({query, element})
      .then(({suppliers}) => setSuppliers(suppliers))
      .finally(() => setTimeout(() => setLoading(false), 500))
    // eslint-disable-next-line
  }, [query, element])

  useEffect(() => {
    fetchElements().then(elems => {
      setOptions([allFilter, ...elems?.map(({name: label, slug: value}) => ({label, value}))])
    })
    // eslint-disable-next-line
  }, [])

  const setItemFavorite = (supplierId, favouritedSupplier) => {
    const newFavouritedSuppliers = favouritedSuppliers
    newFavouritedSuppliers.push(favouritedSupplier)
    setFavouritedSuppliers([...newFavouritedSuppliers])

    const newCurrentSuppliers = suppliers
    const currentIndex = newCurrentSuppliers.findIndex(({id}) => id === supplierId)
    const currentItem = newCurrentSuppliers.find(({id}) => id === supplierId)

    currentItem.favourited = true
    newCurrentSuppliers[currentIndex] = currentItem
    setSuppliers([...newCurrentSuppliers])
  }

  const removeItemFavorite = supplierId => {
    const newFavouritedSuppliers = favouritedSuppliers
    const newCurrentSuppliers = suppliers

    const currentIndex = newCurrentSuppliers.findIndex(({id}) => id === supplierId)
    const favouriteIndex = newFavouritedSuppliers.findIndex(({id}) => id === supplierId)

    if (favouriteIndex !== -1) {
      newFavouritedSuppliers.splice(favouriteIndex, 1)
      setFavouritedSuppliers([...newFavouritedSuppliers])
    }

    const currentItem = newCurrentSuppliers.find(({id}) => id === supplierId)
    if (currentIndex !== -1) {
      currentItem.favourited = false
      newCurrentSuppliers[currentIndex] = currentItem
      setSuppliers([...newCurrentSuppliers])
    }
  }

  const updateFavorite = supplierId => {
    let currentSupplier = suppliers.find(({id}) => id === supplierId)
    if (!currentSupplier) currentSupplier = favouritedSuppliers.find(({id}) => id === supplierId)

    if (currentSupplier) {
      if (currentSupplier.favourited) {
        deleteSupplierFavourites(supplierId).then(() => removeItemFavorite(supplierId))
      } else {
        createSupplierFavourites(supplierId)
          .then(favourited_supplier => setItemFavorite(supplierId, favourited_supplier))
      }
    }
  }

  const Loading = () => (
    <Row>
      {
        _.times(loadingLength, () => (
          <Col key={v4()} md={4} lg={3}>
            <Skeleton className='my-2' height={220} />
          </Col>
        ))
      }
    </Row>
  )

  const renderSuppliers = supplierList =>
    <Row className='mt-3'>
      {
        supplierList?.map(({address, branch, favourited, id, logos, name, supplier_accreditations}) =>
          <Col key={v4()} sm={6} lg={3} className='mb-3'>
            <SupplierCard
              addressLine1={address?.line1}
              addressLine2={address?.line2}
              addressTownCity={address?.city}
              addressCountyState={address?.state}
              addressCountry={address?.country_code}
              addressPostcode={address?.postal_code}
              certificates={supplier_accreditations}
              compactMode
              heading={name}
              isSelectionMode={false}
              isFavorite={favourited}
              onClick={() => toSupplier(id)}
              onFavoriteChange={() => updateFavorite(id)}
              subheading={branch}
              profileImg={logos?.small_url || PLACEHOLDER_LANDSCAPE}>
            </SupplierCard>
          </Col>
        )
      }
    </Row>

  return (
    <FiltersBarView dropdownOptions={{onChange: ({value}) => setElement(value), options}} onSearch={setQuery}>
      {
        loading
          ? <Loading />
          : <>
            {
              favouritedSuppliers?.length > 0 &&
              <>
                <div className='font-bold font-22 mb-3'>
                  {localise('headings.starredSuppliers')}
                </div>
                {renderSuppliers(favouritedSuppliers)}
                <Separator/>
              </>
            }
            {
              query?.length > 0 && suppliers?.length === 0 ?
                <NoSearchResultsPlaceholder
                  className='mt-2'
                  name={localise('headings.suppliers')}
                /> :
                renderSuppliers(suppliers)
            }
          </>
      }
    </FiltersBarView>
  )
}

export default MMCSuppliers

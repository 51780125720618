import React, {useEffect, useState} from 'react'
import {localise} from '../../../../services/LocalizationServices'
import TabMenu from '../../../../components/navs/TabMenu'
import {Col, Row} from 'react-bootstrap'
import Overview from './Overview'
import UnitBreakdown from './UnitBreakdown'
import {useSelector} from 'react-redux'
import {
  getBuildingUnitBreakdown,
} from '../../../../store/selectors/buildingSelectors'
import {
  getBuildingGrossCommercialMetrics,
  getBuildingNetCommercialMetrics,
  getBuildingOutputProgrammeMetrics,
} from '../../../../store/selectors/buildingOutputSelectors'
import {default as Output} from '../../calculator/form/BuildingAnalysisSummaryTable'
import {getSurveyProgress} from '../../../../store/selectors/surveySelectors'

const TAB_ONE = 'overview'
const TAB_TWO = 'unit-breakdown'
const TAB_THREE = 'output'

const BuildingDetails = () => {
  const [currentEventKey, setCurrentEventKey] = useState(TAB_ONE)

  const grossCommercialMetrics = useSelector(getBuildingGrossCommercialMetrics)
  const netCommercialMetrics = useSelector(getBuildingNetCommercialMetrics)
  const programmeMetrics = useSelector(getBuildingOutputProgrammeMetrics)
  const unitBreakdown = useSelector(getBuildingUnitBreakdown)
  const surveyComplete = (useSelector(getSurveyProgress) || 0) === 1

  const views = {
    [TAB_ONE]: (<Overview />),
    [TAB_TWO]: (<UnitBreakdown />),
    [TAB_THREE]: (
      <Output
        grossCommercialMetrics={grossCommercialMetrics}
        netCommercialMetrics={netCommercialMetrics}
        programmeMetrics={programmeMetrics}
      />
    ),
  }

  useEffect(() => {
    setCurrentEventKey(
      surveyComplete
        ? TAB_ONE
        : !!unitBreakdown
          ? TAB_TWO
          : !!grossCommercialMetrics || !!netCommercialMetrics || !!programmeMetrics
            ? TAB_THREE
            : null
    )
  }, [grossCommercialMetrics, netCommercialMetrics, programmeMetrics, surveyComplete, unitBreakdown])

  return (
    <Row className='my-2'>
      <Col xs='12'>
        <TabMenu
          className='mb-2'
          data={[
            surveyComplete &&
            {
              eventKey: TAB_ONE,
              title: localise('nav.overview'),
              tabClassName: `font-15 ${currentEventKey === TAB_ONE && 'active'}`,
            },
            !!unitBreakdown &&
            {
              eventKey: TAB_TWO,
              title: localise('nav.unitBreakdown'),
              tabClassName: `font-15 ${currentEventKey === TAB_TWO && 'active'}`,
            },
            (!!grossCommercialMetrics || !!netCommercialMetrics || !!programmeMetrics) &&
            {
              eventKey: TAB_THREE,
              title: localise('nav.output'),
              tabClassName: `font-15 ${currentEventKey === TAB_THREE && 'active'}`,
            },
          ]}
          onSelect={setCurrentEventKey}
        />
      </Col>
      <Col xs='12'>
        {views[currentEventKey]}
      </Col>
    </Row>
  )
}

export default BuildingDetails

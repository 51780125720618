import React from 'react'
import {bool, node, number, string} from 'prop-types'
import styled from 'styled-components'
import {BRAND_PRIMARY, LIGHTEST_GREY} from '../../constants/colours'

const propTypes = {
  children: node,
  className: string,
  completed: bool,
  completedColor: string,
  fontSize: number,
  size: number,
}

const defaultProps = {
  children: null,
  className: '',
  completed: false,
  completedColor: null,
  fontSize: null,
  size: 50,
}

const NumberDiv = styled.div`
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  font-size:  ${({size, fontSize}) => fontSize || size / 2}px;
  background-color: ${({completed, completedColor}) => completed ? (completedColor || BRAND_PRIMARY) : LIGHTEST_GREY}
`

const NumberBadge = ({children, className, completed, completedColor, size, fontSize}) => {
  return (
    <NumberDiv
      size={size}
      fontSize={fontSize}
      completedColor={completedColor}
      completed={completed}
      className={`badge d-flex justify-content-center align-items-center rounded-circle
      ${completed ? 'font-white' : 'font-dark-grey'} ${className}`}>
      {children}
    </NumberDiv>
  )
}

NumberBadge.propTypes = propTypes
NumberBadge.defaultProps = defaultProps

export default NumberBadge

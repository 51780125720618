import React from 'react'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import store, {persistor} from '../store/store'
import {loadStripe} from '@stripe/stripe-js'
import {setupInterceptors} from '../api/client'
import LoadingIndicator from '../services/LoadingIndicator'
import Router from '../routes/Router'
import {Toaster} from 'react-hot-toast'
import {Elements} from '@stripe/react-stripe-js'
import {REACT_APP_STRIPE_API_KEY} from '../constants/env'
import Init from './Init'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../stylesheets/App.scss'
import {PersistGate} from 'redux-persist/integration/react'
import 'react-loading-skeleton/dist/skeleton.css'

const STRIPE_PROMISE = loadStripe(REACT_APP_STRIPE_API_KEY)

setupInterceptors()

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <LoadingIndicator />
          <Init />
          <Toaster
            position='top-center'
            reverseOrder={false}
          />
          <Elements
            stripe={STRIPE_PROMISE}
            options={{
              fonts: [{
                family: 'Roboto',
              }],
            }}>
            <Router/>
          </Elements>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  )
}

export default App

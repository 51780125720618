export const getProjectAddSuppliers = state => state?.project?.add_suppliers
export const getProjectAddress = state => state?.project?.address
export const getProjectAddressCity = state => state?.project?.address?.city
export const getProjectAddressCountry = state => state?.project?.address?.country
export const getProjectAddressCountryCode = state => state?.project?.address?.country_code
export const getProjectAddressLine1 = state => state?.project?.address?.line1
export const getProjectAddressLine2 = state => state?.project?.address?.line2
export const getProjectAddressPostcode = state => state?.project?.address?.postal_code
export const getProjectAddressState = state => state?.project?.address?.state
export const getProjectCreatedAt = state => state?.project?.created_at
export const getProjectEditable = state => state?.project?.editable
export const getProjectGlaFunding = state =>
  typeof state?.project?.gla_funding === 'boolean' ? state?.project?.gla_funding : false
export const getProjectHomesEnglandFunding = state =>
  typeof state?.project?.homes_england_funding === 'boolean' ? state?.project?.homes_england_funding : false
export const getProjectId = state => state?.project?.id
export const getProjectIsFavorite = state => state?.project?.favourited
export const getProjectMMCCategories = state => state?.project?.mmc_categories
export const getProjectMyPMVRating = state => state?.project?.rating
export const getProjectOtherPublicFunding = state =>
  typeof state?.project?.other_public_funding === 'boolean' ? state?.project?.other_public_funding : false
export const getProjectTitle = state => state?.project?.name
export const getProjectUnitOfMeasurementName = state => state?.project?.unit_of_measurement?.name

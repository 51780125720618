import React, {useState} from 'react'
import BuildingFlow from '../../flows/BuildingFlow'
import {BUILDINGS_URL, CALCULATOR_URL, EDIT_URL, PROJECTS_URL} from '../../../constants/urls'
import {localise} from '../../../services/LocalizationServices'
import {useDispatch, useSelector} from 'react-redux'
import {getProjectId, getProjectTitle} from '../../../store/selectors/projectSelectors'
import useBuilding from '../../../hooks/useBuilding'
import {
  getBuildingId,
  getBuildingName,
  getBuildingSurveyId,
  getBuildingSurveyResponseId,
} from '../../../store/selectors/buildingSelectors'
import {setSurvey} from '../../../store/slices/surveySlice'
import {fetchSurvey} from '../../../api/surveys/surveys'
import {getSurveyElements} from '../../../store/selectors/surveySelectors'
import Element from './Element'
import {v4} from 'uuid'
import Skeleton from 'react-loading-skeleton'
import {useHistory} from 'react-router'

const Components = () => {
  useBuilding({
    onSurveyFetched: () => setLoading(false),
    suppressCalculatorFetches: true,
  })
  const dispatch = useDispatch()
  const {push} = useHistory()

  const [loading, setLoading] = useState(true)

  const elements = useSelector(getSurveyElements)
  const projectId = useSelector(getProjectId)
  const surveyId = useSelector(getBuildingSurveyId)
  const surveyResponseId = useSelector(getBuildingSurveyResponseId)
  const title = useSelector(getProjectTitle)
  const buildingId = useSelector(getBuildingId)
  const buildingName = useSelector(getBuildingName)

  const inCompleteIndex = elements.find(({progress}) => progress !== 1)?.element?.sort_order
  const finalElement = elements[elements.length - 1]?.element?.name

  const toCalculator = () =>
    push(`${PROJECTS_URL}/${projectId}${BUILDINGS_URL}/${buildingId}${EDIT_URL}${CALCULATOR_URL}`)

  const loadSurvey = () => {
    setLoading(true)
    fetchSurvey(surveyId, surveyResponseId)
      .then(survey => dispatch(setSurvey(survey)))
      .finally(() => setLoading(false))
  }

  const handleComplete = element => {
    if (element.toLowerCase() === finalElement.toLowerCase()) return toCalculator()
    loadSurvey()
  }

  return (
    <BuildingFlow
      activeStep={1}
      breadcrumbs={[
        {
          active: false,
          href: `${PROJECTS_URL}/${projectId}`,
          text: title,
        },
        {
          active: false,
          href: `${PROJECTS_URL}/${projectId}${BUILDINGS_URL}/${buildingId}`,
          text: buildingName,
        },
        {
          active: true,
          text: localise('nav.buildingComponents'),
        },
      ]}>
      <div className='d-grid gap-3'>
        {
          loading
            ? <Skeleton height={80} count={5} className='mb-2' />
            : elements.map(
              ({element, progress}, index) => {
                return (
                  <Element
                    key={v4()}
                    active={index === inCompleteIndex}
                    disabled={index > inCompleteIndex}
                    element={element?.slug}
                    icon={element?.images?.original_url}
                    index={index}
                    name={element?.name}
                    onAnswerUpdated={loadSurvey}
                    onComplete={handleComplete}
                  />
                )
              }
            )
        }
      </div>
    </BuildingFlow>
  )
}

export default Components

import React from 'react'
import {bool, node, string} from 'prop-types'
import {FormCheck} from 'react-bootstrap'
import styled from 'styled-components'

const propTypes = {
  className: string,
  hideValidUI: bool,
  label: node,
}

const defaultProps = {
  className: '',
  hideValidUI: false,
  label: '',
}

const HideValidationInput = styled(FormCheck.Input)`
  border-color: black !important;
  box-shadow: none !important;
  &:valid {
    background-color: transparent;
  }
  &:valid:checked {
    background-color: black !important;
  }
`

const Checkbox = ({className, hideValidUI, label, ...rest}) =>
  <FormCheck custom className={className}>
    {
      hideValidUI
        ? <HideValidationInput  {...rest} />
        :  <FormCheck.Input {...rest} />
    }
    <FormCheck.Label>
      <div className='font-13 ms-2'>{label}</div>
    </FormCheck.Label>
  </FormCheck>

Checkbox.propTypes = propTypes
Checkbox.defaultProps = defaultProps

export default Checkbox

import axios from 'axios'
import Qs from 'qs'
import {
  REACT_APP_API_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_CLIENT_SECRET,
} from '../constants/env'
import {authenticate, refreshAuthToken, refreshFailed} from './authentication/auth'
import {BEARER_TOKEN, CLIENT_CREDS_TOKEN, useRefreshToken} from '../utilities/auth-helpers/authHelpers'
import {clearPersisted} from '../store/store'
import {NOT_FOUND_URL} from '../constants/urls'

export const GRANT_TYPE_CLIENT_CREDENTIALS = 'client_credentials'
export const GRANT_TYPE_PASSWORD = 'password'
export const GRANT_TYPE_AUTHORIZATION_CODE = 'authorization_code'

export const apiClient = axios.create({
  baseURL: `${REACT_APP_API_URL}/api/v1/`,
})

export const oauthClient = axios.create({
  baseURL: `${REACT_APP_API_URL}/oauth/`,
})

const CLIENT_SCOPES = 'basic'

export const setupInterceptors = () => {
  [oauthClient].forEach(client => {
    client.interceptors.request.use(configuration => {
      configuration.data = configuration.data || {}
      configuration.data.client_id = REACT_APP_CLIENT_ID
      configuration.data.client_secret = REACT_APP_CLIENT_SECRET
      configuration.data.scopes = CLIENT_SCOPES

      configuration.paramsSerializer = params => {
        return Qs.stringify(params, {
          arrayFormat: 'brackets',
          encode: false,
        })
      }

      if (localStorage.getItem(BEARER_TOKEN) || (localStorage.getItem(CLIENT_CREDS_TOKEN))) {
        configuration.headers.Authorization
          = `Bearer ${localStorage.getItem(BEARER_TOKEN) || localStorage.getItem(CLIENT_CREDS_TOKEN)}`
      }

      return configuration
    })

    client.interceptors.response.use(
      response => response?.data?.data || response,
      error => Promise.reject(error.response.data)
    )
  });

  [apiClient].forEach(client => {
    client.interceptors.request.use(
      configuration => {
        configuration.paramsSerializer = params => {
          return Qs.stringify(params, {
            arrayFormat: 'brackets',
            encode: false,
          })
        }

        if (localStorage.getItem(BEARER_TOKEN) || (localStorage.getItem(CLIENT_CREDS_TOKEN))) {
          configuration.headers.Authorization
            = `Bearer ${localStorage.getItem(BEARER_TOKEN) || localStorage.getItem(CLIENT_CREDS_TOKEN)}`
        }

        configuration.params = configuration.params || {}

        const limit = configuration?.data?.limit
        const offset = configuration?.data?.offset

        if (limit) configuration.params.limit = limit
        if (offset) configuration.params.offset = offset

        return configuration
      },
      error => Promise.reject(error)
    )

    client.interceptors.response.use(
      response => response?.data?.data || response,
      error => {
        const originalRequest = error.config

        if (error?.response) {
          switch (error.response.status) {
            case 404:
              window.location.href = NOT_FOUND_URL
              break
            case 401:
              if (useRefreshToken()) {
                if (!originalRequest.refreshTokenRetried) {
                  originalRequest.refreshTokenRetried = true
                  return refreshAuthToken().then(() => client(originalRequest).then(res => res))
                }
              } else if (originalRequest.retryWithClientCredentials) {
                if (!originalRequest.clientCredentialsRetried) {
                  originalRequest.clientCredentialsRetried = true
                  clearPersisted()
                  return authenticate().then(() => client(originalRequest).then(res => res))
                }
              } else {
                return refreshFailed(error.response.data)
              }
              break
            default:
              return Promise.reject(error.response.data)
          }
        }
      })
  })
}

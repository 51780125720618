import React, {useEffect, useState} from 'react'
import {array, bool, func} from 'prop-types'
import ConfirmForm from './ConfirmForm'
import Skeleton from 'react-loading-skeleton'
import {localise} from '../../services/LocalizationServices'
import ConfirmModal from '../modals/ConfirmModal'
import Slider from '../inputs/Slider'
import InfoTooltip from '../tooltip/InfoTooltip'
import {Form} from 'react-bootstrap'
import Switch from '../inputs/Switch'
import Separator from '../separator/Separator'
import {
  SURVEY_QUESTION_BORDER_BOTTOM_SLUG,
  SURVEY_QUESTION_BORDER_TOP_SLUG,
  SURVEY_QUESTION_CHECKBOX_INPUT_SLUG,
  SURVEY_QUESTION_RADIO_INPUT_SLUG,
  SURVEY_QUESTION_SLIDER_INPUT_SLUG,
} from '../../constants/survey'
import Checkbox from '../checkbox/Checkbox'
import {setSurveyAnswers} from '../../store/slices/surveyAnswersSlice'
import {useDispatch, useSelector} from 'react-redux'
import {getSurveyAnswers} from '../../store/selectors/surveyAnswersSelectors'

const propTypes = {
  disabled: bool,
  loading: bool,
  onConfirm: func,
  questions: array,
}

const defaultProps = {
  disabled: false,
  loading: true,
  onConfirm: null,
  questions: [],
}

const SurveyForm = ({disabled, loading, onConfirm, questions}) => {
  const dispatch = useDispatch()

  const [fields, setFields] = useState([])
  const [show, setShow] = useState(false)
  const [confirmSelection, setConfirmSelection] = useState(null)

  const answers = useSelector(getSurveyAnswers)

  useEffect(() => {
    if (questions?.length > 0) {
      const currentAnswers = {}
      const fields = []

      const value = question => {
        if (question?.survey_question_input?.slug === SURVEY_QUESTION_SLIDER_INPUT_SLUG) {
          return question?.survey_answer?.value || '0'
        }

        if (question?.survey_question_input?.slug === SURVEY_QUESTION_CHECKBOX_INPUT_SLUG) {
          return question?.survey_answer?.value || 'false'
        }

        return question?.survey_answer?.value
      }

      questions
        .sort((first, second) => (first?.sort_order < second?.sort_order ? -1 : 1))
        .forEach(question => {
          currentAnswers[question?.id] = {
            sort_order: question?.sort_order,
            answerId: question?.survey_answer?.id,
            value: value(question),
            answerType: question?.survey_answer?.answer_type,
          }

          fields.push({
            type: 'custom',
            render: <Question question={question}/>,
          })
        })

      dispatch(setSurveyAnswers(currentAnswers))
      setFields(fields)
    }
  }, [dispatch, questions])

  const handleSubmit = () => {
    const submittableAnswers = {}
    questions.forEach(question => (
      submittableAnswers[question?.id] = {
        ...answers[question?.id],
      }
    ))
    onConfirm(submittableAnswers)
  }

  const handleUserInput = (questionId, answerId, value) => {
    const current = answers[questionId]

    dispatch(setSurveyAnswers({
      [questionId]: {
        ...current,
        answerId,
        value,
      },
    }))
  }

  const handleBooleanQuestionChange = (val, setter, questionId, answerId) => {
    setConfirmSelection(null)
    const setVal = value => {
      setShow(false)
      setter(value)
      handleUserInput(questionId, answerId, Boolean(value).toString())
      onConfirm({
        [questionId]: {
          answerId,
          value: Boolean(val).toString(),
        },
      })
    }

    if (answerId) {
      setShow(true)
      setConfirmSelection(() => () => {
        setVal(val)
        onConfirm({
          [questionId]: {
            answerId,
            value: Boolean(val).toString(),
          },
        }, true)
      })
    } else {
      setVal(val)
    }
  }

  const CheckboxQuestion = ({answerId, id, information, label, value}) => {
    const [yes, setYes] = useState(value === 'true')

    return (
      <div className='d-flex'>
        <Form.Label
          hidden={!label}
          className='font-13 flex-grow-1 mb-0 mt-1'>
          <span className='font-bold'>{label}</span>
          {
            information && (
              <InfoTooltip className='ms-1 mb-1' text={information}/>
            )
          }
        </Form.Label>
        <Checkbox
          checked={yes}
          onChange={_ => handleBooleanQuestionChange(!yes, setYes, id, answerId)}
          hideValidUI
        />
      </div>
    )
  }

  const RadioQuestion = ({answerId, id, information, label, value}) => {
    const [yes, setYes] = useState(value === 'true' ? true : value === 'false' ? false : null)

    return (
      <div className='d-flex'>
        <Form.Label
          hidden={!label}
          className='font-13 flex-grow-1 mb-0 mt-1'>
          <span className='font-bold'>{label}</span>
          {
            information && (
              <InfoTooltip className='ms-1 mb-1' text={information} />
            )
          }
        </Form.Label>
        <Switch
          value={yes}
          onChange={val => handleBooleanQuestionChange(val, setYes, id, answerId)}
        />
      </div>
    )
  }

  const SliderQuestion = ({answerId, id, information, label, value: passedValue}) => {
    const [value, setValue] = useState(passedValue)

    return <Slider
      className='mt-2'
      value={value}
      max={80}
      onChange={val => {
        setValue(val)
        handleUserInput(id, answerId, val)
      }}
      hideValueLabel
      sliderLabel={(
        <Form.Label
          hidden={!label}
          className='text-start w-100 font-13'>
          <span className='font-bold'>{label}</span>
          {
            information && (
              <InfoTooltip className='ms-1 mb-1' text={information}/>
            )
          }
        </Form.Label>
      )}
    />
  }

  const Question = ({question}) => {
    const type = question?.survey_question_input?.slug
    const props = {
      answerId: question?.survey_answer?.id,
      id: question?.id,
      information: question?.information,
      label: question?.text,
      value: question?.survey_answer?.value,
    }
    let Comp

    if (type === SURVEY_QUESTION_CHECKBOX_INPUT_SLUG) Comp = CheckboxQuestion
    if (type === SURVEY_QUESTION_RADIO_INPUT_SLUG) Comp =  RadioQuestion
    if (type === SURVEY_QUESTION_SLIDER_INPUT_SLUG) Comp = SliderQuestion

    if (Comp)
      return (
        <>
          {
            question?.survey_question_style?.slug === SURVEY_QUESTION_BORDER_TOP_SLUG
            && <Separator className='my-2' />
          }
          <Comp {...props} />
          {
            question?.survey_question_style?.slug === SURVEY_QUESTION_BORDER_BOTTOM_SLUG
            && <Separator className='my-2' />
          }
        </>
      )
    return null
  }

  const submittable = () => {
    let submittable = true
    let count
    const sliderQuestions = questions
      .filter(question => question?.survey_question_input?.slug === SURVEY_QUESTION_SLIDER_INPUT_SLUG)

    questions.forEach(question => {
      if (answers[question?.id]?.value === null || answers[question?.id]?.value === undefined) {
        submittable = false
      }
    })

    if (sliderQuestions?.length > 0) {
      count = 0
      sliderQuestions
        .forEach(question => {
          if (answers[question?.id]?.value) {
            count += typeof answers[question?.id]?.value === 'string'
              ? parseInt(answers[question?.id]?.value, 10)
              : answers[question?.id]?.value
          }
        })
    }

    if (count > 100) submittable = false

    return submittable
  }

  return (
    <>
      <ConfirmForm
        disabled={disabled}
        fields={fields}
        hideButton={loading}
        submittable={submittable()}
        onSubmit={handleSubmit}
      />
      {loading && <Skeleton height={30} />}
      <ConfirmModal
        show={show}
        onCancel={() => setShow(false)}
        headerText={localise('modalText.changeSelectionConfirmation')}
        bodyText={localise('modalText.changeSelectionConfirmationMsg')}
        onConfirm={() => confirmSelection()}
      />
    </>
  )
}

SurveyForm.propTypes = propTypes
SurveyForm.defaultProps = defaultProps

export default SurveyForm

import React from 'react'
import {Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import {
  COST_CERTAINTY_URL,
  PRODUCTIVITY_URL,
  PROGRAMME_CERTAINTY_URL,
  SAFETY_URL,
  SPEED_URL,
  WASTE_URL,
} from '../constants/urls'
import WasteLogs from '../views/project/logs/WasteLogs'
import CostCertaintyLogs from '../views/project/logs/CostCertaintyLogs'
import ProgrammeCertaintyLogs from '../views/project/logs/ProgrammeCertaintyLogs'
import ProductivityLogs from '../views/project/logs/ProductivityLogs'
import SpeedLogs from '../views/project/logs/SpeedLogs'
import SafetyLogs from '../views/project/logs/SafetyLogs'

const ProjectLogRoutes = ({path}) => {
  return (
    <Switch>
      <RouteWithLayout exact path={`${path}${COST_CERTAINTY_URL}`} component={CostCertaintyLogs} />
      <RouteWithLayout exact path={`${path}${PROGRAMME_CERTAINTY_URL}`} component={ProgrammeCertaintyLogs} />
      <RouteWithLayout exact path={`${path}${PRODUCTIVITY_URL}`} component={ProductivityLogs} />
      <RouteWithLayout exact path={`${path}${WASTE_URL}`} component={WasteLogs} />
      <RouteWithLayout exact path={`${path}${SPEED_URL}`} component={SpeedLogs} />
      <RouteWithLayout exact path={`${path}${SAFETY_URL}`} component={SafetyLogs} />
    </Switch>
  )
}

export default ProjectLogRoutes

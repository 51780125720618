import {createSlice} from '@reduxjs/toolkit'

const buildingNetCostSlice = createSlice({
  name: 'buildingNetCost',
  initialState: {},
  reducers: {
    setBuildingNetCost: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearBuildingNetCost: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const buildingNetCostReducer = buildingNetCostSlice.reducer
export const {clearBuildingNetCost, setBuildingNetCost} = buildingNetCostSlice.actions

import React, {useEffect, useState} from 'react'
import {arrayOf, node, bool, string, func, shape, number, oneOfType} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import Table from './Table'
import buildForm from '../../utilities/form-helpers/FormBuilder'

const propTypes = {
  cellData: arrayOf(
    shape({
      slug: string,
      name: string,
      value: oneOfType([string, number]),
    })),
  className: string,
  customNoData: node,
  headings: arrayOf(string),
  isPercent: bool,
  onChange: func,
  showTotal: bool,
}

const defaultProps = {
  cellData: [],
  className: '',
  customNoData: null,
  headings: [],
  isPercent: true,
  onChange: () => {},
  showTotal: true,
}

const BreakdownTable = props => {
  const {
    cellData,
    className,
    customNoData,
    headings,
    isPercent,
    onChange,
    showTotal,
  } = props

  const [currentQuantityCell, setCurrentQuantityCell] = useState(null)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)

  useEffect(() => {
    setData(cellData)
  }, [cellData])

  useEffect(() => {
    updateAllValues()
    // eslint-disable-next-line
  }, [])

  const leftAlignAttr = {width: '66%', className: 'align-middle'}

  const getRightSideAttr = (width = '5%', className) => ({width, className: `pe-2 text-end ${className}`})

  const updateAllValues = () => {
    if (data?.length > 0) {
      const newTotal = data.reduce((a, b) => a + (b['value'] || 0), 0)
      setTotal(newTotal)
      onChange({data, isValid: isPercent ? newTotal === 100 : true})
    }
  }

  const QuantityCell = ({id, name, slug}) => buildForm([
    {
      type: 'number',
      autoFocus: id === currentQuantityCell,
      className: 'font-12 border-0 p-0 text-end',
      hideValidUI: true,
      handler: val => {
        const value = val ? Math.max(0, Number.parseInt(val, 10)) : 0
        const itemIndex = data.findIndex(item => item.slug === slug)
        const item = data.find(item => item.slug === slug)

        item.value = value
        item.slug = slug

        data[itemIndex] = item
        setData(data)
        setCurrentQuantityCell(id)

        updateAllValues()
      },
      name,
      value: data?.find(item => item.slug === slug)?.value || 0,
    },
  ])

  const createHeadings = () =>
    [...headings].map((heading, index) => ({
      text: heading,
      attributes: index === 0 ? leftAlignAttr :
        getRightSideAttr(index === 1 && '10%'),
    }))

  const createRows = () => {
    const rows = []
    data.forEach(({slug, name, id = 0}) => {
      rows.push({
        row: [
          {cell: name, attributes: leftAlignAttr},
          {
            cell: <QuantityCell name={name} slug={slug} id={id}/>,
            attributes: getRightSideAttr(),
          },
        ],
      })
    })

    if (showTotal) {
      rows.push({
        row: [
          {
            cell: localise('tables.total'),
            attributes: {className: 'align-middle font-bold font-13'},
          },
          {
            cell: total,
            attributes: {className: 'pe-2 text-end font-bold font-13'},
          },
        ],
      })
    }

    return rows
  }

  return (
    <Table
      className={className}
      customNoData={customNoData}
      data={{
        tableHeadings: createHeadings(),
        tableBody: createRows(),
      }}
    />
  )
}

BreakdownTable.propTypes = propTypes
BreakdownTable.defaultProps = defaultProps

export default BreakdownTable

import React, {useState} from 'react'
import BreadCrumbsView from '../../common/BreadcrumbsView'
import {
  ACCOUNT_URL,
  PROJECTS_URL,
  PURCHASE_OPTIONS_URL,
  SUBSCRIPTIONS_URL,
} from '../../../constants/urls'
import {localise} from '../../../services/LocalizationServices'
import {Col} from 'react-bootstrap'
import Card from '../../../components/cards/Card'
import Separator from '../../../components/separator/Separator'
import PaymentMethodEdit from '../../../components/payment-method/PaymentMethodEdit'
import {SUBSCRIPTION} from '../../../constants/BillingTypes'
import {toggleLoading} from '../../../utilities/LoadingIndicatorUtil'
import {createPurchase, createSubscription, updateSubscription} from '../../../api/purchases/Purchases'
import PurchaseSuccessModal from '../../../components/modals/PurchaseSuccessModal'
import {useHistory, useLocation} from 'react-router'
import {toast} from 'react-hot-toast'

const SubscriptionPayment = props => {
  const {push} = useHistory()
  const {state: {product, subscriptionId, isUpdating}} = useLocation()

  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const isSubscription = product?.billing_type === SUBSCRIPTION
  const toProjects = () => push(PROJECTS_URL)
  const toAccountSubscriptions = () => push(`${ACCOUNT_URL}${SUBSCRIPTIONS_URL}`)

  const onSuccess = payment_method => {
    toggleLoading()
    if (isUpdating) {
      updateSubscription(subscriptionId, payment_method?.id)
        .then(() => {
          toAccountSubscriptions()
          toast.success(localise('toast.paymentMethodUpdated'))
        }).finally(toggleLoading)
    } else {
      if (isSubscription) {
        createSubscription(product?.id, payment_method?.id)
          .then(() => setShowSuccessModal(true)).finally(toggleLoading)
      } else {
        createPurchase(product?.id, payment_method?.id)
          .then(() => setShowSuccessModal(true)).finally(toggleLoading)
      }
    }
  }

  const breadcrumbItemsProject = [
    {
      active: false,
      href: PROJECTS_URL,
      text: localise('nav.projects'),
    },
    {
      active: false,
      href: `${PROJECTS_URL}${PURCHASE_OPTIONS_URL}`,
      text: localise('headings.purchaseOptions'),
    },
    {
      active: true,
      text: localise('headings.payment'),
    },
  ]

  const breadcrumbItemsAccount = [
    {
      active: false,
      href: ACCOUNT_URL,
      text: localise('nav.myAccount'),
    },
    {
      active: false,
      href: `${ACCOUNT_URL}${SUBSCRIPTIONS_URL}`,
      text: localise('nav.mySubscription'),
    },
    {
      active: true,
      text: localise('headings.updatePaymentMethod'),
    },
  ]

  return (
    <>
      <BreadCrumbsView breadcrumbItems={isUpdating ? breadcrumbItemsAccount : breadcrumbItemsProject}/>
      <Col xs={11} sm={10} md={8} lg={6} xl={5} className='mx-auto'>
        <Card className='mb-2'>
          <div className='mx-3 mt-1'>
            <div className='font-10'>{`${product?.title} (${product?.description})`}</div>
            <div className='d-flex'>
              <div className='font-bold font-17'>{product?.price?.display}</div>
              {
                isSubscription &&
                <div className='font-10 ms-1 mb-1 mt-auto'>{localise('subscription.billedAnnually')}</div>
              }
            </div>
          </div>
          {
            !isUpdating &&
            <>
              <Separator className='bg-light-grey my-1'/>
              <div className='ms-3 pb-2 font-12'>
                {isSubscription ?
                  localise('headings.agreePurchaseTermsSubscription') :
                  localise('headings.agreePurchaseTerms')}
              </div>
            </>
          }
        </Card>
        <PaymentMethodEdit
          confirmButtonText={isUpdating ? localise('payment.updatePaymentMethod') : null}
          isSubscription={isSubscription}
          onSuccess={onSuccess}
        />
      </Col>
      <PurchaseSuccessModal
        isSubscription={isSubscription}
        onConfirm={isUpdating ? toAccountSubscriptions : toProjects}
        purchaseDesc={product?.title}
        show={showSuccessModal}
      />
    </>
  )
}

export default SubscriptionPayment

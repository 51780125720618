import {apiClient} from '../client'
import getIndexParams from '../../utilities/api-helpers/getIndexParams'

export const fetchSuppliers = async opts => {
  let params = getIndexParams(opts)
  const boundingBox = opts?.boundingBox
  const element = opts?.element
  const sort = opts?.sort

  if (element) params = {...params, element}
  if (sort) params = {...params, sort}
  if (boundingBox) params = {...params, bounding_box: encodeURIComponent(JSON.stringify(boundingBox))}

  const {suppliers, meta: {total}} = await apiClient.get('suppliers', {params})
  return {suppliers, total}
}

export const createSupplierFavourites = async id => {
  const {favourited_supplier} = await apiClient.post(`/suppliers/${id}/favourites`)
  return favourited_supplier
}

export const deleteSupplierFavourites = async id => {
  const {destroyed} = await apiClient.delete(`/suppliers/${id}/favourites`)
  return {destroyed}
}

export const fetchSupplier = async id => {
  const {supplier} = await apiClient.get(`/suppliers/${id}`)
  return supplier
}

import React from 'react'
import {Route, Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import {
  ADDRESS_URL,
  BUILDINGS_URL,
  DETAILS_URL,
  EDIT_URL,
  FIT_OUT_URL,
  FUNDING_URL,
  LOG_URL,
  MECHANICAL_ELECTRICAL_PLUMBING_URL,
  NEW_URL,
  PAYMENT_URL,
  PRELIMINARIES_URL,
  PURCHASE_OPTIONS_URL,
  SUBSTRUCTURE_URL,
  SUPERSTRUCTURE_URL,
  SUPPLIERS_URL,
} from '../constants/urls'
import CreateDetails from '../views/project/create/details/Details'
import EditDetails from '../views/project/edit/details/Details'
import EditAddress from '../views/project/edit/address/Address'
import EditFunding from '../views/project/edit/funding/Funding'
import FlowLayout from './layouts/FlowLayout'
import Address from '../views/project/create/address/Address'
import Projects from '../views/project/Projects'
import Funding from '../views/project/create/funding/Funding'
import Project from '../views/project/view/Project'
import BuildingRoutes from './BuildingRoutes'
import PurchaseOptions from '../views/project/purchase/PurchaseOptions'
import SubscriptionPayment from '../views/project/purchase/SubscriptionPayment'
import MainMenuFlowLayout from './layouts/MainMenuFlowLayout'
import Substructure from '../views/project/mmc-supplier/substructure/Substructure'
import Superstructure from '../views/project/mmc-supplier/superstructure/Superstructure'
import FitOut from '../views/project/mmc-supplier/fitout/FitOut'
// eslint-disable-next-line max-len
import MechanicalElectricalPlumbing from '../views/project/mmc-supplier/mechanical-electrical-plumbing/MechanicalElectricalPlumbing'
import Preliminaries from '../views/project/mmc-supplier/preliminaries/Preliminaries'
import ProjectLogRoutes from './ProjectLogRoutes'
import PageNotFound from '../views/error/PageNotFound'

const ProjectRoutes = ({path}) => {
  return (
    <Switch>
      <RouteWithLayout exact path={path} component={Projects} />
      <RouteWithLayout exact path={`${path}${NEW_URL}`} component={CreateDetails} layout={FlowLayout} />
      <RouteWithLayout
        exact
        path={`${path}${PURCHASE_OPTIONS_URL}`}
        component={PurchaseOptions}
      />
      <RouteWithLayout exact path={`${path}/:slug`} component={Project} />
      <RouteWithLayout
        component={Substructure}
        exact
        layout={MainMenuFlowLayout}
        path={`${path}/:slug${SUPPLIERS_URL}${NEW_URL}${SUBSTRUCTURE_URL}`}
      />
      <RouteWithLayout
        component={Superstructure}
        exact
        layout={MainMenuFlowLayout}
        path={`${path}/:slug${SUPPLIERS_URL}${NEW_URL}${SUPERSTRUCTURE_URL}`}
      />
      <RouteWithLayout
        component={FitOut}
        exact
        layout={MainMenuFlowLayout}
        path={`${path}/:slug${SUPPLIERS_URL}${NEW_URL}${FIT_OUT_URL}`}
      />
      <RouteWithLayout
        component={MechanicalElectricalPlumbing}
        exact
        layout={MainMenuFlowLayout}
        path={`${path}/:slug${SUPPLIERS_URL}${NEW_URL}${MECHANICAL_ELECTRICAL_PLUMBING_URL}`}
      />
      <RouteWithLayout
        component={Preliminaries}
        exact
        layout={MainMenuFlowLayout}
        path={`${path}/:slug${SUPPLIERS_URL}${NEW_URL}${PRELIMINARIES_URL}`}
      />
      <RouteWithLayout
        exact
        path={`${path}${PURCHASE_OPTIONS_URL}${PAYMENT_URL}`}
        component={SubscriptionPayment}
      />
      <ProjectLogRoutes path={`${path}/:slug${LOG_URL}`} />
      <BuildingRoutes path={`${path}/:projectSlug${BUILDINGS_URL}`} />
      <RouteWithLayout exact path={`${path}${NEW_URL}${ADDRESS_URL}`} component={Address} layout={FlowLayout} />
      <RouteWithLayout exact path={`${path}${NEW_URL}${FUNDING_URL}`} component={Funding} layout={FlowLayout} />
      <RouteWithLayout exact path={`${path}${EDIT_URL}${DETAILS_URL}`} component={EditDetails} />
      <RouteWithLayout exact path={`${path}${EDIT_URL}${ADDRESS_URL}`} component={EditAddress} />
      <RouteWithLayout exact path={`${path}${EDIT_URL}${FUNDING_URL}`} component={EditFunding} />
      <Route path='*'><PageNotFound /></Route>
    </Switch>
  )
}

export default ProjectRoutes

import React from 'react'
import {bool, func, node, object, oneOfType, string} from 'prop-types'
import Console from '../../utilities/ConsoleUtil'
import {Modal as BSModal} from 'react-bootstrap'

const propTypes = {
  animation: bool,
  bodyClasses: string,
  bodyElement: node,
  bodyText: oneOfType([string, object]),
  className: string,
  footerClasses: string,
  footerElement: node,
  headerClasses: string,
  headerElement: node,
  headerText: node,
  show: bool,
  showCloseButton: bool,
  toggle: func,
}

const defaultProps = {
  animation: true,
  bodyClasses: '',
  bodyElement: null,
  bodyText: '',
  className: '',
  footerClasses: '',
  footerElement: null,
  headerClasses: '',
  headerElement: null,
  headerText: '',
  show: false,
  showCloseButton: false,
  toggle: () => Console.dev('click toggle prop'),
}

const Modal = props => {

  const {
    animation,
    bodyClasses,
    bodyElement,
    bodyText,
    className,
    footerClasses,
    footerElement,
    headerClasses,
    headerElement,
    headerText,
    show,
    showCloseButton,
    toggle,
  } = props

  const defaultHeader =
    <BSModal.Title>
      <div className='font-20 text-capitalize font-bold'>
        {headerText}
      </div>
    </BSModal.Title>

  const defaultBody = <span className='font-15'>{bodyText}</span>

  return (
    <BSModal
      show={show}
      onHide={toggle}
      animation={animation}
      className={`modal-container ${className}`}>
      <BSModal.Header
        hidden={!headerElement && !headerText}
        closeButton={showCloseButton}
        className={`py-3 px-4 ${headerClasses}`}>
        {headerElement || defaultHeader}
      </BSModal.Header>
      <BSModal.Body hidden={!bodyElement && !bodyText} className={`px-4 pb-4 ${bodyClasses}`}>
        {bodyElement || defaultBody}
      </BSModal.Body>
      {
        footerElement &&
        <BSModal.Footer className={`py-3 px-4 ${footerClasses}`}>
          {footerElement}
        </BSModal.Footer>
      }
    </BSModal>
  )
}

Modal.propTypes = propTypes
Modal.defaultProps = defaultProps

export default Modal

import React from 'react'
import {func, string} from 'prop-types'
import {SUPPLIER_ICON} from '../../constants/svgs'
import {Button} from 'react-bootstrap'
import Console from '../../utilities/ConsoleUtil'
import {localise} from '../../services/LocalizationServices'

const propTypes = {
  buttonText: string,
  buttonVariant: string,
  className: string,
  description: string,
  icon: string,
  onButtonClick: func,
  title: string,
}

const defaultProps = {
  buttonText: '',
  buttonVariant: 'white-secondary',
  className: '',
  description: '',
  icon: '',
  onButtonClick: () => Console.dev('click onButtonClick prop'),
  title: '',
}

const Banner = props => {
  const {
    buttonText,
    buttonVariant,
    className,
    description,
    icon,
    onButtonClick,
    title,
  } = props

  return (
    <div className={`bg-brand-secondary w-100 pb-4 ${className}`}>
      <div className='ps-3 pt-3 d-flex'>
        <div className='pt-2'>
          <img src={icon || SUPPLIER_ICON} alt={localise('alt.supplierIcon')} />
        </div>
        <div className='ps-4'>
          <div className='font-20 font-white font-bold'>{title || localise('banner.mmcSupplier')}</div>
          <div className='font-13 font-light font-white pe-2'>{description}</div>
          <Button
            variant={buttonVariant}
            className='mt-2 py-1 font-12'
            onClick={onButtonClick}>
            {buttonText || localise('banner.addSuppliersNow')}
          </Button>
        </div>
      </div>
    </div>
  )
}

Banner.propTypes = propTypes
Banner.defaultProps = defaultProps

export default Banner

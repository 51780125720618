import React from 'react'
import {Tabs, Tab} from 'react-bootstrap'
import {array, func, string} from 'prop-types'
import {v4} from 'uuid'
import Console from '../../utilities/ConsoleUtil'

const propTypes = {
  additionalContainerClasses: string,
  data: array.isRequired,
  defaultActiveKey: string,
  onSelect: func,
}

const defaultProps = {
  additionalContainerClasses: '',
  data: [
    {
      content: 'A',
      eventKey: 'A',
      tabClassName: '',
      title: 'A',
    },
    {
      content: 'B',
      eventKey: 'B',
      tabClassName: '',
      title: 'B',
    },
  ],
  defaultActiveKey: 'A',
  onSelect: eventKey => Console.dev(`onSelect eventKey => ${eventKey}`),
}

const TabMenu = ({className, defaultActiveKey, data, onSelect, title}) =>
  <div className='position-relative'>
    {
      title &&
      <div className='position-absolute tabs-title font-15 font-grey'>{title}</div>
    }
    <Tabs
      id='tab-nav-id'
      className={`tab-nav-container font-20 ${className}`}
      onSelect={onSelect}
      defaultActiveKey={defaultActiveKey}>
      {
        data.map(({eventKey, content, tabClassName, title}) =>
          <Tab tabClassName={`ps-0 pb-1 me-3 isolate ${tabClassName}`} eventKey={eventKey} title={title} key={v4()}>
            {content}
          </Tab>
        )
      }
    </Tabs>
  </div>

TabMenu.propTypes = propTypes
TabMenu.defaultProps = defaultProps

export default TabMenu

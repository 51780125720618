import {createSlice} from '@reduxjs/toolkit'

const thresholdsSlice = createSlice({
  name: 'thresholds',
  initialState: {},
  reducers: {
    setThresholds: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearThresholds: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const thresholdsReducer = thresholdsSlice.reducer
export const {clearThresholds, setThresholds} = thresholdsSlice.actions

import React, {useEffect, useState} from 'react'
import {localise} from '../../services/LocalizationServices'
import PLACEHOLDER_PORTRAIT from '../../assets/svgs/placeholder-portrait.svg'
import {MMC_SUPPLIERS_URL} from '../../constants/urls'
import {useHistory} from 'react-router-dom'
import BreadCrumbsView from '../common/BreadcrumbsView'
import SupplierCard from '../../components/cards/SupplierCard'
import Card from '../../components/cards/Card'
import Separator from '../../components/separator/Separator'
import {createSupplierFavourites, deleteSupplierFavourites, fetchSupplier} from '../../api/supplier/supplier'
import {useParams} from 'react-router'

const MMCSupplierDetails = () => {
  const {push} = useHistory()
  const {slug} = useParams()

  const [supplier, setSupplier] = useState(null)
  const [isSupplierFavorite, setIsSupplierFavorite] = useState(false)

  useEffect(() => {
    fetchSupplier(slug).then(supplier => {
      setSupplier(supplier)
      setIsSupplierFavorite(supplier.favourited)
    })
  }, [slug])

  const favoriteSupplier = () => {
    if (isSupplierFavorite) {
      deleteSupplierFavourites(supplier?.id).then(() => setIsSupplierFavorite(false))
    } else {
      createSupplierFavourites(supplier?.id).then(() => setIsSupplierFavorite(true))
    }
  }

  const toMMCSuppliers = () => push(MMC_SUPPLIERS_URL)

  const breadcrumbs = [
    {
      active: false,
      linkProps: {onClick: toMMCSuppliers},
      text: localise('nav.mmcSuppliers'),
    },
    {
      active: true,
      text: localise('nav.supplier'),
    },
  ]

  return (
    <BreadCrumbsView breadcrumbItems={breadcrumbs}>
      <SupplierCard
        addressLine1={supplier?.address?.line1}
        addressLine2={supplier?.address?.line2}
        addressTownCity={supplier?.address?.city}
        addressCountyState={supplier?.address?.state}
        addressCountry={supplier?.address?.country_code}
        addressPostcode={supplier?.address?.postal_code}
        certificates={supplier?.supplier_accreditations}
        clickable={false}
        isSelectionMode={false}
        isFavorite={isSupplierFavorite}
        onFavoriteChange={favoriteSupplier}
        heading={supplier?.name}
        subheading={supplier?.branch}
        profileImg={supplier?.logos?.small_url || PLACEHOLDER_PORTRAIT}
        categoryItems={supplier?.mmc_categories}>
      </SupplierCard>
      <div className='mt-3'>
        <div className='font-15 mb-2'>{localise('headings.details')}</div>
        <Card hideBoxShadow>
          <div className='mx-3 my-2'>
            <div className='font-10'>{localise('headings.description')}</div>
            <div className='font-17'>{supplier?.description}</div>
          </div>
          <Separator/>
          <div className='mx-3 my-2'>
            <div className='font-10'>{localise('headings.phone')}</div>
            <div className='font-bold font-17'>{supplier?.phone_number}</div>
          </div>
          <Separator/>
          <div className='mx-3 my-2'>
            <div className='font-10'>{localise('headings.email')}</div>
            <div className='font-bold font-17'>{supplier?.email}</div>
          </div>
          <Separator/>
          <div className='mx-3 my-2'>
            <div className='font-10'>{localise('headings.headOffice')}</div>
            <div className='font-bold font-17'>
              <div>{supplier?.address?.line1}</div>
              <div>{supplier?.address?.city}, {supplier?.address?.state}</div>
              <div>{supplier?.address?.country_code}, {supplier?.address?.postal_code}</div>
            </div>
          </div>
        </Card>
      </div>
    </BreadCrumbsView>
  )
}

export default MMCSupplierDetails

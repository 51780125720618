import React, {useEffect, useState} from 'react'
import {localise} from '../../services/LocalizationServices'
import {Button} from 'react-bootstrap'
import useNav from '../../hooks/useNav'
import SideNavView from '../common/SideNavView'
import Separator from '../../components/separator/Separator'
import {useHistory} from 'react-router'
import {
  ACCOUNT_URL,
  PAYMENT_HISTORY_URL,
  PAYMENT_URL,
  SUBSCRIPTIONS_URL,
} from '../../constants/urls'
import CardIcon from '../../components/card-icon/CardIcon'
import Modal from '../../components/modals/Modal'
import {useDispatch, useSelector} from 'react-redux'
import {getCurrentUserSubscription} from '../../store/selectors/currentUserSelectors'
import {fetchProducts} from '../../api/products/Products'
import {PAYMENT} from '../../constants/BillingTypes'
import {fetchUser} from '../../api/user/user'
import {setCurrentUser, clearCurrentUserSubscription} from '../../store/slices/currentUserSlice'
import {deleteSubscription} from '../../api/purchases/Purchases'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {DateTime} from 'luxon'
import {LONG_DATE_FORMAT} from '../../constants/dates'

const Subscription = () => {
  const dispatch = useDispatch()
  const {push} = useHistory()
  const {SIDE_NAV_ACCOUNT} = useNav()
  const userSubscription = useSelector(getCurrentUserSubscription)

  const [showCancelSubModal, setShowCancelSubModal] = useState(false)
  const [showCancelSubSuccessModal, setShowCancelSubSuccessModal] = useState(false)
  const [standardProduct, setStandardProduct] = useState(null)

  const toPaymentHistory = () => push(`${ACCOUNT_URL}${SUBSCRIPTIONS_URL}${PAYMENT_HISTORY_URL}`)
  const toUpdatePaymentMethod = product => push(
    `${ACCOUNT_URL}${SUBSCRIPTIONS_URL}${PAYMENT_URL}`,
    {product, subscriptionId: userSubscription?.id, isUpdating: true}
  )
  const hideCancelSubModal = () => setShowCancelSubModal(false)
  const hideCancelSubSuccessModal = () => setShowCancelSubSuccessModal(false)

  const handleCancelSubscription = async () => {
    toggleLoading()
    deleteSubscription(userSubscription.id).then(() => {
      dispatch(clearCurrentUserSubscription(userSubscription))
      setShowCancelSubModal(false)
      setShowCancelSubSuccessModal(true)
    }).finally(toggleLoading)
  }

  useEffect(() => {
    fetchUser().then(user => dispatch(setCurrentUser(user)))
    fetchProducts()
      .then(items => {
        const paymentProducts = items?.products.filter(product => product?.billing_type === PAYMENT)
        if (paymentProducts?.length > 0) {
          setStandardProduct(paymentProducts[0])
        }
      })
    // eslint-disable-next-line
  }, [])

  const createPaymentHistoryDiv = () => (
    <div className='cursor-pointer py-3 text-center font-15 font-grey' onClick={toPaymentHistory}>
      {localise('subscription.viewPaymentHistory')}
    </div>
  )

  const StandardSubscription = () => (
    <div className='box-shadow border-light-grey'>
      <div className='bg-brand-secondary font-white'>
        <div className='font-white ms-3 py-2'>
          <div className='font-bold font-20'>{standardProduct?.title}</div>
          <div className='font-light font-17'>{standardProduct?.price.display}</div>
          <div className='font-13'>{localise('subscription.perProject')}</div>
        </div>
      </div>
      {createPaymentHistoryDiv()}
    </div>
  )

  const MyPMVPlusSubscription = () => (
    <div className='box-shadow border-light-grey'>
      <div className='bg-brand-yellow font-white'>
        <div className='font-white ms-3 py-2'>
          <div className='font-bold font-20'>{userSubscription?.product?.title}</div>
          <div className='d-flex font-light'>
            <div className='font-17'>{userSubscription?.product?.price?.display}</div>
            <div className='font-10 ms-1 mb-1 mt-auto'>{localise('subscription.billedAnnually')}</div>
          </div>
          <div className='font-13'>{localise('subscription.unlimitedProjects')}</div>
        </div>
      </div>
      <div className='bg-brand-yellow font-white'>
        <Separator className='bg-white'/>
        <div className='ms-3 font-bold font-13 py-1'>
          {localise('subscription.nextPaymentOn',
            {amount: userSubscription?.product?.price?.display,
              date: DateTime.fromSeconds(userSubscription?.billing_date || 0).toFormat(LONG_DATE_FORMAT)})}
        </div>
      </div>
      <div className='ms-3'>
        <div className='pt-1 font-13'>{localise('subscription.paymentMethod')}</div>
        <Separator className='bg-light-grey'/>
        <div className='d-flex mt-2 pb-2'>
          <CardIcon className='align-self-center' brand={userSubscription?.payment_method?.card?.brand}/>
          <div className='ps-3 w-100'>{localise('subscription.cardNumLastFour',
            {lastFour: userSubscription?.payment_method.card?.last_four})}</div>
          <div className='pe-3'>
            {userSubscription?.payment_method?.card?.exp_year}/{userSubscription?.payment_method?.card?.exp_month}
          </div>
        </div>
        <Button variant='outline' className='font-15 py-0 mt-1 mb-2 me-2'
          onClick={() => toUpdatePaymentMethod(userSubscription.product)}>
          {localise('buttonText.change')}
        </Button>
      </div>
      <Separator className='bg-light-grey'/>
      <div className='mx-3 my-2'>
        <Button variant='outline' className='font-15 mt-1 mb-2 w-100' onClick={setShowCancelSubModal}>
          {localise('buttonText.cancelSubscription')}
        </Button>
      </div>
      <Separator className='bg-light-grey mx-3'/>
      {createPaymentHistoryDiv()}
    </div>
  )

  const CancelSubscriptionModal = () => (
    <Modal
      bodyClasses='border py-3'
      bodyElement={localise('modalText.cancelThisSubscriptionDesc')}
      headerText={localise('modalText.cancelThisSubscription')}
      show={showCancelSubModal}
      toggle={() => setShowCancelSubModal(false)}
      footerElement={<div className='mt-3'>
        <Button variant='outline' className='font-15 font-bold py-2 rounded me-2' onClick={hideCancelSubModal}>
          {localise('buttonText.cancel')}
        </Button>
        <Button variant='primary' className='font-15 font-bold ms-2 py-2 rounded' onClick={handleCancelSubscription}>
          {localise('buttonText.cancelSubscription')}
        </Button>
      </div>}
    />
  )

  const CancelSubscriptionSuccessModal = () => (
    <Modal
      show={showCancelSubSuccessModal}
      headerText={localise('modalText.subscriptionCancelled')}
      bodyElement={localise('modalText.subscriptionCancelledSuccessDesc')}
      bodyClasses='border py-3'
      footerElement={<div className='mt-3'>
        <Button variant='outline' className='font-15 font-bold py-2 px-3 rounded' onClick={hideCancelSubSuccessModal}>
          {localise('buttonText.ok')}
        </Button>
      </div>}
    />
  )

  return (
    <SideNavView navList={SIDE_NAV_ACCOUNT}>
      <div className='pb-3'>
        <div className='font-bold font-20 mb-2'>{localise('nav.mySubscription')}</div>
        {userSubscription && <MyPMVPlusSubscription/>}
        {!userSubscription && <StandardSubscription/>}
      </div>
      <CancelSubscriptionModal/>
      <CancelSubscriptionSuccessModal/>
    </SideNavView>
  )
}

export default Subscription

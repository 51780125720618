import React, {useEffect, useState} from 'react'
import {bool, node, object, string} from 'prop-types'
import {Accordion as BSAccordion} from 'react-bootstrap'
import IconHeader from '../headings/IconHeader'
import ProgressBar from '../progress-bar/ProgressBar'

const propTypes = {
  active: bool,
  bodyClassName: string,
  children: node,
  className: string,
  disabled: bool,
  header: node,
  headerProps: object,
  hideProgress: bool,
  progressBarProps: object,
}

const defaultProps = {
  active: false,
  bodyClassName: '',
  children: null,
  className: '',
  disabled: false,
  header: null,
  headerProps: {},
  hideProgress: false,
  progressBarProps: {},
}

const HeaderAccordion = props => {
  const {
    active: passedActive,
    bodyClassName,
    children,
    className,
    disabled,
    header,
    headerProps,
    hideProgress,
    progressBarProps,
  } = props

  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(passedActive)
  }, [passedActive])

  return (
    <div className={`header-accordion ${disabled ? 'opacity-50 disabled' : ''}`}>
      <BSAccordion
        activeKey={active && '0'}
        flush
        className={className}
        defaultActiveKey={active && '0'}>
        <BSAccordion.Item eventKey='0'>
          <BSAccordion.Header onClick={() => setActive(!active)}>
            {header || <IconHeader {...headerProps} />}
          </BSAccordion.Header>
          {!hideProgress && <ProgressBar {...progressBarProps} />}
          {!disabled && <BSAccordion.Body className={`p-0 ${bodyClassName}`}>{children}</BSAccordion.Body>}
        </BSAccordion.Item>
      </BSAccordion>
    </div>
  )
}

HeaderAccordion.propTypes = propTypes
HeaderAccordion.defaultProps = defaultProps

export default HeaderAccordion

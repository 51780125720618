import React from 'react'
import {Button, Col, Image, Nav, Navbar, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {ACCOUNT_URL, EDIT_URL, PROJECTS_URL, ROOT_URL} from '../../constants/urls'
import {localise} from '../../services/LocalizationServices'
import {v4} from 'uuid'
import {COMPANY_LOGO} from '../../constants/svgs'
import useNav from '../../hooks/useNav'
import {useLocation} from 'react-router'
import SupportButton from '../buttons/SupportButton'

const TopNav = () => {
  const {
    breakpoint,
    isActiveLink,
    links,
    subscriptionsLinkAvailable,
  } = useNav()

  const {pathname} = useLocation()

  const MENU_TYPE = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
  }

  const handleLink = (link, linkClasses) => {
    if (link?.button) {
      const Button = link.button
      return <Button key={v4()} additionalContainerClasses={`dropdown-item p-2 ${linkClasses}`} />
    }

    return (
      <Link key={v4()} className={`text-decoration-none ${linkClasses}`} to={link?.to}>
        <div
          className={`dropdown-item font-black p-2 ${isActiveLink(link?.activeViews || []) ? 'active' : ''}`}>
          {localise(link?.translationKey)}
        </div>
      </Link>
    )
  }

  const buildMenu = menuType => {
    const classes = `d-${breakpoint}-none d-block`

    const navList = [
      links.projects,
      //TODO: UNCOMMENT WHEN MMC SUPPLIERS ARE INTRODUCED
      // links.mmcSuppliers,
      links.helpfulLinks,
      links.mapView,
      links.myAccount,
    ]

    if (menuType === MENU_TYPE.MOBILE) {
      if (pathname.includes(ACCOUNT_URL)) {
        navList.push(...[links.myDetails, links.password, links.preferences])
        if (subscriptionsLinkAvailable) navList.push(...[links.subscriptions])
        navList.splice(navList.indexOf(links.myAccount), 1)
      }

      if (pathname.includes(`${PROJECTS_URL}${EDIT_URL}`)) {
        navList.splice(navList.indexOf(links.projects), 1)
        navList.push(...[links.details, links.address, links.funding])
      }
    }
    return navList.map(navItem => handleLink(navItem, classes))
  }

  return (
    <Row className='top-nav-container bg-white py-1'>
      <Col xs='12' className='nav-wrapper'>
        <Navbar className='top-nav-container bg-white' expand={breakpoint}>
          <Navbar.Brand className='p-2' href={ROOT_URL}>
            <Image src={COMPANY_LOGO} height={64} alt={localise('alt.companyLogo')} />
          </Navbar.Brand>
          <Navbar.Toggle className='mx-2' aria-controls='top-navbar' />
          <Navbar.Collapse id='top-navbar'>
            <Nav className={`d-none d-${breakpoint}-block ms-auto mt-3 mt-${breakpoint}-0`}>
              {buildMenu(MENU_TYPE.DESKTOP)}
            </Nav>
            <Nav className={`d-${breakpoint}-none ms-auto mt-3 mt-${breakpoint}-0`}>
              {buildMenu(MENU_TYPE.MOBILE)}
              <SupportButton className='font-15 me-auto ms-2 mb-2' />
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Col>
    </Row>
  )
}

export default TopNav

import {createSlice} from '@reduxjs/toolkit'

const configurationSlice = createSlice({
  name: 'configuration',
  initialState: {
    //NOTE: Hardcoded for now but should be returned from the config endpoint
    support_email: 'pmv@cast-consultancy.com',
  },
  reducers: {
    setConfiguration: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
  },
})

export const configurationReducer = configurationSlice.reducer
export const {setConfiguration} = configurationSlice.actions

import React, {useState} from 'react'
import Form from '../../components/forms/Form'
import {localise} from '../../services/LocalizationServices'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {Button} from 'react-bootstrap'
import {Link, useHistory} from 'react-router-dom'
import {AUTH_URL, RESET_PASSWORD_CONFIRMATION_URL, SIGN_IN_URL} from '../../constants/urls'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {resetPassword} from '../../api/authentication/auth'

const Reset = () => {
  const {push} = useHistory()

  const [validated, setValidated] = useState(false)
  const [email, setEmail] = useState('')

  const fields = [
    {
      type: 'email',
      required: true,
      label: localise('form.label.email'),
      placeholder: localise('form.placeholder.email'),
      validationMessage: localise('form.validation.email'),
      handler: setEmail,
    },
  ]

  const toConfirmation = () => push(`${AUTH_URL}${RESET_PASSWORD_CONFIRMATION_URL}`)

  const submitFormData = () => {
    toggleLoading()
    resetPassword(email)
      .then(toConfirmation)
      .finally(toggleLoading)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      submitFormData()
    }
    setValidated(true)
  }

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      {buildForm(fields)}
      <Button type='submit' variant='primary' className='font-15 mt-3' size='lg'>
        {localise('buttonText.sendResetLink')}
      </Button>
      <div className='font-15 font-bold text-center mt-2'>
        {localise('auth.rememberedPassword')}
        <Link to={`${AUTH_URL}${SIGN_IN_URL}`} className='ms-1'>
          {localise('buttonText.signIn')}
        </Link>
      </div>
    </Form>
  )
}

export default Reset
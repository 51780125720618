import React, {useState} from 'react'
import {localise, localiseWithHTML} from '../../services/LocalizationServices'
import Form from '../../components/forms/Form'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {Button} from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import {toast} from 'react-hot-toast'
import {changePassword} from '../../api/user/user'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import SideNavView from '../common/SideNavView'
import useNav from '../../hooks/useNav'

const Password = () => {
  const {SIDE_NAV_ACCOUNT} = useNav()
  const {validatePassword} = useAuth()
  const [validated, setValidated] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const resetForm = () => {
    setCurrentPassword('')
    setNewPassword('')
    setConfirmPassword('')
    setValidated(false)
  }

  const FORM_DATA = [
    {
      type: 'password',
      required: true,
      label: localise('form.label.currentPassword'),
      placeholder: localise('form.placeholder.enterCurrentPassword'),
      validationMessage: localise('form.validation.currentPassword'),
      value: currentPassword,
      handler: setCurrentPassword,
      isInvalid: currentPassword && !validatePassword(currentPassword),
    },
    {
      type: 'password',
      required: true,
      label: localise('form.label.newPassword'),
      placeholder: localise('form.placeholder.enterNewPassword'),
      validationMessage: localise('form.validation.newPassword'),
      value: newPassword,
      handler: setNewPassword,
      isInvalid: newPassword && !validatePassword(newPassword),
    },
    {
      type: 'password',
      required: true,
      label: localise('form.label.confirmNewPassword'),
      placeholder: localise('form.placeholder.reenterNewPassword'),
      validationMessage: localise('form.validation.confirmPasswordMatch'),
      value: confirmPassword,
      handler: setConfirmPassword,
      isInvalid: confirmPassword && !validatePassword(confirmPassword),
    },
  ]

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (newPassword === confirmPassword) {
      if (!form.checkValidity()) {
        e.stopPropagation()
      } else if (!validatePassword(newPassword)) {
        toast.error(localiseWithHTML('toast.passwordValidationFailed'))
      } else {
        toggleLoading()
        changePassword({
          current_password: currentPassword,
          new_password: newPassword,
        })
          .then(() => {
            setValidated(false)
            resetForm()
            toast.success(localise('toast.passwordUpdated'))
          })
          .finally(() => {
            toggleLoading()
          })
        return
      }
    } else {
      toast.error(localise('toast.passwordsDoNotMatch'))
    }
    setValidated(true)
  }

  return (
    <SideNavView navList={SIDE_NAV_ACCOUNT}>
      <div className='pb-3'>
        <div className='font-bold font-20 mb-2'>{localise('nav.myPassword')}</div>
        <Form validated={validated} onSubmit={handleSubmit}>
          {buildForm(FORM_DATA)}
          <div className='font-grey font-15 my-2'>{localiseWithHTML('auth.passwordCriteria')}</div>
          <Button variant='primary' type='submit' className='w-100 mt-1 font-15'>
            {localise('buttonText.update')}
          </Button>
        </Form>
      </div>
    </SideNavView>
  )
}

export default Password

import React from 'react'
import {array, bool, func} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import ConfirmModal from './ConfirmModal'
import {v4} from 'uuid'
import Separator from '../separator/Separator'

const propTypes = {
  onCancel: func,
  onConfirm: func,
  show: bool,
  warnings: array,
}

const defaultProps = {
  onCancel: null,
  onConfirm: null,
  show: false,
  warnings: [],
}

const WarningModal = ({onCancel, onConfirm, show, warnings}) =>
  <ConfirmModal
    show={show}
    onCancel={onCancel}
    onConfirm={onConfirm}
    headerText={localise('modalText.warning')}
    bodyElement={(
      <div className='font-15'>
        {
          warnings?.map((warning, i) => {
            return (
              <div key={v4()}>
                <div>{warning}</div>
                {i < warnings.length - 1 && <Separator className='my-1' />}
              </div>
            )
          })
        }
      </div>
    )}
  />


WarningModal.propTypes = propTypes
WarningModal.defaultProps = defaultProps

export default WarningModal

import React from 'react'
import {array, arrayOf, bool, exact, func, number, oneOfType, string} from 'prop-types'
import Card from './Card'
import Separator from '../separator/Separator'
import {v4} from 'uuid'
import HeadingParagraph from '../headings/HeadingParagraph'
import IconButton from '../buttons/IconButton'
import MMCCategories from '../headings/MMCCategories'
import {localise} from '../../services/LocalizationServices'
import Checkbox from '../checkbox/Checkbox'

const propTypes = {
  addressCountry: string,
  addressCountyState: string,
  addressLine1: string,
  addressPostcode: string,
  addressTownCity: string,
  badgeCompletedColor: string,
  buildingType: string,
  categoryItems: arrayOf(exact({
    added_to_supplier: bool,
    name: string,
  })),
  certificates: array,
  className: string,
  clickable: bool,
  compactMode: bool,
  id: oneOfType(([string, number])),
  isFavorite: bool,
  isSelected: bool,
  isSelectionMode: bool,
  name: string,
  onClick: func,
  onFavoriteChange: func,
  onInputChange: func,
  profileImg: string,
  showFavorite: bool,
  subheading: string,
}

const defaultProps = {
  addressCountry: '',
  addressCountyState: '',
  addressLine1: '',
  addressPostcode: '',
  addressTownCity: '',
  badgeCompletedColor: null,
  buildingType: '',
  categoryItems: [],
  certificates: null,
  className: '',
  clickable: true,
  compactMode: false,
  id: null,
  isFavorite: false,
  isSelected: false,
  isSelectionMode: false,
  name: '',
  onClick: () => {},
  onFavoriteChange: null,
  onInputChange: null,
  profileImg: '',
  showFavorite: true,
  subheading: '',
}

const SupplierCard = props => {
  const {
    addressCountry,
    addressCountyState,
    addressLine1,
    addressPostcode,
    addressTownCity,
    badgeCompletedColor,
    categoryItems,
    certificates,
    className,
    clickable,
    compactMode,
    heading,
    id,
    isFavorite,
    isSelected,
    isSelectionMode,
    onClick,
    onFavoriteChange,
    onInputChange,
    profileImg,
    showFavorite,
    subheading,
  } = props

  return (
    <Card clickable={clickable} className={className} onClick={onClick}>
      <div className={`d-flex ${compactMode ? 'pb-0' : 'ms-3 mt-3 pb-3'}`}>
        {
          profileImg &&
          <img
            alt={localise('alt.placeholder')}
            className='object-fit-cover'
            src={profileImg}
            width={58}
            height={58}
          />
        }
        <div className='mx-2 d-flex w-100'>
          <HeadingParagraph
            className='flex-grow-1'
            heading={heading}
            paragraph={subheading}
            headingClasses='font-bold font-20'
            paragraphClasses='font-regular font-12'
          />
          {
            !isSelectionMode && showFavorite &&
            <IconButton
              className={`ms-2 mb-auto ${compactMode ? 'my-auto' : ''}`}
              active={isFavorite}
              onClick={() => onFavoriteChange(isFavorite)}
            />
          }
          {
            isSelectionMode &&
            <Checkbox
              checked={isSelected}
              className='ms-2 my-auto'
              id={id}
              onChange={({target: {id}}) => onInputChange(id)}
            />
          }
        </div>
      </div>
      {
        <div>
          {
            (addressLine1 || addressTownCity || addressCountyState || addressCountry || addressPostcode) &&
            <>
              <Separator/>
              <div className='ms-3 my-2 font-light font-13'>
                <div>{addressLine1}</div>
                <div>{addressTownCity}, {addressCountyState}</div>
                <div>{addressCountry}, {addressPostcode}</div>
              </div>
            </>
          }
          <Separator/>
          {
            categoryItems?.length > 0 &&
            <MMCCategories
              badgeCompletedColor={badgeCompletedColor}
              categoryItems={categoryItems.map(({added_to_supplier: completed, ...rest}) => ({completed, ...rest}))}
              className='ms-3 mt-2'
            />
          }
          {
            certificates && categoryItems?.length > 0 &&
            <Separator className='pt-1 mb-2'/>
          }
          {
            certificates?.length > 0 &&
            <div className='ms-3 my-1'>
              {
                certificates?.map(({images}) => (
                  <img
                    alt='cert'
                    className='object-fit-cover me-2'
                    height={40}
                    key={v4()}
                    src={images?.small_url}
                    width={79}
                  />
                ))
              }
            </div>
          }
        </div>
      }
    </Card>
  )
}

SupplierCard.propTypes = propTypes
SupplierCard.defaultProps = defaultProps

export default SupplierCard

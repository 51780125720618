import React from 'react'
import {bool, func, number, oneOf, string} from 'prop-types'
import {Image} from 'react-bootstrap'
import Console from '../../utilities/ConsoleUtil'
import {
  MAP_PIN_CONSTRUCTION_WORKER,
  MAP_PIN_DOORWAY,
  MAP_PIN_EMPTY,
  MAP_PIN_LIGHTNING_COG,
  MAP_PIN_SELECTED,
  MAP_PIN_SHOVEL,
  MAP_PIN_STRIPED_BUILDING,
  MAP_PIN_SUPPLIER,
} from '../../constants/svgs'
import {localise} from '../../services/LocalizationServices'
import styled from 'styled-components'
import {FIT_OUT, MEP, PRELIMINARIES, SUBSTRUCTURE, SUPERSTRUCTURE} from '../../constants/suppliers'
import {PROJECT} from '../../constants/project'

const propTypes = {
  className: string,
  icon: string,
  lat: number,
  lng: number,
  onClick: func,
  percent: number,
  selected: bool,
  type: oneOf([
    FIT_OUT,
    MEP,
    PRELIMINARIES,
    PROJECT,
    SUBSTRUCTURE,
    SUPERSTRUCTURE,
  ]),
}

const defaultProps = {
  className: '',
  icon: null,
  lat: 52.79492245641029,
  lng: -6.082953681808775,
  onClick: () => Console.dev('onClick'),
  percent: 0,
  selected: false,
}

const MARKER_TYPES = {
  fit_out: MAP_PIN_DOORWAY,
  mechanical_electrical_plumbing: MAP_PIN_LIGHTNING_COG,
  preliminaries: MAP_PIN_CONSTRUCTION_WORKER,
  substructure: MAP_PIN_SHOVEL,
  superstructure: MAP_PIN_STRIPED_BUILDING,
}

const PercentDivWrapper = styled.div`
  width: 48px;
  height: 48px;
  padding-top: 3px;
  transform: translate(-50%, -90%);
`

const ImgTransformWrapper = styled(Image)`
  transform: translate(-50%, -90%);
`

const MapMarker = ({className, icon, onClick, lat, lng, percent, selected, type, ...rest}) => {
  const handleMarkerType = () => {
    if (type === PROJECT) {
      return (
        <div className='position-relative cursor-pointer' onClick={() => onClick({lat, lng})}>
          <ImgTransformWrapper
            alt='map marker'
            className={`position-absolute text-center ${className}`}
            src={selected ? MAP_PIN_SELECTED : MAP_PIN_EMPTY}
            {...rest}
          />
          {
            !selected && !!percent &&
            <PercentDivWrapper className='position-absolute font-white text-center'>
              <div className='font-12 mt-1'>{percent}</div>
              <div className='font-10'>{localise('symbols.percent')}</div>
            </PercentDivWrapper>
          }
        </div>
      )
    }
    return (
      <ImgTransformWrapper
        alt='map marker'
        className={`cursor-pointer rounded-circle ${className}`}
        onClick={() => onClick({lat, lng})}
        src={selected ? MAP_PIN_SELECTED : icon || MARKER_TYPES[type] || MAP_PIN_SUPPLIER}
        width={48}
        height={48}
        {...rest}
      />
    )
  }

  return handleMarkerType()
}

MapMarker.propTypes = propTypes
MapMarker.defaultProps = defaultProps

export default MapMarker

import React, {useEffect, useState} from 'react'
import {func, string} from 'prop-types'
import SupplierCard from './SupplierCard'
import {localise} from '../../services/LocalizationServices'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {useDispatch} from 'react-redux'
import {setSelectedSuppliers} from '../../store/slices/selectedSuppliersSlice'

const propTypes = {
  className: string,
  element: string,
  onChange: func,
}

const defaultProps = {
  className: '',
  element: '',
  onChange: () => {},
}

const StagedSupplierCard = ({className, element, onChange}) => {
  const dispatch = useDispatch()
  const [stagedSupplier, setStagedSupplier] = useState({})
  const [stagedSupplierVisible, setStagedSupplierVisible] = useState(!!stagedSupplier.name)

  useEffect(() => {
    if (!stagedSupplierVisible) setStagedSupplier({})
  }, [stagedSupplierVisible])

  useEffect(() => {
    onChange(stagedSupplier?.name)
  }, [onChange, stagedSupplier])

  return (
    <>
      <SupplierCard
        className={className}
        compactMode
        heading={localise('headings.otherSupplier')}
        isSelected={stagedSupplierVisible}
        isSelectionMode
        onInputChange={() => {
          dispatch(setSelectedSuppliers({dirty: true}))
          setStagedSupplierVisible(!stagedSupplierVisible)
        }}
        subheading={localise('headings.supplierNotListedAbove')}
      />
      {
        stagedSupplierVisible &&
        <>
          <div className='font-17 font-bold mt-3'>{localise('form.label.unlistedSupplier')}</div>
          {
            buildForm([
              {
                type: 'text',
                handler: name => setStagedSupplier({element, name}),
                label: localise('nav.supplier'),
                maxLength: 100,
                placeholder: localise('form.placeholder.enterSupplier'),
                value: stagedSupplier?.name || '',
              },
            ])
          }
        </>
      }
    </>
  )
}
StagedSupplierCard.propTypes = propTypes
StagedSupplierCard.defaultProps = defaultProps

export default StagedSupplierCard

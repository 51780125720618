import React, {useState} from 'react'
import {array, arrayOf, bool, exact, number, string} from 'prop-types'
import {
  CHART_PRIMARY,
  CHART_UNSELECTED,
  HAMBURGER_PRIMARY,
  HAMBURGER_UNSELECTED,
  PIE_PRIMARY,
  PIE_UNSELECTED,
} from '../../constants/svgs'
import TabMenu from '../navs/TabMenu'
import PieChart from '../charts/PieChart'
import Card from '../cards/Card'
import BarChart from '../charts/BarChart'
import OverallCostsTable from '../tables/OverallCostsTable'
import {useSelector} from 'react-redux'
import {getCurrentUserCurrencySymbol} from '../../store/selectors/currentUserSelectors'
import {getUserPreferenceCurrencySymbol} from '../../store/selectors/userPreferenceSelectors'
import {externalTooltipHandler} from '../charts/chart-js/tooltip'
import useChart from '../../hooks/useChart'
import ChartPlaceholder from '../placeholders/ChartPlaceholder'

export const CONTENT = {
  BAR: 'bar',
  PIE: 'pie',
  TABLE: 'table',
}

const propTypes = {
  active: string,
  categoryItems: arrayOf(exact({
    completed: bool, name: number,
  })),
  className: string,
  colors: arrayOf(string),
  data: array,
  firstCellWidth: string,
  material: string,
  materialPercent: number,
  max: number,
  minText: string,
  onSiteLabour: string,
  onSiteLabourPercent: number,
  overallValue: string,
  preliminaries: string,
  preliminariesPercent: number,
  title: string,
}

const defaultProps = {
  active: CONTENT.PIE,
  categoryItems: [],
  className: '',
  colors: [],
  data: [],
  firstCellWidth: null,
  material: '',
  materialPercent: null,
  max: 100,
  minText: '0',
  onSiteLabour: '',
  onSiteLabourPercent: null,
  overallValue: '',
  preliminaries: '',
  preliminariesPercent: null,
  title: '',
}

const OverallCostsWrapper = props => {
  const {
    active: activeProp,
    categoryItems,
    className,
    colors,
    data,
    firstCellWidth,
    material,
    materialPercent,
    max,
    onSiteLabour,
    onSiteLabourPercent,
    overallValue,
    preliminaries,
    preliminariesPercent,
    title,
  } = props

  const [active, setActive] = useState(activeProp)

  const {isChartDataArrayEmpty} = useChart()
  const userCurrencySymbol = useSelector(getCurrentUserCurrencySymbol || getUserPreferenceCurrencySymbol)

  const createChartWrapper = content =>
    <Card hideBoxShadow>
      {
        content === CONTENT.PIE &&
          <div>
            {
              isChartDataArrayEmpty(data) ? <ChartPlaceholder className='border-0'/> :
                <div className='d-flex justify-content-center'>
                  <PieChart
                    donut
                    colors={colors}
                    data={data}
                    legendPosition='right'
                    libraryProps={{
                      plugins: {
                        tooltip: {
                          enabled: false,
                          external: context => externalTooltipHandler(context, {unitSymbol: userCurrencySymbol}),
                          position: 'nearest',
                        },
                      }}}
                    width={400}
                  />
                </div>
            }
          </div>

      }
      {
        content === CONTENT.BAR &&
        <BarChart
          colors={colors}
          data={data}
          libraryProps={{
            plugins: {
              tooltip: {
                enabled: false,
                external: context => externalTooltipHandler(context, {unitSymbol: userCurrencySymbol}),
                position: 'nearest',
              },
            },
          }}
          max={max}
        />
      }
    </Card>

  return (
    <div className={`${className} tab-nav-container-icon tab-menu-charts`}>
      <TabMenu
        className='float-end'
        defaultActiveKey={active || CONTENT.PIE}
        onSelect={setActive}
        title={title}
        data={[
          {
            content: createChartWrapper(CONTENT.PIE),
            eventKey: CONTENT.PIE,
            title: <img src={active === CONTENT.PIE ? PIE_PRIMARY : PIE_UNSELECTED} alt='pie chart'/>,
          },
          {
            content: createChartWrapper(CONTENT.BAR),
            eventKey: CONTENT.BAR,
            title: <img src={active === CONTENT.BAR ? CHART_PRIMARY : CHART_UNSELECTED} alt='bar chart'/>,
          },
          {
            eventKey: CONTENT.TABLE,
            title: <img src={active === CONTENT.TABLE ? HAMBURGER_PRIMARY : HAMBURGER_UNSELECTED} alt='hamburger'/>,
            content: (
              <OverallCostsTable
                categoryItems={categoryItems}
                firstCellWidth={firstCellWidth}
                material={material}
                materialPercent={materialPercent}
                onSiteLabour={onSiteLabour}
                onSiteLabourPercent={onSiteLabourPercent}
                overallValue={overallValue}
                preliminaries={preliminaries}
                preliminariesPercent={preliminariesPercent}
              />
            ),
          },
        ]}
      />
    </div>
  )
}

OverallCostsWrapper.propTypes = propTypes
OverallCostsWrapper.defaultProps = defaultProps

export default OverallCostsWrapper

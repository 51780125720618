import React from 'react'
import {func, node, string} from 'prop-types'
import {localise} from '../../../services/LocalizationServices'
import {Button, Row} from 'react-bootstrap'
import Card from '../Card'
import Separator from '../../separator/Separator'

const propTypes = {
  className: string,
  children: node,
  date: string,
  onViewLogClick: func,
  title: string,
}

const defaultProps = {
  className: 'mt-2',
  children: null,
  date: null,
  onViewLogClick: null,
  title: '',
}

const LogEntryCard = ({children, className, date, onViewLogClick, title}) => {
  return (
    <div className={className}>
      <div className='d-flex justify-content-between'>
        <div className='my-auto font-15 font-grey'>{title}</div>
        {
          onViewLogClick &&
          <Button variant='outline' className='ms-auto font-12 py-1 mb-2' onClick={onViewLogClick}>
            {localise('buttonText.viewLog')}
          </Button>
        }
      </div>
      <Card hideBoxShadow>
        <div className='font-13 ps-3 py-1'>
          {date || localise('headings.latestEntry')}
          <Separator />
        </div>
        <Row className='ps-3 py-1'>{children}</Row>
      </Card>
    </div>
  )
}

LogEntryCard.propTypes = propTypes
LogEntryCard.defaultProps = defaultProps

export default LogEntryCard

import React, {useEffect, useMemo, useState} from 'react'
import Table from './Table'
import {localise} from '../../services/LocalizationServices'
import Slider from '../inputs/Slider'
import {array, arrayOf, func, node, number, object, oneOfType, shape, string} from 'prop-types'
import ConfirmModal from '../modals/ConfirmModal'

const propTypes = {
  className: string,
  currentValPrefix: string,
  data: arrayOf(shape({
    label: string,
    name: string,
    onChange: func,
  })).isRequired,
  headings: oneOfType([string, node, array]),
  maxPercent: oneOfType([string, number]),
  maxTotal: oneOfType([string, number]),
  onChange: func,
  validations: object,
}

const defaultProps = {
  className: '',
  currentValPrefix: '',
  data: [{
    label: '',
    name: '',
    onChange: () => {},
  }],
  headings: null,
  maxPercent: 100,
  maxTotal: null,
  onChange: null,
  validations: null,
}

const SliderDistributionTable = props => {
  const {
    className,
    currentValPrefix,
    data,
    headings,
    maxPercent,
    maxTotal,
    onChange,
    validations,
  } = props

  const [autoSlug, setAutoSlug] = useState(null)
  const [currentSlug, setCurrentSlug] = useState(null)
  const [error, setError] = useState('')
  const [potentialValue, setPotentialValue] = useState(0)
  const [show, setShow] = useState(false)
  const [sum, setSum] = useState(0)
  const [values, setValues] = useState({})

  const sumValues = values => {
    if (Object.values(values)?.length > 0) {
      setSum(Object.values(values).reduce((previous, current) => previous + current?.value, 0))
    }
  }

  useMemo(() => {
    const initialValues = values || {}
    data.forEach(({auto = false, label, name, value}) => {
      const slug = (!name && label) ? label.toLowerCase().replaceAll(' ', '-') : name
      if (auto) setAutoSlug(slug)
      initialValues[slug] = {
        auto,
        value: value !== null && value !== undefined
          ? value
          : initialValues[slug]?.value || 0,
      }
    })
    setValues(initialValues)
    sumValues(initialValues)
    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    sumValues(values)
  },[values])

  const parseSliderNumber = number => Number(number)?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  const tableHeadings = headings ||
    [{
      text: `<div>${localise('headings.area')}</div>
             <div>
               <span>${localise('tables.value')}</span> 
               <span style='width:1px; height:18px' class='bg-black ms-1 d-inline-block position-relative top-4'></span>
               <span class='ps-3'>%</span>
             </div>
            `,
      attributes: {
        className: 'd-flex justify-content-between px-2',
      },
    }]

  const doChange = (slug, val) => {
    const existingValue = values[slug]?.value || 0
    const existingAutoValue = values[autoSlug]?.value || 0
    const everythingElse = Math.abs(sum - existingValue - existingAutoValue)
    const current = values
    current[slug].value = val
    if (autoSlug) current[autoSlug].value = maxPercent - everythingElse - val

    if (
      ((sum - existingAutoValue - existingValue) + val > maxPercent) &&
      val > existingValue
    ) {
      const fallbackValue = maxPercent - everythingElse
      const autoFallbackValue = maxPercent - everythingElse - current[slug].value
      current[slug].value = fallbackValue < 0 ? 0 : fallbackValue
      if (autoSlug) current[autoSlug].value = autoFallbackValue < 0 ? 0 : fallbackValue
    }

    setValues({...current})
    if (onChange) onChange(current)
  }

  const buildRows = () => (
    data?.map(({label, name, auto, ...rest}) => {
      const slug = (!name && label) ? label.toLowerCase().replaceAll(' ', '-') : name

      const handleChange = requestedValue => {
        if (validations && validations[slug]?.threshold && (requestedValue > validations[slug]?.threshold)) {
          setError(validations[slug]?.errMsg)
          setCurrentSlug(slug)
          setPotentialValue(requestedValue)
          setShow(true)
        } else {
          doChange(slug, requestedValue)
        }
      }

      const valueLabel = parseSliderNumber(maxTotal * (values[slug]?.value / 100))

      return (
        {
          row: [{
            cell:
              <Slider
                currentValPrefix={currentValPrefix}
                disabled={!maxTotal || auto}
                hideValueLabel={maxTotal === 'NaN' || !maxTotal}
                max={maxPercent}
                onChange={handleChange}
                sliderLabel={label}
                value={values[slug]?.value}
                valueLabel={valueLabel === 'NaN' ? 0 : valueLabel}
                {...rest}
              />,
          }],
        })
    })
  )
  const totalValueLabel = () => {
    const calculatedTotal = Object.values(values).reduce((previous, current) =>
      previous + (maxTotal * (current?.value / 100)), 0)

    return parseSliderNumber(calculatedTotal || 0)
  }

  const bottomRow = [{
    row: [{
      attributes: {
        className: 'font-bold d-flex justify-content-between',
      },
      cell: `
            <div>${localise('tables.total')}</div> 
            <div>
               <span> 
                ${currentValPrefix}${totalValueLabel() === 'NaN' ? 0 : totalValueLabel()}
               </span>
               <span style='width:1px; height:18px' class='bg-black ms-1 d-inline-block position-relative top-4'></span>
               <span class='ps-2'>
                ${
                  Object.values(values).length > 0
                    ? Object.values(values).reduce((previous, current) => previous + current?.value, 0)
                    : 0
                }%
               </span>
            </div>`,
    }],
  }]

  const tableBody = [...buildRows(), ...bottomRow]

  return (
    <>
      <Table className={className} data={{tableHeadings, tableBody}} />
      <ConfirmModal
        bodyText={error}
        headerText={localise('modalText.warning')}
        show={show}
        onCancel={() => setShow(false)}
        onConfirm={() => {
          setShow(false)
          doChange(currentSlug, potentialValue)
        }}
      />
    </>
  )
}

SliderDistributionTable.propTypes = propTypes
SliderDistributionTable.defaultProps = defaultProps

export default SliderDistributionTable

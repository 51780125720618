import React, {useEffect, useState} from 'react'
import FiltersBarView from '../common/FiltersBarView'
import SupplierCard from '../../components/cards/SupplierCard'
import Map from '../../components/map/Map'
import ProjectCard from '../../components/cards/ProjectCard'
import {fetchSuppliers} from '../../api/supplier/supplier'
import {fetchProjects} from '../../api/project/project'
import {MAP_PIN_STAR, PLACEHOLDER_PORTRAIT} from '../../constants/svgs'
import {fetchElements} from '../../api/elements/elements'
import {PROJECT} from '../../constants/project'

const MapView = () => {
  const [boundingBox, setBoundingBox] = useState({left: null, top: null, right: null, bottom: null})
  const [element, setElement] = useState(null)
  const [options, setOptions] = useState([{value: null, label: null}])
  const [projectMarkers, setProjectMarkers] = useState([])
  const [query, setQuery] = useState('')
  const [supplierMarkers, setSupplierMarkers] = useState([])

  useEffect(() => {
    fetchElements().then(elems => setOptions(elems?.map(({name: label, slug: value}) => ({label, value}))))
  }, [])

  useEffect(() => {
    fetchSuppliers({boundingBox, element, query})
      .then(({suppliers}) => {
        setSupplierMarkers(
          suppliers?.map(({address, branch, elements, logos, mmc_categories, name, supplier_accreditations}) => (
            {
              icon: elements?.length >= 2 ? MAP_PIN_STAR : null,
              lat: address?.latitude,
              lng: address?.longitude,
              type: elements?.length === 1 ? elements[0]?.slug : null,
              overlay: (
                <SupplierCard
                  addressCountry={address?.country}
                  addressCountyState={address?.state}
                  addressLine1={address?.line1}
                  addressLine2={address?.line2}
                  addressPostcode={address?.postal_code}
                  addressTownCity={address?.city}
                  categoryItems={mmc_categories}
                  certificates={supplier_accreditations}
                  clickable={false}
                  compactMode
                  heading={name}
                  isSelectionMode={false}
                  profileImg={logos?.small_url || PLACEHOLDER_PORTRAIT}
                  showFavorite={false}
                  subheading={branch}
                />
              ),
            }
          )))
      })

  }, [boundingBox, element, query])

  useEffect(() => {
    fetchProjects({boundingBox, query})
      .then(({projects}) => {
        setProjectMarkers(projects?.map(({address, building_count, created_at, mmc_categories, name, rating}) => ({
          lat: address?.latitude,
          lng: address?.longitude,
          percent: rating,
          type: PROJECT,
          overlay: (
            <ProjectCard
              address={address}
              categoryItems={mmc_categories}
              clickable={false}
              createdAt={created_at}
              isAbleToFavorite={false}
              isSelected={false}
              numberOfBuildings={building_count}
              pmvRating={rating}
              title={name}
            />
          ),
        })))
      })}, [boundingBox, query])

  return (
    <FiltersBarView
      childrenClasses='px-0'
      dropdownOptions={{onChange: ({value}) => setElement(value), options}}
      onSearch={setQuery}>
      <Map
        markers={[...supplierMarkers, ...projectMarkers]}
        onChange={({marginBounds}) => {
          const {ne, sw} = marginBounds

          setBoundingBox({
            bottom: sw?.lat,
            left: sw?.lng,
            right: ne?.lng,
            top: ne?.lat,
          })
        }}
      />
    </FiltersBarView>
  )
}

export default MapView

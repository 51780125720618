import React, {useState} from 'react'
import {string} from 'prop-types'
import {Button} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {logout} from '../../api/authentication/auth'
import {useHistory} from 'react-router'
import {AUTH_URL} from '../../constants/urls'
import {clearStore} from '../../store/store'
import Modal from '../modals/Modal'
import Console from '../../utilities/ConsoleUtil'
import {fetchConfiguration} from '../../api/configuration/configuration'
import {setConfiguration} from '../../store/slices/configurationSlice'
import {useDispatch} from 'react-redux'

const propTypes = {className: string}
const defaultProps = {className: ''}

const LogoutButton = ({className}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [show, setShow] = useState(false)

  const handleLogout = async () => {
    try {
      setShow(false)
      toggleLoading()
      await logout()
      clearStore()
      history.push(AUTH_URL)
      const configuration = await fetchConfiguration()
      if (configuration) dispatch(setConfiguration(configuration))
    }
    catch (e) {
      toggleLoading()
      Console.dev(e, 'error')
    }
  }

  const hide = () => setShow(false)

  return (
    <>
      <div
        className={`font-15 cursor-pointer ${className}`}
        onClick={() => setShow(true)}>
        {localise('buttonText.logout')}
      </div>
      <Modal
        show={show}
        toggle={hide}
        headerText={localise('modalText.logOut')}
        bodyElement={localise('modalText.logOutMessage')}
        bodyClasses='py-3'
        footerClasses='pt-3'
        footerElement={<>
          <Button variant='outline' className='font-15 me-2' onClick={hide}>
            {localise('buttonText.cancel')}
          </Button>
          <Button variant='primary' className='font-15' onClick={handleLogout}>
            {localise('buttonText.confirm')}
          </Button>
        </>}
      />
    </>
  )
}

LogoutButton.propTypes = propTypes
LogoutButton.defaultProps = defaultProps

export default LogoutButton

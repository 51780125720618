import React from 'react'
import {func} from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import ConfirmForm from '../../../../components/forms/ConfirmForm'
import {localise} from '../../../../services/LocalizationServices'
import {getBuildingProgrammeAreas} from '../../../../store/selectors/buildingProgrammeSelectors'
import {setBuildingProgramme} from '../../../../store/slices/buildingProgrammeSlice'
import BreakdownTable from '../../../../components/tables/BreakdownTable'

const propTypes = {
  onSubmit: func,
}

const defaultProps = {
  onSubmit: () => {},
}

const BuildingProgrammeForm = ({onSubmit}) => {
  const dispatch = useDispatch()

  const areas = useSelector(getBuildingProgrammeAreas) || []

  const handleChange = data => {
    dispatch(setBuildingProgramme({
      building_programme_breakdown_areas: areas?.map(({building_programme_breakdown_area_type}) => {
        const areaType = data?.data?.find(({name}) => building_programme_breakdown_area_type?.name === name)
        const id = areaType?.id
        const name = areaType?.name
        const slug = areaType?.slug
        const sortOrder = areaType?.sort_order
        const weeks = areaType?.value

        return {
          id,
          name,
          weeks,
          building_programme_breakdown_area_type: {id, name, slug, sort_order: sortOrder},
        }
      }),
    }))
  }

  const fields = [
    {
      type: 'custom',
      render: (
        <BreakdownTable
          cellData={areas?.map(({building_programme_breakdown_area_type: {id, slug, sort_order}, name, weeks}) => (
            {id, slug, name, value: weeks, sort_order}
          ))}
          headings={[localise('headings.area'), localise('tables.weeks')]}
          isPercent
          onChange={handleChange}
          showTotal={false}
        />
      ),
    },
  ]

  const submitFormData = () => {
    onSubmit({
      building_programme_breakdown: {
        building_programme_breakdown_areas: areas.map(({building_programme_breakdown_area_type: {id}, weeks}) => ({
          building_programme_breakdown_area_type: id,
          weeks,
        })),
      },
    })
  }

  return <ConfirmForm fields={fields} onSubmit={submitFormData} submittable />
}

BuildingProgrammeForm.propTypes = propTypes
BuildingProgrammeForm.defaultProps = defaultProps

export default BuildingProgrammeForm

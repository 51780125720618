import {createSlice} from '@reduxjs/toolkit'

const selectedSuppliersSlice = createSlice({
  name: 'selectedSuppliers',
  initialState: {
    dirty: false,
    suppliers: [],
  },
  reducers: {
    setSelectedSuppliers: (state, action) => {
      if (typeof action?.payload?.dirty === 'boolean') {
        state.dirty = action.payload.dirty
        delete action.payload.dirty
      }

      if (Object.keys(action?.payload)?.length > 0) {
        state.suppliers = [...state?.suppliers, action?.payload]
      }
    },
    clearSelectedSuppliers: state => {
      state.suppliers = []
    },
  },
})

export const selectedSuppliersReducer = selectedSuppliersSlice.reducer
export const {clearSelectedSuppliers, setSelectedSuppliers} = selectedSuppliersSlice.actions

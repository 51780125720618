import React from 'react'
import {func} from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {generatePreliminariesCostBreakdown} from '../../../../api/generate-breakdowns/generateBreakdowns'
import {
  getBuildingPreliminariesCostAreas,
  getBuildingPreliminariesCostTotal,
  getBuildingPreliminariesCostTotalDisplay,
  getInitialBuildingPreliminariesCostAreas,
  getInitialBuildingPreliminariesCostTotal,
} from '../../../../store/selectors/buildingPreliminariesCostSelectors'
import {setBuildingPreliminariesCost} from '../../../../store/slices/buildingPreliminariesCostSlice'
import ConfirmForm from '../../../../components/forms/ConfirmForm'
import {localise} from '../../../../services/LocalizationServices'
import GenerateBreakdownButton from '../../../../components/buttons/GenerateBreakdownButton'
import SliderDistributionTable from '../../../../components/tables/SliderDistributionTable'
import Separator from '../../../../components/separator/Separator'
import {getCurrentUserCurrencySymbol} from '../../../../store/selectors/currentUserSelectors'
import {getBuildingId} from '../../../../store/selectors/buildingSelectors'

const propTypes = {
  onSubmit: func,
}

const defaultProps = {
  onSubmit: () => {},
}

const OTHER_WORKS_SLUG = 'other_works'

const PreliminariesCostForm = ({onSubmit}) => {
  const dispatch = useDispatch()

  const areas = useSelector(getBuildingPreliminariesCostAreas) || []
  const buildingId = useSelector(getBuildingId)
  const preliminariesCostTotal = useSelector(getBuildingPreliminariesCostTotal)
  const preliminariesCostTotalDisplay = useSelector(getBuildingPreliminariesCostTotalDisplay)
  const initialPreliminariesCostTotal = useSelector(getInitialBuildingPreliminariesCostTotal)
  const initialAreas = useSelector(getInitialBuildingPreliminariesCostAreas)

  const setPreliminariesCostBreakdownRedux = payload => dispatch(setBuildingPreliminariesCost(payload))

  const generateBreakdown = () => {
    toggleLoading()
    generatePreliminariesCostBreakdown(preliminariesCostTotal * 100, buildingId)
      .then(setPreliminariesCostBreakdownRedux)
      .finally(toggleLoading)
  }

  const handleChange = values => {
    setPreliminariesCostBreakdownRedux({
      building_preliminaries_cost_breakdown_areas: areas.map(area => ({
        ...area,
        percentage: values[area?.building_preliminaries_cost_breakdown_area_type?.slug]?.value,
      })),
    })
  }

  const renderAreas = () => areas.map(area => (
    {
      label: area?.building_preliminaries_cost_breakdown_area_type?.name,
      name: area?.building_preliminaries_cost_breakdown_area_type?.slug,
      auto: area?.building_preliminaries_cost_breakdown_area_type?.slug === OTHER_WORKS_SLUG,
      value: area?.percentage,
    }
  ))

  const fields = [
    {
      type: 'custom',
      render: (
        <>
          <div className='font-13 font-bold'>{localise('headings.preliminaryCosts')}</div>
          <div className='font-13 font-grey'>{localise('headings.calculatedFromBuildingGrossCost')}</div>
          <div className='font-17 font-bold'>{preliminariesCostTotalDisplay}</div>
          <Separator className='mt-2' />
        </>
      ),
    },
    {
      type: 'custom',
      className: 'my-2',
      render: (
        <GenerateBreakdownButton
          message={localise('helpText.generatePreliminariesCostBreakdownMessage')}
          onClick={generateBreakdown}
        />
      ),
    },
    {
      type: 'custom',
      render: (
        <SliderDistributionTable
          currentValPrefix={useSelector(getCurrentUserCurrencySymbol)}
          data={renderAreas()}
          maxTotal={parseFloat(preliminariesCostTotal).toFixed(2)}
          onChange={handleChange}
        />
      ),
    },
  ]

  const handleSubmit = () => {
    let dirty = false

    if (preliminariesCostTotal !== initialPreliminariesCostTotal) dirty = true

    areas.forEach(area => {
      const newPercentage = area?.percentage
      const initPercentage = initialAreas.filter(initArea => initArea?.name === area?.name)[0]?.percentage

      if (newPercentage !== initPercentage) dirty = true
    })

    onSubmit(
      dirty
        ? {
          building_preliminaries_cost_breakdown: {
            building_preliminaries_cost: {
              total_cents: Math.round(preliminariesCostTotal * 100),
            },
            building_preliminaries_cost_breakdown_areas: areas.map(area => ({
              amount_cents: Math.round(preliminariesCostTotal * (area?.percentage / 100) * 100),
              percentage: area?.percentage,
              building_preliminaries_cost_breakdown_area_type: (
                area?.building_preliminaries_cost_breakdown_area_type?.slug
              ),
            })),
          },
        }
        : null
    )
  }

  const submittable = !!preliminariesCostTotal && areas.reduce((prev, current) => prev + current?.percentage, 0) > 0

  return <ConfirmForm fields={fields} onSubmit={handleSubmit} submittable={submittable} />
}

PreliminariesCostForm.propTypes = propTypes
PreliminariesCostForm.defaultProps = defaultProps

export default PreliminariesCostForm

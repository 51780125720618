export const BLACK = '#000'
export const BRAND_PRIMARY = '#d0103a'
export const BRAND_SECONDARY = '#3f544fFF'
export const BRAND_YELLOW = '#d9c91a'
export const BROWN = '#651c32'
export const DARK_GREY = '#616161'
export const GREY = '#9e9e9e'
export const LIGHTEST_GREEN = '#c8cecdFF'
export const LIGHTEST_GREY = '#dcdcdc'
export const LIGHT_GREEN = '#949f9dFF'
export const LIGHT_GREY = '#e0e0e0'
export const LIGHT_PINK = '#fabbcb'
export const MEDIUM_GREY = '#eeeeee'
export const NAVY = '#253746'
export const PALE_GREEN = '#d3e8bf'
export const PALE_GREY = '#d9e1e2'
export const PALEST_GREY = '#eff3f3'
export const PINK = '#e8879c'
export const SKY_BLUE = '#bae8ef'
export const OFF_WHITE = '#F7F7F7'

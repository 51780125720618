import React from 'react'
import {Form, InputGroup} from 'react-bootstrap'
import Select from 'react-select'
import {v4} from 'uuid'
import Radio from '../../components/radio/Radio'
import Checkbox from '../../components/checkbox/Checkbox'
import Skeleton from 'react-loading-skeleton'
import InfoTooltip from '../../components/tooltip/InfoTooltip'

const DEFAULT_TEXTAREA_ROWS = 5
const STANDARD_SELECT_ID = 'standard-select'

const defaultHandleChange = (e, setStateHandler) => setStateHandler(e?.target?.value)

export const buildForm = (FORM_DATA, handleChange = defaultHandleChange) => FORM_DATA.map((field, index) => {
  const {
    additionalLabelClasses,
    autoFocus,
    className,
    classNamePrefix,
    defaultInputValue,
    defaultValue,
    disabled,
    handler,
    hideValidUI,
    id,
    information,
    isInvalid,
    label,
    loading,
    maxLength,
    name,
    placeholder,
    prepend,
    readOnly,
    render,
    required,
    rows,
    subLabel,
    type,
    validationMessage,
    value,
    ...rest
  } = field

  const handleInputTypes = () => {
    if (type === 'textarea') {
      return (
        <Form.Control
          as='textarea'
          defaultValue={defaultValue}
          isInvalid={isInvalid || false}
          maxLength={maxLength}
          name={name}
          onChange={e => handleChange(e, handler)}
          placeholder={placeholder || label}
          readOnly={readOnly || false}
          required={required || false}
          rows={rows || DEFAULT_TEXTAREA_ROWS}
          type={type}
          value={value}
        />
      )
    }
    else if (type === 'radio') {
      const {label, ...rest} = field
      return <Radio label={label} {...rest} />
    }
    else if (type === 'radio-group') {
      return field?.radios.map(
        ({className, label, ...rest}) =>
          <Radio
            className={`mb-2 ${className}`}
            hideValidUI={field.hideValidUI}
            key={v4()}
            label={label}
            {...rest}
          />
      )
    }
    else if (type === 'checkbox') {
      const {label, ...rest} = field
      return <Checkbox label={label} {...rest} />
    }
    else if (type === 'dropdown') {
      if (loading) {
        return <Skeleton height={38} />
      }
      if (handler) {
        return (
          <Select
            {...field}
            id={id || STANDARD_SELECT_ID}
            classNamePrefix={classNamePrefix || STANDARD_SELECT_ID}
            className={`${readOnly && 'readonly'} ${isInvalid && 'invalid'}`}
            defaultInputValue={defaultInputValue}
            handler={0}
            isClearable={!readOnly}
            isDisabled={disabled}
            isSearchable={!readOnly}
            key={defaultInputValue}
            onChange={handler}
            openMenuOnClick={!readOnly}
          />
        )
      }
      return (
        <Select
          {...field}
          id={id || STANDARD_SELECT_ID}
          classNamePrefix={classNamePrefix || STANDARD_SELECT_ID}
          className={`${readOnly && 'readonly'} ${isInvalid && 'invalid'}`}
          isDisabled={disabled}
          isClearable={!readOnly}
          isSearchable={!readOnly}
          openMenuOnClick={!readOnly}
          key={defaultInputValue}
          defaultInputValue={defaultInputValue}
        />
      )
    } else if (type === 'custom') {
      return render
    }
    else {
      const input = (
        <Form.Control
          autoFocus={autoFocus || false}
          className={`${className || ''} ${hideValidUI && 'prevent-valid-validation-ui'}`}
          defaultValue={defaultValue}
          isInvalid={isInvalid || false}
          key={defaultValue}
          maxLength={maxLength}
          name={name}
          onChange={e => handleChange(e, handler)}
          placeholder={placeholder || label}
          readOnly={readOnly || false}
          required={required || false}
          type={type}
          value={value}
          {...rest}
        />
      )

      return prepend
        ? (
          <InputGroup>
            <InputGroup.Text className='input-group-prepend'>
              {prepend}
            </InputGroup.Text>
            {input}
            <Form.Control.Feedback className='font-12' type='invalid'>
              {validationMessage}
            </Form.Control.Feedback>
          </InputGroup>
        )
        : input
    }
  }

  return (
    <Form.Group
      controlId={v4()}
      key={`${label || name}${index}`}
      className={className || ''}>
      <Form.Label
        hidden={!label || type === 'radio' || type === 'checkbox'}
        className={`text-start w-100 font-13 ${additionalLabelClasses || ''}`}>
        <span className='font-bold'>
          {
            required ? `${label}*` : label
          }
        </span>
        <span hidden={!subLabel} className='font-15 font-light-grey ms-2'>{subLabel}</span>
        {
          information && (
            <InfoTooltip className='ms-1 mb-1' text={information} />
          )
        }
      </Form.Label>
      {handleInputTypes()}
      {
        (type === 'dropdown' || type === 'number' || type === 'image-upload') && isInvalid
          ? <div className='d-block invalid-feedback font-12'>{validationMessage}</div>
          : (
            <Form.Control.Feedback className='font-12' type='invalid'>
              {validationMessage}
            </Form.Control.Feedback>
          )
      }
    </Form.Group>
  )
})

export default buildForm

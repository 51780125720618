import React from 'react'
import {array, object, oneOfType, string} from 'prop-types'
import {Col, Container, Row} from 'react-bootstrap'
import {COMPANY_LOGO_WHITE} from '../../constants/svgs'
import styled from 'styled-components'
import AUTH_BACKGROUND from '../../assets/images/auth-background.png'

const propTypes = {children: oneOfType([string, array, object])}
const defaultProps = {children: null}

const Brand = styled(Col)`
  background-image: url(${AUTH_BACKGROUND});
  background-position: center;
  background-size: cover;
  height: 100%;
  position: -webkit-sticky; /* for older versions of safari */
  position: sticky;
  top: 0;
  z-index: 1;
`

const AuthLayout = ({children}) => {
  return (
    <Container fluid className='auth-layout'>
      <Row>
        <Brand className='pe-md-0 vh-100 vh-md-20' xs='12' md='6'>
          <div className='d-flex align-items-center justify-content-center h-100'>
            <img className='w-60 h-50' src={COMPANY_LOGO_WHITE} alt='company-logo'/>
          </div>
        </Brand>
        <Col className='py-5 py-md-3' xs='12' md='6'>
          <div className='d-flex align-items-center justify-content-center h-100'>
            <div className='w-50 w-md-80'>
              {children}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

AuthLayout.propTypes = propTypes
AuthLayout.defaultProps = defaultProps

export default AuthLayout
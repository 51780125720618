import React from 'react'
import {bool, node, number, oneOf, string} from 'prop-types'
import {ProgressBar as BSProgressBar} from 'react-bootstrap'
import styled from 'styled-components'

const propTypes = {
  animated: bool,
  children: node,
  className: string,
  isChild: bool,
  label: node,
  max: number,
  min: number,
  now: number.isRequired,
  striped: bool,
  variant: oneOf(['success', 'danger', 'warning', 'info', 'brand-primary']),
  visuallyHidden: bool,
}

const defaultProps = {
  animated: false,
  children: null,
  className: '',
  isChild: false,
  label: null,
  max: 100,
  min: 0,
  now: 0,
  striped: false,
  variant: 'brand-primary',
  visuallyHidden: false,
}

const ProgressBarContainer = styled.div`
  z-index: 4;
  ${({containerStyle}) => containerStyle};
`

const ProgressBar = ({className, containerStyle, variant, ...rest}) =>
  <ProgressBarContainer className={`${className} ${variant}`} containerStyle={containerStyle}>
    <BSProgressBar
      bsPrefix='progress-bar'
      variant={variant}
      {...rest}
    />
  </ProgressBarContainer>

ProgressBar.propTypes = propTypes
ProgressBar.defaultProps = defaultProps

export default ProgressBar

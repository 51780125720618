import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const createLogs = async (id, payload) => {
  const {logs} = await apiClient.post(`/projects/${id}/logs`, {logs: payload}).catch(handleError)
  return {logs}
}

export const fetchLogs = async id => {
  const {logs} = await apiClient.get(`projects/${id}/logs`).catch(handleError)
  return logs
}

export const fetchCostCertaintyLogs = async id => {
  const {cost_certainty_logs, meta: {total}} =
    await apiClient.get(`projects/${id}/logs/cost_certainty_logs`).catch(handleError)

  return {cost_certainty_logs, total}
}

export const fetchProductivityLogs = async id => {
  const {productivity_logs, meta: {total}} =
    await apiClient.get(`projects/${id}/logs/productivity_logs`).catch(handleError)

  return {productivity_logs, total}
}

export const fetchProgrammeCertaintyLogs = async id => {
  const {programme_certainty_logs, meta: {total}} =
    await apiClient.get(`projects/${id}/logs/programme_certainty_logs`).catch(handleError)

  return {programme_certainty_logs, total}
}

export const fetchSpeedLogs = async id => {
  const {speed_logs, meta: {total}} = await apiClient.get(`projects/${id}/logs/speed_logs`).catch(handleError)

  return {speed_logs, total}
}

export const fetchSafetyLogs = async id => {
  const {safety_logs, meta: {total}} = await apiClient.get(`projects/${id}/logs/safety_logs`).catch(handleError)

  return {safety_logs, total}
}

export const fetchWasteLogs = async id => {
  const {waste_logs, meta: {total}} =
    await apiClient.get(`projects/${id}/logs/waste_logs`).catch(handleError)

  return {waste_logs, total}
}

import React from 'react'
import {string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import Placeholder from './Placeholder'

const propTypes = {
  className: string,
  name: string,
}

const defaultProps = {
  className: '',
  name: '',
}

const NoSearchResultsPlaceholder = ({className, name}) =>
  <Placeholder
    alt={localise('alt.noResults')}
    className={className}
    description={localise('placeholder.noSearchResultsDesc', {name})}
    title={localise('placeholder.noSearchResults')}
  />

NoSearchResultsPlaceholder.propTypes = propTypes
NoSearchResultsPlaceholder.defaultProps = defaultProps

export default NoSearchResultsPlaceholder

import React, {useEffect, useState} from 'react'
import {arrayOf, bool, node, string, func, array, object, oneOfType} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import Table from './Table'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {useSelector} from 'react-redux'
import {
  getBuildingUnitBreakdownBuildingUnits,
  getBuildingUnitCount,
} from '../../store/selectors/buildingSelectors'
import {fetchBuildingUnitTypes} from '../../api/building-unit-types/buildingUnitTypes'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'

const propTypes = {
  className: string,
  customNoData: node,
  data: oneOfType([array, object]),
  headings: arrayOf(string),
  onChange: func,
  readOnly: bool,
}

const defaultProps = {
  className: '',
  customNoData: null,
  data: null,
  headings: null,
  onChange: () => {},
  readOnly: true,
}

const BuildingTable = props => {
  const {
    className,
    customNoData,
    headings,
    data,
    onChange,
    readOnly,
  } = props

  const [buildingUnitTypes, setBuildingUnitTypes] = useState([])
  const [cellData, setCellData] = useState([])
  const [currentQuantityCell, setCurrentQuantityCell] = useState(null)
  const [totalPercent, setTotalPercent] = useState(0)
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [totalUnitCount, setTotalUnitCount] = useState(0)

  const buildingUnits = useSelector(getBuildingUnitBreakdownBuildingUnits) || null
  const unitCount = useSelector(getBuildingUnitCount) || null

  useEffect(() => {
    toggleLoading()
    fetchBuildingUnitTypes()
      .then(({building_unit_types}) => setBuildingUnitTypes(building_unit_types))
      .finally(toggleLoading)
  }, [])

  useEffect(() => {
    updateAllValues()
  // eslint-disable-next-line
  }, [cellData, totalPercent])

  useEffect(() => {
    setTotalUnitCount(Math.max(0, Number.parseInt(unitCount ? unitCount : 0, 10)))
  }, [unitCount])

  useEffect(() => {
    setCurrentQuantityCell(null)
    updateAllValues()
    // eslint-disable-next-line
  }, [totalUnitCount])

  useEffect(() => {
    if (data?.length > 0) setCellData(data)
  }, [data])

  useEffect(() => {
    if (buildingUnits) {
      const mappedBuildingUnits = buildingUnitTypes.map(unitType => {
        const mappedUnit = buildingUnits?.find(({name}) => name === unitType.name)
        const {building_unit_type, name, percentage, quantity} = mappedUnit
        return {
          slug: building_unit_type?.slug,
          building_unit_type: building_unit_type?.id,
          name,
          percentage,
          quantity,
        }
      })
      setCellData(mappedBuildingUnits)
    } else {
      const mappedBuildingUnits = buildingUnitTypes.map(unitType => {
        return {
          quantity: 0,
          percentage: 0,
          building_unit_type: unitType.id,
          slug: unitType.slug,
          name: unitType.name,
        }
      })
      setCellData(mappedBuildingUnits)
    }
  }, [buildingUnits, buildingUnitTypes])

  const updateAllValues = () => {
    if (cellData?.length > 0) {
      const newData = cellData

      newData.forEach(item => item.percentage = (item?.quantity / totalUnitCount) * 100)
      setCellData(newData)

      const percent = Object?.values(cellData?.map(item => item.percentage))?.reduce((a, b) => a + b)
      setTotalPercent(Number.isNaN(percent) ? 0 : percent.toFixed(0))

      const quantity = Object?.values(cellData?.map(item => item.quantity))?.reduce((a, b) => a + b)
      setTotalQuantity(quantity)

      onChange({
        unitsPayload: {units: cellData},
        isValid: quantity === totalUnitCount && Math.round(percent) === 100,
      })
    }
  }

  const getRightSideAttr = (width = '22.6%', className) => ({width, className: `pe-2 text-end ${className}`})

  const QuantityCell = ({quantity, name, slug}) =>
    buildForm([
      {
        type: 'number',
        autoFocus: slug === currentQuantityCell,
        className: `font-12 border-0 p-0 text-end ${readOnly && 'cursor-default'}`,
        defaultValue: quantity,
        hideValidUI: true,
        name,
        readOnly,
        handler: val => {
          const value = val ? Math.max(0, Number.parseInt(val, 10)) : 0
          const itemIndex = cellData?.findIndex(item => item.slug === slug)
          const item = cellData?.find(item => item.slug === slug)

          item.quantity = value
          cellData[itemIndex] = item
          setCellData(cellData)
          setCurrentQuantityCell(slug)
          updateAllValues()
        },
      },
    ])

  const TotalQuantityCell = () => {
    const currentQuantity = cellData?.length > 0 ?
      cellData?.map(({quantity}) => quantity)?.reduce((a = 0, b = 0) => a + b) : totalUnitCount

    return totalUnitCount === undefined ? 0 : (
      currentQuantity === totalUnitCount ? totalUnitCount :
        <>
          <div className='font-invalid-red d-inline-block me-1'>({totalUnitCount})</div>
          <div className='d-inline-block'>{totalQuantity}</div>
        </>
    )
  }

  const summaryTotalRow = {
    row: [
      {
        cell: localise('tables.total'),
        attributes: {className: 'align-middle font-bold font-13'},
      },
      {
        cell: <TotalQuantityCell/>,
        attributes: {className: 'pe-2 text-end font-bold font-13'},
      },
      {
        cell: `${totalPercent}%`,
        attributes: {
          className: `pe-2 text-end font-bold font-13 ${Number(totalPercent) !== 100 && 'font-invalid-red'}`,
        },
      },
    ],
  }

  return (
    <Table
      className={className}
      customNoData={customNoData}
      data={headings || {
        tableHeadings: [
          {text: localise('tables.type')},
          {
            text: localise('tables.qty'),
            attributes: getRightSideAttr('33%'),
          },
          {
            text: localise('tables.percentSymbol'),
            attributes: getRightSideAttr(),
          },
        ],
        tableBody:
          cellData?.map(({slug, name, quantity, percentage}) => ({
            row: [
              {
                cell: name,
                attributes: {width: '66%'},
              },
              {
                cell: <QuantityCell name={name} quantity={quantity} slug={slug} />,
                attributes: getRightSideAttr('23.6%'),
              },
              {
                cell: isFinite(percentage) && !isNaN(percentage) ? percentage.toFixed(2) : '',
                attributes: getRightSideAttr('23.6%'),
              },
            ],
          })).concat(summaryTotalRow),
      }}
    />
  )
}

BuildingTable.propTypes = propTypes
BuildingTable.defaultProps = defaultProps

export default BuildingTable

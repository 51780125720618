import React, {useEffect, useState} from 'react'
import {string} from 'prop-types'
import {PLACEHOLDER_LANDSCAPE} from '../../constants/svgs'
import styled from 'styled-components'
import Card from './Card'

const propTypes = {
  className: string,
  description: string,
  src: string,
  title: string,
  url: string,
}

const defaultProps = {
  className: '',
  description: '',
  src: '',
  title: '',
  url: '',
}

const LinkCardContainer = styled(Card) `
  height: 220px;
`
const RoundedCornerDiv = styled.div`
  border-top-right-radius: 50px;
  bottom: 0;
  left: 0;
  opacity: 0.8;
  width: 96%;
`

const DescContainerDiv = styled.div`
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const LinkCard = props => {
  const {
    className,
    category,
    description,
    src,
    title,
    url,
  } = props

  const [parsedUrl, setParsedUrl] = useState(url)

  useEffect(() => {
    if (!url?.split('://')[0]?.includes('http')) setParsedUrl(`//${url}`)
  }, [url])


  return (
    <LinkCardContainer
      className={` 'box-shadow box-shadow-hover cursor-pointer px-0 ${className}`}>
      <div
        className='position-relative w-100 h-100'
        onClick={() => window.open(parsedUrl, '_blank', 'noopener noreferrer')}>
        <img className='w-100 h-100 object-fit-cover' src={src || PLACEHOLDER_LANDSCAPE} alt=''/>
        <RoundedCornerDiv className='position-absolute bg-dark-red h-50 ps-2 pt-2 pe-1 pb-1'>
          <div className='font-bold font-20 font-light-pink text-truncate'>{title}</div>
          <DescContainerDiv className='font-light font-15 font-light-pink pe-1'>{description}</DescContainerDiv>
          <div className='font-bold font-15 font-light-pink position-absolute bottom-0 mb-1'>{category}</div>
        </RoundedCornerDiv>
      </div>
    </LinkCardContainer>
  )
}

LinkCard.propTypes = propTypes
LinkCard.defaultProps = defaultProps

export default LinkCard

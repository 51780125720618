import React from 'react'
import {string} from 'prop-types'

const propTypes = {className: string}
const defaultProps = {className: ''}

const Pipe = ({className}) =>
  <div style={{width: '1px', height: '18px'}} className={`bg-black ms-2 me-3 ${className}`} />

Pipe.propTypes = propTypes
Pipe.defaultProps = defaultProps

export default Pipe

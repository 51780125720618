import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'
import {setAuth} from '../../utilities/auth-helpers/authHelpers'

export const fetchUser = async (id = 'me') => {
  const {user} = await apiClient.get(`users/${id}`)
  return user
}

const userAction = async (action, url, data) => {
  const {user} = await apiClient[action](url, data).catch(handleError)
  return user
}

export const updateUser = async (user, id = 'me') => userAction('put', `users/${id}`, {user})
export const deleteUser = async (id = 'me') => {
  const {deactivated} = await apiClient.delete(`users/${id}`)
  return deactivated
}

export const changePassword = async user => {
  const {token} = await apiClient.post('/users/me/password', {user}).catch(handleError)

  if (token) {
    setAuth(token)
    return token
  }
  return handleError()
}

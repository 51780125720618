import React from 'react'
import {string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import {PMV_RATING_ICON, PMV_RATING_ICON_EMPTY} from '../../constants/svgs'

const propTypes = {
  className: string,
  rating: Number,
}

const defaultProps = {
  className: '',
  rating: null,
}

const PMVRating = ({className, rating}) =>
  <div className={className}>
    <div className='font-10'>{localise('projectCard.myPmvScore')}</div>
    <div className='d-flex'>
      <img className='pt-1' src={rating ? PMV_RATING_ICON : PMV_RATING_ICON_EMPTY} alt={localise('alt.pmvRating')}/>
      <div
        className='ps-1 my-auto font-bold font-13'>
        {rating ? `${rating}%` : localise('projectCard.pending')}
      </div>
    </div>
  </div>


PMVRating.propTypes = propTypes
PMVRating.defaultProps = defaultProps

export default PMVRating

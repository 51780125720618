import React from 'react'
import {arrayOf, bool, node, number, shape, string} from 'prop-types'
import HeaderAccordion from './HeaderAccordion'
import BodyAccordion from './BodyAccordion'
import {Image} from 'react-bootstrap'

const propTypes = {
  active: bool,
  activeKey: string,
  bodyClassName: string,
  className: string,
  disabled: bool,
  icon: string,
  progress: number,
  sections: arrayOf(shape({
    body: node,
    className: string,
    complete: bool,
    disabled: bool,
    title: string,
  })),
  title: string,
}

const defaultProps = {
  active: false,
  activeKey: '',
  bodyClassName: '',
  className: '',
  disabled: false,
  icon: '',
  progress: null,
  sections: [],
  title: string,
}

const Accordion = props => {
  const {
    active,
    activeKey,
    bodyClassName,
    className,
    disabled,
    icon,
    progress,
    sections,
    title,
  } = props

  const progressBarProps = {
    max: !progress && sections?.length > 0 ? sections.length : 100,
    now: sections?.filter(({complete}) => complete)?.length || (progress * 100),
  }

  const items = sections?.map(({body, complete, disabled, title, ...rest}) => (
    {
      body,
      disabled,
      headerProps: {complete, completedIconClasses: 'bg-brand-primary border-0 p-2', heading: title},
      ...rest,
    }
  ))

  const header = (
    <div className='d-flex align-items-center'>
      <Image height={48} src={icon} />
      <div className='heading font-bold font-20 ms-3'>{title}</div>
    </div>
  )

  return (
    <HeaderAccordion
      active={active}
      bodyClassName={bodyClassName}
      className={className}
      disabled={disabled}
      header={header}
      progressBarProps={progressBarProps}>
      <BodyAccordion items={items} activeKey={activeKey} />
    </HeaderAccordion>
  )
}

Accordion.propTypes = propTypes
Accordion.defaultProps = defaultProps

export default Accordion

import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

const userPreferenceAction = async (action, url, data) => {
  const {user_preference} = await apiClient[action](url, data).catch(handleError)
  return user_preference
}

export const fetchUserPreference = () => userPreferenceAction('get', 'users/me/user_preference')
export const updateUserPreference = async payload => userPreferenceAction(
  'put',
  'users/me/user_preference',
  {
    user_preference: payload,
  }
)

import React from 'react'
import {bool, func, node, string} from 'prop-types'
import Modal from './Modal'
import Console from '../../utilities/ConsoleUtil'
import {Button} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'

const propTypes = {
  bodyElement: node,
  bodyText: string,
  className: string,
  headerText: string,
  onCancel: func,
  onConfirm: func,
  show: bool,
}

const defaultProps = {
  bodyElement: null,
  bodyText: '',
  className: '',
  headerText: '',
  onCancel: () => Console.dev('on cancel'),
  onConfirm: () => Console.dev('on confirm'),
  show: false,
}

const ConfirmModal = ({bodyElement, bodyText, className, headerText, onCancel, onConfirm, show}) => {
  return (
    <Modal
      className={className}
      headerText={headerText}
      bodyElement={bodyElement}
      bodyText={bodyText}
      show={show}
      toggle={onCancel}
      bodyClasses='mt-3'
      footerElement={<>
        <Button variant='outline' className='font-15 me-2' onClick={onCancel}>
          {localise('buttonText.cancel')}
        </Button>
        <Button variant='primary' className='font-15' onClick={onConfirm}>
          {localise('buttonText.confirm')}
        </Button>
      </>}
    />
  )
}

ConfirmModal.propTypes = propTypes
ConfirmModal.defaultProps = defaultProps

export default ConfirmModal
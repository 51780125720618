import React from 'react'
import {array, node, number, string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import Table from './Table'
import MMCCategories from '../headings/MMCCategories'

const propTypes = {
  badgeCompletedColor: string,
  categoryItems: array,
  className: string,
  customNoData: node,
  firstCellWidth: string,
  material: string,
  materialPercent: number,
  overallValue: string,
  onSiteLabour: string,
  onSiteLabourPercent: number,
  preliminaries: string,
  preliminariesPercent: number,
}

const defaultProps = {
  badgeCompletedColor: null,
  categoryItems: null,
  className: '',
  customNoData: null,
  firstCellWidth: '37%',
  material: '',
  materialPercent: null,
  overallValue: '',
  onSiteLabour: '',
  onSiteLabourPercent: null,
  preliminaries: '',
  preliminariesPercent: null,
}

const OverallCostsTable = props => {
  const {
    badgeCompletedColor,
    categoryItems,
    className,
    customNoData,
    firstCellWidth,
    material,
    materialPercent,
    onSiteLabour,
    onSiteLabourPercent,
    overallValue,
    preliminaries,
    preliminariesPercent,
  } = props

  const rightSideAttr = {
    width: '21%',
    className: `pe-2 text-end ${categoryItems && 'border-bottom-0'}`,
  }

  const headerRightSideAttr = {
    className: 'pe-2 text-end',
  }

  const createPercentDiv = (title, percent) => (
    <>
      <div className='font-13'>{title}</div>
      <div className='font-12 font-grey'>{percent}%</div>
    </>
  )

  const tableHeadings = [
    {text: localise('tables.overallValue')},
    {
      text: localise('tables.material'),
      attributes: headerRightSideAttr,
    },
    {
      text: localise('tables.onsiteLabour'),
      attributes: headerRightSideAttr,
    },
    {
      text: localise('tables.preliminaries'),
      attributes: headerRightSideAttr,
    },
  ]

  const tableBody = [
    {
      customNoData,
      row: [
        {
          cell: overallValue,
          attributes: {
            width: firstCellWidth,
            className: `align-middle ${categoryItems && 'border-bottom-0'}`,
          },
        },
        {
          cell: createPercentDiv(material, materialPercent),
          attributes: rightSideAttr,
        },
        {
          cell: createPercentDiv(onSiteLabour, onSiteLabourPercent),
          attributes: rightSideAttr,
        },
        {
          cell: createPercentDiv(preliminaries, preliminariesPercent),
          attributes: rightSideAttr,
        },
      ],
    },
  ]

  return (
    <>
      <Table
        className={className}
        data={{tableHeadings, tableBody}}
      />
      {
        <div className='border'>
          {
            categoryItems?.length > 0 &&
            <MMCCategories
              className='ms-2 mt-2'
              categoryItems={categoryItems}
              badgeCompletedColor={badgeCompletedColor}
            />
          }
        </div>
      }
    </>
  )
}

OverallCostsTable.propTypes = propTypes
OverallCostsTable.defaultProps = defaultProps

export default OverallCostsTable

import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const createPurchase = async (productId, paymentId) => {
  const data = {
    purchase: {
      cast_product_id: productId,
      payment_method_id: paymentId,
    },
  }

  const {purchase} = await apiClient.post('/purchases', data).catch(handleError)
  return purchase
}

const subscriptionAction = async (action, url, data) => {
  const {subscription} = await apiClient[action](url, data).catch(handleError)
  return subscription
}

export const createSubscription = (productId, paymentId) => subscriptionAction(
  'post',
  '/subscriptions',
  {
    subscription: {
      cast_product_id: productId,
      payment_method_id: paymentId,
    },
  }
)

export const updateSubscription = (subscriptionId, paymentId) => subscriptionAction(
  'patch',
  `/subscriptions/${subscriptionId}`,
  {
    subscription: {
      payment_method_id: paymentId,
    },
  }
)

export const deleteSubscription = async subscriptionId => {
  const response = await apiClient.delete(`subscriptions/${subscriptionId}`).catch(handleError)
  return response
}

export const fetchPaymentHistory = async () => {
  const {payments} = await apiClient.get('users/me/payments').catch(handleError)
  return payments
}
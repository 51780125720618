import {apiClient} from '../client'
import {VALIDATE_INTERNAL_AREA_SLUG} from '../../constants/validations'
import handleError from '../../utilities/api-helpers/handleError'

const validationAction = async (url, data) => {
  const {validation} = await apiClient.post(url, data).catch(handleError)
  return validation
}

export const validateUnitCount = (projectId, buildingId, unit_count) => validationAction(
  `projects/${projectId}/buildings/${buildingId}/units/validate`,
  {
    unit_count,
  }
)

export const validateInternalArea = (projectId, buildingId, payload) => validationAction(
  `projects/${projectId}/buildings/${buildingId}/validate`,
  {
    rule_slug: VALIDATE_INTERNAL_AREA_SLUG,
    ...payload,
  }
)

export const validateGrossCostBreakdown = (projectId, buildingId, grossCost, netCost) => validationAction(
  `projects/${projectId}/buildings/${buildingId}/gross_cost_breakdown/validate`,
  {
    building_gross_cost_breakdown: {
      building_gross_cost: {
        total_cents : grossCost,
      },
      building_net_cost: {
        total_cents : netCost,
      },
    },
  }
)

export const validateNetCostBreakdown = (projectId, buildingId, payload) => validationAction(
  `projects/${projectId}/buildings/${buildingId}/net_cost_breakdown/validate`,
  payload
)

export const validatePreliminariesCostBreakdown = (projectId, buildingId, payload) => validationAction(
  `projects/${projectId}/buildings/${buildingId}/preliminaries_cost_breakdown/validate`,
  payload
)

const thresholdsAction = async url => {
  const {thresholds} = await apiClient.get(url)
  return thresholds
}

export const fetchGrossCostBreakdownValidations = (projectId, buildingId) => thresholdsAction(
  `/projects/${projectId}/buildings/${buildingId}/gross_cost_breakdown/thresholds`
)

export const fetchNetCostBreakdownValidations = (projectId, buildingId) => thresholdsAction(
  `/projects/${projectId}/buildings/${buildingId}/net_cost_breakdown/thresholds`
)

export const fetchPreliminariesCostBreakdownValidations = (projectId, buildingId) => thresholdsAction(
  `/projects/${projectId}/buildings/${buildingId}/preliminaries_cost_breakdown/thresholds`
)
import React, {useEffect, useState} from 'react'
import BreadCrumbsView from '../../common/BreadcrumbsView'
import {DETAILS_URL, EDIT_URL, PROJECTS_URL} from '../../../constants/urls'
import {localise} from '../../../services/LocalizationServices'
import {useDispatch, useSelector} from 'react-redux'
import {
  getProjectAddress,
  getProjectCreatedAt,
  getProjectId,
  getProjectEditable,
  getProjectIsFavorite,
  getProjectMMCCategories,
  getProjectMyPMVRating,
  getProjectTitle,
  getProjectAddSuppliers,
} from '../../../store/selectors/projectSelectors'
import {useParams} from 'react-router'
import {toggleLoading} from '../../../utilities/LoadingIndicatorUtil'
import {deleteProject, fetchProject, updateProject} from '../../../api/project/project'
import {setProject} from '../../../store/slices/projectSlice'
import {Button, Row} from 'react-bootstrap'
import ProjectCard from '../../../components/cards/ProjectCard'
import BuildingsSection from './BuildingsSection'
import Banner from '../../../components/banner/Banner'
import {useHistory} from 'react-router-dom'
import useSupplierForm from '../../../hooks/useSupplierForm'
import DeleteModal from '../../../components/modals/DeleteModal'

const Project = () => {
  const dispatch = useDispatch()
  const {SUBSTRUCTURE_STEP_URL} = useSupplierForm()
  const {push} = useHistory()
  const {slug} = useParams()

  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const addSuppliers = useSelector(getProjectAddSuppliers)
  const address = useSelector(getProjectAddress)
  const createdAt = useSelector(getProjectCreatedAt)
  const editable = useSelector(getProjectEditable)
  const isFavorite = useSelector(getProjectIsFavorite)
  const mmcCategories = useSelector(getProjectMMCCategories)
  const myPMVRating = useSelector(getProjectMyPMVRating)
  const projectId = useSelector(getProjectId)
  const title = useSelector(getProjectTitle)

  useEffect(() => {
    toggleLoading()
    fetchProject(slug)
      .then(project => dispatch(setProject(project)))
      .finally(toggleLoading)
  }, [dispatch, slug])

  const toAddSuppliers = () => push(SUBSTRUCTURE_STEP_URL)
  const toProjects = () => push(PROJECTS_URL)
  const hideDeleteModal = () => setShowDeleteModal(false)

  const triggerDeleteProject = () => {
    toggleLoading()
    deleteProject(projectId)
      .then(destroyed => {
        if (destroyed) toProjects()
      })
      .finally(toggleLoading)
  }

  const updateFavourite = () => {
    toggleLoading()
    updateProject(projectId, {favourited: !isFavorite})
      .then(project => dispatch(setProject(project)))
      .finally(toggleLoading)
  }

  return (
    <BreadCrumbsView
      breadcrumbItems={[
        {
          active: false,
          href: PROJECTS_URL,
          text: localise('nav.projects'),
        }, {
          active: true,
          text: title,
        },
      ]}
      buttons={(
        <>
          {
            editable &&
            <Button
              variant='outline'
              className='font-12 py-1 px-2 me-2'
              onClick={() => push(`${PROJECTS_URL}${EDIT_URL}${DETAILS_URL}`)}>
              {localise('buttonText.edit')}
            </Button>
          }
          <Button variant='outline' className='font-12 py-1 px-2' onClick={() => setShowDeleteModal(true)}>
            {localise('buttonText.delete')}
          </Button>
        </>
      )}>
      {/*TODO: UNCOMMENT WHEN MMC SUPPLIERS ARE INTRODUCED*/}
      {/*{*/}
      {/*  addSuppliers &&*/}
      {/*  <Row className='mb-3'>*/}
      {/*    <Banner*/}
      {/*      description={localise('banner.mmcSupplierAddDesc')}*/}
      {/*      onButtonClick={toAddSuppliers}*/}
      {/*    />*/}
      {/*  </Row>*/}
      {/*}*/}
      <ProjectCard
        address={address}
        categoryItems={mmcCategories}
        className='mb-3'
        clickable={false}
        createdAt={createdAt}
        isFavorite={isFavorite}
        onFavoriteClick={updateFavourite}
        pmvRating={myPMVRating}
        projectId={projectId}
        showBuildings={false}
        title={title}
      />
      <BuildingsSection />
      <DeleteModal
        show={showDeleteModal}
        onCancel={hideDeleteModal}
        onDelete={triggerDeleteProject}
        headerText={localise('modalText.deleteProjectTitle')}
        bodyText={localise('modalText.deleteActionCantBeUndone')}
      />
    </BreadCrumbsView>
  )
}

export default Project

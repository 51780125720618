import React, {useEffect, useState} from 'react'
import BuildingTable from '../../../../components/tables/BuildingTable'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {fetchBuildingUnitBreakdown} from '../../../../api/building/building'
import {useParams} from 'react-router'

const UnitBreakdown = () => {
  const {projectSlug, slug} = useParams()

  const [buildingUnits, setBuildingUnits] = useState([])

  useEffect(() => {
    toggleLoading()
    fetchBuildingUnitBreakdown(projectSlug, slug)
      .then(({building_units}) => setBuildingUnits(building_units))
      .finally(toggleLoading)
  }, [projectSlug, slug])

  return <BuildingTable data={buildingUnits} />
}

export default UnitBreakdown

import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {localise} from '../../services/LocalizationServices'
import {EMAIL_REGEX} from '../../constants/regex'
import Form from '../../components/forms/Form'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {Button} from 'react-bootstrap'
import LogoutButton from '../../components/buttons/LogoutButton'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {
  getCurrentUserEmail,
  getCurrentUserFirstName,
  getCurrentUserLastName,
  getCurrentUserOrganisation,
} from '../../store/selectors/currentUserSelectors'
import {deleteUser, updateUser} from '../../api/user/user'
import {setCurrentUser} from '../../store/slices/currentUserSlice'
import {clearStore} from '../../store/store'
import {AUTH_URL} from '../../constants/urls'
import {useHistory} from 'react-router'
import {clearAuth} from '../../utilities/auth-helpers/authHelpers'
import Separator from '../../components/separator/Separator'
import SideNavView from '../common/SideNavView'
import useNav from '../../hooks/useNav'
import DeleteModal from '../../components/modals/DeleteModal'
import {toast} from 'react-hot-toast'
import {fetchConfiguration} from '../../api/configuration/configuration'
import {setConfiguration} from '../../store/slices/configurationSlice'
import Console from '../../utilities/ConsoleUtil'

const Details = () => {
  const {SIDE_NAV_ACCOUNT} = useNav()
  const dispatch = useDispatch()
  const history = useHistory()

  const [validated, setValidated] = useState(false)
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [companyUniversity, setCompanyUniversity] = useState('')
  const [emailValid, setEmailValid] = useState(true)

  const userFirstName = useSelector(getCurrentUserFirstName)
  const userLastName = useSelector(getCurrentUserLastName)
  const userEmail = useSelector(getCurrentUserEmail)
  const userOrg = useSelector(getCurrentUserOrganisation)

  useEffect(() => {
    setFirstName(userFirstName || '')
    setLastName(userLastName || '')
    setEmail(userEmail || '')
    setCompanyUniversity(userOrg || '')
  }, [userFirstName, userLastName, userEmail, userOrg])

  useEffect(() => {
    setEmailValid(true)
    const validationDelay = setTimeout(() => {
      setEmailValid(EMAIL_REGEX.test(String(email).toLowerCase()))
    }, 500)
    return () => clearTimeout(validationDelay)
  }, [email])

  const toAuth = () => history.push(AUTH_URL)

  const FORM_DATA = [
    {
      type: 'text',
      required: true,
      label: localise('form.label.firstName'),
      handler: setFirstName,
      validationMessage: localise('form.validation.firstName'),
      value: firstName,
    },
    {
      type: 'text',
      required: true,
      label: localise('form.label.lastName'),
      handler: setLastName,
      validationMessage: localise('form.validation.lastName'),
      value: lastName,
    },
    {
      type: 'email',
      required: true,
      label: localise('form.label.email'),
      handler: setEmail,
      placeholder: localise('form.placeholder.emailAddress'),
      validationMessage: localise('form.validation.email'),
      value: email,
      isInvalid: email && !emailValid,
    },
    {
      type: 'text',
      label: localise('form.label.companyUniversity'),
      handler: setCompanyUniversity,
      placeholder: localise('form.placeholder.companyUniversity'),
      value: companyUniversity,
    },
  ]

  const submitFormData = () => {
    toggleLoading()
    const formData = {
      first_name: firstName,
      last_name: lastName,
      email,
      organisation: companyUniversity,
    }

    updateUser(formData)
      .then(user => dispatch(setCurrentUser(user)))
      .finally(toggleLoading)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      submitFormData()
    }
    setValidated(true)
  }

  const handleDeleteAccount = async () => {
    try {
      toggleLoading()
      const deactivated = await deleteUser()

      if (deactivated) {
        clearAuth()
        clearStore()
        toAuth()
        const configuration = await fetchConfiguration()
        if (configuration) dispatch(setConfiguration(configuration))
        toast.success(localise('toast.accountSuccessfullyDeleted'))
        return
      }

      Console.dev('API ERROR')
      toggleLoading()
    }
    catch (e) {
      toggleLoading()
      Console.dev(e, 'error')
    }
  }

  const hideDeleteAccountModal = () => setShowDeleteAccountModal(false)
  return (
    <>
      <SideNavView navList={SIDE_NAV_ACCOUNT}>
        <div className='account-details-container pb-3'>
          <div className='font-bold font-20'>{localise('nav.myDetails')}</div>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {buildForm(FORM_DATA)}
            <Button variant='primary' type='submit' className='w-100 mt-2 font-15'>
              {localise('buttonText.update')}
            </Button>
          </Form>
          <Separator className='my-3' />
          <LogoutButton className='hover-underline' />
          <Separator className='my-3' />
          <div
            className='font-15 font-bold cursor-pointer hover-underline'
            onClick={() => setShowDeleteAccountModal(true)}>
            {localise('buttonText.deleteAccount')}
          </div>
        </div>
      </SideNavView>
      <DeleteModal
        bodyText={localise('modalText.deleteAccountMessage')}
        headerText={localise('modalText.deleteAccountTitle')}
        onCancel={hideDeleteAccountModal}
        onDelete={handleDeleteAccount}
        show={showDeleteAccountModal}
      />
    </>
  )
}

export default Details

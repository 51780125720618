import React, {useEffect, useState} from 'react'
import {bool, func, string} from 'prop-types'
import Console from '../../utilities/ConsoleUtil'
import {localise} from '../../services/LocalizationServices'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import Form from '../forms/Form'
import Modal from './Modal'
import {Button} from 'react-bootstrap'
import {fetchUnitsOfMeasurement} from '../../api/units-of-measurement/unitsOfMeasurement'

const propTypes = {
  animation: bool,
  className: string,
  onCancel: func,
  onConfirm: func,
  show: bool,
}

const defaultProps = {
  animation: true,
  className: '',
  onCancel: () => Console.dev('click onCancel prop'),
  onConfirm: () => Console.dev('click onConfirm prop'),
  show: false,
}

const UNIT_OF_MEASURE_SELECT_ID = 'unit-of-measure-select'

const UnitOfMeasurementModal = props => {
  const {
    animation,
    className,
    onCancel,
    onConfirm,
    show,
  } = props

  const [loading, setLoading] = useState(false)
  const [measurements, setMeasurements] = useState([])
  const [selectedMeasurement, setSelectedMeasurement] = useState(null)

  useEffect(() => {
    if (show) {
      setLoading(true)
      fetchUnitsOfMeasurement()
        .then(({units_of_measurement}) =>
          setMeasurements(units_of_measurement.map(({id: value, name: label}) => ({label, value}))))
        .finally(() => setLoading(false))
    }
  }, [show])

  const FORM_DATA = [
    {
      type: 'dropdown',
      id: UNIT_OF_MEASURE_SELECT_ID,
      classNamePrefix: UNIT_OF_MEASURE_SELECT_ID,
      label: localise('form.label.unitOfMeasure'),
      name: 'dropdown-unit-measure',
      placeholder: localise('form.label.unitOfMeasure'),
      onChange: ({value}) => setSelectedMeasurement(value),
      options: measurements,
      loading,
    },
  ]

  const confirmMeasurement = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      onConfirm(selectedMeasurement)
    }
  }

  return (
    <Modal
      additionalBodyClasses='font-15 pt-2 pb-4'
      animation={animation}
      bodyClasses='py-2'
      className={className}
      headerText={localise('headings.measurementPreferences')}
      show={show}
      toggle={onCancel}
      bodyElement={(
        <>
          <div className='font-15 mt-2'>{localise('modalText.selectPreferredUnitMeasurement')}</div>
          <Form className='mt-3' noValidate>
            {buildForm(FORM_DATA)}
          </Form>
        </>
      )}
      footerElement={<div>
        <Button variant='outline' className='font-15 mt-3' onClick={onCancel}>
          {localise('buttonText.cancel')}
        </Button>
        <Button
          variant='primary'
          disabled={selectedMeasurement === null || selectedMeasurement === undefined}
          className='font-15 mt-3 ms-2'
          onClick={confirmMeasurement}>
          {localise('buttonText.confirm')}
        </Button>
      </div>}
    />
  )
}

UnitOfMeasurementModal.propTypes = propTypes
UnitOfMeasurementModal.defaultProps = defaultProps

export default UnitOfMeasurementModal

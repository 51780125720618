import {useEffect} from 'react'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import {setBuilding} from '../store/slices/buildingSlice'
import {toggleLoading} from '../utilities/LoadingIndicatorUtil'
import {fetchProject} from '../api/project/project'
import {setProject} from '../store/slices/projectSlice'
import {
  fetchBuilding,
  fetchBuildingGrossCostBreakdown,
  fetchBuildingNetCostBreakdown,
  fetchBuildingOutput,
  fetchBuildingPreliminariesCostBreakdown,
  fetchBuildingProgrammeBreakdown,
} from '../api/building/building'
import {setBuildingGrossCost} from '../store/slices/buildingGrossCostSlice'
import {setBuildingNetCost} from '../store/slices/buildingNetCostSlice'
import {setBuildingPreliminariesCost} from '../store/slices/buildingPreliminariesCostSlice'
import {setBuildingProgramme} from '../store/slices/buildingProgrammeSlice'
import {clearBuildingOutput, setBuildingOutput} from '../store/slices/buildingOutputSlice'
import {HOUSE_SLUG} from '../constants/buildingTypes'
import {fetchSurvey} from '../api/surveys/surveys'
import {setSurvey} from '../store/slices/surveySlice'
import {getBuildingTypeSlug} from '../store/selectors/buildingSelectors'

const useBuilding = ({
  onBuildingChanged = null,
  onBuildingGrossCostChanged = null,
  onBuildingNetCostChanged = null,
  onBuildingPreliminariesCostChanged = null,
  onBuildingProgrammeChanged = null,
  onSurveyFetched = null,
  suppressCalculatorFetches = false,
} = {}) => {
  const {projectSlug, slug} = useParams()
  const dispatch = useDispatch()

  const buildingType = useSelector(getBuildingTypeSlug)

  const isHouse = () => buildingType === HOUSE_SLUG

  const setBuildingRedux = building => {
    const data = {
      ...building,
      applyWallToFloor: !!building?.wall_to_floor_ratio_percentage,
    }

    const IS_HOUSE_TYPE = data?.building_type?.slug === HOUSE_SLUG

    const sectionOneComplete = !!(data?.name && data?.building_sector && data?.building_type)
    const sectionTwoComplete = !!((data?.house_type && data?.building_bedroom_range) ||
      (data?.unit_mix !== null && data?.unit_count))
    const sectionThreeComplete =
      IS_HOUSE_TYPE ?
        !!data?.house_gia_nia :
        !!data?.gross_internal_area && !!data?.net_internal_area

    data.setup = {
      sectionOneComplete,
      sectionTwoComplete,
      sectionThreeComplete,
      complete: sectionOneComplete && sectionTwoComplete && sectionThreeComplete,
    }

    dispatch(setBuilding(data))
    if (onBuildingChanged) onBuildingChanged(data)

    if (data?.survey_id && data?.survey_response_id) {
      fetchSurvey(data?.survey_id, data?.survey_response_id)
        .then(survey => {
          dispatch(setSurvey(survey))
          if (onSurveyFetched) onSurveyFetched()
        })
    }
  }

  const setBuildingGrossCostRedux = data => {
    dispatch(setBuildingGrossCost(data))
    if (onBuildingGrossCostChanged) onBuildingGrossCostChanged(data)
  }

  const setBuildingNetCostRedux = data => {
    dispatch(setBuildingNetCost(data))
    if (onBuildingNetCostChanged) onBuildingNetCostChanged(data)
  }

  const setBuildingPreliminariesCostRedux = data => {
    dispatch(setBuildingPreliminariesCost(data))
    if (onBuildingPreliminariesCostChanged) onBuildingPreliminariesCostChanged(data)
  }

  const setBuildingProgrammeRedux = data => {
    dispatch(setBuildingProgramme(data))
    if (onBuildingProgrammeChanged) onBuildingProgrammeChanged(data)
  }

  const setBuildingOutputRedux = data => {
    if (data) {
      dispatch(setBuildingOutput(data))
    } else {
      dispatch(clearBuildingOutput())
    }
  }

  useEffect(() => {
    if (projectSlug) {
      toggleLoading()
      fetchProject(projectSlug)
        .then(project => dispatch(setProject(project)))
        .finally(toggleLoading)
    }
  }, [dispatch, projectSlug])

  useEffect(() => {
    if (projectSlug && slug) {
      toggleLoading()
      Promise.all([
        fetchBuilding(projectSlug, slug).then(setBuildingRedux),
        ...suppressCalculatorFetches
          ? []
          : [
            fetchBuildingGrossCostBreakdown(projectSlug, slug).then(data => {
              data.initial = {...data}
              setBuildingGrossCostRedux(data)
            }),
            fetchBuildingNetCostBreakdown(projectSlug, slug).then(data => {
              data.initial = {...data}
              setBuildingNetCostRedux(data)
            }),
            fetchBuildingPreliminariesCostBreakdown(projectSlug, slug).then(data => {
              data.initial = {...data}
              setBuildingPreliminariesCostRedux(data)
            }),
            fetchBuildingProgrammeBreakdown(projectSlug, slug).then(setBuildingProgrammeRedux),
            fetchBuildingOutput(projectSlug, slug).then(setBuildingOutputRedux),
          ],
      ]).finally(toggleLoading)
    }
    // eslint-disable-next-line
  }, [projectSlug, slug])

  return {
    isHouse,
    setBuildingGrossCostRedux,
    setBuildingNetCostRedux,
    setBuildingPreliminariesCostRedux,
    setBuildingProgrammeRedux,
    setBuildingRedux,
    setBuildingOutputRedux,
  }
}

export default useBuilding

import React from 'react'
import {bool, elementType, object, string, exact, arrayOf} from 'prop-types'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import {v4} from 'uuid'

const propTypes = {
  breadcrumb: exact({
    as: elementType,
    bsPrefix: string,
    children: elementType,
    label: string,
    listProps: object,
  }),
  breadcrumbItems: arrayOf(
    exact({
      active: bool,
      as: elementType,
      bsPrefix: string,
      href: string,
      linkAs: elementType,
      linkProps: object,
      target: string,
      text: string,
      title: string,
    })
  ),
  className: string,
}

const defaultProps = {
  breadcrumb: {
    as: 'nav',
    bsPrefix: '',
    children: null,
    label: 'breadcrumb',
    listProps: {},
  },
  breadcrumbItems: [
    {
      active: false,
      as: 'li',
      bsPrefix: '',
      href: '#',
      target: null,
      text: 'breadcrumb 1',
      title: null,
    },
    {
      active: true,
      as: 'li',
      bsPrefix: '',
      href: null,
      target: null,
      text: 'breadcrumb 2',
      title: null,
    },
  ],
  className: '',
}

const BreadCrumbs = ({breadcrumb: crumb, breadcrumbItems, className}) =>
  <Breadcrumb {...crumb} bsPrefix={`breadcrumb ${crumb?.bsPrefix}`} className={`bread-crumb-container ${className}`}>
    {
      breadcrumbItems.map(breadcrumbItem => (
        <Breadcrumb.Item {...breadcrumbItem} bsPrefix={`breadcrumb-item ${breadcrumbItem?.bsPrefix}`} key={v4()}>
          {breadcrumbItem?.text}
        </Breadcrumb.Item>
      ))
    }
  </Breadcrumb>

BreadCrumbs.propTypes = propTypes
BreadCrumbs.defaultProps = defaultProps

export default BreadCrumbs

import React from 'react'
import {func, string} from 'prop-types'
import {Form, Image, InputGroup} from 'react-bootstrap'
import {debounce} from 'lodash'
import {MAGNIFYING_GLASS} from '../../constants/svgs'
import {localise} from '../../services/LocalizationServices'
import styled from 'styled-components'

const propTypes = {
  className: string,
  defaultValue: string,
  handleSearch: func,
}

const defaultProps = {
  className: '',
  defaultValue: '',
  handleSearch: () => {},
}

const DEBOUNCE_TIME = 500

const InputContainer = styled.div`max-height: 24px;`
const Input = styled(Form.Control)`
  border-left: 0 !important;
  font-size: 12px !important;

  :-ms-input-placeholder { color: black !important }
  ::-ms-input-placeholder { color: black !important }
  ::placeholder { color: black !important }
`

const SearchInput = ({className, defaultValue, handleSearch, ...rest}) =>
  <InputContainer className={`search-input-container d-flex ${className}`}>
    <InputGroup.Text id='search-icon-addon' className='input-group-prepend'>
      <Image src={MAGNIFYING_GLASS} alt='search' width={16} />
    </InputGroup.Text>
    <Input
      className='search-input'
      defaultValue={defaultValue}
      onChange={debounce(({target: {value}}) => handleSearch(value), DEBOUNCE_TIME)}
      type='text'
      placeholder={localise('form.placeholder.search')}
      {...rest}
    />
  </InputContainer>

SearchInput.propTypes = propTypes
SearchInput.defaultProps = defaultProps

export default SearchInput

import React, {useEffect, useState} from 'react'
import {func} from 'prop-types'
import ConfirmForm from '../../../../components/forms/ConfirmForm'
import {useDispatch, useSelector} from 'react-redux'
import {
  getBuildingHouseTypeId,
  getBuildingHouseTypeName,
  getBuildingNumberOfBedroomsId,
  getBuildingNumberOfBedroomsName,
  getBuildingUnitCount,
} from '../../../../store/selectors/buildingSelectors'
import {fetchHouseTypes} from '../../../../api/house-types/houseTypes'
import {localise} from '../../../../services/LocalizationServices'
import {setBuilding} from '../../../../store/slices/buildingSlice'
import {fetchBuildingBedroomRanges} from '../../../../api/building-bedroom-ranges/buildingBedroomRanges'

const propTypes = {
  onSubmit: func,
}

const defaultProps = {
  onSubmit: () => {},
}

const HouseForm = ({onSubmit}) => {
  const dispatch = useDispatch()

  const [bedrooms, setBedrooms] = useState([])
  const [bedroomsLoading, setBedroomsLoading] = useState(false)
  const [typeLoading, setTypeLoading] = useState(false)
  const [types, setTypes] = useState([])

  const numberOfBedroomsId = useSelector(getBuildingNumberOfBedroomsId)
  const numberOfBedroomsName = useSelector(getBuildingNumberOfBedroomsName)
  const numberOfUnits = useSelector(getBuildingUnitCount) || null
  const typeId = useSelector(getBuildingHouseTypeId)
  const typeName = useSelector(getBuildingHouseTypeName)

  useEffect(() => {
    setBedroomsLoading(true)
    setTypeLoading(true)

    fetchHouseTypes()
      .then(({house_types}) => setTypes(house_types.map(({id: value, name: label}) => ({value, label}))))
      .finally(() => setTypeLoading(false))

    fetchBuildingBedroomRanges()
      .then(({building_bedroom_ranges}) => setBedrooms(building_bedroom_ranges.map(
        ({id: value, name: label}) => ({value, label})
      )))
      .finally(() => setBedroomsLoading(false))
  }, [])

  const fields = [
    {
      type: 'dropdown',
      name: 'type',
      handler: ({value: id, label: name}) => dispatch(setBuilding({house_type: {id, name}})),
      label: localise('form.label.houseType'),
      loading: typeLoading,
      menuPortalTarget: document.body,
      options: types,
      placeholder: localise('form.placeholder.houseType'),
      required: true,
      validationMessage: localise('form.validation.houseType'),
      value: typeId && typeName ? {value: typeId, label: typeName} : null,
    },
    {
      type: 'dropdown',
      name: 'number-of-bedrooms',
      label: localise('form.label.numberOfBedrooms'),
      options: bedrooms,
      placeholder: localise('form.placeholder.numberOfBedrooms'),
      handler: ({value: id, label: name}) => dispatch(setBuilding({building_bedroom_range: {id, name}})),
      loading: bedroomsLoading,
      menuPortalTarget: document.body,
      required: true,
      validationMessage: localise('form.validation.numberOfBedrooms'),
      value: numberOfBedroomsId && numberOfBedroomsName
        ? {value: numberOfBedroomsId, label: numberOfBedroomsName}
        : null,
    },
    {
      name: 'number-of-units',
      handler: numberOfUnits => dispatch(setBuilding({unit_count: numberOfUnits})),
      label: localise('form.label.numberOfUnits'),
      min: 1,
      required: true,
      validationMessage:
        `${localise('form.validation.numberOfUnits')}. 
        ${localise('form.validation.minValueRequired', {min: 1})}`,
      value: numberOfUnits,
    },
  ]

  const submitFormData = () => onSubmit({
    house_type: typeId,
    building_bedroom_range: numberOfBedroomsId,
    unit_count: numberOfUnits,
  })

  const submittable = !!typeId && !!numberOfBedroomsId && !!numberOfUnits

  return <ConfirmForm fields={fields} onSubmit={submitFormData} submittable={submittable} />
}

HouseForm.propTypes = propTypes
HouseForm.defaultProps = defaultProps

export default HouseForm

import React from 'react'
import {array, number, string} from 'prop-types'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Connector from './Connector'
import StepIcon from './StepIcon'
import {v4} from 'uuid'
import {makeStyles} from '@material-ui/core/styles'
import {BRAND_PRIMARY} from '../../constants/colours'

const propTypes = {
  activeStep: number,
  className: string,
  connectorColor: string,
  icons: array.isRequired,
}

const defaultProps = {
  activeStep: 0,
  className: '',
  connectorColor: null,
  icons: [],
}

const useStepperClasses = makeStyles({
  root: {
    maxWidth: '100%',
    padding: 0,
  },
})

const FlowStepper = ({activeStep, className, connectorColor, icons}) => {
  const {root} = useStepperClasses()

  const renderSteps = () => icons?.map(icon =>
    <Step key={v4()}>
      <StepLabel
        StepIconComponent={StepIcon}
        StepIconProps={typeof icon === 'string' ? {src: icon} : icon}
      />
    </Step>
  )

  return (
    <div className={`flow-stepper-container ${className}`}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        classes={{root}}
        connector={<Connector backgroundcolor={connectorColor || BRAND_PRIMARY} />}>
        {renderSteps()}
      </Stepper>
    </div>
  )
}

FlowStepper.propTypes = propTypes
FlowStepper.defaultProps = defaultProps

export default FlowStepper

import React from 'react'
import {bool, func, string} from 'prop-types'
import Console from '../../utilities/ConsoleUtil'
import {Button} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import {CHECK, BUILDING_SETUP_ICON} from '../../constants/svgs'
import IconBadge from '../badge/IconBadge'
import {BRAND_PRIMARY, LIGHT_PINK} from '../../constants/colours'
import styled from 'styled-components'
import Modal from './Modal'

const propTypes = {
  animation: bool,
  badgeIconColour: string,
  badgeIconSrc: string,
  badgeTickBgColour: string,
  buttonText: string,
  className: string,
  descriptionLine1: string,
  descriptionLine2: string,
  onConfirm: func,
  show: bool,
  showCloseButton: bool,
  titleLine1: string,
  titleLine2: string,
  toggle: func,
}

const defaultProps = {
  animation: true,
  badgeIconColour: LIGHT_PINK,
  badgeIconSrc: BUILDING_SETUP_ICON,
  badgeTickBgColour: BRAND_PRIMARY,
  buttonText: '',
  className: '',
  descriptionLine1: '',
  descriptionLine2: '',
  onConfirm: () => Console.dev('click onConfirm prop'),
  show: false,
  showCloseButton: false,
  titleLine1: '',
  titleLine2: '',
  toggle: () => Console.dev('click toggle prop'),
}

const TickContainer = styled.div`
  left: 70%;
`

const EntityCreateSuccessModal = props => {

  const {
    animation,
    badgeIconColour,
    badgeIconSrc,
    badgeTickBgColour,
    buttonText,
    className,
    descriptionLine1,
    descriptionLine2,
    onConfirm,
    show,
    titleLine1,
    titleLine2,
    toggle,
  } = props

  return (
    <Modal
      animation={animation}
      className={`entity-modal-container ${className}`}
      toggle={toggle}
      show={show}
      bodyElement={<>
        <div className='border-0 mx-auto'>
          <div className='mt-3 d-flex justify-content-center'>
            <div className='position-relative'>
              <IconBadge size={50} src={badgeIconSrc} colour={badgeIconColour}/>
              <TickContainer className='position-absolute top-0'>
                <IconBadge size={21} src={CHECK} colour={badgeTickBgColour}/>
              </TickContainer>
            </div>
          </div>
          <div className='text-center mt-3'>
            <div className='font-bold font-20'>
              <div>{titleLine1}</div>
              <div>{titleLine2}</div>
            </div>
            <div className='font-15 mt-3'>
              <div>{descriptionLine1}</div>
              <div>{descriptionLine2}</div>
            </div>
            <Button
              className='px-4 mt-3 font-15'
              variant='primary'
              onClick={onConfirm}>
              {buttonText || localise('buttonText.continue')}
            </Button>
          </div>
        </div>
      </>}
    />
  )
}

EntityCreateSuccessModal.propTypes = propTypes
EntityCreateSuccessModal.defaultProps = defaultProps

export default EntityCreateSuccessModal

import React from 'react'
import {array, node, number, object, string} from 'prop-types'
import BreadCrumbsView from './BreadcrumbsView'
import FlowStepper from '../../components/flow-stepper/FlowStepper'
import {Col, Row} from 'react-bootstrap'

const propTypes = {
  activeStep: number,
  breadcrumbs: array,
  children: node,
  className: string,
  connectorColor: string,
  icons: array.isRequired,
  stepperContainerProps: object,
}

const defaultProps = {
  activeStep: 0,
  breadcrumbs: [],
  children: null,
  className: '',
  connectorColor: null,
  icons: [],
  stepperContainerProps: {},
}

const FlowView = ({activeStep, breadcrumbs, children, className, connectorColor, icons, stepperContainerProps}) => {
  return (
    <BreadCrumbsView breadcrumbItems={breadcrumbs} className={className}>
      <Row>
        <Col xs={12} {...stepperContainerProps}>
          <FlowStepper
            activeStep={activeStep}
            connectorColor={connectorColor}
            icons={icons}
          />
        </Col>
        <Col xs={12} md={8} className='mx-auto py-3 px-4 px-md-0'>
          {children}
        </Col>
      </Row>
    </BreadCrumbsView>
  )
}

FlowView.propTypes = propTypes
FlowView.defaultProps = defaultProps

export default FlowView

import {createSlice} from '@reduxjs/toolkit'

const buildingSlice = createSlice({
  name: 'building',
  initialState: {},
  reducers: {
    setBuilding: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearBuilding: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const buildingReducer = buildingSlice.reducer
export const {clearBuilding, setBuilding} = buildingSlice.actions

import React from 'react'
import {string} from 'prop-types'

const propTypes = {
  className: string,
  heading: string,
  headingClasses: string,
  paragraph: string,
  paragraphClasses: string,
}

const defaultProps = {
  className: '',
  heading: '',
  headingClasses: '',
  paragraph: '',
  paragraphClasses: 'font-17 ',
}

const HeadingParagraph = ({className, heading, headingClasses, paragraph, paragraphClasses}) =>
  <div className={`heading-paragraph mb-2 ${className}`}>
    <div className={`font-10 ${headingClasses}`}>{heading}</div>
    <div className={`font-bold ${paragraphClasses}`}>{paragraph}</div>
  </div>

HeadingParagraph.propTypes = propTypes
HeadingParagraph.defaultProps = defaultProps

export default HeadingParagraph

import {withStyles} from '@material-ui/core/styles'
import StepConnector from '@material-ui/core/StepConnector'
import {BRAND_PRIMARY, LIGHT_PINK} from '../../constants/colours'
import {lighten} from '@material-ui/core'

const Connector = withStyles({
  alternativeLabel: {
    backgroundColor: ({backgroundcolor}) => lighten(backgroundcolor, 0.7) || LIGHT_PINK,
    left: 'calc(-50% + 17px)',
    right: 'calc(50% + 17px)',
    top: 18,
  },
  active: {backgroundColor: ({backgroundcolor}) => backgroundcolor || BRAND_PRIMARY},
  completed: {backgroundColor: ({backgroundcolor}) => backgroundcolor || BRAND_PRIMARY},
  line: {
    border: 0,
    borderRadius: 1,
    height: 2,
  },
})(StepConnector)

export default Connector

import React from 'react'
import {Route, Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import HelpfulLinks from '../views/helpful-links/HelpfulLinks'
import PageNotFound from '../views/error/PageNotFound'

const HelpfulLinksRoutes = ({path}) => {
  return (
    <Switch>
      <RouteWithLayout exact path={path} component={HelpfulLinks} />
      <Route path='*'><PageNotFound /></Route>
    </Switch>
  )
}

export default HelpfulLinksRoutes
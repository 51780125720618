import React from 'react'
import {string} from 'prop-types'
import {
  AMEX,
  DINER_CLUB,
  DISCOVER,
  JCB,
  MAESTRO,
  MASTERCARD,
  UNION_PAY,
  UNKNOWN,
} from '../../constants/pngs'
import {VISA} from '../../constants/svgs'

const BRAND_AMEX = 'amex'
const BRAND_DINER_CLUB = 'diner-club'
const BRAND_DISCOVER = 'discover'
const BRAND_JCB = 'jcb'
const BRAND_MAESTRO = 'maestro'
const BRAND_MASTERCARD = 'mastercard'
const BRAND_UNION_PAY = 'union-pay'
const BRAND_UNKNOWN = 'unknown'
const BRAND_VISA = 'visa'

const BRANDS = {
  [BRAND_AMEX]: AMEX,
  [BRAND_DINER_CLUB]: DINER_CLUB,
  [BRAND_DISCOVER]: DISCOVER,
  [BRAND_JCB]: JCB,
  [BRAND_MAESTRO]: MAESTRO,
  [BRAND_MASTERCARD]: MASTERCARD,
  [BRAND_UNION_PAY]: UNION_PAY,
  [BRAND_UNKNOWN]: UNKNOWN,
  [BRAND_VISA]: VISA,
}

const propTypes = {
  brand: string,
  className: string,
}

const defaultProps = {
  brand: BRAND_UNKNOWN,
  className: '',
}


const CardIcon = props => {
  const {
    brand,
    className,
  } = props

  const getSrc = () => BRANDS[brand]

  return <img className={`card-icon ${className}`} src={getSrc()} alt='card-icon' width={35} height={30} />
}

CardIcon.propTypes = propTypes
CardIcon.defaultProps = defaultProps

export default CardIcon

import React from 'react'
import {array, node, string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import Table from './Table'

const propTypes = {
  className: string,
  customNoData: node,
  heading: string,
  programmeMetrics: array,
}

const defaultProps = {
  className: '',
  customNoData: null,
  heading: '',
  programmeMetrics: [],
}

const ProgrammeMetricsTable = props => {
  const {className, customNoData, heading, programmeMetrics} = props

  const cellRightSideAttr = {
    width: '8%',
    className: 'pe-2 text-end',
  }

  const headerRightSideAttr = {
    className: 'pe-2 text-end align-middle',
  }

  return (
    <div className={className}>
      <div className='font-regular font-15 font-grey py-2 mt-2'>{heading || localise('tables.programmeMetrics')}</div>
      <Table
        customNoData={customNoData}
        data={{
          tableHeadings: [
            {
              text: localise('tables.element'),
              attributes: {className: 'align-middle'},
            },
            {
              text: localise('tables.weeks'),
              attributes: headerRightSideAttr,
            },
            {
              text: localise('tables.cost'),
              attributes: headerRightSideAttr,
            },
            {
              attributes: headerRightSideAttr,
              text: (
                <>
                  <div className='font-bold font-13'>{localise('tables.price')}</div>
                  <div className='font-regular font-12 text-lowercase'>{localise('tables.perWeek')}</div>
                </>
              ),
            },
          ],
          tableBody: programmeMetrics?.map(({name, price, price_per_week, weeks}) => ({
            row: [
              {
                cell: name,
                attributes: {width: '40%', className: 'align-middle'},
              },
              {
                cell: `${weeks} ${localise('tables.weeks')}`,
                attributes: cellRightSideAttr,
              },
              {
                cell: price?.display,
                attributes: cellRightSideAttr,
              },
              {
                cell: price_per_week?.display,
                attributes: cellRightSideAttr,
              },
            ],
          })) || [],
        }}
      />
    </div>
  )
}

ProgrammeMetricsTable.propTypes = propTypes
ProgrammeMetricsTable.defaultProps = defaultProps

export default ProgrammeMetricsTable

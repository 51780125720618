import {useMemo} from 'react'
import {useSelector} from 'react-redux'
import {getSupportEmail} from '../store/selectors/configurationSelectors'
import {
  getCurrentUserEmail,
  getCurrentUserFirstName,
  getCurrentUserLastName,
} from '../store/selectors/currentUserSelectors'
import {localise} from '../services/LocalizationServices'

const useSupportEmail = (subject, messageValues = [], messageText = '') => {
  const firstName = useSelector(getCurrentUserFirstName)
  const lastName = useSelector(getCurrentUserLastName)
  const email = useSelector(getCurrentUserEmail)
  const userName = `${firstName} ${lastName}`
  const supportEmail = useSelector(getSupportEmail)

  const body = useMemo(() => {
    let body

    const values = [
      {
        value: messageText,
      },
      {
        name: localise('support.name'),
        value: userName,
      },
      {
        name: localise('support.email'),
        value: email,
      },
      ...messageValues,
    ]

    values?.forEach(({name, value}) => {
      if (name && value) {
        body = `${body || ''}${name}: ${value}\n`
      }
      else if (value) {
        body = `${body || ''}${value}\n`
      }
    })
    return body
  }, [messageValues])

  return () =>
    window.open(
      `mailto:${supportEmail}?subject=${subject}&body=${encodeURIComponent(body)}`,
      '_blank'
    )
}

export default useSupportEmail

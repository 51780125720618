import {createSlice} from '@reduxjs/toolkit'

const userPreferenceSlice = createSlice({
  name: 'userPreference',
  initialState: {},
  reducers: {
    setUserPreference: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearUserPreference: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const userPreferenceReducer = userPreferenceSlice.reducer
export const {clearUserPreference, setUserPreference} = userPreferenceSlice.actions

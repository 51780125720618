export const ACCOUNT_URL = '/account'
export const ADDRESS_URL = '/address'
export const AUTH_URL = '/auth'
export const BUILDINGS_URL = '/buildings'
export const CALCULATOR_URL = '/calculator'
export const COMPONENTS_URL = '/components'
export const COST_CERTAINTY_URL = '/cost-certainty'
export const DETAILS_URL = '/details'
export const EDIT_URL = '/edit'
export const FIT_OUT_URL = '/fit-out'
export const FUNDING_URL = '/funding'
export const HELPFUL_LINKS_URL = '/helpful-links'
export const LOG_URL = '/log'
export const MAP_VIEW_URL = '/map'
export const MECHANICAL_ELECTRICAL_PLUMBING_URL = '/mechanical-electrical-plumbing'
export const MMC_SUPPLIERS_URL = '/mmc-suppliers'
export const NEW_URL = '/new'
export const NOT_FOUND_URL = '/not-found'
export const PASSWORD_URL = '/password'
export const PAYMENT_HISTORY_URL = '/payment-history'
export const PAYMENT_URL = '/payment'
export const PREFERENCES_URL = '/preferences'
export const PRELIMINARIES_URL = '/preliminaries'
export const PRODUCTIVITY_URL = '/productivity'
export const PROGRAMME_CERTAINTY_URL = '/programme-certainty'
export const PROJECTS_URL = '/projects'
export const PURCHASE_OPTIONS_URL = '/purchase-options'
export const RESET_PASSWORD_CONFIRMATION_URL = '/reset-password-confirmation'
export const RESET_PASSWORD_URL = '/reset-password'
export const ROOT_URL = '/'
export const SAFETY_URL = '/safety'
export const SIGN_IN_URL = '/sign-in'
export const SIGN_UP_URL = '/sign-up'
export const SPEED_URL = '/speed'
export const SUBSCRIPTIONS_URL = '/subscriptions'
export const SUBSTRUCTURE_URL = '/substructure'
export const SUPERSTRUCTURE_URL = '/superstructure'
export const SUPPLIERS_URL = '/suppliers'
export const WASTE_URL = '/waste'

import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const generateGrossCostBreakdown = async (total_cents, building_id) => {
  const {building_gross_cost_breakdown} = await apiClient.post('generate_breakdowns/gross_cost', {
    building_id,
    building_gross_cost_breakdown: {
      building_gross_cost: {total_cents},
    },
  }).catch(handleError)

  return building_gross_cost_breakdown
}

export const generateNetCostBreakdown = async (total_cents, building_id) => {
  const {building_net_cost_breakdown} = await apiClient.post('generate_breakdowns/net_cost', {
    building_id,
    building_net_cost_breakdown: {
      building_net_cost: {total_cents},
    }}).catch(handleError)

  return building_net_cost_breakdown
}

export const generatePreliminariesCostBreakdown = async (total_cents, building_id) => {
  const {building_preliminaries_cost_breakdown} = await apiClient.post('generate_breakdowns/preliminaries_cost', {
    building_id,
    building_preliminaries_cost_breakdown: {
      building_preliminaries_cost: {total_cents},
    }}).catch(handleError)

  return building_preliminaries_cost_breakdown
}

export const generateUnitBreakdown = async (payload, building_id) => {
  const {building_unit_breakdown} = await apiClient.post(
    'generate_breakdowns/unit',
    {
      building_id,
      building_unit_breakdown: payload,
    }
  ).catch(handleError)

  return building_unit_breakdown
}


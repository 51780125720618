import React, {useEffect, useState} from 'react'
import BuildingFlow from '../../flows/BuildingFlow'
import {BUILDINGS_URL, PROJECTS_URL} from '../../../constants/urls'
import {localise} from '../../../services/LocalizationServices'
import {useSelector} from 'react-redux'
import {getProjectId, getProjectTitle} from '../../../store/selectors/projectSelectors'
import Accordion from '../../../components/accordion/Accordion'
import {CALCULATOR_ICON, DOCUMENT_ICON} from '../../../constants/svgs'
import useBuilding from '../../../hooks/useBuilding'
import GrossCostForm from './form/GrossCostForm'
import {toggleLoading} from '../../../utilities/LoadingIndicatorUtil'
import {
  fetchBuildingNetCostBreakdown,
  fetchBuildingOutput,
  fetchBuildingPreliminariesCostBreakdown,
  fetchBuildingProgrammeBreakdown,
  updateBuildingGrossCostBreakdown,
  updateBuildingNetCostBreakdown,
  updateBuildingPreliminariesCostBreakdown,
  updateBuildingProgrammeBreakdown,
} from '../../../api/building/building'
import {getBuildingId, getBuildingName} from '../../../store/selectors/buildingSelectors'
import NetCostForm from './form/NetCostForm'
import PreliminariesCostForm from './form/PreliminariesCostForm'
import BuildingProgrammeForm from './form/BuildingProgrammeForm'
import BuildingAnalysisSummaryTable from './form/BuildingAnalysisSummaryTable'
import BuildingCreateSuccessModal from '../../../components/modals/BuildingCreateSuccessModal'
import {useHistory} from 'react-router'
import {
  getBuildingGrossCommercialMetrics,
  getBuildingNetCommercialMetrics,
  getBuildingOutputProgrammeMetrics,
} from '../../../store/selectors/buildingOutputSelectors'
import useValidations from '../../../hooks/useValidations'
import WarningModal from '../../../components/modals/WarningModal'
import Skeleton from 'react-loading-skeleton'

const SECTION_ONE = 'gross-cost'
const SECTION_TWO = 'net-cost'
const SECTION_THREE = 'preliminaries'
const SECTION_FOUR = 'programme'
const OUTPUT_SECTION = 'output-section'

const BUILDING_CREATE_SUCCESS_MODAL = 'BUILDING_CREATE_SUCCESS_MODAL'
const WARNING_MODAL = 'WARNING_MODAL'

const Calculator = () => {
  const {
    setBuildingGrossCostRedux,
    setBuildingNetCostRedux,
    setBuildingPreliminariesCostRedux,
    setBuildingProgrammeRedux,
    setBuildingOutputRedux,
  } = useBuilding({
    onBuildingGrossCostChanged: data => {
      const sectionOneCriteria = !!data?.building_gross_cost?.total?.amount &&
        data?.building_gross_cost_breakdown_areas?.reduce((previous, current) => previous + current?.percentage, 0) > 0

      setSectionOneComplete(sectionOneCriteria)
    },
    onBuildingNetCostChanged: data => {
      const sectionTwoCriteria = !!data?.building_net_cost?.total?.amount &&
        data?.building_net_cost_breakdown_areas?.reduce((previous, current) => previous + current?.percentage, 0) > 0

      setSectionTwoComplete(sectionTwoCriteria)
    },
    onBuildingPreliminariesCostChanged: data => {
      const sectionThreeCriteria = !!data?.building_preliminaries_cost?.total?.amount &&
        data?.building_preliminaries_cost_breakdown_areas?.reduce((prev, current) => prev + current?.percentage, 0) > 0

      setSectionThreeComplete(sectionThreeCriteria)
    },
    onBuildingProgrammeChanged: data => {
      const sectionFourCriteria = data?.building_programme?.id &&
        data?.building_programme_breakdown_areas.every(({id}) => id)

      setSectionFourComplete(sectionFourCriteria)
      setTimeout(() => setLoading(false), 500)
    },
  })

  const {
    showValidationErrors,
    validateGrossCostBreakdown,
    validateNetCostBreakdown,
    validatePreliminariesCostBreakdown,
  } = useValidations({loadThresholds: true})

  const [calcActiveKey, setCalcActiveKey] = useState(SECTION_ONE)
  const [loading, setLoading] = useState(true)
  const [onConfirm, setOnConfirm] = useState(null)
  const [sectionOneComplete, setSectionOneComplete] = useState(false)
  const [sectionTwoComplete, setSectionTwoComplete] = useState(false)
  const [sectionThreeComplete, setSectionThreeComplete] = useState(false)
  const [sectionFourComplete, setSectionFourComplete] = useState(false)
  const [show, setShow] = useState(null)
  const [warnings, setWarnings] = useState([])

  const buildingId = useSelector(getBuildingId)
  const buildingName = useSelector(getBuildingName)
  const grossCommercialMetrics = useSelector(getBuildingGrossCommercialMetrics)
  const netCommercialMetrics = useSelector(getBuildingNetCommercialMetrics)
  const programmeMetrics = useSelector(getBuildingOutputProgrammeMetrics)
  const projectId = useSelector(getProjectId)
  const title = useSelector(getProjectTitle)
  const {push} = useHistory()

  useEffect(() => {
    setCalcActiveKey(
      sectionFourComplete
        ? SECTION_ONE
        : sectionThreeComplete
          ? SECTION_FOUR
          : sectionTwoComplete
            ? SECTION_THREE
            : sectionOneComplete
              ? SECTION_TWO
              : SECTION_ONE
    )
  }, [
    sectionOneComplete,
    sectionTwoComplete,
    sectionThreeComplete,
    sectionFourComplete,
  ])

  const toProjects = () => push(`${PROJECTS_URL}/${projectId}`)

  const handleValidation = (validation, confirmCallback) => {
    const {warnings, errors} = validation

    setOnConfirm(null)
    if (errors?.length > 0) {
      showValidationErrors(errors)
    } else if (warnings?.length > 0) {
      setWarnings(warnings)
      setShow(WARNING_MODAL)
      setOnConfirm(() => () => {
        setShow(false)
        confirmCallback()
      })
    } else {
      confirmCallback()
    }
  }

  const handleGrossCostForm = async payload => {
    if (payload) {
      try {
        toggleLoading()
        const validation = await validateGrossCostBreakdown(payload)

        handleValidation(validation, async () => {
          toggleLoading()
          try {
            const grossCostData = await updateBuildingGrossCostBreakdown(projectId, buildingId, payload)
            grossCostData.initial = {...grossCostData}
            setBuildingGrossCostRedux(grossCostData)

            const netCostData = await fetchBuildingNetCostBreakdown(projectId, buildingId)
            netCostData.initial = {...netCostData}
            setBuildingNetCostRedux(netCostData)

            const preliminariesCostData = await fetchBuildingPreliminariesCostBreakdown(projectId, buildingId)
            preliminariesCostData.initial = {...preliminariesCostData}
            setBuildingPreliminariesCostRedux(preliminariesCostData)

            setCalcActiveKey(SECTION_TWO)
            await loadOutput()
          }
          catch (e) {console.error(e)}
          finally {toggleLoading()}
        })
      }
      catch (e) {console.error(e)}
      finally {toggleLoading()}
    } else {
      setCalcActiveKey(SECTION_TWO)
    }
  }

  const handleNetCostForm = async payload => {
    if (payload) {
      try {
        toggleLoading()
        const validation = await validateNetCostBreakdown(payload)

        handleValidation(validation, async () => {
          toggleLoading()
          try {
            const netCostData = await updateBuildingNetCostBreakdown(projectId, buildingId, payload)
            netCostData.initial = {...netCostData}
            setBuildingNetCostRedux(netCostData)

            const preliminariesCostData = await fetchBuildingPreliminariesCostBreakdown(projectId, buildingId)
            preliminariesCostData.initial = {...preliminariesCostData}
            setBuildingPreliminariesCostRedux(preliminariesCostData)

            setCalcActiveKey(SECTION_THREE)
            await loadOutput()
          }
          catch (e) {console.error(e)}
          finally {toggleLoading()}
        })
      }
      catch (e) {console.error(e)}
      finally {toggleLoading()}
    } else {
      setCalcActiveKey(SECTION_THREE)
    }
  }

  const handlePreliminariesCostForm = async payload => {
    if (payload) {
      try {
        toggleLoading()
        const validation = await validatePreliminariesCostBreakdown(payload)

        handleValidation(validation, async () => {
          toggleLoading()
          try {
            const preliminariesCostData = await updateBuildingPreliminariesCostBreakdown(projectId, buildingId, payload)
            preliminariesCostData.initial = {...preliminariesCostData}
            setBuildingPreliminariesCostRedux(preliminariesCostData)

            const buildingProgrammeData = await fetchBuildingProgrammeBreakdown(projectId, buildingId)
            setBuildingProgrammeRedux(buildingProgrammeData)

            setCalcActiveKey(SECTION_FOUR)
            await loadOutput()
          }
          catch (e) {console.error(e)}
          finally {toggleLoading()}
        })
      }
      catch (e) {console.error(e)}
      finally {toggleLoading()}
    } else {
      setCalcActiveKey(SECTION_FOUR)
    }
  }

  const handleBuildingProgrammeForm = async payload => {
    try {
      toggleLoading()
      const buildingProgrammeData = await updateBuildingProgrammeBreakdown(projectId, buildingId, payload)
      setBuildingProgrammeRedux(buildingProgrammeData)

      setCalcActiveKey(null)
      await loadOutput()
    }
    catch (e) {console.error(e)}
    finally {toggleLoading()}
  }

  const loadOutput = async _ => {
    const outputData = await fetchBuildingOutput(projectId, buildingId)
    setBuildingOutputRedux(outputData)
  }

  const calculatorSections = [
    {
      eventKey: SECTION_ONE,
      body: <GrossCostForm onSubmit={handleGrossCostForm} />,
      complete: sectionOneComplete,
      onClick: () => setCalcActiveKey(SECTION_ONE),
      title: localise('accordion.buildingGrossCost'),
    },
    {
      eventKey: SECTION_TWO,
      body: <NetCostForm onSubmit={handleNetCostForm} />,
      complete: sectionTwoComplete,
      disabled: !sectionOneComplete,
      onClick: () => setCalcActiveKey(SECTION_TWO),
      title: localise('accordion.buildingNetCost'),
    },
    {
      eventKey: SECTION_THREE,
      body: <PreliminariesCostForm onSubmit={handlePreliminariesCostForm} />,
      complete: sectionThreeComplete,
      disabled: !sectionTwoComplete,
      onClick: () => setCalcActiveKey(SECTION_THREE),
      title: localise('accordion.buildingPreliminariesCost'),
    },
    {
      eventKey: SECTION_FOUR,
      body: <BuildingProgrammeForm onSubmit={handleBuildingProgrammeForm} />,
      complete: sectionFourComplete,
      disabled: !sectionThreeComplete,
      onClick: () => setCalcActiveKey(SECTION_FOUR),
      title: localise('accordion.buildingProgramme'),
    },
  ]

  const outputSections = [
    {
      body: (
        <BuildingAnalysisSummaryTable
          grossCommercialMetrics={grossCommercialMetrics}
          netCommercialMetrics={netCommercialMetrics}
          programmeMetrics={programmeMetrics}
          onConfirm={() => setShow(BUILDING_CREATE_SUCCESS_MODAL)}
        />
      ),
      complete: false,
      eventKey: OUTPUT_SECTION,
      disabled: !grossCommercialMetrics && !netCommercialMetrics && !programmeMetrics,
      title: localise('headings.buildingAnalysisSummary'),
    },
  ]

  const Modals = () => (
    <>
      <BuildingCreateSuccessModal onConfirm={toProjects} show={show === BUILDING_CREATE_SUCCESS_MODAL} />
      <WarningModal
        onCancel={() => setShow(null)}
        onConfirm={onConfirm}
        show={show === WARNING_MODAL}
        warnings={warnings}
      />
    </>
  )

  return (
    <>
      <BuildingFlow
        activeStep={2}
        breadcrumbs={[
          {
            active: false,
            href: `${PROJECTS_URL}/${projectId}`,
            text: title,
          },
          {
            active: false,
            href: `${PROJECTS_URL}/${projectId}${BUILDINGS_URL}/${buildingId}`,
            text: buildingName,
          },
          {
            active: true,
            text: localise('nav.buildingComponents'),
          },
        ]}>
        <div className='d-grid gap-3'>
          {
            loading
              ? (
                <Skeleton height={80} count={2} className='my-2' />
              )
              : (
                <>
                  <Accordion
                    active={!sectionOneComplete || !sectionTwoComplete || !sectionThreeComplete || !sectionFourComplete}
                    activeKey={calcActiveKey}
                    icon={CALCULATOR_ICON}
                    sections={calculatorSections}
                    title={localise('accordion.calculator')}
                  />
                  <Accordion
                    active={sectionOneComplete && sectionTwoComplete && sectionThreeComplete && sectionFourComplete}
                    activeKey={OUTPUT_SECTION}
                    disabled={!grossCommercialMetrics && !netCommercialMetrics && !programmeMetrics}
                    icon={DOCUMENT_ICON}
                    sections={outputSections}
                    title={localise('accordion.output')}
                  />
                </>
              )
          }
        </div>
      </BuildingFlow>
      <Modals />
    </>
  )
}

export default Calculator

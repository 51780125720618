import React, {useState} from 'react'
import {array, bool, func} from 'prop-types'
import Form from './Form'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {Button} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'

const propTypes = {
  disabled: bool,
  fields: array,
  hideButton: bool,
  onSubmit: func,
  submittable: bool,
}

const defaultProps = {
  disabled: false,
  fields: [],
  hideButton: false,
  onSubmit: null,
  submittable: false,
}

const ConfirmForm = ({disabled, fields, hideButton, onSubmit, submittable}) => {
  const [validated, setValidated] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      onSubmit()
    }
    setValidated(true)
  }

  return (
    <Form disabled={disabled} validated={validated} onSubmit={handleSubmit}>
      {buildForm(fields)}
      <div className='d-grid d-flex justify-content-end mt-2'>
        {
          !hideButton && (
            <Button
              disabled={!submittable}
              type='submit'
              variant='primary'
              className='font-15'>
              {localise('buttonText.confirm')}
            </Button>
          )
        }
      </div>
    </Form>
  )
}

ConfirmForm.propTypes = propTypes
ConfirmForm.defaultProps = defaultProps

export default ConfirmForm

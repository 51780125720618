export const getBuildingProgrammeAreas = state => {
  if (state?.buildingProgramme?.building_programme_breakdown_areas?.length > 0) {
    const sortable = [...state?.buildingProgramme?.building_programme_breakdown_areas]
    return sortable.sort((a, b) => {
      return a?.building_programme_breakdown_area_type?.sort_order >
      b?.building_programme_breakdown_area_type?.sort_order
        ? 1
        : -1
    })
  }
  return []
}

import React from 'react'
import {localise} from '../../services/LocalizationServices'
import {Button} from 'react-bootstrap'
import {useHistory} from 'react-router-dom'
import {AUTH_URL, SIGN_IN_URL} from '../../constants/urls'

const ResetConfirmation = () => {
  const {push} = useHistory()

  const toLogin = () => push(`${AUTH_URL}${SIGN_IN_URL}`)

  return (
    <div className='d-grid gap-3'>
      <div className='font-22 font-bold'>{localise('auth.passwordResetEmailSent')}</div>
      <div className='font-17'>{localise('auth.passwordResetEmailSentMsg')}</div>
      <Button variant='primary' className='font-15 mt-3' size='lg' onClick={toLogin}>
        {localise('buttonText.login')}
      </Button>
    </div>
  )
}

export default ResetConfirmation
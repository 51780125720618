import React, {useEffect, useState} from 'react'
import {localise} from '../../../services/LocalizationServices'
import Form from '../../../components/forms/Form'
import buildForm from '../../../utilities/form-helpers/FormBuilder'
import {func, node} from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {
  getProjectAddress,
  getProjectAddressCity,
  getProjectAddressCountry,
  getProjectAddressCountryCode,
  getProjectAddressLine1,
  getProjectAddressLine2,
  getProjectAddressPostcode,
  getProjectAddressState,
} from '../../../store/selectors/projectSelectors'
import {setProject} from '../../../store/slices/projectSlice'
import {Button} from 'react-bootstrap'
import {getCountries} from '../../../store/selectors/configurationSelectors'

const propTypes = {
  buttons: node,
  isSubmittable: func,
  onSubmit: func,
}

const defaultProps = {
  buttons: null,
  isSubmittable: () => {},
  onSubmit: () => 'on submit',
}

const COUNTRY_SELECT_ID = 'country-select'

const AddressForm = ({buttons, isSubmittable, onSubmit}) => {
  const dispatch = useDispatch()

  const [validated, setValidated] = useState(false)

  const address = useSelector(getProjectAddress)
  const line1 = useSelector(getProjectAddressLine1) || ''
  const line2 = useSelector(getProjectAddressLine2) || ''
  const city = useSelector(getProjectAddressCity) || ''
  const county = useSelector(getProjectAddressState) || ''
  const postcode = useSelector(getProjectAddressPostcode) || ''
  const countryCode = useSelector(getProjectAddressCountryCode) || ''
  const country = useSelector(getProjectAddressCountry) || ''
  const countries = useSelector(getCountries)

  useEffect(() => {
    isSubmittable(
      !!line1 &&
      !!city &&
      !!county &&
      !!postcode &&
      !!countryCode
    )
  }, [city, countryCode, county, isSubmittable, line1, postcode])

  const fields = [
    {
      type: 'text',
      name: 'line1',
      handler: line1 => dispatch(setProject({address:{...address, line1}})),
      label: localise('form.label.addressLine1'),
      required: true,
      validationMessage: localise('form.validation.addressLine1'),
      value: line1,
    },
    {
      type: 'text',
      name: 'line2',
      handler: line2 => dispatch(setProject({address:{...address, line2}})),
      label: localise('form.label.addressLine2'),
      value: line2,
    },
    {
      type: 'text',
      name: 'city',
      handler: city => dispatch(setProject({address:{...address, city}})),
      label: localise('form.label.townCity'),
      required: true,
      validationMessage: localise('form.validation.townCity'),
      value: city,
    },
    {
      type: 'text',
      name: 'county',
      handler: state => dispatch(setProject({address:{...address, state}})),
      label: localise('form.label.countyState'),
      required: true,
      validationMessage: localise('form.validation.countyState'),
      value: county,
    },
    {
      type: 'text',
      name: 'postcode',
      handler: postal_code => dispatch(setProject({address:{...address, postal_code}})),
      label: localise('form.label.postcode'),
      required: true,
      validationMessage: localise('form.validation.postcode'),
      value: postcode,
    },
    {
      type: 'dropdown',
      id: COUNTRY_SELECT_ID,
      classNamePrefix: COUNTRY_SELECT_ID,
      name: 'country',
      label: localise('form.label.country'),
      menuPortalTarget: document.body,
      options: countries,
      placeholder: localise('form.placeholder.country'),
      required: true,
      validationMessage: localise('form.validation.country'),
      value: country && countryCode ? {label: country, value: countryCode} : null,
      handler: event => {
        if (event) {
          dispatch(setProject({address: {...address, country: event.label, country_code: event.value}}))
        }
      },
    },
  ]

  const submitFormData = () => onSubmit(
    {
      line1,
      line2,
      city,
      state: county,
      postal_code: postcode,
      country_code: countryCode,
    }
  )

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      submitFormData()
    }
    setValidated(true)
  }

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      {buildForm(fields)}
      {
        buttons || (
          <Button
            disabled={(
              !line1 &&
              !city &&
              !county &&
              !postcode &&
              !countryCode
            )}
            type='submit'
            variant='primary'
            className='font-15'
            size='lg'>
            {localise('buttonText.update')}
          </Button>
        )
      }
    </Form>
  )
}

AddressForm.propTypes = propTypes
AddressForm.defaultProps = defaultProps

export default AddressForm

import React, {useEffect, useState} from 'react'
import {func} from 'prop-types'
import ConfirmForm from '../../../../components/forms/ConfirmForm'
import {localise} from '../../../../services/LocalizationServices'
import {useDispatch, useSelector} from 'react-redux'
import {
  getBuildingId,
  getBuildingUnitCount,
  getBuildingUnitMix,
} from '../../../../store/selectors/buildingSelectors'
import {setBuilding} from '../../../../store/slices/buildingSlice'
import BuildingTable from '../../../../components/tables/BuildingTable'
import GenerateBreakdownButton from '../../../../components/buttons/GenerateBreakdownButton'
import {generateUnitBreakdown} from '../../../../api/generate-breakdowns/generateBreakdowns'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'

const propTypes = {
  onSubmit: func,
}

const defaultProps = {
  onSubmit: () => {},
}

const UnitsForm = ({onSubmit}) => {
  const dispatch = useDispatch()

  const [fields, setFields] = useState([])
  const [isTableValid, setIsTableValid] = useState(false)
  const [submittable, setSubmittable] = useState(false)
  const [unitsPayload, setUnitsPayload] = useState([])

  const buildingId = useSelector(getBuildingId)
  const unitCount = useSelector(getBuildingUnitCount) || ''
  const unitMix = useSelector(getBuildingUnitMix)

  const doBreakdown = () => {
    toggleLoading()
    const payload = {unit_count: unitCount}
    generateUnitBreakdown(payload, buildingId)
      .then(building_unit_breakdown => {
        setUnitsPayload(building_unit_breakdown?.building_units.map(({building_unit_type: {slug, id}, ...rest}) => (
          {
            ...rest,
            slug,
            building_unit_type: id,
          }
        )))
      })
      .finally(toggleLoading)
  }

  useEffect(() => {
    if (unitMix) setIsTableValid(false)
  }, [unitMix])

  useEffect(() => {
    setSubmittable(!!unitMix ? isTableValid : true)
    if (unitCount <= 0) setSubmittable(false)
  }, [isTableValid, unitCount, unitMix])

  useEffect(() => {
    const handleTableData = ({isValid}) => setIsTableValid(isValid)

    const unconditionalFields = [
      {
        type: 'number',
        handler: unit_count => dispatch(setBuilding({unit_count})),
        hideValidUI: true,
        label: localise('form.label.numberOfUnits'),
        min: 0,
        required: true,
        validationMessage: localise('form.validation.numberOfUnits'),
        value: unitCount,
      },
      {
        type: 'radio-group',
        hideValidUI: true,
        label: localise('form.label.unitMix'),
        name: 'unit-mix',
        radios: [
          {
            checked: unitMix,
            label: localise('form.label.yes'),
            onChange: () => dispatch(setBuilding({unit_mix: true})),
          },
          {
            checked: !unitMix,
            label: localise('form.label.no'),
            onChange: () => dispatch(setBuilding({unit_mix: false})),
          },
        ],
      },
    ]

    const conditionalFields = [
      {
        type: 'custom',
        render: (
          <GenerateBreakdownButton
            disabled={!unitCount}
            message={localise('helpText.generateUnitBreakdownMessage')}
            onClick={doBreakdown}
          />
        ),
      },
      {
        type: 'custom',
        render: (
          <BuildingTable
            data={unitsPayload}
            onChange={handleTableData}
            quantity={unitCount}
            readOnly={false}
          />
        ),
      },
    ]

    setFields(
      !unitMix
        ? unconditionalFields
        : [...unconditionalFields, ...conditionalFields]
    )
    // eslint-disable-next-line
  }, [dispatch, unitCount, unitMix, unitsPayload])

  const submitFormData = () => {
    const buildingUnitBreakdown = {
      unit_count: unitCount,
      unit_mix: !!unitMix,
    }

    if (unitMix && unitsPayload !== null) {
      buildingUnitBreakdown['units'] = unitsPayload.units
    }

    onSubmit({building_unit_breakdown: buildingUnitBreakdown})
  }
  return <ConfirmForm fields={fields} onSubmit={submitFormData} submittable={submittable} />
}

UnitsForm.propTypes = propTypes
UnitsForm.defaultProps = defaultProps

export default UnitsForm

import React from 'react'
import {bool, func, string} from 'prop-types'
import Modal from './Modal'
import {Button} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import Console from '../../utilities/ConsoleUtil'

const propTypes = {
  bodyText: string,
  className: string,
  headerText: string,
  onCancel: func,
  onDelete: func,
  show: bool,
}

const defaultProps = {
  bodyText: '',
  className: '',
  headerText: '',
  onCancel: () => Console.dev('on cancel'),
  onDelete: () => Console.dev('on delete'),
  show: false,
}

const DeleteModal = ({bodyText, className, headerText, onCancel, onDelete, show}) =>
  <Modal
    bodyClasses='mt-3'
    bodyText={bodyText}
    className={className}
    headerText={headerText}
    show={show}
    toggle={onCancel}
    footerElement={<>
      <Button variant='outline' className='font-15 me-2' onClick={onCancel}>
        {localise('buttonText.cancel')}
      </Button>
      <Button variant='primary' className='font-15' onClick={onDelete}>
        {localise('buttonText.delete')}
      </Button>
    </>}
  />


DeleteModal.propTypes = propTypes
DeleteModal.defaultProps = defaultProps

export default DeleteModal

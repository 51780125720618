import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchSurvey = async (id, responseId = null) => {
  const query = responseId ? `?survey_response_id=${responseId}` : ''

  const {survey} = await apiClient.get(`surveys/${id}${query}`).catch(handleError)
  return survey
}

export const fetchSurveyGroup = async (groupId, surveyId, responseId = null) => {
  const {group} = await apiClient.get(`surveys/${surveyId}/groups/${groupId}`, {
    params: {survey_response_id: responseId},
  }).catch(handleError)
  return group
}

export const fetchSurveyGroups = async (id, element = null, responseId = null) => {
  const {groups, meta: {total}} = await apiClient.get(`surveys/${id}/groups`, {
    params: {
      survey_response_id: responseId,
      element,
    },
  }).catch(handleError)
  return {groups, total}
}

export const fetchSurveyQuestions = async (id, groupId, element = null, responseId = null) => {
  const {questions, meta: {total}} = await apiClient.get(`surveys/${id}/groups/${groupId}/questions`, {
    params: {
      survey_response_id: responseId,
      element,
    },
  }).catch(handleError)
  return {questions, total}
}

export const fetchSurveyAnswers = async (id, building_id = null) => {
  const {survey} = await apiClient.get(`projects/${id}/buildings/${building_id}/survey/answers`).catch(handleError)
  return survey
}

export const createSurveyAnswer = async (projectId, buildingId, questionId, value) => {
  const {answer} = await apiClient.post(
    `projects/${projectId}/buildings/${buildingId}/survey/answers`,
    {
      answer: {
        value,
        question_id: questionId,
      },
    }
  ).catch(handleError)

  return answer
}

export const updateSurveyAnswer = async (projectId, buildingId, answerId, value) => {
  const {answer} = await apiClient.put(
    `projects/${projectId}/buildings/${buildingId}/survey/answers/${answerId}`,
    {
      answer: {
        value,
      },
    }
  ).catch(handleError)

  return answer
}

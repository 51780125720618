class Console {
  static dev = (message, type = '') => {
    if (process.env.NODE_ENV === 'development') {
      console.log('%c---- dev ----', 'background: #fff; color: #000')
      switch(type) {
        case 'error':
          console.error(message)
          break
        default:
          console.groupCollapsed(message)
          console.trace()
          console.groupEnd()
          break
      }
      console.log('%c-------------', 'background: #fff; color: #000')
    }
  }
}

export default Console

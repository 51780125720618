import React, {useEffect, useState} from 'react'
import {localise} from '../../../../services/LocalizationServices'
import Buttons from '../common/Buttons'
import {useHistory} from 'react-router-dom'
import {useSelector} from 'react-redux'
import MMCSupplierCreateFlow from '../../../flows/MMCSupplierCreateFlow'
import {PROJECTS_URL} from '../../../../constants/urls'
import SupplierForm from '../form/SupplierForm'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {useParams} from 'react-router'
import {createProjectStagedSupplier} from '../../../../api/project/project'
import useSupplierForm from '../../../../hooks/useSupplierForm'
import {getSelectedSuppliers} from '../../../../store/selectors/selectedSuppliersSelectors'
import StagedSupplierCard from '../../../../components/cards/StagedSupplierCard'
import {MEP} from '../../../../constants/suppliers'

const MechanicalElectricalPlumbing = () => {
  const {push} = useHistory()
  const {slug} = useParams()

  const {
    FIT_OUT_STEP_URL,
    PRELIMINARIES_STEP_URL,
  } = useSupplierForm()

  const selectedSuppliers = useSelector(getSelectedSuppliers)

  const [name, setName] = useState('')
  const [submittable, setSubmittable] = useState(false)

  useEffect(() => {
    const suppliersExist = selectedSuppliers?.filter(({element: selectedElem}) => selectedElem === MEP)?.length > 0
    setSubmittable(suppliersExist || !!name)
  }, [selectedSuppliers, name])

  const toNextStep = () => push(PRELIMINARIES_STEP_URL)


  const handleSubmit = () => {
    if (name) {
      toggleLoading()
      createProjectStagedSupplier(slug, name).then(toNextStep).finally(toggleLoading)
    } else {toNextStep()}
  }

  return (
    <MMCSupplierCreateFlow activeStep={4} title={localise('headings.mechanicalElectricalPlumbing')}>
      <SupplierForm isSubmittable={setSubmittable} element={MEP} />
      <StagedSupplierCard element={MEP} onChange={setName} />
      <Buttons
        continueButtonDisabled={!submittable}
        onCancelClicked={() => push(`${PROJECTS_URL}/${slug}`)}
        onPreviousClicked={() => push(FIT_OUT_STEP_URL)}
        onSubmit={handleSubmit}
        submitText={localise('buttonText.confirm')}
      />
    </MMCSupplierCreateFlow>
  )
}

export default MechanicalElectricalPlumbing

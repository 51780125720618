import React from 'react'
import DetailsForm from '../../form/DetailsForm'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {updateProject} from '../../../../api/project/project'
import {useDispatch, useSelector} from 'react-redux'
import {setProject} from '../../../../store/slices/projectSlice'
import {getProjectId} from '../../../../store/selectors/projectSelectors'
import {toast} from 'react-hot-toast'
import {localise} from '../../../../services/LocalizationServices'
import SideNavView from '../../../common/SideNavView'
import useNav from '../../../../hooks/useNav'
import useProject from '../../../../hooks/useProject'

const Details = () => {
  const dispatch = useDispatch()
  const {SIDE_NAV_PROJECT} = useNav()

  const projectId = useSelector(getProjectId)
  const {editProjectBreadcrumbs: breadcrumbs} = useProject()

  const handleSubmit = ({projectTitle: name}) => {
    toggleLoading()
    updateProject(projectId, {name})
      .then(project => {
        dispatch(setProject(project))
        toast.success(localise('toast.projectSuccessfullyUpdated'))
      })
      .finally(toggleLoading)
  }

  return (
    <SideNavView breadcrumbs={breadcrumbs} navList={SIDE_NAV_PROJECT}>
      <DetailsForm onSubmit={handleSubmit} />
    </SideNavView>
  )
}

export default Details

import React from 'react'
import {Button} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import {useHistory} from 'react-router'
import {ROOT_URL} from '../../constants/urls'

const PageNotFound = () => {
  const {push} = useHistory()

  return (
    <div className='my-5'>
      <div className='text-center p-4'>
        <div className='font-52'>404</div>
        <h3>{localise('error.pageNotFound')}</h3>
      </div>
      <div className='text-center'>
        <Button variant='primary' className='text-uppercase font-16 py-2 my-3' onClick={() => push(ROOT_URL)}>
          {localise('buttonText.goBack')}
        </Button>
      </div>
    </div>
  )
}

export default PageNotFound

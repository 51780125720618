import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {localise, localiseWithHTML} from '../../services/LocalizationServices'
import {AUTH_URL, PROJECTS_URL, SIGN_IN_URL} from '../../constants/urls'
import buildForm from '../../utilities/form-helpers/FormBuilder'
import {Link, useHistory} from 'react-router-dom'
import Separator from '../../components/separator/Separator'
import {useDispatch, useSelector} from 'react-redux'
import Form from '../../components/forms/Form'
import {EMAIL_REGEX} from '../../constants/regex'
import useAuth from '../../hooks/useAuth'
import {toast} from 'react-hot-toast'
import {setCurrentUser} from '../../store/slices/currentUserSlice'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {register} from '../../api/authentication/auth'
import {getTermsURL} from '../../store/selectors/configurationSelectors'
import {fetchUserPreference} from '../../api/user-preference/userPreference'
import {setUserPreference} from '../../store/slices/userPreferenceSlice'

const Register = () => {
  const {MIN_PASSWORD_LENGTH, validatePassword} = useAuth()
  const dispatch = useDispatch()
  const {push} = useHistory()

  const [validated, setValidated] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [organisation, setOrganisation] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailValid, setEmailValid] = useState(true)
  const [passwordValid, setPasswordValid] = useState(true)

  const termsUrl = useSelector(getTermsURL)

  useEffect(() => {
    setEmailValid(true)
    const validationDelay = setTimeout(() => {
      setEmailValid(EMAIL_REGEX.test(email.toLowerCase()))
    }, 500)
    return () => clearTimeout(validationDelay)
  }, [email])

  const fields = [
    {
      type: 'text',
      required: true,
      label: localise('form.label.firstName'),
      placeholder: localise('form.placeholder.firstName'),
      validationMessage: localise('form.validation.firstName'),
      handler: setFirstName,
    },
    {
      type: 'text',
      required: true,
      label: localise('form.label.lastName'),
      placeholder: localise('form.placeholder.lastName'),
      validationMessage: localise('form.validation.lastName'),
      handler: setLastName,
    },
    {
      type: 'text',
      label: localise('form.label.companyUniversity'),
      placeholder: localise('form.placeholder.companyUniversity'),
      handler: setOrganisation,
    },
    {
      type: 'email',
      required: true,
      label: localise('form.label.email'),
      placeholder: localise('form.placeholder.email'),
      validationMessage: localise('form.validation.email'),
      handler: setEmail,
      isInvalid: email && !emailValid,
    },
    {
      type: 'password',
      required: true,
      label: localise('form.label.password'),
      placeholder: localise('form.placeholder.password'),
      validationMessage: localise('validation.label.password'),
      handler: setPassword,
      isInvalid: password && !passwordValid && !validatePassword(password),
      minLength: MIN_PASSWORD_LENGTH,
    },
  ]

  const submitFormData = () => {
    const user = {
      first_name: firstName,
      last_name: lastName,
      organisation,
      password,
      email,
    }

    if (!validatePassword(password)) {
      setPasswordValid(false)
      toast.error(localiseWithHTML('toast.passwordValidationFailed'))
    } else {
      setPasswordValid(true)
      toggleLoading()
      register(user)
        .then(async user => {
          dispatch(setCurrentUser(user))
          const user_preference = await fetchUserPreference()
          if (user_preference) dispatch(setUserPreference(user_preference))
          push(PROJECTS_URL)
        })
        .finally(toggleLoading)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget
    if (!form.checkValidity()) {
      e.stopPropagation()
    } else {
      submitFormData()
    }
    setValidated(true)
  }

  return (
    <Form validated={validated} onSubmit={handleSubmit}>
      <div className='font-22 font-bold mb-3'>{localise('headings.createAccount')}</div>
      {buildForm(fields)}
      <div className='font-grey font-15 my-3'>{localiseWithHTML('auth.passwordCriteria')}</div>
      <Button type='submit' variant='primary' className='font-15' size='lg'>
        {localise('buttonText.signUp')}
      </Button>
      <div className='font-15 mt-3'>
        {
          localiseWithHTML('auth.signUpDisclaimer', {termsUrl})
        }
      </div>
      <Separator className='my-2' />
      <div className='font-15 font-bold text-center'>
        {localise('auth.haveAccount')}
        <Link to={`${AUTH_URL}${SIGN_IN_URL}`} className='ms-1'>
          {localise('buttonText.signIn')}
        </Link>
      </div>
    </Form>
  )
}

export default Register
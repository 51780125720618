import React from 'react'
import {Route, Switch} from 'react-router-dom'
import RouteWithLayout from './RouteWithLayout'
import MMCSuppliers from '../views/mmc-supplier/MMCSuppliers'
import MMCSupplierDetails from '../views/mmc-supplier/MMCSupplierDetails'
import PageNotFound from '../views/error/PageNotFound'

const MMCSupplierRoutes = ({path}) => {
  return (
    <Switch>
      <RouteWithLayout exact path={path} component={MMCSuppliers} />
      <RouteWithLayout exact path={`${path}/:slug`} component={MMCSupplierDetails} />
      <Route path='*'><PageNotFound /></Route>
    </Switch>
  )
}

export default MMCSupplierRoutes
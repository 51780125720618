import React from 'react'
import {string} from 'prop-types'
import {LOGS_PLACEHOLDER} from '../../constants/svgs'
import {localise} from '../../services/LocalizationServices'
import Placeholder from './Placeholder'

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

const LogsPlaceholder = ({className}) =>
  <Placeholder
    alt={localise('alt.logs')}
    className={className}
    description={localise('placeholder.noLogsCreatedDesc')}
    src={LOGS_PLACEHOLDER}
    title={localise('placeholder.noLogsCreated')}
  />

LogsPlaceholder.propTypes = propTypes
LogsPlaceholder.defaultProps = defaultProps

export default LogsPlaceholder

import React, {useEffect, useState} from 'react'
import {arrayOf, bool, func, node, object, shape, string} from 'prop-types'
import {Accordion as BSAccordion} from 'react-bootstrap'
import IconHeader from '../headings/IconHeader'

const propTypes = {
  activeKey: string,
  className: string,
  items: arrayOf(shape({
    body: node,
    complete: bool,
    disabled: bool,
    eventKey: string,
    headerProps: object,
    onClick: func,
  })),
}

const defaultProps = {
  activeKey: null,
  className: '',
  items: [],
}

const BodyAccordion = ({activeKey: passedActiveKey, className, items}) => {
  const [activeKey, setActiveKey] = useState(null)

  useEffect(() => {
    setActiveKey(passedActiveKey)
  }, [passedActiveKey])

  return (
    <BSAccordion flush className={`body-accordion ${className}`} activeKey={activeKey}>
      {
        items?.map(({body, complete, disabled, headerProps, eventKey, onClick}, index) => (
          <BSAccordion.Item
            eventKey={eventKey}
            className={disabled ? 'disabled' : ''}
            key={index}>
            <BSAccordion.Header onClick={() => {
              if (!disabled) {
                setActiveKey(eventKey !== activeKey ? passedActiveKey : null)
                if (onClick) onClick()
              }
            }}>
              <IconHeader value={index + 1} {...headerProps} />
            </BSAccordion.Header>
            { !disabled && <BSAccordion.Body>{body}</BSAccordion.Body> }
          </BSAccordion.Item>
        ))
      }
    </BSAccordion>
  )
}

BodyAccordion.propTypes = propTypes
BodyAccordion.defaultProps = defaultProps

export default BodyAccordion

import Console from '../utilities/ConsoleUtil'

const useChart = () => {
  const isChartDataArrayEmpty = data => {
    try {
      const numbers = data.map(item => item[1])
      return numbers.reduce((prev, curr) => prev + curr) === 0
    } catch (e) {
      Console.dev(e)
      return true
    }
  }

  return {
    isChartDataArrayEmpty,
  }
}

export default useChart

import {createSlice} from '@reduxjs/toolkit'

const buildingPreliminariesCostSlice = createSlice({
  name: 'buildingPreliminariesCost',
  initialState: {},
  reducers: {
    setBuildingPreliminariesCost: (state, action) => {
      action?.payload && Object.keys(action.payload).map(key => state[key] = action.payload[key])
    },
    clearBuildingPreliminariesCost: state => {
      Object.keys(state).map(key => delete state[key])
    },
  },
})

export const buildingPreliminariesCostReducer = buildingPreliminariesCostSlice.reducer
export const {clearBuildingPreliminariesCost, setBuildingPreliminariesCost} = buildingPreliminariesCostSlice.actions

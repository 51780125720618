import React from 'react'
import {func} from 'prop-types'
import ConfirmForm from '../../../../components/forms/ConfirmForm'
import {localise} from '../../../../services/LocalizationServices'
import {setBuildingGrossCost} from '../../../../store/slices/buildingGrossCostSlice'
import {useDispatch, useSelector} from 'react-redux'
import {
  getBuildingGrossCostAreas,
  getBuildingGrossCostTotal,
  getInitialBuildingGrossCostAreas,
  getInitialBuildingGrossCostTotal,
} from '../../../../store/selectors/buildingGrossCostSelectors'
import GenerateBreakdownButton from '../../../../components/buttons/GenerateBreakdownButton'
import SliderDistributionTable from '../../../../components/tables/SliderDistributionTable'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {generateGrossCostBreakdown} from '../../../../api/generate-breakdowns/generateBreakdowns'
import {getCurrentUserCurrencySymbol} from '../../../../store/selectors/currentUserSelectors'
import {getBuildingId} from '../../../../store/selectors/buildingSelectors'
import {
  getOverheadAndProfitThreshold,
  getPreliminariesThreshold,
  getRiskThreshold,
} from '../../../../store/selectors/thresholdsSelectors'

const propTypes = {
  onSubmit: func,
}

const defaultProps = {
  onSubmit: () => {},
}

const RISK_SLUG = 'risk'
const OVERHEAD_AND_PROFIT_SLUG = 'overhead_and_profit'
const PRELIMINARIES_SLUG = 'preliminaries'
const NET_CONSTRUCTION_COST_SLUG = 'net_construction_cost'

const GrossCostForm = ({onSubmit}) => {
  const dispatch = useDispatch()

  const areas = useSelector(getBuildingGrossCostAreas) || []
  const buildingId = useSelector(getBuildingId)
  const currencySymbol = useSelector(getCurrentUserCurrencySymbol)
  const grossCostTotal = useSelector(getBuildingGrossCostTotal)
  const riskThreshold = useSelector(getRiskThreshold)
  const overheadAndProfitThreshold = useSelector(getOverheadAndProfitThreshold)
  const preliminariesThreshold = useSelector(getPreliminariesThreshold)
  const initialGrossCostTotal = useSelector(getInitialBuildingGrossCostTotal)
  const initialAreas = useSelector(getInitialBuildingGrossCostAreas)

  const setGrossCostBreakdownRedux = payload => dispatch(setBuildingGrossCost(payload))

  const generateBreakdown = () => {
    toggleLoading()
    generateGrossCostBreakdown(grossCostTotal * 100, buildingId)
      .then(setGrossCostBreakdownRedux)
      .finally(toggleLoading)
  }

  const handleChange = values => {
    setGrossCostBreakdownRedux({
      building_gross_cost_breakdown_areas: areas.map(area => ({
        ...area,
        percentage: values[area?.building_gross_cost_breakdown_area_type?.slug]?.value,
      })),
    })
  }

  const renderAreas = () => areas.map(area => (
    {
      label: area?.building_gross_cost_breakdown_area_type?.name,
      name: area?.building_gross_cost_breakdown_area_type?.slug,
      auto: area?.building_gross_cost_breakdown_area_type?.slug === NET_CONSTRUCTION_COST_SLUG,
      value: area?.percentage,
    }
  ))

  const fields = [
    {
      type: 'number',
      hideValidUI: true,
      label: localise('form.label.grossConstructionCost'),
      validationMessage: localise('form.validation.grossConstructionCost'),
      prepend: currencySymbol,
      required: true,
      min: 0,
      step: '.01',
      value: grossCostTotal,
      handler: cost => setGrossCostBreakdownRedux({
        building_gross_cost: {
          total: {amount: parseFloat(cost).toFixed(2) * 100},
        },
      }),
    },
    {
      type: 'custom',
      className: 'my-2',
      render: (
        <GenerateBreakdownButton
          message={localise('helpText.generateGrossConstructionCostBreakdownMessage')}
          onClick={generateBreakdown}
        />
      ),
    },
    {
      type: 'custom',
      render: (
        <SliderDistributionTable
          currentValPrefix={currencySymbol}
          data={renderAreas()}
          maxTotal={parseFloat(grossCostTotal).toFixed(2)}
          onChange={handleChange}
          validations={{
            [RISK_SLUG]: {
              threshold: riskThreshold,
              errMsg: localise('modalText.riskError', {percent: riskThreshold}),
            },
            [OVERHEAD_AND_PROFIT_SLUG]: {
              threshold: overheadAndProfitThreshold,
              errMsg: localise('modalText.overheadAndProfitError', {percent: overheadAndProfitThreshold}),
            },
            [PRELIMINARIES_SLUG]: {
              threshold: preliminariesThreshold,
              errMsg: localise('modalText.preliminariesError', {percent: preliminariesThreshold}),
            },
          }}
        />
      ),
    },
  ]

  const submitFormData = () => {
    let dirty = false

    if (grossCostTotal !== initialGrossCostTotal) dirty = true

    areas.forEach(area => {
      const newPercentage = area?.percentage
      const initPercentage = initialAreas.filter(initArea => initArea?.name === area?.name)[0]?.percentage

      if (newPercentage !== initPercentage) dirty = true
    })

    onSubmit(
      dirty
        ? {
          building_gross_cost_breakdown: {
            building_gross_cost: {
              total_cents: grossCostTotal * 100,
            },
            building_gross_cost_breakdown_areas: areas.map(area => ({
              amount_cents: Math.round((grossCostTotal * (area?.percentage / 100)) * 100),
              percentage: area?.percentage,
              building_gross_cost_breakdown_area_type: area?.building_gross_cost_breakdown_area_type?.slug,
            })),
          },
        }
        : null
    )
  }

  const submittable = !!grossCostTotal && areas.reduce((previous, current) => previous + current?.percentage, 0) > 0

  return <ConfirmForm fields={fields} onSubmit={submitFormData} submittable={submittable} />
}

GrossCostForm.propTypes = propTypes
GrossCostForm.defaultProps = defaultProps

export default GrossCostForm

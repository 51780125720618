import React from 'react'
import {bool, func, string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import {Button} from 'react-bootstrap'

const propTypes = {
  buttonText: string,
  className: string,
  disabled: bool,
  message: string,
  onClick: func,
}

const defaultProps = {
  buttonText: null,
  className: '',
  disabled: false,
  message: '',
  onClick: null,
}

const GenerateBreakdownButton = ({buttonText, className, disabled, message, onClick}) => {
  return (
    <div className={`font-13 ${className}`}>
      <div className='font-bold'>{localise('headings.myPMVWizard')}</div>
      <div className='font-grey'>{message}</div>
      <Button
        variant='primary'
        className='font-15 mt-2'
        disabled={disabled}
        onClick={onClick}>
        {buttonText || localise('buttonText.generateBreakdown')}
      </Button>
    </div>
  )
}

GenerateBreakdownButton.propTypes = propTypes
GenerateBreakdownButton.defaultProps = defaultProps

export default GenerateBreakdownButton

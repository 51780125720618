import React, {useEffect, useState} from 'react'
import {array, func, string} from 'prop-types'
import SurveyForm from '../../../components/forms/SurveyForm'
import {
  createSurveyAnswer,
  fetchSurveyGroup,
  updateSurveyAnswer,
} from '../../../api/surveys/surveys'
import {useSelector} from 'react-redux'
import {
  getBuildingId,
  getBuildingProjectId,
  getBuildingSurveyId,
  getBuildingSurveyResponseId,
} from '../../../store/selectors/buildingSelectors'
import {SURVEY_QUESTION_ANSWER_TYPE_DEFAULT_SLUG} from '../../../constants/survey'

const propTypes = {
  groupId: string,
  onAnswered: func,
  questions: array,
}

const defaultProps = {
  groupId: '',
  onAnswered: null,
  questions: [],
}

const Survey = ({groupId, onAnswered, questions: passedQuestions}) => {
  const [loading, setLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const [disabled, setDisabled] = useState(false)

  const projectId = useSelector(getBuildingProjectId)
  const buildingId = useSelector(getBuildingId)
  const surveyId = useSelector(getBuildingSurveyId)
  const surveyResponseId = useSelector(getBuildingSurveyResponseId)

  useEffect(() => {
    setQuestions(passedQuestions)
  }, [passedQuestions])

  const handleAnswers = async (answers, updated) => {
    setLoading(true)
    setDisabled(true)

    const requests = Object.entries(answers)
      // Sort the answers as they appear in view
      .sort(([a, first], [b, second]) => (
        first?.sort_order < second?.sort_order ? -1 : 1
      ))
      // Filter out any questions that have not had their answer changed
      // Or do not have an answer type of 'default'
      .filter(([questionId, {answerType, value}]) => {
        if (answerType === SURVEY_QUESTION_ANSWER_TYPE_DEFAULT_SLUG) return true
        const question = questions.find(question => question?.id === questionId)
        return value !== question?.survey_answer?.value
      })
      // Format answers for the loop that sends the requests
      .map(([questionId, {answerId, value}]) => (
        {
          method: answerId ? updateSurveyAnswer : createSurveyAnswer,
          id: answerId ? answerId : questionId,
          value,
        }
      ))


    let err
    for (let request of requests) {
      const {
        id,
        method,
        value,
      } = request

      try {await method(projectId, buildingId, id, value)}
      catch (e) {
        setLoading(false)
        setDisabled(false)
        err = true
        break
      }
    }

    if (!err) {
      if (updated) {
        onAnswered(updated)
        setLoading(false)
        setDisabled(false)
      } else {
        fetchSurveyGroup(groupId, surveyId, surveyResponseId)
          .then(({complete, questions}) => {
            if (complete) return onAnswered(updated)
            setQuestions(questions)
          })
          .finally(() => {
            setLoading(false)
            setDisabled(false)
          })
      }
    }
  }

  return (
    <SurveyForm
      disabled={disabled}
      loading={loading}
      onConfirm={handleAnswers}
      questions={questions}
    />
  )
}

Survey.propTypes = propTypes
Survey.defaultProps = defaultProps

export default Survey

import React from 'react'
import {bool, func, string} from 'prop-types'
import {Button, ButtonGroup} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import styled from 'styled-components'
import {GREY} from '../../constants/colours'

const propTypes = {
  className: string,
  onChange: func,
  value: bool,
}

const defaultProps = {
  className: '',
  onChange: () => {},
  value: null,
}

const YesButton = styled(Button)`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right-color: ${GREY} !important;
  padding: 2px 10px;
`

const NoButton = styled(Button)`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left-color: ${GREY} !important;
  padding: 2px 10px;
`

const Switch = ({className, onChange, value}) => {
  return (
    <ButtonGroup className={className}>
      <YesButton
        variant={value === null || value === undefined || value === false ? 'neutral' : 'black'}
        className={`font-12 ${value === true ? 'no-hover' : ''}`}
        onClick={() => onChange(true)}>
        {localise('buttonText.yes')}
      </YesButton>
      <NoButton
        variant={value === null || value === undefined || value === true ? 'neutral' : 'black'}
        className={`font-12 ${value === false ? 'no-hover' : ''}`}
        onClick={() => onChange(false)}>
        {localise('buttonText.no')}
      </NoButton>
    </ButtonGroup>
  )
}

Switch.propTypes = propTypes
Switch.defaultProps = defaultProps

export default Switch
import React, {useEffect, useState} from 'react'
import {localise} from '../../../../services/LocalizationServices'
import Buttons from '../common/Buttons'
import {useHistory} from 'react-router-dom'
import MMCSupplierCreateFlow from '../../../flows/MMCSupplierCreateFlow'
import {PROJECTS_URL} from '../../../../constants/urls'
import SupplierForm from '../form/SupplierForm'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {useParams} from 'react-router'
import {createProjectStagedSupplier} from '../../../../api/project/project'
import useSupplierForm from '../../../../hooks/useSupplierForm'
import StagedSupplierCard from '../../../../components/cards/StagedSupplierCard'
import {useSelector} from 'react-redux'
import {getSelectedSuppliers} from '../../../../store/selectors/selectedSuppliersSelectors'
import {SUBSTRUCTURE} from '../../../../constants/suppliers'

const Substructure = () => {
  const {push} = useHistory()
  const {slug} = useParams()
  const {SUPERSTRUCTURE_STEP_URL} = useSupplierForm()
  const selectedSuppliers = useSelector(getSelectedSuppliers)

  const [name, setName] = useState('')
  const [submittable, setSubmittable] = useState(false)

  useEffect(() => {
    const suppliersExist =
      selectedSuppliers?.filter(({element: selectedElem}) => selectedElem === SUBSTRUCTURE)?.length > 0
    setSubmittable(suppliersExist || !!name)
  }, [selectedSuppliers, name])

  const toNextStep = () => push(SUPERSTRUCTURE_STEP_URL)

  const handleSubmit = () => {
    if (name) {
      toggleLoading()
      createProjectStagedSupplier(slug, name)
        .then(toNextStep)
        .finally(toggleLoading)
    } else {toNextStep()}
  }

  return (
    <>
      <MMCSupplierCreateFlow activeStep={1} title={localise('headings.substructure')}>
        <SupplierForm isSubmittable={setSubmittable} element={SUBSTRUCTURE} />
        <StagedSupplierCard element={SUBSTRUCTURE} onChange={setName} />
        <Buttons
          continueButtonDisabled={!submittable}
          onCancelClicked={() => push(`${PROJECTS_URL}/${slug}`)}
          previousButtonHidden
          onSubmit={handleSubmit}
          submitText={localise('buttonText.confirm')}
        />
      </MMCSupplierCreateFlow>
    </>
  )
}

export default Substructure

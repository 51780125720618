import React from 'react'
import {array, string} from 'prop-types'
import {Nav} from 'react-bootstrap'
import {localise} from '../../services/LocalizationServices'
import {v4} from 'uuid'
import {useLocation, withRouter} from 'react-router-dom'
import {useHistory} from 'react-router'
import Skeleton from 'react-loading-skeleton'

const propTypes = {
  className: string,
  navList: array.isRequired,
}

const defaultProps = {
  className: '',
  navList: [],
}

const SideNav = ({className, navList}) => {
  const {pathname} = useLocation()
  const {push} = useHistory()
  const isActive = activeViews => {
    const parsedUrl = pathname.toLowerCase().replace(/\s/g, '')
    return activeViews?.some(view => parsedUrl.includes(view.toLowerCase().replace(/\s/g, '')))
  }

  return (
    <nav className={`side-nav-container d-inline-block box-shadow mb-2 ${className}`}>
      <ul>
        {
          navList?.length > 0 ?
            navList.map(({activeViews, url, translationKey}) =>
              <li key={v4()}>
                <Nav.Link
                  className={`p-3 ${isActive(activeViews) ? 'active' : ''} font-17 border-bottom`}
                  onClick={() => push(url)}>
                  {localise(translationKey)}
                </Nav.Link>
              </li>
            ) : <Skeleton count={3} className='my-2 ms-3' height={20} width={177} />
        }
      </ul>
    </nav>
  )
}

SideNav.propTypes = propTypes
SideNav.defaultProps = defaultProps

export default withRouter(SideNav)

export const getBuildingApplyWallToFloor = state => state?.building?.applyWallToFloor
export const getBuildingGrossInternalArea = state => state?.building?.gross_internal_area
export const getBuildingHouseTypeId = state => state?.building?.house_type?.id
export const getBuildingHouseTypeName = state => state?.building?.house_type?.name
export const getBuildingId = state => state?.building?.id
export const getBuildingName = state => state?.building?.name
export const getBuildingNetInternalArea = state => state?.building?.net_internal_area
export const getBuildingNumberOfBedroomsId = state => state?.building?.building_bedroom_range?.id
export const getBuildingNumberOfBedroomsName = state => state?.building?.building_bedroom_range?.name
export const getBuildingPhaseCountDisplay = state => state?.building?.phase_count_display
export const getBuildingPhaseDisplay = state => state?.building?.phase_display
export const getBuildingProjectId = state => state?.building?.project_id
export const getBuildingSectorId = state => state?.building?.building_sector?.id
export const getBuildingSectorName = state => state?.building?.building_sector?.name
export const getBuildingSectorSlug = state => state?.building?.building_sector?.slug
export const getBuildingSetupComplete = state => state?.building?.setup?.complete
export const getBuildingStatusColorRGBA = state => state?.building?.status_color_rgba
export const getBuildingStatusDisplay = state => state?.building?.status_display
export const getBuildingStepCountDisplay = state => state?.building?.step_count_display
export const getBuildingSurveyId = state => state?.building?.survey_id
export const getBuildingSurveyResponseId = state => state?.building?.survey_response_id
export const getBuildingTypeId = state => state?.building?.building_type?.id
export const getBuildingTypeName = state => state?.building?.building_type?.name
export const getBuildingTypeSlug = state => state?.building?.building_type?.slug
export const getBuildingUnitBreakdown = state => state?.building?.unit_breakdown
export const getBuildingUnitBreakdownBuildingUnits = state => state?.building?.unit_breakdown?.building_units
export const getBuildingUnitCount = state => {
  const parsedIntUnitCount = parseInt(state?.building?.unit_count)
  if (typeof parsedIntUnitCount === 'number') return parsedIntUnitCount
  return 0
}

export const getBuildingUnitMix = state =>
  typeof state?.building?.unit_mix === 'boolean' ? state?.building?.unit_mix : false
export const getBuildingWallToFloorRatio = state => state?.building?.wall_to_floor_ratio_percentage
export const getHouseGrossNetInternalArea = state => state?.building?.house_gia_nia
export const getHouseTotalGrossInternalArea = state => state?.building?.total_gross_internal_area

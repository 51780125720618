import getIndexParams from '../../utilities/api-helpers/getIndexParams'
import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchBuildings = async (id, opts) => {
  const params = getIndexParams(opts)

  const {buildings, meta: {total}} = await apiClient.get(`projects/${id}/buildings`, {params}).catch(handleError)
  return {buildings, total}
}

export const fetchBuildingOutput = async (id, building_id) => {
  const {
    building_output,
  } = await apiClient.get(`/projects/${id}/buildings/${building_id}/output`).catch(handleError)
  return building_output
}

const buildingAction = async (action, url, data) => {
  const {building} = await apiClient[action](url, data).catch(handleError)
  return building
}

export const fetchBuilding = (projectId, id) => buildingAction(
  'get',
  `projects/${projectId}/buildings/${id}`
)

export const createBuilding = (projectId, building) => buildingAction(
  'post',
  `projects/${projectId}/buildings`,
  {building}
)

export const updateBuilding = (projectId, id, building) => buildingAction(
  'put',
  `projects/${projectId}/buildings/${id}`,
  {building}
)

export const duplicateBuilding = (projectId, id) => buildingAction(
  'post',
  `projects/${projectId}/buildings/${id}/duplicate`
)

export const deleteBuilding = async (projectId, id) => {
  const {destroyed} = await apiClient.delete(`projects/${projectId}/buildings/${id}`).catch(handleError)
  return destroyed
}

const buildingGrossCostBreakdownAction =  async (action, url, data) => {
  const {
    building_gross_cost_breakdown,
  } = await apiClient[action](url, data).catch(handleError)
  return building_gross_cost_breakdown
}

export const fetchBuildingGrossCostBreakdown = async (projectId, id) => buildingGrossCostBreakdownAction(
  'get',
  `projects/${projectId}/buildings/${id}/gross_cost_breakdown`
)

export const updateBuildingGrossCostBreakdown = async (projectId, id, payload) => buildingGrossCostBreakdownAction(
  'put',
  `projects/${projectId}/buildings/${id}/gross_cost_breakdown`,
  payload
)

const buildingNetCostBreakdownAction =  async (action, url, data) => {
  const {
    building_net_cost_breakdown,
  } = await apiClient[action](url, data).catch(handleError)
  return building_net_cost_breakdown
}

export const fetchBuildingNetCostBreakdown = async (projectId, id) => buildingNetCostBreakdownAction(
  'get',
  `projects/${projectId}/buildings/${id}/net_cost_breakdown`
)

export const updateBuildingNetCostBreakdown = async (projectId, id, payload) => buildingNetCostBreakdownAction(
  'put',
  `projects/${projectId}/buildings/${id}/net_cost_breakdown`,
  payload
)

const buildingPreliminariesCostBreakdownAction =  async (action, url, data) => {
  const {
    building_preliminaries_cost_breakdown,
  } = await apiClient[action](url, data).catch(handleError)
  return building_preliminaries_cost_breakdown
}

export const fetchBuildingPreliminariesCostBreakdown = async (projectId, id) =>
  buildingPreliminariesCostBreakdownAction(
    'get',
  `projects/${projectId}/buildings/${id}/preliminaries_cost_breakdown`
  )

export const updateBuildingPreliminariesCostBreakdown = async (projectId, id, payload) =>
  buildingPreliminariesCostBreakdownAction(
    'put',
    `projects/${projectId}/buildings/${id}/preliminaries_cost_breakdown`,
    payload
  )


const buildingProgrammeBreakdownAction =  async (action, url, data) => {
  const {building_programme_breakdown} = await apiClient[action](url, data).catch(handleError)
  return building_programme_breakdown
}

export const fetchBuildingProgrammeBreakdown = async (projectId, id) => buildingProgrammeBreakdownAction(
  'get',
  `projects/${projectId}/buildings/${id}/programme_breakdown`
)

export const updateBuildingProgrammeBreakdown = async (projectId, id, payload) =>
  buildingProgrammeBreakdownAction(
    'put',
    `projects/${projectId}/buildings/${id}/programme_breakdown`,
    payload
  )

export const fetchBuildingUnitBreakdown = async (projectId, id) => {
  const {
    building_unit_breakdown,
  } = await apiClient.get(`projects/${projectId}/buildings/${id}/unit_breakdown`).catch(handleError)
  return building_unit_breakdown
}

export const updateBuildingUnitBreakdown = async (projectId, id, payload) => {
  const {
    building_unit_breakdown,
  } = await apiClient.put(`projects/${projectId}/buildings/${id}/unit_breakdown`, payload).catch(handleError)
  return building_unit_breakdown
}

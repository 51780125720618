import React from 'react'
import {array, node, string} from 'prop-types'
import {Col, Row} from 'react-bootstrap'
import BreadCrumbs from '../../components/bread-crumbs/BreadCrumbs'

const propTypes = {
  breadcrumbItems: array,
  buttons: node,
  children: node,
  className: string,
}

const defaultProps = {
  breadcrumbItems: [],
  buttons: null,
  children: null,
  className: '',
}

const BreadCrumbsView = props => {
  const {
    breadcrumbItems,
    buttons,
    children,
    className,
  } = props

  return (
    <Row className={`breadcrumbs-view-container mt-3 gx-5 ${className}`}>
      <Col>
        <Row>
          <Col className='align-self-center'><BreadCrumbs breadcrumbItems={breadcrumbItems} /></Col>
          {buttons && <Col className='col-auto'>{buttons}</Col>}
        </Row>

        <Row>
          <Col className='py-3'>
            {children}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

BreadCrumbsView.propTypes = propTypes
BreadCrumbsView.defaultProps = defaultProps

export default BreadCrumbsView

import {
  ACCOUNT_URL,
  ADDRESS_URL,
  DETAILS_URL,
  EDIT_URL,
  FUNDING_URL,
  HELPFUL_LINKS_URL,
  MAP_VIEW_URL,
  MMC_SUPPLIERS_URL,
  PASSWORD_URL,
  PREFERENCES_URL,
  PROJECTS_URL,
  SUBSCRIPTIONS_URL,
} from '../constants/urls'
import {useSelector} from 'react-redux'
import {isLoading} from '../store/selectors/loadingSelectors'
import {getCurrentUserPaymentsOverview} from '../store/selectors/currentUserSelectors'

const SIDE_NAV_PROJECT = [
  {url: `${PROJECTS_URL}${EDIT_URL}${DETAILS_URL}`, translationKey: 'nav.projectDetails', activeViews: ['details']},
  {url: `${PROJECTS_URL}${EDIT_URL}${ADDRESS_URL}`, translationKey: 'nav.projectAddress', activeViews: ['address']},
  {url: `${PROJECTS_URL}${EDIT_URL}${FUNDING_URL}`, translationKey: 'nav.projectFunding', activeViews: ['funding']},
]

const useNav = () => {
  const loading = useSelector(isLoading)
  const paymentsOverview = useSelector(getCurrentUserPaymentsOverview)

  const isFreeTier =
    paymentsOverview?.allowances?.subscription?.total === 0 &&
    paymentsOverview?.allowances?.purchased?.total === 0

  const subscriptionsLinkAvailable = !isFreeTier && !loading

  const SIDE_NAV_ACCOUNT = [
    {url: `${ACCOUNT_URL}${DETAILS_URL}`, translationKey: 'nav.myDetails', activeViews: ['details']},
    {url: `${ACCOUNT_URL}${PASSWORD_URL}`, translationKey: 'nav.myPassword', activeViews: ['password']},
    {url: `${ACCOUNT_URL}${PREFERENCES_URL}`, translationKey: 'nav.myPreferences', activeViews: ['preferences']},
    ...(subscriptionsLinkAvailable ? [
      {
        url: `${ACCOUNT_URL}${SUBSCRIPTIONS_URL}`,
        translationKey: 'nav.mySubscription',
        activeViews: ['subscriptions'],
      },
    ]: []),
  ]
  const links = {
    projects: {
      to: PROJECTS_URL,
      translationKey: 'nav.projects',
      activeViews: ['projects'],
    },
    mmcSuppliers: {
      to: MMC_SUPPLIERS_URL,
      translationKey: 'nav.mmcSuppliers',
      activeViews: ['mmc-suppliers'],
    },
    helpfulLinks: {
      to: HELPFUL_LINKS_URL,
      translationKey: 'nav.helpfulLinks',
      activeViews: ['helpful-links'],
    },
    mapView: {
      to: MAP_VIEW_URL,
      translationKey: 'nav.mapView',
      activeViews: ['map'],
    },
    myAccount: {
      to: `${ACCOUNT_URL}${DETAILS_URL}`,
      translationKey: 'nav.myAccount',
      activeViews: ['account'],
    },
    myDetails: {
      to: `${ACCOUNT_URL}${DETAILS_URL}`,
      translationKey: 'nav.myDetails',
      activeViews: ['details'],
    },
    password: {
      to: `${ACCOUNT_URL}${PASSWORD_URL}`,
      translationKey: 'nav.myPassword',
      activeViews: ['password'],
    },
    preferences: {
      to: `${ACCOUNT_URL}${PREFERENCES_URL}`,
      translationKey: 'nav.myPreferences',
      activeViews: ['preferences'],
    },
    subscriptions: {
      to: `${ACCOUNT_URL}${SUBSCRIPTIONS_URL}`,
      translationKey: 'nav.mySubscription',
      activeViews: ['subscriptions'],
    },
    details: {
      to: `${PROJECTS_URL}${EDIT_URL}${DETAILS_URL}`,
      translationKey: 'nav.projectDetails',
      activeViews: ['details'],
    },
    address: {
      to: `${PROJECTS_URL}${EDIT_URL}${ADDRESS_URL}`,
      translationKey: 'nav.projectAddress',
      activeViews: ['address'],
    },
    funding: {
      to: `${PROJECTS_URL}${EDIT_URL}${FUNDING_URL}`,
      translationKey: 'nav.projectFunding',
      activeViews: ['funding'],
    },
  }

  const isActiveLink = views => {
    const pathname = window.location.href

    const parsedUrl = pathname.toLowerCase().replace(/\s/g, '')
    return views.some(view => parsedUrl.includes(view.toLowerCase().replace(/\s/g, '')))
  }

  return {
    SIDE_NAV_ACCOUNT,
    SIDE_NAV_PROJECT,
    breakpoint: 'lg',
    isActiveLink,
    isFreeTier,
    links,
    subscriptionsLinkAvailable,
  }
}

export default useNav

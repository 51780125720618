import React from 'react'
import {bool, func, node, string} from 'prop-types'
import {Form as BSForm} from 'react-bootstrap'

const propTypes = {
  children: node,
  className: string,
  disabled: bool,
  onSubmit: func,
  validated: bool,
}

const defaultProps = {
  children: null,
  className: '',
  disabled: false,
  onSubmit: () => {},
  validated: false,
}

const Form = ({children, className, disabled, onSubmit, validated}) =>
  <BSForm noValidate validated={validated} onSubmit={onSubmit} className={className}>
    <fieldset disabled={disabled} className='d-grid gap-2'>
      {children}
    </fieldset>
  </BSForm>


Form.propTypes = propTypes
Form.defaultProps = defaultProps

export default Form

import React from 'react'
import {array, bool, func, number, object, oneOfType, shape, string} from 'prop-types'
import Card from './Card'
import Separator from '../separator/Separator'
import {localise} from '../../services/LocalizationServices'
import IconButton from '../buttons/IconButton'
import PMVRating from '../headings/PMVRating'
import MMCCategories from '../headings/MMCCategories'
import {DateTime} from 'luxon'
import {EUROPEAN_DATE_FORMAT} from '../../constants/dates'
import {Image} from 'react-bootstrap'

const propTypes = {
  address: shape({
    city: string.isRequired,
    country: oneOfType([object, string]).isRequired,
    created_at: number,
    default: bool,
    display: string,
    id: oneOfType([string, number]),
    latitude: number,
    line1: string.isRequired,
    line2: string,
    longitude: number,
    phone_number: string,
    postal_code: string,
    state: string.isRequired,
    updated_at: number,
  }),
  badgeCompletedColor: string,
  categoryItems: array,
  className: string,
  clickable: bool,
  createdAt: oneOfType([number, string]),
  isAbleToFavorite: bool,
  isFavorite: bool,
  mapImage: string,
  numberOfBuildings: number,
  onClick: func,
  onFavoriteClick: func,
  pmvRating: number,
  showBuildings: bool,
  title: string,
}

const defaultProps = {
  address: null,
  badgeCompletedColor: '',
  categoryItems: [],
  className: '',
  clickable: true,
  createdAt: '',
  isAbleToFavorite: true,
  isFavorite: null,
  mapImage: null,
  numberOfBuildings: null,
  onClick: () => {},
  onFavoriteClick: () => {},
  pmvRating: null,
  showBuildings: true,
  title: '',
}

const ProjectCard = props => {
  const {
    address,
    badgeCompletedColor,
    className,
    clickable,
    categoryItems,
    createdAt,
    isAbleToFavorite,
    isFavorite,
    mapImage,
    numberOfBuildings,
    onClick,
    onFavoriteClick,
    pmvRating,
    showBuildings,
    title,
  } = props

  const mapImgHeight = 110

  const Address = () => {
    let line1 = localise('headings.buildings', {numberOfBuildings})
    let line2 = address?.postal_code

    if (!showBuildings) {
      line1 =
        <>
          <div>{address?.line1}</div>
          <div>{address?.city}, {address?.state}</div>
        </>
      line2 = `${address?.country} ${address?.postal_code ? `, ${address?.postal_code}` : ''}`
    }
    return (
      <div className='font-13'>
        <div className='font-light'>{line1}</div>
        <div>{line2}</div>
      </div>
    )
  }

  return (
    <Card className={className} clickable={clickable} onClick={onClick}>
      {
        mapImage &&
        <Image
          className='object-fit-cover'
          height={mapImgHeight}
          src={mapImage}
          width='100%'
        />
      }
      <div className='ms-3 mt-1'>
        {
          isAbleToFavorite &&
          <div className='d-flex w-100 mt-3'>
            <div className='font-10 flex-grow-1'>
              {localise('projectCard.created')}
              {DateTime.fromSeconds(createdAt || 0).toFormat(EUROPEAN_DATE_FORMAT)}
            </div>
            <IconButton
              active={isFavorite}
              className='ms-2 mb-auto me-3'
              onClick={onFavoriteClick}
            />
          </div>
        }
        <div className='font-20 font-bold'>{title}</div>
        <Address/>
      </div>
      <Separator className='pt-2'/>
      <PMVRating className='ms-3 mt-1' rating={pmvRating}/>
      <Separator className='pt-2'/>
      <MMCCategories
        badgeCompletedColor={badgeCompletedColor}
        categoryItems={categoryItems?.map(({added_to_project: completed, ...rest}) => ({completed, ...rest}))}
        className='ms-3 mt-2 pb-0 pe-2'
      />
    </Card>
  )
}

ProjectCard.propTypes = propTypes
ProjectCard.defaultProps = defaultProps

export default ProjectCard

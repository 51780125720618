import React from 'react'
import {array, node, string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import Table from './Table'

const propTypes = {
  className: string,
  customNoData: node,
  grossCommercialMetrics: array,
  netCommercialMetrics: array,
}

const defaultProps = {
  className: '',
  customNoData: null,
  grossCommercialMetrics: [],
  netCommercialMetrics: [],

}

const CommercialMetricsTable = props => {
  const {
    className,
    customNoData,
    grossCommercialMetrics,
    netCommercialMetrics,
  } = props

  const handleTables = ({data, heading}) => {
    return (
      <>
        <div className='font-regular font-15 font-grey py-2 mt-2'>{heading}</div>
        <Table
          data={{
            customNoData,
            tableHeadings: [
              {
                text: localise('tables.element'),
                attributes:  {className: 'align-middle'},
              },
              {
                text: localise('tables.price'),
                attributes: {className: 'align-middle text-end pe-2'},
              },
              {
                text: (
                  <>
                    <div className='font-bold font-13'>{localise('tables.price')}</div>
                    <div className='font-regular font-12 text-lowercase'>
                      /{data?.length > 0 ? data[0]?.unit_of_measurement: ''}
                    </div>
                  </>
                ),
                attributes: {className: 'align-middle text-end pe-2'},
              },
            ],
            tableBody: data?.map(({name, price, price_per_measurement, unit_of_measurement}) => ({
              row: [
                {
                  cell: name,
                  attributes: {width: '76%', className: 'align-middle'},
                },
                {
                  cell: price?.display,
                  attributes: {className: 'text-end px-2'},
                },
                {
                  cell:
                      `${price_per_measurement?.display}
                   <span class='font-12 font-grey text-lowercase'> /${unit_of_measurement}</span>`,
                  attributes: {className: 'text-end px-2'},
                },
              ],
            })
            ),
          }}
        />
      </>
    )
  }

  return (
    <div className={className}>
      {handleTables({data: grossCommercialMetrics, heading: localise('tables.commercialMetricsGross')})}
      {handleTables({data: netCommercialMetrics, heading: localise('tables.commercialMetricsNet')})}
    </div>
  )
}

CommercialMetricsTable.propTypes = propTypes
CommercialMetricsTable.defaultProps = defaultProps

export default CommercialMetricsTable

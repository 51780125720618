import React from 'react'
import {array, node, number, string} from 'prop-types'
import {BUILDING_SETUP_ICON, CALCULATOR_WHITE_ICON, SINGLE_BUILDING_ICON} from '../../constants/svgs'
import FlowView from '../common/FlowView'
import {useHistory} from 'react-router-dom'
import {BUILDINGS_URL, CALCULATOR_URL, COMPONENTS_URL, EDIT_URL, PROJECTS_URL} from '../../constants/urls'
import {useSelector} from 'react-redux'
import {getBuildingId, getBuildingSetupComplete} from '../../store/selectors/buildingSelectors'
import {getProjectId} from '../../store/selectors/projectSelectors'
import {getSurveyProgress} from '../../store/selectors/surveySelectors'

const propTypes = {
  activeStep: number,
  breadcrumbs: array,
  children: node,
  className: string,
}

const defaultProps = {
  activeStep: 0,
  breadcrumbs: [],
  children: null,
  className: '',
}

const BuildingFlow = ({activeStep, breadcrumbs, children, className}) => {
  const {push} = useHistory()

  const buildingId = useSelector(getBuildingId)
  const projectId = useSelector(getProjectId)
  const surveyProgress = useSelector(getSurveyProgress)
  const setupComplete = useSelector(getBuildingSetupComplete)
  const basePath = `${PROJECTS_URL}/${projectId}${BUILDINGS_URL}/${buildingId}${EDIT_URL}`

  const canNav = () => buildingId && projectId
  const toSetup = () => canNav() ? push(basePath) : null
  const toComponents = () => {
    return canNav() ? push(`${basePath}${COMPONENTS_URL}`) : null
  }
  const toCalculator = () => canNav() ? push(`${basePath}${CALCULATOR_URL}`) : null

  const icons = [
    {
      clickable: setupComplete,
      onClick: toSetup,
      src: BUILDING_SETUP_ICON,
    },
    {
      clickable: setupComplete || surveyProgress === 1,
      onClick: toComponents,
      src: SINGLE_BUILDING_ICON,
    },
    {
      clickable: setupComplete && surveyProgress === 1,
      onClick: toCalculator,
      src: CALCULATOR_WHITE_ICON,
    },
  ]

  return (
    <FlowView
      activeStep={activeStep}
      breadcrumbs={breadcrumbs}
      className={className}
      icons={icons}>
      <div className='mt-2'>
        {children}
      </div>
    </FlowView>
  )
}

BuildingFlow.propTypes = propTypes
BuildingFlow.defaultProps = defaultProps

export default BuildingFlow

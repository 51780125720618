import {
  apiClient,
  oauthClient,
  GRANT_TYPE_CLIENT_CREDENTIALS,
  GRANT_TYPE_PASSWORD,
  GRANT_TYPE_AUTHORIZATION_CODE,
} from '../client'
import {clearAuth, getRefreshToken, getToken, setAuth} from '../../utilities/auth-helpers/authHelpers'
import {toast} from 'react-hot-toast'
import {localise} from '../../services/LocalizationServices'
import {
  RESET_PASSWORD_CONFIRMATION_URL,
  RESET_PASSWORD_URL,
  ROOT_URL,
  SIGN_IN_URL,
  SIGN_UP_URL,
} from '../../constants/urls'
import handleError from '../../utilities/api-helpers/handleError'

export const register = async payload => {
  const handleErr = err => {
    toast.error(err.message || localise('toast.registerError'))
    Promise.reject(err)
  }

  const {
    token,
    user,
  } = await apiClient.post(
    'users',
    {user: payload},
    {retryWithClientCredentials: true}
  ).catch(handleErr)

  if (token && user) setAuth(token)
  else return handleErr()
  return user
}

export const login = async (username, password) => {
  const handleErr = err => {
    toast.error(err?.message || localise('toast.loginError'))
    return Promise.reject(err)
  }

  const {
    token,
  } = await oauthClient.post(
    'token',
    {
      grant_type : GRANT_TYPE_PASSWORD,
      username,
      password,
    }
  ).catch(handleErr)

  if (token) setAuth(token)
  else return handleErr()
  return token
}

export const logout = async () => {
  await oauthClient.post('revoke', {token: getToken()})
  clearAuth()
}

const redirect = () => {
  if (
    ![
      SIGN_IN_URL,
      SIGN_UP_URL,
      RESET_PASSWORD_URL,
      RESET_PASSWORD_CONFIRMATION_URL,
    ].some(url => window.location.href.includes(url))
  ) {
    window.location.href = ROOT_URL
  }
}

export const refreshFailed = err => {
  clearAuth()
  redirect()
  Promise.reject(err)
}

export const refreshAuthToken = () => new Promise(resolve => {
  oauthClient.post('token', {refresh_token: getRefreshToken()})
    .then(({token}) => {
      if (token) {
        setAuth(token)
      } else {
        clearAuth()
      }
      resolve(token)
    })
    .catch(refreshFailed)
})

export const resetPassword = async email => {
  const handleErr = err => {
    toast.error(err.message || localise('toast.resetPasswordError'))
    return Promise.reject(err)
  }

  const {
    data,
  } = await apiClient.post(
    'users/passwords/reset',
    {user: {email}},
    {retryWithClientCredentials: true}
  ).catch(handleErr)

  if (!data) return handleErr()
  return data?.message
}

export const authenticate = async code => {
  const payload = code
    ? {
      grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
      code,
      redirect_uri: 'urn:ietf:wg:oauth:2.0:oob',
    }
    : {
      grant_type: GRANT_TYPE_CLIENT_CREDENTIALS,
    }

  const {token} = await oauthClient.post('token', payload).catch(handleError)
  setAuth(token, !code)
  return token
}

import React, {useEffect, useState} from 'react'
import {localise} from '../../services/LocalizationServices'
import {
  ACCOUNT_URL,
  SUBSCRIPTIONS_URL,
} from '../../constants/urls'
import BreadCrumbs from '../../components/bread-crumbs/BreadCrumbs'
import Card from '../../components/cards/Card'
import {fetchPaymentHistory} from '../../api/purchases/Purchases'
import {DateTime} from 'luxon'
import {EUROPEAN_DATE_FORMAT} from '../../constants/dates'
import {v4} from 'uuid'

const PaymentHistory = () => {
  const [payments, setPayments] = useState([])

  useEffect(() => {
    fetchPaymentHistory()
      .then(setPayments)
  }, [])

  return (
    <div className='mt-2 pb-5'>
      <BreadCrumbs
        breadcrumbItems={[
          {
            active: false,
            href: ACCOUNT_URL,
            text: localise('nav.myAccount'),
          },
          {
            active: false,
            href: `${ACCOUNT_URL}${SUBSCRIPTIONS_URL}`,
            text: localise('nav.mySubscription'),
          },
          {
            active: true,
            text: localise('headings.paymentHistory'),
          },
        ]}
      />
      {
        payments?.length > 0 &&
        <Card hideBoxShadow className='mx-auto mt-3 w-80'>
          {
            payments?.map(
              payment => payment.lines?.at(0)?.amount?.display &&
                <div key={v4()}>
                  <div className='bg-lightest-grey font-15 ps-2'>
                    {DateTime.fromSeconds(payment?.date || 0).toFormat(EUROPEAN_DATE_FORMAT)}
                  </div>
                  <div className='px-2 d-flex'>
                    <div className='w-100 py-1'>
                      <div className='font-15'>{payment?.product?.title}</div>
                      <div className='font-13 font-dark-grey'>{payment?.product?.description}</div>
                    </div>
                    <div className='text-end my-auto'>{payment?.lines?.at(0)?.amount?.display}</div>
                  </div>
                </div>
            )
          }
        </Card>
      }
      {
        payments?.length === 0 &&
        <div className='mx-auto mt-4 w-75 font-grey'>
          <div className='font-20 font-bold'>{localise('headings.paymentHistoryEmptyTitle')}</div>
          <div className='font-18'>{localise('headings.paymentHistoryEmptyDesc')}</div>
        </div>
      }
    </div>
  )
}

export default PaymentHistory

import {apiClient} from '../client'
import handleError from '../../utilities/api-helpers/handleError'
import getIndexParams from '../../utilities/api-helpers/getIndexParams'

const projectAction = async (action, url, data) => {
  const {project} = await apiClient[action](url, data).catch(handleError)
  return project
}

export const fetchProject = id => projectAction('get', `projects/${id}`)
export const createProject = project => projectAction('post', 'projects', {project})
export const updateProject = (id, project) => projectAction('put', `projects/${id}`, {project})

export const createProjectSuppliers = async (id, supplier_ids) => {
  const {suppliers, meta} = await apiClient.post(`/projects/${id}/suppliers`, {supplier_ids}).catch(handleError)
  return {suppliers, meta}
}

export const createProjectStagedSupplier = async (id, name) => {
  const {suppliers, meta} = await apiClient.post(
    `/projects/${id}/staged_suppliers`,
    {staged_supplier: {name}}
  ).catch(handleError)
  return {suppliers, meta}
}

export const fetchProjects = async opts => {
  let params = getIndexParams(opts)
  const boundingBox = opts?.boundingBox
  if (boundingBox) params = {...params, bounding_box: encodeURIComponent(JSON.stringify(boundingBox))}

  const {projects, meta: {total}} = await apiClient.get('projects', {params}).catch(handleError)
  return {projects, total}
}

export const deleteProject = async id => {
  const {destroyed} = await apiClient.delete(`projects/${id}`).catch(handleError)
  return destroyed
}

import React, {useEffect, useState} from 'react'
import FiltersBarView from '../common/FiltersBarView'
import {localise} from '../../services/LocalizationServices'
import ProjectPlaceholder from '../../components/placeholders/ProjectPlaceholder'
import {deleteProject, fetchProject, fetchProjects, updateProject} from '../../api/project/project'
import ProjectCard from '../../components/cards/ProjectCard'
import {Button, Col, Row} from 'react-bootstrap'
import {v4} from 'uuid'
import {useHistory} from 'react-router-dom'
import {PROJECTS_URL, NEW_URL, FUNDING_URL, ADDRESS_URL, PURCHASE_OPTIONS_URL} from '../../constants/urls'
import {useDispatch, useSelector} from 'react-redux'
import UnitOfMeasurementModal from '../../components/modals/UnitOfMeasurementModal'
import {clearProject, setProject} from '../../store/slices/projectSlice'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'
import Modal from '../../components/modals/Modal'
import {
  getCurrentUserDraftProjectId,
  getCurrentUserPaymentsOverviewAllowanceFreemium,
  getCurrentUserPaymentsOverviewAllowancePurchased,
  getCurrentUserPaymentsOverviewCanCreateProject,
  getCurrentUserSubscription,
} from '../../store/selectors/currentUserSelectors'
import {getUserPreferenceUnitOfMeasurementDisplay} from '../../store/selectors/userPreferenceSelectors'
import {getProjectIsFavorite} from '../../store/selectors/projectSelectors'
import {toggleLoading} from '../../utilities/LoadingIndicatorUtil'
import {setCurrentUser} from '../../store/slices/currentUserSlice'
import FreeProjectsLeftModal from '../../components/modals/FreeProjectsLeftModal'
import {fetchUser} from '../../api/user/user'
import {fetchUserPreference, updateUserPreference} from '../../api/user-preference/userPreference'
import {fetchProjectSortOpts} from '../../api/sort-options/sortOptions'
import {setUserPreference} from '../../store/slices/userPreferenceSlice'
import NoSearchResultsPlaceholder from '../../components/placeholders/NoSearchResultsPlaceholder'
import {getPaymentsActive} from '../../store/selectors/configurationSelectors'

const UNIT_OF_MEASURE_MODAL = 'UNIT_OF_MEASURE'
const DRAFT_MODAL = 'DRAFT'

const Projects = () => {
  const dispatch = useDispatch()
  const {push} = useHistory()

  const [freemiumAllowanceLeft, setFreemiumAllowanceLeft] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingLength, setLoadingLength] = useState(8)
  const [projects, setProjects] = useState([])
  const [query, setQuery] = useState('')
  const [show, setShow] = useState(null)
  const [showFreeAlertModal, setShowFreeAlertModal] = useState(false)
  const [filterOptions, setFilterOptions] = useState([])
  const [sort, setSort] = useState('')

  const draftProjectId = useSelector(getCurrentUserDraftProjectId)
  const isFavorite = useSelector(getProjectIsFavorite)
  const unitOfMeasure = useSelector(getUserPreferenceUnitOfMeasurementDisplay)
  const userPaymentOverviewAllowanceFreemium = useSelector(getCurrentUserPaymentsOverviewAllowanceFreemium)
  const userPaymentsOverviewAllowancePurchased = useSelector(getCurrentUserPaymentsOverviewAllowancePurchased)
  const userPaymentsOverviewCanCreateProject = useSelector(getCurrentUserPaymentsOverviewCanCreateProject)
  const userSubscription = useSelector(getCurrentUserSubscription)
  const paymentsActive = useSelector(getPaymentsActive)

  const loadProjects = () => {
    setLoadingLength(projects?.length <= 8 && projects?.length > 0 ? projects?.length : 8)
    setLoading(true)
    fetchProjects({sort, query})
      .then(({projects}) => setProjects(projects))
      .finally(() => setTimeout(() => setLoading(false), 500))
  }

  useEffect(() => {
    toggleLoading()
    fetchProjectSortOpts()
      .then(({sort_options}) => setFilterOptions(sort_options.map(([label, value]) => ({label, value}))))
      .finally(toggleLoading)
  }, [])

  useEffect(() => {
    loadProjects()
    fetchUser().then(user => dispatch(setCurrentUser(user)))
    fetchUserPreference().then(preference => dispatch(setUserPreference(preference)))
    // eslint-disable-next-line
  }, [sort, isFavorite, query])

  const checkIfCanCreateProject = (clear = true, section = '', fromDraftAlert = false) => {
    if (!paymentsActive) return toProjectCreate(clear, section)

    if (userPaymentsOverviewCanCreateProject) {
      if (userSubscription) {
        toProjectCreate(clear, section)
      } else if (userPaymentsOverviewAllowancePurchased.used >= userPaymentsOverviewAllowancePurchased.total) {
        if (userPaymentOverviewAllowanceFreemium.used < userPaymentOverviewAllowanceFreemium.total) {
          const freemiumAllowanceLeft =
            userPaymentOverviewAllowanceFreemium.total - userPaymentOverviewAllowanceFreemium.used

          setFreemiumAllowanceLeft(freemiumAllowanceLeft)

          if (fromDraftAlert) {
            toProjectCreate(clear, section)
          } else {
            setShowFreeAlertModal(true)
          }
        } else {
          toProjectPurchase()
        }
      } else {
        toProjectCreate(clear, section)
      }
    } else {
      toProjectPurchase()
    }
  }

  const toProjectCreate = (clear = true, section = '') => {
    if (clear) dispatch(clearProject())
    push(`${PROJECTS_URL}${NEW_URL}${section}`)
  }

  const toProjectPurchase = () => push(`${PROJECTS_URL}${PURCHASE_OPTIONS_URL}`)

  const toggleModals = () => {
    if (!unitOfMeasure) return setShow(UNIT_OF_MEASURE_MODAL)
    else if (draftProjectId) return setShow(DRAFT_MODAL)
    checkIfCanCreateProject()
  }

  const hide = () => setShow(null)
  const hideFreeProjectModal = () => setShowFreeAlertModal(false)

  const toProjectEdit = id => push(`${PROJECTS_URL}/${id}`)

  const List = () => projects?.map(project => {
    const updateFavorite = () =>
      updateProject(project?.id, {favourited: !project?.favourited})
        .then(loadProjects)

    return (
      <Col key={v4()} sm='6' md='4' lg='3'>
        <ProjectCard
          address={{
            ...project?.address,
            country: project?.address?.country?.name,
          }}
          badgeCompletedColor=''
          categoryItems={project?.mmc_categories}
          className='my-2'
          createdAt={project?.created_at}
          isAbleToFavorite
          isFavorite={project?.favourited}
          mapImage={project?.mapview_thumbnail_url}
          numberOfBuildings={project?.building_count}
          onClick={() => toProjectEdit(project?.id)}
          onFavoriteClick={updateFavorite}
          pmvRating={project?.rating}
          projectId={project?.id}
          showMapPreview
          title={project?.name}
        />
      </Col>
    )
  })

  const Loading = () => (
    _.times(loadingLength, () => (
      <Col xs='3' key={v4()}>
        <Skeleton className='my-2' height={380} />
      </Col>
    ))
  )

  const Modals = () => (
    <>
      <UnitOfMeasurementModal
        show={show === UNIT_OF_MEASURE_MODAL}
        toggle={hide}
        onCancel={hide}
        onConfirm={unit_of_measurement_id => {
          setShow(null)
          toggleLoading()
          updateUserPreference({unit_of_measurement_id})
            .then(checkIfCanCreateProject)
            .catch(() => setShow(UNIT_OF_MEASURE_MODAL))
            .finally(toggleLoading)
        }}
      />
      <Modal
        show={show === DRAFT_MODAL}
        toggle={hide}
        bodyClasses='pt-3'
        headerText={localise('modalText.draft')}
        bodyText={localise('modalText.draftExistsMessage')}
        footerClasses='pt-3'
        footerElement={<>
          <Button
            variant='outline'
            className='font-15 me-2'
            onClick={() => {
              toggleLoading()
              deleteProject(draftProjectId)
                .then(destroyed => {
                  if (destroyed) {
                    dispatch(setCurrentUser({current_draft_project_id: null}))
                    checkIfCanCreateProject()
                  }
                })
                .finally(toggleLoading)
            }}>
            {localise('buttonText.startNew')}
          </Button>
          <Button
            variant='primary'
            className='font-15'
            onClick={() => {
              toggleLoading()
              fetchProject(draftProjectId)
                .then(project => {
                  dispatch(setProject(project))
                  checkIfCanCreateProject(false, project?.address ? FUNDING_URL : ADDRESS_URL, true)
                })
                .finally(toggleLoading)
            }}>
            {localise('buttonText.continue')}
          </Button>
        </>}
      />
      <FreeProjectsLeftModal
        amount={freemiumAllowanceLeft}
        onConfirm={toProjectCreate}
        show={showFreeAlertModal}
        toggle={hideFreeProjectModal}
      />
    </>
  )

  return (
    <>
      <FiltersBarView
        buttonProps={{
          children: localise('buttonText.createProject'),
          onClick: toggleModals,
        }}
        childrenClasses='mt-3'
        dropdownOptions={{
          options: filterOptions,
          onChange: ({value}) => setSort(value),
        }}
        onSearch={setQuery}>
        <Row>
          {
            loading
              ? <Loading />
              : projects?.length > 0
                ? <List />
                : <Col xs='12'>
                  {
                    query?.length > 0 ?
                      <NoSearchResultsPlaceholder
                        name={localise('headings.projects')}
                      /> :
                      <ProjectPlaceholder/>}
                </Col>
          }
        </Row>
      </FiltersBarView>
      <Modals />
    </>
  )
}

export default Projects

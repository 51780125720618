import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {toggleLoading} from '../../../utilities/LoadingIndicatorUtil'
import {fetchLogs} from '../../../api/logs/logs'
import {useParams} from 'react-router'
import CostCertaintyLog from '../../../components/cards/logs/CostCertaintyLog'
import {localise} from '../../../services/LocalizationServices'
import ProgrammeCertaintyLog from '../../../components/cards/logs/ProgrammeCertaintyLog'
import ProductivityLog from '../../../components/cards/logs/ProductivityLog'
import WasteLog from '../../../components/cards/logs/WasteLog'
import SpeedLog from '../../../components/cards/logs/SpeedLog'
import SafetyLog from '../../../components/cards/logs/SafetyLog'
import {
  COST_CERTAINTY_URL,
  LOG_URL,
  PRODUCTIVITY_URL,
  PROGRAMME_CERTAINTY_URL,
  PROJECTS_URL,
  SAFETY_URL,
  SPEED_URL,
  WASTE_URL,
} from '../../../constants/urls'
import LogsPlaceholder from '../../../components/placeholders/LogsPlaceholder'

const ProjectLogs = ({className, show}) => {
  const {push} = useHistory()
  const {slug} = useParams()

  const [costCertaintyLogs, setCostCertaintyLogs] = useState(null)
  const [productivityLogs, setProductivityLogs] = useState(null)
  const [programmeCertaintyLogs, setProgrammeCertaintyLogs] = useState(null)
  const [safetyLogs, setSafetyLogs] = useState(null)
  const [speedLogs, setSpeedLogs] = useState(null)
  const [wasteLogs, setWasteLogs] = useState(null)

  useEffect(() => {
    toggleLoading()
    fetchLogs(slug).then(({
      current_cost_certainty_log,
      current_programme_certainty_log,
      current_productivity_log,
      current_waste_log,
      current_speed_log,
      current_safety_log,
    }) => {
      setCostCertaintyLogs(current_cost_certainty_log)
      setProgrammeCertaintyLogs(current_programme_certainty_log)
      setProductivityLogs(current_productivity_log)
      setWasteLogs(current_waste_log)
      setSpeedLogs(current_speed_log)
      setSafetyLogs(current_safety_log)
    })
      .finally(toggleLoading)
  }, [show, slug])

  if (!costCertaintyLogs && !programmeCertaintyLogs && !productivityLogs && !wasteLogs && !speedLogs && !safetyLogs) {
    return <LogsPlaceholder />
  }

  return (
    <div className={className}>
      {
        costCertaintyLogs &&
        <CostCertaintyLog
          log={costCertaintyLogs}
          onViewLogClick={() => push(`${PROJECTS_URL}/${slug}${LOG_URL}${COST_CERTAINTY_URL}`)}
          title={localise('headings.costCertainty')}
        />
      }
      {
        programmeCertaintyLogs &&
        <ProgrammeCertaintyLog
          log={programmeCertaintyLogs}
          onViewLogClick={() => push(`${PROJECTS_URL}/${slug}${LOG_URL}${PROGRAMME_CERTAINTY_URL}`)}
          title={localise('headings.programmeCertainty')}
        />
      }
      {
        productivityLogs &&
        <ProductivityLog
          log={productivityLogs}
          onViewLogClick={() => push(`${PROJECTS_URL}/${slug}${LOG_URL}${PRODUCTIVITY_URL}`)}
          title={localise('headings.productivity')}
        />
      }

      {
        wasteLogs &&
        <WasteLog
          log={wasteLogs}
          onViewLogClick={() => push(`${PROJECTS_URL}/${slug}${LOG_URL}${WASTE_URL}`)}
          title={localise('headings.waste')}
        />
      }
      {
        speedLogs &&
        <SpeedLog
          log={speedLogs}
          onViewLogClick={() => push(`${PROJECTS_URL}/${slug}${LOG_URL}${SPEED_URL}`)}
          title={localise('headings.speed')}
        />
      }
      {
        safetyLogs &&
        <SafetyLog
          log={safetyLogs}
          onViewLogClick={() => push(`${PROJECTS_URL}/${slug}${LOG_URL}${SAFETY_URL}`)}
          title={localise('headings.healthAndSafety')}
        />
      }
    </div>
  )
}

export default ProjectLogs

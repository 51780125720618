import React from 'react'
import {string} from 'prop-types'
import {BUILDING_SETUP_PLACEHOLDER} from '../../constants/svgs'
import {localise} from '../../services/LocalizationServices'
import Placeholder from './Placeholder'

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

const BuildingPlaceholder = ({className}) =>
  <Placeholder
    alt={localise('alt.buildingSetup')}
    className={className}
    description={localise('placeholder.noBuildingsCreatedDesc')}
    src={BUILDING_SETUP_PLACEHOLDER}
    title={localise('placeholder.noBuildingsCreated')}
  />

BuildingPlaceholder.propTypes = propTypes
BuildingPlaceholder.defaultProps = defaultProps

export default BuildingPlaceholder

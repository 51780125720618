import React from 'react'
import {string} from 'prop-types'
import {localise} from '../../services/LocalizationServices'
import {Button} from 'react-bootstrap'
import useSupportEmail from '../../hooks/useSupportEmail'

const propTypes = {
  className: string,
}

const defaultProps = {
  className: '',
}

const SupportButton = ({className, ...rest}) => {
  const createEmail = useSupportEmail(localise('support.subject'), [], localise('support.supportMsg'))

  return (
    <Button
      variant='primary'
      className={className}
      onClick={createEmail}
      {...rest}>
      {localise('buttonText.support')}
    </Button>
  )
}

SupportButton.propTypes = propTypes
SupportButton.defaultProps = defaultProps

export default SupportButton

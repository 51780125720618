import React, {useEffect, useState} from 'react'
import Form from '../../../../components/forms/Form'
import Skeleton from 'react-loading-skeleton'
import SupplierCard from '../../../../components/cards/SupplierCard'
import {PLACEHOLDER_LANDSCAPE} from '../../../../constants/svgs'
import {
  clearSelectedSuppliers,
  setSelectedSuppliers,
} from '../../../../store/slices/selectedSuppliersSlice'
import {fetchSuppliers} from '../../../../api/supplier/supplier'
import {string} from 'prop-types'
import {
  getAreSelectedSuppliersDirty,
  getSelectedSuppliers,
} from '../../../../store/selectors/selectedSuppliersSelectors'
import {isLoading} from '../../../../store/selectors/loadingSelectors'
import {localise} from '../../../../services/LocalizationServices'
import {toggleLoading} from '../../../../utilities/LoadingIndicatorUtil'
import {useDispatch, useSelector} from 'react-redux'
import {v4} from 'uuid'
import {Prompt} from 'react-router-dom'
import useSupplierForm from '../../../../hooks/useSupplierForm'

const propTypes = {
  className: string,
  element: string.isRequired,
}

const defaultProps = {
  className: '',
  element: null,
}

const SupplierForm = ({className, element}) => {
  const dispatch = useDispatch()
  const loading = useSelector(isLoading)
  const dirty = useSelector(getAreSelectedSuppliersDirty)

  const {setSupplierFormDirty} = useSupplierForm()
  const selectedSuppliers = useSelector(getSelectedSuppliers)
  const [suppliers, setSuppliers] = useState([])

  useEffect(() => {
    toggleLoading()
    fetchSuppliers({element})
      .then(({suppliers}) => setSuppliers(suppliers))
      .finally(toggleLoading)
  }, [element])

  useEffect(() => {
    if (suppliers?.length > 0) {
      const firstSupplier = suppliers[0]
      const {id, name} = firstSupplier
      if (id && name) dispatch(setSelectedSuppliers({id, name, element}))
    }
    // eslint-disable-next-line
  }, [element, suppliers])

  const supplierDispatched = id => !!selectedSuppliers.find(supplier => id === supplier?.id)

  return (
    <Form className={className}>
      <Prompt when={dirty} message={localise('form.validation.unsavedChangesConfirm')} />
      <div className='font-13 font-bold'>{localise('headings.selectSupplier')}</div>
      {
        loading ? <Skeleton count={1} height={190} className='mb-2' /> :
          <>
            {
              suppliers?.map(({address, branch, name, id, logos, supplier_accreditations}) =>
                <SupplierCard
                  onInputChange={async id => {
                    await setSupplierFormDirty()
                    if (supplierDispatched(id)) {
                      const existingSuppliers = selectedSuppliers.filter(supplier => supplier?.id !== id)
                      dispatch(clearSelectedSuppliers())
                      existingSuppliers.forEach(existingSupplier => dispatch(setSelectedSuppliers(existingSupplier)))
                    } else {
                      dispatch(setSelectedSuppliers({id, name, element}))
                    }
                  }}
                  addressLine1={address?.line1}
                  addressTownCity={address?.city}
                  addressCountyState={address?.state}
                  addressCountry={address?.country_code}
                  addressPostcode={address?.postal_code}
                  certificates={supplier_accreditations}
                  compactMode
                  className='mb-3'
                  clickable={false}
                  heading={name}
                  id={id}
                  isSelectionMode
                  isSelected={supplierDispatched(id)}
                  key={v4()}
                  profileImg={logos?.small_url || PLACEHOLDER_LANDSCAPE}
                  subheading={branch}
                />
              )
            }
          </>
      }
    </Form>
  )
}

SupplierForm.propTypes = propTypes
SupplierForm.defaultProps = defaultProps

export default SupplierForm

import {apiClient} from '../client'
import getIndexParams from '../../utilities/api-helpers/getIndexParams'
import handleError from '../../utilities/api-helpers/handleError'

export const fetchHelpfulLinks = async opts => {
  let params = getIndexParams(opts)
  const element = opts?.element
  if (element) params = {...params, element}

  const {helpful_links} = await apiClient.get('helpful_links', {params}).catch(handleError)
  return helpful_links
}
import React, {useEffect, useState} from 'react'
import {func} from 'prop-types'
import ConfirmForm from '../../../../components/forms/ConfirmForm'
import {localise} from '../../../../services/LocalizationServices'
import {setBuilding} from '../../../../store/slices/buildingSlice'
import {useDispatch, useSelector} from 'react-redux'
import {
  getBuildingApplyWallToFloor,
  getBuildingGrossInternalArea,
  getBuildingNetInternalArea,
  getBuildingTypeSlug,
  getBuildingWallToFloorRatio,
  getBuildingUnitCount,
  getHouseGrossNetInternalArea,
  getHouseTotalGrossInternalArea,
} from '../../../../store/selectors/buildingSelectors'
import {HOUSE_SLUG} from '../../../../constants/buildingTypes'
import Separator from '../../../../components/separator/Separator'
import {getProjectUnitOfMeasurementName} from '../../../../store/selectors/projectSelectors'

const propTypes = {
  onSubmit: func,
}

const defaultProps = {
  onSubmit: () => {},
}

const AreaForm = ({onSubmit}) => {
  const dispatch = useDispatch()

  const [fields, setFields] = useState([])
  const [totalGrossInternalArea, setTotalGrossInternalArea] = useState(useSelector(getHouseTotalGrossInternalArea))

  const applyWallToFloor = useSelector(getBuildingApplyWallToFloor) || false
  const grossInternalArea = useSelector(getBuildingGrossInternalArea) || ''
  const grossNetInternalArea = useSelector(getHouseGrossNetInternalArea) || ''
  const netInternalArea = useSelector(getBuildingNetInternalArea) || ''
  const numberOfUnits = useSelector(getBuildingUnitCount) || ''
  const type = useSelector(getBuildingTypeSlug)
  const unitOfMeasurementLabel = `(${useSelector(getProjectUnitOfMeasurementName)})` || ''
  const wallToFloorRatio = useSelector(getBuildingWallToFloorRatio) || null

  const IS_HOUSE_TYPE = type === HOUSE_SLUG

  useEffect(() => {
    const unconditionalFields = [
      {
        type: 'number',
        className: IS_HOUSE_TYPE && 'd-none',
        handler: gross_internal_area => dispatch(setBuilding({gross_internal_area})),
        label: `${localise('form.label.grossInternalArea')} ${unitOfMeasurementLabel}`,
        min: 0,
        placeholder: localise('form.label.grossInternalArea'),
        required: !IS_HOUSE_TYPE,
        validationMessage: localise('form.validation.grossInternalArea'),
        value: grossInternalArea,
      },
      {
        type: 'number',
        className: IS_HOUSE_TYPE && 'd-none',
        handler: net_internal_area => dispatch(setBuilding({net_internal_area})),
        label: `${localise('form.label.residentialNetInternalArea')} ${unitOfMeasurementLabel}`,
        min: 0,
        placeholder: localise('form.label.residentialNetInternalArea'),
        required: !IS_HOUSE_TYPE,
        validationMessage: localise('form.validation.residentialNetInternalArea'),
        value: netInternalArea,
      },
      {
        type: 'number',
        className: !IS_HOUSE_TYPE ? 'd-none' : 'mb-2',
        handler: house_gia_nia => {
          setTotalGrossInternalArea(house_gia_nia * numberOfUnits)
          dispatch(setBuilding({house_gia_nia}))
        },
        label:
          `${localise('form.label.houseGrossInternalArea')} / ${localise('buildingCard.netInternalArea')} 
          ${unitOfMeasurementLabel}`,
        min: 0,
        placeholder: `${localise('form.label.houseGrossInternalArea')} / ${localise('buildingCard.netInternalArea')}`,
        required: IS_HOUSE_TYPE,
        validationMessage: localise('form.validation.grossInternalArea'),
        value: grossNetInternalArea,
      },
      {
        type: 'number',
        additionalLabelClasses: 'mb-0',
        className: !IS_HOUSE_TYPE || !totalGrossInternalArea ? 'd-none' : 'border-0 ps-0',
        label: `${localise('form.label.totalGrossInternalArea')} ${unitOfMeasurementLabel}`,
        readOnly: true,
        value: totalGrossInternalArea,
      },
    ]

    const conditionalFields = [
      {
        type: 'custom',
        render: <Separator className='my-2' />,
      },
      {
        type: 'radio-group',
        hideValidUI: true,
        name: 'unit-mix',
        label: localise('form.label.applyWallToFloorRatio'),
        radios: [
          {
            label: localise('form.label.yes'),
            checked: applyWallToFloor,
            onChange: () => dispatch(setBuilding({applyWallToFloor: true})),
          },
          {
            label: localise('form.label.no'),
            checked: !applyWallToFloor,
            onChange: () => dispatch(setBuilding({applyWallToFloor: false})),
          },
        ],
      },
      {
        type: 'number',
        className: applyWallToFloor ? 'd-block' : 'd-none',
        handler: wall_to_floor_ratio_percentage => dispatch(setBuilding({wall_to_floor_ratio_percentage})),
        label: `${localise('form.label.wallToFloorRatio')} (%) `,
        max: 100,
        min: 0,
        placeholder: localise('form.label.wallToFloorRatio'),
        required: applyWallToFloor,
        validationMessage: localise('form.validation.wallToFloorRatio'),
        value: wallToFloorRatio,
      },
    ]

    setFields(
      IS_HOUSE_TYPE ?
        unconditionalFields
        : [...unconditionalFields, ...conditionalFields]
    )
    // eslint-disable-next-line
  }, [
    applyWallToFloor,
    dispatch,
    grossInternalArea,
    grossNetInternalArea,
    netInternalArea,
    type,
    wallToFloorRatio,
  ])

  const submitFormData = () => {
    onSubmit({
      gross_internal_area: grossInternalArea,
      house_gia_nia: Number(grossNetInternalArea),
      net_internal_area: netInternalArea,
      wall_to_floor_ratio_percentage: applyWallToFloor ? wallToFloorRatio : null,
    })
  }

  const submittable = IS_HOUSE_TYPE ? !!grossNetInternalArea :
    !!grossInternalArea &&
    !!netInternalArea &&
    !!(!applyWallToFloor || (applyWallToFloor && wallToFloorRatio))

  return <ConfirmForm fields={fields} onSubmit={submitFormData} submittable={submittable} />
}

AreaForm.propTypes = propTypes
AreaForm.defaultProps = defaultProps

export default AreaForm

import React from 'react'
import {object, string} from 'prop-types'

const propTypes = {
  alt: string,
  className: string,
  description: string,
  src: string,
  style: object,
  title: string,
}

const defaultProps = {
  alt: '',
  className: '',
  description: '',
  src: '',
  style: {},
  title: '',
}

const Placeholder = ({alt, className, description, src, style, title}) =>
  <div className={`border border-grey pt-1 pb-2 ${className}`} style={style}>
    <div className='ms-3'>
      <div className='font-20 font-bold mt-2 font-grey'>{title}</div>
      <div className='font-13 font-grey'>{description}</div>
      {src && <img className='mt-2' src={src} alt={alt}/>}
    </div>
  </div>

Placeholder.propTypes = propTypes
Placeholder.defaultProps = defaultProps

export default Placeholder

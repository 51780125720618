import React from 'react'
import {func} from 'prop-types'
import {useSelector} from 'react-redux'
import {getBuildingTypeSlug} from '../../../../store/selectors/buildingSelectors'
import HouseForm from './HouseForm'
import UnitsForm from './UnitsForm'
import {HOUSE_SLUG} from '../../../../constants/buildingTypes'

const propTypes = {
  onHouseSubmit: func,
  onSubmit: func,
}

const defaultProps = {
  onHouseSubmit: () => {},
  onSubmit: () => {},
}

const UnitsBedrooms = ({onSubmit, onHouseSubmit}) => {
  const typeSlug = useSelector(getBuildingTypeSlug)

  return typeSlug === HOUSE_SLUG
    ? <HouseForm onSubmit={onHouseSubmit} />
    : <UnitsForm onSubmit={onSubmit} />
}

UnitsBedrooms.propTypes = propTypes
UnitsBedrooms.defaultProps = defaultProps

export default UnitsBedrooms

import React, {useEffect, useState} from 'react'
import BreadCrumbsView from '../../common/BreadcrumbsView'
import {PAYMENT_URL, PROJECTS_URL, PURCHASE_OPTIONS_URL} from '../../../constants/urls'
import {localise} from '../../../services/LocalizationServices'
import {Button, Col, Row} from 'react-bootstrap'
import {useHistory} from 'react-router'
import {fetchProducts} from '../../../api/products/Products'
import styled from 'styled-components'
import {v4} from 'uuid'
import {SUBSCRIPTION} from '../../../constants/BillingTypes'

const BannerBgDiv = styled.div`
  background-color: ${({bgColor}) => bgColor};
`

const PurchaseBtn = styled(Button)`
  background-color: ${({fillColor}) => fillColor} !important;
  border-color: transparent !important;
  border-radius: 0;
  color: #ffffff;
  padding: .4rem .6rem;
  text-transform: capitalize;
  transition: all .2s;

  :active,
  :hover:not([disabled]) {
    background-color: #ffffff !important;
    border-color: ${({fillColor}) => fillColor} !important;
    box-shadow: none;
    color: ${({fillColor}) => fillColor} !important;
  }
`

const PurchaseOptions = () => {
  const {push} = useHistory()

  const [products, setProducts] = useState([])

  const toPayment = product => push(`${PROJECTS_URL}${PURCHASE_OPTIONS_URL}${PAYMENT_URL}`,{product, isUpdating: false})

  useEffect(() => {
    fetchProducts().then(({products}) => setProducts(products))
  }, [])

  const TickSVG = props => (
    <svg width={24} height={24} xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <circle fill={props.fillColor} cx={12} cy={12} r={9} />
        <path fill='#FFF' d='M10.571 15.871 7 12.3l1.007-1.007 2.564 2.557 5.422-5.421L17 9.443z'/>
      </g>
    </svg>
  )

  const createTickDiv = (fillColor, option) => (
    <div key={v4()}>
      <div className='d-flex mb-3'>
        <div className='align-self-baseline'>
          <TickSVG fillColor={fillColor}/>
        </div>
        <div className='ms-2 font-13'>
          <div className='font-13'>{option?.title}</div>
          <div className='font-light'>{option?.description}</div>
        </div>
      </div>
    </div>
  )

  const SubscriptionItem = ({product}) => (
    <div className='box-shadow border-light-grey h-100 d-flex flex-column'>
      <BannerBgDiv bgColor={product?.highlight_color} className='font-white'>
        <div className='font-white ms-3 py-3'>
          <div className='font-bold font-20'>{product?.title}</div>
          <div className='font-light d-flex'>
            <div className='font-17'>{product?.price.display}</div>
            {
              product?.billing_type === SUBSCRIPTION &&
              <div className='font-10 ms-1 mb-1 mt-auto'>{localise('subscription.billedAnnually')}</div>
            }
          </div>
          <div className='font-13'>{localise('subscription.perProject')}</div>
        </div>
      </BannerBgDiv>
      {
        product?.options_heading !== null &&
        <div className='bg-medium-grey py-2'>
          <div className='font-bold font-13 ms-3'>{product?.options_heading}</div>
        </div>
      }
      <div className='m-2 d-flex flex-column flex-grow-1'>
        {
          product?.options.map(option => createTickDiv(product?.highlight_color, option))
        }
        <PurchaseBtn
          className='font-15 w-100 mb-2 py-2 mt-auto'
          fillColor={product?.highlight_color}
          onClick={() => toPayment(product)}>
          {product?.button_cta}
        </PurchaseBtn>
      </div>
    </div>
  )

  return (
    <>
      <BreadCrumbsView
        breadcrumbItems={[
          {
            active: false,
            href: PROJECTS_URL,
            text: localise('nav.projects'),
          },
          {
            active: true,
            text: localise('headings.purchaseOptions'),
          },
        ]}
      />
      <Row className='justify-content-center'>
        <Col xs={10} lg={{offset: 2}} xxl={{offset: 4}}>
          <div className='font-bold mb-2'>
            <div className='font-20'>{localise('headings.purchaseOptions')}</div>
            <div className='font-13'>{localise('headings.selectPreferredPurchaseOption')}</div>
          </div>
        </Col>
        {
          products?.map(product => (
            <Col key={v4()} xs={10} md={5} lg={4} xxl={3} className='pb-3'>
              <SubscriptionItem product={product}/>
            </Col>
          ))
        }
      </Row>
    </>
  )
}

export default PurchaseOptions

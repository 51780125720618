import React from 'react'
import {bool, func, number, oneOfType, string} from 'prop-types'
import Card from './Card'
import Separator from '../separator/Separator'
import {localise} from '../../services/LocalizationServices'
import {BRAND_PRIMARY} from '../../constants/colours'
import styled from 'styled-components'
import {useSelector} from 'react-redux'
import {HOUSE_SLUG} from '../../constants/buildingTypes'
import {getProjectUnitOfMeasurementName} from '../../store/selectors/projectSelectors'

const propTypes = {
  bannerColor: string,
  buildingType: string,
  className: string,
  clickable: bool,
  grossInternalArea: oneOfType([string, number]),
  houseGiaNia: number,
  netInternalArea: oneOfType([string, number]),
  onClick: func,
  phaseCountDisplay: string,
  phaseDisplay: string,
  sector: string,
  statusDisplay: string,
  stepCount: string,
  title: string,
  totalGrossInternalArea: oneOfType([string, number]),
  units: number,
}

const defaultProps = {
  bannerColor: BRAND_PRIMARY,
  buildingType: '',
  className: '',
  clickable: false,
  grossInternalArea: 0,
  houseGiaNia: null,
  netInternalArea: 0,
  onClick: null,
  phaseCountDisplay: null,
  phaseDisplay: null,
  sector: '',
  statusDisplay: null,
  stepCount: null,
  title: '',
  totalGrossInternalArea: '',
  units: 0,
}

const BuildingTitleDiv = styled.div`
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis
`

const BuildingCard = props => {
  const {
    bannerColor,
    buildingType,
    className,
    clickable,
    grossInternalArea,
    netInternalArea,
    onClick,
    houseGiaNia,
    phaseDisplay,
    phaseCountDisplay,
    sector,
    statusDisplay,
    stepCount,
    title,
    totalGrossInternalArea,
    units,
  } = props

  const unitOfMeasurementDisplay = useSelector(getProjectUnitOfMeasurementName)

  const AreaRow = ({className, areaLabelClass, areaLabel, areaNumLabel, areaNumLabelClass}) =>
    <div className={`mt-1 ${className}`}>
      <div className={`px-3 pt-1 font-10 ${areaLabelClass}`}>{areaLabel}</div>
      <div className='px-3 pb-1 d-flex'>
        <div className={`me-1 font-13 font-bold ${areaNumLabelClass}`}>{areaNumLabel}</div>
        <div className={`my-auto font-10 ${areaNumLabelClass}`}>{unitOfMeasurementDisplay}</div>
      </div>
      <Separator/>
    </div>

  return (
    <Card className={className} clickable={clickable} onClick={onClick}>
      <div className='px-3 font-13'>
        <BuildingTitleDiv className='pt-3 font-20 font-bold'>{title}</BuildingTitleDiv>
        {units && <div className='font-light'>{units} {localise('buildingCard.units')}</div>}
        <div className='pt-1'>{buildingType}</div>
        <div className='mt-2 font-bold'>{sector}</div>
      </div>
      <Separator className='pt-2' />
      {
        buildingType?.toLowerCase() === HOUSE_SLUG?.toLowerCase() ?
          <>
            <AreaRow
              areaLabel={`${localise('form.label.houseGrossInternalArea')} / 
              ${localise('buildingCard.netInternalArea')}`}
              areaNumLabel={houseGiaNia || 0}
            />
            <AreaRow
              areaLabel={localise('form.label.totalGrossInternalArea')}
              areaNumLabel={totalGrossInternalArea || 0}
            />
          </> :
          <>
            <AreaRow areaLabel={localise('buildingCard.grossInternalArea')} areaNumLabel={grossInternalArea || 0}/>
            <AreaRow areaLabel={localise('buildingCard.netInternalArea')} areaNumLabel={netInternalArea || 0}/>
          </>
      }
      <div className='px-3 py-1 font-white font-12' style={{backgroundColor: bannerColor}}>
        <div className='d-flex'>
          <div className='font-bold'>{phaseDisplay}</div>
          <div className='ms-auto font-white'>{phaseCountDisplay}</div>
        </div>
      </div>
      <div className='px-3 py-1'>
        <div className='font-10'>{stepCount}</div>
        <div className='font-13 font-bold'>{statusDisplay}</div>
      </div>
    </Card>
  )
}

BuildingCard.propTypes = propTypes
BuildingCard.defaultProps = defaultProps

export default BuildingCard
